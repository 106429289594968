
import BaseExtend from "@/components/Base/Mixin/BaseExtend";

export default {
  name: "BaseFieldStatus",
  components: {},
  mixins: [ BaseExtend ],
  data() {
    return {
      soptions: null,
      needFocusedToNextField: true
    };
  },
  methods: {
    cleanModel() {
      if (this.isReadonly) {
        return false;
      }
      this.model = '';
    },
  },
  computed: {
  },
};
