import useModulesStore from "@/components/Modules/Store/ModuleStore";
import Column from "../../Models/Column";
import {FieldType} from "../../Models/FieldType";
import { StatusField } from '../../Models/FieldTypes/StatusField';
import { StatusFieldValue } from "../../Models/FieldValueTypes/StatusFieldValue";
import store from "@/store";
import { ValueGetterParams } from "ag-grid-community";

export default class StatusFieldImpl implements StatusField {
    name: string;
    ID: string;
    column: number;
    order: number;
    mandatory: boolean | null | undefined;
    section: string;
    disabled: boolean | null | undefined;
    readOnly: boolean | null | undefined;
    hidden: boolean | null | undefined;
    metaData: any;
    type: FieldType;
    label: string;
    defaultValue: StatusFieldValue
    expression: string | null | undefined;

    constructor(
        field: StatusField
    ) {
        this.name = field.name
        this.ID = field.ID
        this.column = field.column
        this.order = field.order
        this.mandatory = field.mandatory
        this.section = field.section
        this.disabled = false
        this.readOnly = field.readOnly
        this.hidden = field.hidden
        this.metaData = field.metaData
        this.type = field.type
        this.label = field.label
        this.defaultValue = field.defaultValue
        this.expression = field.expression
    }

    canAggregate(): false {
        return false;
    }

    getColumn(column: any, moduleID: string): any {
        const baseColumn = new Column().getBaseColumn()
        const additionalProperties = {
            field: column.name,
            headerName: this.getLabel(moduleID),
            editable: !this.disabled && !this.readOnly && !this.hidden ? true : false,
            cellRenderer: 'baseFieldRenderer',
            cellEditor: 'statusFieldEditor',
            cellEditorParams: {
                values: this.getDropdownValues(column.name, moduleID),
                valueGetter: (params: ValueGetterParams) => {
                    const translationKey = `${moduleID}.dropDowns.${this.name}.${params.data[this.name]}`
                    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                      return store.state.fieldTranslations[translationKey]
                    }
                    return params.data[this.name]
                }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                values: this.getDropdownValues(column.name, moduleID)
            }
        }
        return {
            ...baseColumn,
            ...additionalProperties
        }
    }

    getLabel(moduleID: string) {
        if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
            return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
          }
        return this.label
    }

    getDropdownValues(columnName: string, moduleID: string): string[] {
        const moduleStore = useModulesStore()
        const dropdownValues = moduleStore.modules[moduleID].dropDownValues[columnName]
        for(const index in dropdownValues) {
            const translationKey = `${moduleID}.dropDowns.${this.name}.${dropdownValues[index]}`
            if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
              dropdownValues[index] = store.state.fieldTranslations[translationKey]
            }
        }
        if(dropdownValues) dropdownValues.push("")
        return dropdownValues
    }

    getFormattedValue(value: any, moduleID?: string) {
        const translationKey = `${moduleID}.dropDowns.${this.name}.${value}`
        if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
          return store.state.fieldTranslations[translationKey]
        }
        return value
    }

    getUnformattedValue(value: any) {
        return value
    }

    getFieldType(): FieldType {
        return FieldType.DROPDOWN
    }

}
