
import { Component, Vue, Prop } from "vue-property-decorator";
import draggable from "vuedraggable";
import { State } from "vuex-class";
import sortList from "@/common/helpers/utilities";
@Component({
  components: { draggable },
})
export default class RoboticProcessDetailsDrawer extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) readonly DetailType!: string;
  @Prop({ required: true }) readonly DetailTypeConditions!: string;
  @Prop({ required: true }) module!: string;
  @Prop({ required: true }) roPro;

  detailsTab = "detailsTrigger";
  filter = ""
  options: Array<string> = [];
  fieldsList: Array<string> = this.options
  // When loading in the page 
  async mounted() {
    for (const field in this.dynamicModules[this.module].fields) {
      this.options.push(this.dynamicModules[this.module].fields[field]);
    }
    this.options = sortList(this.options, 'label');
    this.filterAvailableFields()
  }

  checkFirstCharacters() {
    for(const field in this.roPro.fields) {
      
      
      const firstCharacters = this.roPro.fields[field].substring(0, 2)
      
      
      if(firstCharacters == "__") {
        return false
      } else {
        return true
      }        
    }  
}

  // Filter the list on alphabetic order
  filterOptions (val) {
    if (val !== "") {
      this.fieldsList = []
      for(const field in this.options) {   
        if(!this.roPro.fields.includes(this.options[field]["name"])) {
          if(this.options[field]["label"].toLowerCase().includes(val.toLowerCase())) {
            this.fieldsList.push(this.options[field])
          }
        }        
      }
      this.fieldsList = sortList(this.fieldsList, 'label');
      return this.fieldsList
    } else {
      this.fieldsList = []
      for(const field in this.options) {   
        if(!this.roPro.fields.includes(this.options[field]["name"])) {
            this.fieldsList.push(this.options[field])
        }        
      }
      this.fieldsList = sortList(this.fieldsList, "label")
      return this.fieldsList
    }
  }
  // Function to select label field
  onSelectLabelField(selectedField, index) {
    if (selectedField.name == this.roPro.fields) {
      this.fieldsList =  sortList(this.fieldsList, 'label');
    } else {
      this.roPro.fields.push(selectedField.name);
      this.fieldsList.splice(index, 1);
    }
  }
  // Function To delete the selected Action
  async deleteSelectedField(index, selectedField) {  
    this.filter = ''  
    this.roPro.fields.splice(index, 1);
    if(!this.options.includes(this.dynamicModules[this.module].fields[selectedField])) {
      this.options.push(
        this.dynamicModules[this.module].fields[selectedField]
      );
    }
    this.filterAvailableFields()
    this.options =  sortList(this.options, 'label');
  }

// Filter available fields
  filterAvailableFields() {
    this.fieldsList = []
    for(const field in this.options) {   
      if(!this.roPro.fields.includes(this.options[field]["name"])) {
          this.fieldsList.push(this.options[field])
      }        
    }
  }
}
