
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import Table from "@/components/Settings/Actions/table.vue";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";
import ListValues from "@/components/Mixin/ListValues";
import sortList from "@/common/helpers/utilities";
import GetRelatedFieldValue from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/GetRelatedFieldValue"

class NewFieldUpdateClass {
  selectedField = {
    label: "",
    value: "",
    type: "",
  };
  typeOfValueField = "";
  dataTypeOfSelectedField = "";
  enterValue = "";
  from = "";
  to = "";
  showValueField = false;
  dateFilterStartCheckBox = false;
  dateFilterEndCheckBox = false;
}

@Component({
  components: { Table, DynamicFieldSingle },
})
export default class FieldUpdate extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) module;
  @Prop() parentToChild;
  @Prop() parentModule;
  @Prop() existingFieldUpdates;
  @Prop() openExistingAction;
  fieldUpdate = new NewFieldUpdateClass();
  fieldOptions = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
    index: number;
  }>();
  fieldsToUpdate = Array<{}>();
  options = this.fieldOptions;
  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };
  parentmodule!: any 
  childmodule!: any 
  propModule!: any 

  async mounted() {    
    this.propModule = this.module
    this.parentmodule = this.parentModule
    this.childmodule = this.module
    if(this.openExistingAction == true) {
      this.fieldsToUpdate = this.existingFieldUpdates
    }
    this.getAvailableFields()
  }

    // Function to clear table when one of the modules is changed
  beforeUpdate() {
    if(!this.parentToChild) {      
      if(this.module != this.childmodule || this.parentModule != this.parentmodule) {        
        this.fieldOptions = []
        this.fieldsToUpdate = []
        this.getAvailableFields()
        this.parentmodule = this.parentModule
        this.childmodule = this.module
      }
    } else {      
      if(this.module != this.parentmodule || this.module != this.childmodule) {
        this.fieldOptions = []
        this.fieldsToUpdate = []
        this.getAvailableFields()
        this.parentmodule = this.module
        this.childmodule = this.module
      }
    }
  }

  getAvailableFields() {
    const fields = this.dynamicModules[this.module.name].fields;
      const listNames: any = []
      for(const existingFieldUpdate in this.existingFieldUpdates) {        
        listNames.push(this.existingFieldUpdates[existingFieldUpdate].field)
      }
        for(const field in fields) {
          if(!listNames.includes(fields[field].name)) {
            this.fieldOptions.push({
              label: fields[field].label,
              value: fields[field].name,
              type: fields[field].type,
              fieldType: fields[field].fieldType,
              index: 0,
            });
          }
      }
    let i = 0;
    this.fieldOptions = sortList(this.fieldOptions, 'label');
    for (const field in this.fieldOptions) {
      this.fieldOptions[field].index = i;
      i++;
    }
  }

  // Function to add the field update to the table
  async addFieldToFieldsToUpdate(selectedField, index) {
    if (selectedField == this.fieldsToUpdate["field"]) {
      this.fieldOptions = sortList(this.fieldOptions, 'label');
    } else {
      let fieldMap = {};
      if(this.fieldUpdate.typeOfValueField != "relatedField") {
        fieldMap = {
          field: this.fieldUpdate.selectedField.value,
          value: this.fieldUpdate.enterValue,
          type: this.fieldUpdate.typeOfValueField,
        };
      } else {
        fieldMap = {
          field: this.fieldUpdate.selectedField.value.split(".")[0],
          value: this.fieldUpdate.enterValue["ID"],
          type: this.fieldUpdate.typeOfValueField,
        };
        fieldMap['name']= await GetRelatedFieldValue(fieldMap["field"], fieldMap["value"], this.dynamicModules, this.propModule.name)
      }    
      this.fieldsToUpdate.push(fieldMap);
      this.fieldUpdate.selectedField = { label: "", value: "", type: "" };
      this.fieldUpdate.enterValue = "";
      let i = 0;
      this.fieldOptions = sortList(this.fieldOptions, 'label');
      for (const field in this.fieldOptions) {
        this.fieldOptions[field].index = i;
        i++;
      }
      this.fieldOptions.splice(index, 1);
      this.sendFieldUpdate();
    }
  }

  // Function to get the data of the selected field
  onFieldSelect(selectedField) {
    this.fieldUpdate.selectedField = Object.assign({}, selectedField);
    if (this.fieldUpdate.selectedField.type === "relatedField") {
      this.fieldUpdate.selectedField.value =
        this.fieldUpdate.selectedField.value + ".ID";
    }
    this.fieldUpdate.typeOfValueField = this.getFieldType(
      this.fieldUpdate.selectedField
    );
    this.fieldUpdate.dataTypeOfSelectedField = this.objectOfType[
      this.fieldUpdate.typeOfValueField
    ];
    this.fieldUpdate.showValueField = true;
  }

  // Function to get the type of the selected field
  getFieldType(field) {
    let result = field.type;
    if (field.type === "autoNumber") result = "text";
    if (field.type === "calculatedField")
      result = ListValues.getRealFieldType(field);
    if (!result) 
    return result;
  }

  // Filter function for the available fields
  filterFieldOptions(val, update) {
    if (val === "") {
      this.options = this.fieldOptions;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.fieldOptions.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  // Emit function to return the fieldsToUpdate
  @Emit("fieldUpdateParent")
  sendFieldUpdate() {
    return this.fieldsToUpdate;
  }
}
