
    import {mapGetters} from "vuex";
    import { Component, Vue } from "vue-property-decorator";
    import { State } from "vuex-class";
    import db from "@/db";
    import store from "@/store";
    import ActionInfo from "@/components/Parts/ActionInfo.vue";


    @Component({
        components: {ActionInfo},
    })

    export default class RolesEdit extends Vue {
        @State("dynamicModules") dynamicModules;
        messageOk = false
        messageError = false
        modules: any = []
        roleName = ""
        pdfTemplates = false
        defaultColumns = false
        defaultPlanboard = false
        email = false
        emailTemplates = false
        importData = false
        organization = false
        reportTemplates = false
        currentUser: any = {}
        dataRoles: any = []
        ACL: any = []
        ACL_Options: any = [{label: "Admin", value: "admin"}, {label: "Super admin", value: "superAdmin"}]
        ACL_USER: any = []
        resetEmailDialog = false
        resetPasswordDialog = false
        profilePicture = null
        dashboards = []
        dashboardsByUserID = []
        availableDashboards = {}
        dashboardConfig = {}
        dashboardConfigVisible = false
        selectedRole = null
        isTenantAdminUser = false
        async mounted() {
          const tenant = await this.getTenantByID(store.state.tenantID)
          if(store.state.currentUser.ID === tenant.tenantAdminUserID) this.isTenantAdminUser = true
          await this.getUser();
          await this.getRoles();
          await this.getACL();
          if(this.isTenantAdminUser) {
            await this.getCumulioDashboards()
            await this.getCumulioDashboardsByUserID()
          }
        }

        computed() {
        mapGetters(["translate"])
        }

        async getTenantByID(tenantID: string) {
            return await db.doc(`tenants/${tenantID}`).get().then((doc) => {
              if(doc.exists) return doc.data()
              return null
            }).catch((err) => {
              console.log('Error while getting tenant by ID:', err)
              return err
            })
        }

        async getUser() {
            const users = await db.collection(`tenants/${store.state.tenantID}/modules/users/records`).doc(this.$route.params.id).get()
            const usersDoc = users.data();
            this.currentUser = usersDoc
            if(!this.currentUser.roles || !this.currentUser.roles.length) this.currentUser.roles = []
            else this.selectedRole = this.currentUser.roles[0]
        }
        
        async getRoles() {
            this.dataRoles = []
            const users = await db.collection(`tenants/${store.state.tenantID}/roles`).get()
            users.forEach(doc => {
                const usersDoc = doc.data();
                this.dataRoles.push({ID: `${usersDoc.ID}`, name: usersDoc.name || usersDoc.ID});
            })            
        }

        async getACL() {
          const ACL = await db.collection(`users`).doc(this.$route.params.id).get()
          const data = ACL.data()
          this.ACL_USER = data
          if(!this.ACL_USER || this.ACL_USER.ACL == undefined) {
            this.ACL = []
          } else {
            this.ACL = this.ACL_USER.ACL
          }
        }

        async saveUser() {            
            if(this.currentUser.roles.length > 0) {            
                db.collection(`tenants/${store.state.tenantID}/modules/users/records/`).doc(this.$route.params.id)
                  .update(Object.assign({}, this.currentUser))
                  .then(() => {
                    this.$router.push({path: "/settings/user-configuration"});
                  })
                  .catch(() => {
                  this.messageError = true;
                  setTimeout(() => this.messageError = false, 5000);
                  });
                this.$q.notify({ message: `${this.$t('common.messages.recordChanged')}`, color: 'positive'})
            } else {
                this.$q.notify({ message: `${this.$t('common.messages.operationNotValidated')}`, color: 'negative', icon: 'warning'})
            }
        }

        async saveACL() {
          this.ACL_USER.ACL = this.ACL
          await db.collection(`users`).doc(this.$route.params.id)
            .update(Object.assign({}, this.ACL_USER))
            .then(() => {
              this.saveUser()
            })
            .catch(() => {
            this.messageError = true;
            setTimeout(() => this.messageError = false, 5000);
            });
        }

        updateUserRole() {
          this.currentUser.roles[0] = this.selectedRole
        }

        onClickResetEmailDialog() {
            this.resetEmailDialog = true
        }

        onCancelResetEmailDialog() {
            this.resetEmailDialog = false
        }

        onClickResetPasswordDialog() {
            this.resetPasswordDialog = true
        }

        onCancelResetPasswordDialog() {
            this.resetPasswordDialog = false
        }

        onBack() {
          this.$router.push({path: "/settings/user-configuration"});
        }

        checkIfDashboardActive(dashboardID) {
          if(this.availableDashboards[dashboardID]) {
            return true
          } else { 
            return false
          }
        }
        async getCumulioDashboards() {
          await this.$store.dispatch('api/getCumulioDashboards').then((response) => {
              this.dashboards = response.data.rows
          }).catch(err => {
              console.log(err, "error");
              this.$q.notify({ message: `Fout bij het ophalen van de dashboards.`, color: 'negative'})
          });
          for(const d in this.dashboards) {
            const dashboard = this.dashboards[d];
            dashboard.label = dashboard.name.nl
            // this.dashboards[dashboard].visible = true
            this.availableDashboards[dashboard.id] = false
          }
        }

        async getCumulioDashboardsByUserID() {
          const userData = {
            userID: this.currentUser.ID
          } 
          await this.$store.dispatch('api/getCumulioDashboardsByUserID', userData).then((response) => {
              this.dashboardsByUserID = response.data.rows
          }).catch(err => {
              console.log(err, "error");
              this.$q.notify({ message: `Fout bij het ophalen van de dashboards.`, color: 'negative'})
          });
          const dashboardIDs = []
          for(const d in this.dashboardsByUserID) {
            const dashboard = this.dashboardsByUserID[d];
            dashboardIDs.push(dashboard.id)            
          }
          
          for(const dashboard of this.dashboards) {
            dashboard.label = dashboard.name.nl
            if(dashboardIDs.includes(dashboard.id)) {
              this.availableDashboards[dashboard.id] = true
            } else {
              // this.dashboards[dashboard].visible = true
              this.availableDashboards[dashboard.id] = false
            }
          }
          this.dashboardConfigVisible = true
        }

        async associateDashboardToUser(dashboard) {
          const request = {
            userID: this.currentUser.ID,
            dashboardID: dashboard.id
          }
          await this.$store.dispatch('api/associateDashboardToUser', request).then(() => {}).catch(err => {
              console.log(err, "error");
              this.$q.notify({ message: `Fout bij het toegang geven tot het dashboard.`, color: 'negative'})
          });
        }

        async dissociateDashboardFromUser(dashboard) {
          const request = {
            userID: this.currentUser.ID,
            dashboardID: dashboard.id
          }
          await this.$store.dispatch('api/dissociateDashboardToUser', request).then(() => {}).catch(err => {
              console.log(err, "error");
              this.$q.notify({ message: `Fout bij het ontzeggen van de toegang tot het dashboard.`, color: 'negative'})
          });
        }

        async makeDashboardInvisible(dashboard) {
          this.dashboardConfigVisible = false
          this.availableDashboards[dashboard.id] = false
          await this.dissociateDashboardFromUser(dashboard)
          this.dashboardConfigVisible = true
        }
        async makeDashboardVisible(dashboard) {
          this.dashboardConfigVisible = false
          this.availableDashboards[dashboard.id] = true
          await this.associateDashboardToUser(dashboard)
          this.dashboardConfigVisible = true
        }
    }
