import db from "../db";
import store from "../store";
import Vue from "vue";
import ListValues from "@/components/Mixin/ListValues";
import SelectModel from "../components/Models/SelectModel";
import { TableConstraints } from "@/components/Table/Constraints/TableConstraints";

const state = {
  listSettings: {},
  currentListPagination: {}, // stored the current list views settings
  planboardViewSettings: [],
  planboardDefaultViewID: '',
  userEmailSettings: null,
  userFormsSettings: {},
  tenantDefaultColumns: {},
  tenantDefaultAlwaysShown: {},
  planboardSchedulerSettings: {},
  planboardListSettings: {},
  sharedFilters: 'shared-filters',
  sharedViews: 'shared-views',
  designSettings: {
    loaded: false,
  },
  defaultDesignSettings: {
    colorSchema: {
      textWhite: '#fff',
      textBlack: '#000',
      primaryShade: '#70949c',
      secondary: '#F8B133',
      canvas: '#f4f5f8',
      primary: '#415F66',
      primaryShadeLight: '#83AAB3'
    },
    icons:{
      logo: '',
      logoMini: ''
    }
  },
  lastUsedTableViews: {},
  recordsPerPage: {}
};

const getters = {
  designColorSchema(state) {
    let result = {}
    if (state.designSettings?.colorSchema && Object.values(state.designSettings?.colorSchema).length) {
      result = state.designSettings.colorSchema
    }
    return result
  },
  designIcons(state) {
    let result = {}
    if (state.designSettings?.icons && Object.values(state.designSettings?.icons).length) {
      result = state.designSettings.icons
    }
    return result
  },
  designImages(state) {
    let result = {}
    if (state.designSettings?.images && Object.values(state.designSettings?.images).length) {
      result = state.designSettings.images
    }
    return result
  },
  designCss(state) {
    let result = '';
    if (state.designSettings && state.designSettings.css) {
      result = state.designSettings.css
    }
    return result
  },
  designLoaded(state) {
    return state.designSettings.loaded
  },
  defaultColorSchema(state) {
    return state.defaultDesignSettings.colorSchema
  },
  defaultIcons(state) {
    return state.defaultDesignSettings.icons
  },
};

const SET_USER_EMAIL_SETTINGS = "SET_USER_EMAIL_SETTINGS";
const SET_USER_FORMS_SETTINGS = "SET_USER_FORMS_SETTINGS";
const SET_USER_VIEWS_SETTINGS = "SET_USER_VIEWS_SETTINGS";
const SET_TENANT_DEFAULT_COLUMNS = "SET_TENANT_DEFAULT_COLUMNS";
const SET_DESIGN_SETTINGS = "SET_DESIGN_SETTINGS";
const SET_CURRENT_LIST = "SET_CURRENT_LIST";
const SET_LAST_USED_TABLE_VIEWS = "SET_LAST_USED_TABLE_VIEWS";
const SET_RECORDS_PER_PAGE = "SET_RECORDS_PER_PAGE";

const mutations = {
  [SET_USER_EMAIL_SETTINGS](state, settings) {
    state.userEmailSettings = settings;
  },
  [SET_USER_FORMS_SETTINGS](state, settings) {
    state.userFormsSettings = settings;
  },
  [SET_TENANT_DEFAULT_COLUMNS](state, settings) {
    state.tenantDefaultColumns = settings.defColumns;
    state.tenantDefaultAlwaysShown = settings.defAlwaysShown;
  },
  [SET_DESIGN_SETTINGS](state, settings){
    state.designSettings = settings;
  },
  [SET_CURRENT_LIST](state, pagination){
    state.currentListPagination = pagination;
  },
  [SET_LAST_USED_TABLE_VIEWS](state, tableViews){
    state.lastUsedTableViews = tableViews;
  },
  [SET_RECORDS_PER_PAGE](state, tables){
    state.recordsPerPage = tables;
  },
};

const actions = {

  async initSettings({dispatch, commit}) {
    await dispatch('getUserLicenses');
    await dispatch('getEmailSettings');
    await dispatch('getFormTableViews');
    await dispatch('getDesignSettings');
    await dispatch('lastUsedTableViews');
    await dispatch('recordsPerPage');
    const defColumns = await dispatch('getTenantDefaultColumns');
    const defAlwaysShown = await dispatch('getTenantAlwaysShown');
    commit("SET_TENANT_DEFAULT_COLUMNS", {defColumns: defColumns.data() || {}, defAlwaysShown: defAlwaysShown.data() || {} });

    // we don't use the statuses from settings
    //const appointmentStatuses = await dispatch('getTenantAppointmentStatuses');
    //if (appointmentStatuses.data()) {
      //SelectModel.appointmentStatus.dragged = appointmentStatuses.data().dragged
      //SelectModel.appointmentStatus = Object.assign(SelectModel.appointmentStatus, appointmentStatuses.data())
      
    //}

  },

  async getFormTableViews({commit}) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/form-settings/";
    const result = await db.collection(spath).get();
    const settings = {};
    result.forEach((doc) => {
      settings[doc.id] = doc.data();
    });
    commit("SET_USER_FORMS_SETTINGS", settings);
    return settings;
  },

  async getUserLicenses() {
    const spath = "tenants/" + store.state.tenantID + "/users/";
    const result = await db.collection(spath).doc(store.state.userID).get();
    const userData = result.data();
    let licenses = {}
    if(userData && userData.licenses) {
      licenses = userData.licenses;
    }
    store.commit("SET_USER_LICENSES", licenses);
    return licenses;
  },

  async lastUsedTableViews({commit}) {
    const views = await db.collection(`/tenants/${store.state.tenantID}/users/${store.state.userID}/preferences`).doc('lastUsedTableViews').get();
    const tableViews = views.data();
    commit("SET_LAST_USED_TABLE_VIEWS", tableViews);
    return tableViews;
  },

  async recordsPerPage({commit}) {
    const dbResponse = await db.collection(`/tenants/${store.state.tenantID}/users/${store.state.userID}/preferences`).doc(TableConstraints.RECORDS_PER_PAGE_DOC_ID).get();
    const tables = dbResponse.data();
    commit("SET_RECORDS_PER_PAGE", tables);
    return tables;
  },

  async saveFormTableViews({commit}, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/form-settings/";
    const doc = await db.collection(spath).doc(params.module).get();
    if (!doc.data()) {
      return await db.collection(spath).doc(params.module).set(params.data);
    } else {
      return await db.collection(spath).doc(params.module).update(params.data);
    }
  },

  async rememberTheCurrentList({commit}, pagination) {
    commit(SET_CURRENT_LIST, pagination);
  },

  async getTenantDefaultColumns({getters}) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-columns').get();
  },

  async getTenantAlwaysShown({getters}) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-always-shown').get();
  },

  async setTenantDefaultColumns({getters}, data) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-columns').set(data);
  },

  async getTenantAppointmentStatuses() {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('appointment-statuses').get();
  },

  async setTenantAppointmentStatuses({state}, data) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('appointment-statuses').set(data);
  },

  async setTenantAlwaysShown({getters}, data) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-always-shown').set(data);
  },

  cleanListSettings({state}, module) {
    state.listSettings[module] = {};
  },

  async setPDFTemplateData({state}, data) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/PDF_Templates/records").doc(String(data.id)).set(data);
  },

  async getPDFTemplatesData({getters}) {
    const result = await db.collection("tenants/" + store.state.tenantID + "/settings/PDF_Templates/records").get();
    const settings = {};
    result.forEach((doc) => {
      settings[String(doc.id).trim()] = doc.data();
    });
    return settings;
  },

  async getListSettings({state}, module) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const spathShared = "tenants/" + store.state.tenantID + "/users/" + state.sharedFilters + "/modules/";
    [spathShared, spath].forEach(async path => {
      const doc = await db.collection(path).doc(module).get();
      if (!doc.data()) {
        await db.collection(path).doc(module).set({});
      }
    });

    const doc = await db.collection(spath).doc(module).get();
    const moduleDoc = doc.data() ?? {};
    moduleDoc.filters = [];
    moduleDoc.defaultFilter = null;
    moduleDoc.selectedFilter = null;

    const result = await db.collection(spath).doc(module).collection('lists').get();
    const resultShared = await db.collection(spathShared).doc(module).collection('lists').get();
    [result, resultShared].forEach(res => {
      res.forEach((doc) => {
        const rec = doc.data();
        rec.ID = doc.id;
        moduleDoc.filters.push(rec);
        if (rec.default && !moduleDoc.defaultFilter) {
          moduleDoc.defaultFilter = rec;
        }
        if (rec.ID === moduleDoc.selectedFilterID) {
          moduleDoc.selectedFilter = rec;
        }
      });
    });

    // create default filter
    if (!moduleDoc.defaultFilter) {
      const defColumns = await db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-columns').get();
      const defColumnsData = defColumns.data();
      const newDoc = db.collection(spath).doc(module).collection('lists').doc();
      moduleDoc.defaultFilter = {
        name: 'default',
        default: true,
        columns: defColumnsData && defColumnsData[module] ? defColumnsData[module] : [],
        filters: {},
        shared: false,
        pagination: {rowsPerPage: 10},
        ID: newDoc.id
      };
      moduleDoc.filters.push(moduleDoc.defaultFilter);
      await newDoc.set(moduleDoc.defaultFilter);
    }

    // clean deleted columns
    const moduleObj = store.state.dynamicModules[module] || {};
    const moduleFields = Object.keys(moduleObj.fields || {});
    if (moduleFields.length) {
      const allDocFields = [...ListValues.serviceDocFields, ...moduleFields];
      [...moduleDoc.filters, moduleDoc.defaultFilter, moduleDoc.selectedFilter].forEach(f => {
        if (f) {
          // columns
          if (f.columns && Array.isArray(f.columns)) {
            f.columns = f.columns.filter(c => allDocFields.indexOf(c) >= 0);
          }
          // filters
          if(f.filters && f.filters.columnOptionsSelected) {
            Object.keys(f.filters.columnOptionsSelected || {}).filter(fc => allDocFields.indexOf(fc) < 0)
              .forEach(nf => delete f.filters.columnOptionsSelected[nf]);
          }
          if(f.filter && f.filter.filterData) {
            Object.keys(f.filters.filterData || {}).filter(fc => allDocFields.indexOf(fc) < 0)
              .forEach(nf => delete f.filters.filterData[nf]);
          }
          // sortBy
          if (f.pagination && f.pagination.sortBy && allDocFields.indexOf(f.pagination.sortBy) < 0) {
            f.pagination.sortBy = '';
          }
        }
      });
    }

    Vue.set(state.listSettings, module, moduleDoc);
    
  },

  async saveListFilter({state}, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + (params.filter.shared ? state.sharedFilters : store.state.userID) + "/modules/"
    const spath2 = "tenants/" + store.state.tenantID + "/users/" + (!params.filter.shared ? state.sharedFilters : store.state.userID) + "/modules/"

    if (params.filter.ID) {
      const newDoc = await db.collection(spath).doc(params.module).collection('lists').doc(params.filter.ID).get();
      let prevDoc;
      if (!newDoc.data()) {
        const oldDoc = await db.collection(spath2).doc(params.module).collection('lists').doc(params.filter.ID).get();
        await db.collection(spath2).doc(params.module).collection('lists').doc(params.filter.ID).delete();
        if (oldDoc.data()) {
          prevDoc = oldDoc;
          params.filter = {...oldDoc.data(), ...params.filter};
        }
        params.filter.ID = '';
      } else {
        prevDoc = newDoc;
      }
      if (prevDoc && prevDoc.data() && prevDoc.data()?.locked && prevDoc.data()?.author && prevDoc.data()?.author !== store.state.userID) {
        
        store.state.alertMessage = "error";
        return false;
      }
    }

    if (params.filter.ID) {
      
      return db.collection(spath).doc(params.module).collection('lists').doc(params.filter.ID).update(params.filter);
    } else {
      return new Promise((resolve, reject) => {
        const newDoc = db.collection(spath).doc(params.module).collection('lists').doc();
        params.filter.ID = newDoc.id;
        params.filter.author = store.state.userID;
        params.filter.authorName = ListValues.getCurrentUserName(),
          newDoc.set(params.filter).then(() => {
            resolve({id: params.filter.ID});
          }).catch((e) => {
            reject(e);
          });
      });
    }
  },

  async deleteListFilter({state}, params) {
    if (params.filter.ID) {
      const spath = params.filter.shared
        ? "tenants/" + store.state.tenantID + "/users/" + state.sharedFilters + "/modules/"
        : "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";

      const prevDoc = await db.collection(spath).doc(params.module).collection('lists').doc(params.filter.ID).get();
      if (prevDoc && prevDoc.data() && prevDoc.data()?.locked && prevDoc.data()?.author && prevDoc.data()?.author !== store.state.userID) {
        
        store.state.alertMessage = "error";
        return false;
      }
      return db.collection(spath).doc(params.module).collection('lists').doc(params.filter.ID).delete();
    } else {
      
      store.state.alertMessage = "error";
    }
  },

  async setListSettings({state}, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(spath).doc(params.module).update(params.data);
  },

  async getPlanboardViewSettings({state}) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const path2 = "tenants/" + store.state.tenantID + "/users/" + state.sharedViews + "/modules/";
    const result = await db.collection(path).doc('planboard').collection('views').get();
    const result2 = await db.collection(path2).doc('planboard').collection('views').get();

    state.planboardViewSettings = [];
    [result, result2].forEach((res) => {
      res.forEach((doc) => {
        state.planboardViewSettings.push({
          ID: doc.id,
          name: doc.data()?.name,
          shared: doc.data()?.shared,
          locked: doc.data()?.locked,
          author: doc.data()?.author,
          authorName: doc.data()?.authorName,
          users: doc.data()?.users,
          period: doc.data()?.period
        })
      });
    });
  },

  async savePlanboardViewSettings({state}, params) {
    const path = "tenants/" + store.state.tenantID + "/users/" + (params.view.shared ? state.sharedViews : store.state.userID) + "/modules/";
    const path2 = "tenants/" + store.state.tenantID + "/users/" + (!params.view.shared ? state.sharedViews : store.state.userID) + "/modules/"
    
    if (params.id) {
      const newDoc = await db.collection(path).doc('planboard').collection('views').doc(params.id).get();
      let prevDoc
      if (!newDoc.data()) {
        const oldDoc = await db.collection(path2).doc('planboard').collection('views').doc(params.id).get();
        if (oldDoc.data()) {
          prevDoc = oldDoc;
          params.view = {...oldDoc.data(), ...params.view};
        }
        if (prevDoc && prevDoc.data() && prevDoc.data()?.locked && prevDoc.data()?.author && prevDoc.data()?.author !== store.state.userID) {
          
          store.state.alertMessage = "error";
          return params.id;
        }
        await db.collection(path2).doc('planboard').collection('views').doc(params.id).delete();
        params.id = '';
      } else {
        prevDoc = newDoc;
        if (prevDoc && prevDoc.data() && prevDoc.data()?.locked && prevDoc.data()?.author && prevDoc.data()?.author !== store.state.userID) {
          
          store.state.alertMessage = "error";
          return params.id;
        }
      }
    }
    return new Promise((resolve, reject) => {
      if (params.id) {
        const ref = db.collection(path).doc('planboard').collection('views').doc(params.id)
        ref.update(params.view)
          .then(() => {
            resolve(ref.id);
          })
      } else {
        const ref = db.collection(path).doc('planboard').collection('views').doc()
        ref.set(params.view)
          .then(() => {
            resolve(ref.id);
          })
      }
    })
  },

  async deletePlanboardViewSettings({state}, params) {
    
    const path = "tenants/" + store.state.tenantID + "/users/" + (params.view.shared ? state.sharedViews : store.state.userID) + "/modules/";
    const prevDoc = await db.collection(path).doc('planboard').collection('views').doc(params.id).get();
    if (prevDoc && prevDoc.data() && prevDoc.data()?.locked && prevDoc.data()?.author && prevDoc.data()?.author !== store.state.userID) {
      
      store.state.alertMessage = "error";
      return false;
    }
    return await db.collection(path)
      .doc('planboard').collection('views').doc(params.id)
      .delete();
  },

  async getPlanboardDefaultViewID({state}) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const result = await db.collection(path).doc('planboard').get();
    const pathToPlanboardViews = "tenants/" + store.state.tenantID + "/users/" + state.sharedViews + "/modules/";
    const res = await db.collection(pathToPlanboardViews).doc('planboard').collection('views').get();
    const planboardViews = [];
    res.forEach((doc) => {
      const rec = doc.data();
      rec.id = doc.id;
      planboardViews.push(rec);
    });
    state.planboardDefaultViewID = result.data()?.userViewID || planboardViews[planboardViews.length -1].id
  },

  async savePlanboardDefaultViewID({state}, params) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(path).doc('planboard').update(params);
  },

  // universal functions
  async getModuleSettings({state}, module) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const doc = await db.collection(spath).doc(module).get();
    if (!doc.data()) {
      await db.collection(spath).doc(module).set({});
    }
    const moduleDoc = doc.data() ?? {};
    return moduleDoc;
  },

  async setModuleSettings({state}, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const doc = await db.collection(spath).doc(params.module).get();
    if (!doc.data()) {
      return await db.collection(spath).doc(params.module).set(params.data);
    } else {
      return await db.collection(spath).doc(params.module).update(params.data);
    }
  },

  async getEmailSettings({commit}) {
    const query = await db.collection(`/tenants/${store.state.tenantID}/settings/email/addresses`).get();
    const result = [];
    query.forEach((doc) => {
      result.push(doc.data());
    });
    this.emailSettings = result;
    const sender = result.filter((el) => el.allowedUsers.includes(store.state.currentUser.ID));
    const emailSender = sender;
    
    this.commit("settings/SET_USER_EMAIL_SETTINGS", emailSender);
    return emailSender;
  },

  async saveSelectedEmailSetting({commit}, data) {
    const docSettings = db.collection(
      "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/settings"
    );
    delete data['allowedUsers'];
    docSettings
      .doc("emails")
      .set(data)
      .then((snapshot) => {
        store.commit("SET_USER_SELECT_EMAIL_SETTINGS", data);
      });
  },

  async getPlanboardSettings( {state} ) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const result = await db.collection(path).doc('planboard').get();
    const planboardSchedulerSettings = result.data()?.schedulerSettings || { showNonBusiness: false, eventHeight: 40, resourcesModule: 'users', cellDurationDay: 30, cellDurationWeek: 60 }
    if (!planboardSchedulerSettings.resourcesModule) planboardSchedulerSettings.resourcesModule = 'users';
    state.planboardSchedulerSettings = planboardSchedulerSettings;
    state.planboardListSettings = result.data()?.listSettings || { showListHeader: true }
  },

  async savePlanboardSettings( {state}, params ) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(path).doc('planboard').update(params);
  },

  async getDesignSettings({ commit }) {
    const docV = db.collection(
      "tenants/" + store.state.tenantID + "/settings/"
    );
    const result = await docV.doc("design").get()
    
    const designSettings = result.data() ? result.data() : {}
    designSettings.loaded = true
    commit(SET_DESIGN_SETTINGS, designSettings)
  },

  setDesignSettings({ state, commit, dispatch }, designSettings) {
    const docDefaultV = db.collection(
      "tenants/" + store.state.tenantID + "/settings"
    );
    docDefaultV
      .doc("design")
      .set(designSettings)
      .then(() => {
        dispatch('savePortalPublicDesign', designSettings)
        designSettings.loaded = true
        commit(SET_DESIGN_SETTINGS, designSettings)
      })
  },

  async savePortalPublicDesign({ state, commit, dispatch }, design) {
    const portals = await dispatch('getPortalsByTenantID');
    portals.map(p => {
      db.collection("portals").doc(p.ID).update({design})
    });
  },

  async getPortalsByTenantID() {
    const portals = [];
    const result = await db.collection("portals").get();
    result.forEach((doc) => {
      const pdoc = doc.data();
      pdoc.ID = doc.id;
      if (pdoc.tenant === store.state.tenantID) {
        portals.push(pdoc);
      }
    });
    return portals;
  },

  async getModuleAlwaysShown({dispatch}, module) {
    const moduleSettings = await dispatch('getModuleSettings', module);
    return moduleSettings && moduleSettings['always-shown-fields'] ? moduleSettings['always-shown-fields'] : {};
  },
  async setModuleAlwaysShown({state}, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(spath).doc(params.module).update(params.data);
  },
  async getModuleOrderInfoShow({dispatch}, module) {
    const moduleSettings = await dispatch('getModuleSettings', module);
    return moduleSettings && typeof moduleSettings['order-info-show'] !== 'undefined' ? moduleSettings['order-info-show'] : true;
  },
  async setModuleOrderInfoShow({state}, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(spath).doc(params.module).update(params.data);
  },

  async saveUsedTags({_}, data) {
    const result = await db.collection("tenants/" + store.state.tenantID + "/settings/").doc("tags").get()
    const usedTags = result.data() && result.data()[data.module] ? result.data()[data.module] : []
    const tags = [...new Set([...usedTags, ...data.tags])]

    if (!result.data()) {
      db.collection("tenants/" + store.state.tenantID + "/settings/")
      .doc("tags")
      .set({[data.module]: tags})
    } else {
      db.collection("tenants/" + store.state.tenantID + "/settings/")
      .doc("tags")
      .update({[data.module]: tags})
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
