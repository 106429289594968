
export default {
  name: "TagsBlock",
  props: {
    value: null,
    preTags: []
  },
  data() {
    return {
      tagName: ''
    }
  },
  computed: {
    tags: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    }
  },
  methods: {
    addTag(tags){
      if (!this.tagName) {
        return
      }
      if (tags.includes(this.tagName.toUpperCase())){
        this.$q.notify({ message: 'This tag is already exist!', color: 'warning', icon: 'warning' })
      } else {
        tags.push(this.tagName.toUpperCase());
        this.tagName = '';
      }
    },
    addPreTag(tagName) {
      if (!this.tags.includes(tagName.toUpperCase())){
        this.tags.push(tagName.toUpperCase());
      }
    },
    deleteTag(tags, tagName){
      //tags = tags.filter(e => e !== tagName);
      tags.splice(tags.indexOf(tagName), 1);
      
    }
  },
  mounted (){
    if (!Array.isArray(this.tags)) {
      this.tags = []
    }
  }
}
