
import { mapState, mapActions } from 'vuex';
import store from "@/store";
import draggable from 'vuedraggable';
import ListValues from '@/components/Mixin/ListValues'

export default {
  name: "DefaultOrderInfo",
  components: {draggable},
  data: () => ({
    messageOk: false,
    messageError: false,
    columns: null,
    dynamicColumns: {},
  }),
  mounted() {
    this.getColumns();
  },

  computed: {
    ...mapState(["dynamicModules"]),
  },
  methods: {
    ...mapActions("settings", ["getTenantAlwaysShown", "setTenantAlwaysShown"]),
    getModuleTitle(module) {
      const translationKey = `modules.${module.ID}.pluralName`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        return store.state.fieldTranslations[translationKey]
      }
      return module.title
    },
    convertObjectToArray(obj){
      if (typeof obj !== 'object'){
        return []
      }
      const arr = Object.keys(obj).map(e => obj[e]);
      const sortedArr = arr.sort((a, b) => a.order - b.order)
      return sortedArr.map(e => {
        delete e.order
        return e
      });
    },
    async getColumns() {
      const dataDoc = await this.getTenantAlwaysShown();
      const columns = dataDoc.data() ?? {};
      for (const i in columns) {
        columns[i] = this.convertObjectToArray(columns[i]);
      }
      
      const result = {};
      const dynColumns = {};
      for (const i in this.dynamicModules) {
        const module = this.dynamicModules[i];
        dynColumns[i] = {
          ID: i,
          title : module.pluralName ?? i,
          fields : ListValues.getValidColumns(module.fields)
        };
      }
      for (const i in dynColumns) {
        const module = dynColumns[i];
        result[i] = columns[i] ? columns[i].filter(c => Object.keys(module.fields).indexOf(c.field) !== -1) : [];
      }
      this.dynamicColumns = dynColumns;
      this.columns = result;
    },
    getFieldLabel(moduleName, item){
      let moduleFields = {};
      moduleFields = this.dynamicModules[moduleName]?.fields
      return moduleFields[item.field]?.label
    },
    getSelectOptions(value) {
      if (!value) {
        return [];
      }
      const fields = Object.values(value.fields)
      return fields.map(e => {
        return { field: e.name, label: e.label }
      })
    },
    getSavedColumns(columns) {
      const savedColumns = {}
      for (const i in columns) {
        if (columns[i].length) {
          savedColumns[i] = {}
          columns[i].forEach((e, index) => {
            e.order = index + 1
            savedColumns[i][e.field] = e
          })
        }
      }
      return savedColumns
    },
    saveSettings() {
      const savedColumns = this.getSavedColumns(this.columns)
      

      this.setTenantAlwaysShown(savedColumns).then(() => {
        store.state.alertMessage = "update";
      })
      this.getColumns()
    },
  },
};
