
  import {mapGetters} from "vuex";
  import { Component, Vue } from "vue-property-decorator";
  import { State } from "vuex-class";
  import db from "@/db";
  import store from "@/store";
  import ActionInfo from "@/components/Parts/ActionInfo.vue";
  import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
  import { Timestamp } from '@firebase/firestore';

  @Component({
    mixins: [CrudMixinVue],
    components: {ActionInfo},
  })

  export default class RolesEdit extends Vue {
      @State("dynamicModules") dynamicModules;
      [x: string]: any;
      messageOk = false
      messageError = false
      modules: any = []
      roleName = ""
      copyRoleName = ""
      allTogglesTrue = false
      pdfTemplates = false
      defaultColumns = false
      defaultPlanboard = false
      email = false
      emailTemplates = false
      importData = false
      organization = false
      reportTemplates = false
      roleManagement = false
      userConfiguration = false
      auditLogsExport = false
      restoreDeletedRecord = false
      statusConfiguration = false
      orderInfo = false
      roboticProcessBuilder = false
      documentTemplates = false
      hideOrDisable: any = ""
      hideOrDisableOptions = [this.$t('admin.role.hide'), this.$t('admin.role.disable')]
      showCopyDialog = false
      changedAt: Timestamp = Timestamp.now()

    mounted() {
      this.getRoleInfo();
    }

    computed() {
      mapGetters(["translate"])
    }

    getFirePath() {
      return "roles";
    }

    setRowFalse(module) {
      if(module.readPermissions == false) {
        module.deletePermissions = false
        module.lockPermissions = false
        module.unlockPermissions = false
        module.statusOverwrite = false
        module.updatePermissions = false
      }
    }

    getImportValues(docDataPermission) {
      if(docDataPermission == "ALLOWED") {
        return true
      } else {
        return false
      }
    }

    getImportHideOrDisable(value) {
      
      if(value == "HIDE") {
        return "Verbergen"
      } else {
        return "Onbruikbaar"
      }
    }

    async getRoleInfo() {
      const firePath = "tenants/" + store.state.tenantID + "/modules/";
      const dataDoc = await db.collection(firePath).get();
      if(dataDoc.empty) {
        
        return;
      }
      const getDoc = await db.collection(`/tenants/${store.state.tenantID}/roles/`).doc(this.$route.params.id).get()
      this.roleName = getDoc.data().name
      this.hideOrDisable = this.getImportHideOrDisable(getDoc.data().hideOrDisableUI)
      
      const role = await db.collection(`/tenants/${store.state.tenantID}/roles/${this.$route.params.id}/modulePermissions`).get()
      
      const settings = await db.collection(`/tenants/${store.state.tenantID}/roles/${this.$route.params.id}/modulePermissions`).doc('settings').get()
      const settingsData = settings.data()
      this.defaultColumns = this.getImportValues(settingsData['default-columns']),
      this.defaultPlanboard = this.getImportValues(settingsData['default-planboard']),
      this.email = this.getImportValues(settingsData['email']),
      this.emailTemplates =  this.getImportValues(settingsData['email-templates']),
      this.importData = this.getImportValues(settingsData['import-data']),
      this.organization = this.getImportValues(settingsData['portal-settings']),
      this.pdfTemplates = this.getImportValues(settingsData['pdf-templates']),
      this.reportTemplates = this.getImportValues(settingsData['report-templates'])
      this.roleManagement = this.getImportValues(settingsData['role-management'])
      this.userConfiguration = this.getImportValues(settingsData['user-configuration'])
      this.auditLogsExport = this.getImportValues(settingsData['audit-logs-export'])
      this.restoreDeletedRecord = this.getImportValues(settingsData['deleted-records'])
      this.statusConfiguration = this.getImportValues(settingsData['status-configuration'])
      this.orderInfo = this.getImportValues(settingsData['order-info'])
      this.roboticProcessBuilder = this.getImportValues(settingsData['robotic-process-builder'])
      this.documentTemplates = this.getImportValues(settingsData['document-templates'])
      
      role.forEach(doc => {
        const docData = doc.data()
        let pluralName = ""
        for(const module in this.dynamicModules) {
          if(this.dynamicModules[module].id == doc.id) {
            pluralName = this.dynamicModules[module].pluralName
          }
        }
        if (this.$route.params.id) {
          if(docData.ID != undefined && doc.id !== "demo") {
            this.modules.push({
              label: pluralName,
              id: docData.ID,
              createPermissions: this.getImportValues(docData.createPermissions),
              deletePermissions: this.getImportValues(docData.deletePermissions),
              lockPermissions: this.getImportValues(docData.lockPermissions),
              readPermissions: this.getImportValues(docData.readPermissions),
              unlockPermissions: this.getImportValues(docData.unlockPermissions),
              statusOverwrite: this.getImportValues(docData.statusOverwrite),
              updatePermissions: this.getImportValues(docData.updatePermissions),
            });
          }      
        }
      });
      this.checkMissingModules()
    }

    checkMissingModules() {
      const moduleNames = []
      for(const mod of this.modules) {
        moduleNames.push(mod.id)
      }
      
      for(const m in this.dynamicModules) {
        const module = this.dynamicModules[m];        
        if (!moduleNames.includes(module.id) && module.id !== "demo") {
            this.modules.push({
              label: module.pluralName,
              id: module.id,
              createPermissions: false,
              deletePermissions: false,
              lockPermissions: false,
              readPermissions: false,
              unlockPermissions: false,
              statusOverwrite: false,
              updatePermissions: false
            });
        }        
      }
    }

    setAllTogglesTrue() {
        for(const module in this.modules) {
          if(this.modules[module] != undefined) {
            this.modules[module].createPermissions = this.allTogglesTrue
            this.modules[module].deletePermissions = this.allTogglesTrue
            this.modules[module].lockPermissions = this.allTogglesTrue
            this.modules[module].readPermissions = this.allTogglesTrue
            this.modules[module].unlockPermissions = this.allTogglesTrue
            this.modules[module].statusOverwrite = this.allTogglesTrue
            this.modules[module].updatePermissions = this.allTogglesTrue
          }
        }
        this.pdfTemplates = this.allTogglesTrue
        this.defaultColumns = this.allTogglesTrue
        this.defaultPlanboard = this.allTogglesTrue
        this.email = this.allTogglesTrue
        this.emailTemplates = this.allTogglesTrue
        this.importData = this.allTogglesTrue
        this.organization = this.allTogglesTrue
        this.reportTemplates = this.allTogglesTrue
        this.roleManagement = this.allTogglesTrue
        this.userConfiguration = this.allTogglesTrue
        this.auditLogsExport = this.allTogglesTrue
        this.restoreDeletedRecord = this.allTogglesTrue
        this.statusConfiguration = this.allTogglesTrue
        this.orderInfo = this.allTogglesTrue
        this.roboticProcessBuilder = this.allTogglesTrue
        this.documentTemplates = this.allTogglesTrue
    }

    getExportValues(modulePermission) {
      if(modulePermission == true) {
        return "ALLOWED"
      } else {
        return "NOT_ALLOWED"
      }
    }

    getHideOrDisable(value) {
      if(value == "Verbergen" || value == "Hide") {
        return "HIDE"
      } else {
        return "DISABLE"
      }
    }

    async saveSettings(ID, roleName) {
        if(this.roleName != '') {
          const doc = db.collection(`tenants/${store.state.tenantID}/roles`).doc(ID)
          await doc.set(Object.assign({}, {
            ID: ID,
            name: roleName,
            hideOrDisableUI: this.getHideOrDisable(this.hideOrDisable),
            changedAt: this.changedAt
          }), {merge: true})
          .catch(() => {
            this.messageError = true;
            setTimeout(() => this.messageError = false, 5000);
          });
          this.modules.forEach(module => {
            db.collection(`tenants/${store.state.tenantID}/roles/${ID}/modulePermissions`).doc(module.id)
              .set(Object.assign({}, {
                createPermissions: this.getExportValues(module.createPermissions),
                deletePermissions: this.getExportValues(module.deletePermissions),
                readPermissions: this.getExportValues(module.readPermissions),
                lockPermissions: this.getExportValues(module.lockPermissions),
                unlockPermissions: this.getExportValues(module.unlockPermissions),
                statusOverwrite: this.getExportValues(module.statusOverwrite),
                updatePermissions: this.getExportValues(module.updatePermissions),
                ID: module.id
              }))
              .catch(() => {
                this.messageError = true;
                setTimeout(() => this.messageError = false, 5000);
              });
          });
            db.collection(`tenants/${store.state.tenantID}/roles/${ID}/modulePermissions`).doc('demo')
              .set(Object.assign({}, {
                createPermissions: "ALLOWED",
                deletePermissions: "ALLOWED",
                readPermissions: "ALLOWED",
                lockPermissions: "ALLOWED",
                unlockPermissions: "ALLOWED",
                statusOverwrite: "ALLOWED",
                updatePermissions: "ALLOWED",
                ID: "demo"
              }))
              .catch(() => {
                this.messageError = true;
                setTimeout(() => this.messageError = false, 5000);
              });
          db.collection(`tenants/${store.state.tenantID}/roles/${ID}/modulePermissions`).doc(`settings`)
            .set(Object.assign({}, {
              "pdf-templates": this.getExportValues(this.pdfTemplates),
              "default-columns": this.getExportValues(this.defaultColumns),
              "default-planboard": this.getExportValues(this.defaultPlanboard),
              "email": this.getExportValues(this.email),
              "email-templates": this.getExportValues(this.emailTemplates),
              "import-data": this.getExportValues(this.importData),
              "portal-settings": this.getExportValues(this.organization),
              "report-templates": this.getExportValues(this.reportTemplates),
              "role-management": this.getExportValues(this.roleManagement),
              "user-configuration": this.getExportValues(this.userConfiguration),
              "audit-logs-export": this.getExportValues(this.auditLogsExport),
              "deleted-records": this.getExportValues(this.restoreDeletedRecord),
              "status-configuration": this.getExportValues(this.statusConfiguration),
              "order-info": this.getExportValues(this.orderInfo),
              "robotic-process-builder": this.getExportValues(this.roboticProcessBuilder),
              "document-templates": this.getExportValues(this.documentTemplates)
            }))
            .then(() => {
              this.$router.push({path: "/settings/role-management"});
            })
            .catch(() => {
              this.messageError = true;
              setTimeout(() => this.messageError = false, 5000);
            });
        } else {
          this.$q.notify({ message: `${this.$t('common.messages.operationNotValidated')}`, color: 'negative', icon: 'warning'})
        }
    }

    onClickCopyBtn() {
      this.showCopyDialog = true;
      this.copyRoleName = this.roleName;
    }

    onCancelCopyDialog() {
      this.copyRoleName = ""
    }

    async onTemplateCopy() {
      const copyDoc = await this.getRecord(this.$route.params.id);
      
      copyDoc.name = this.copyRoleName;
      copyDoc.ID = ""
      const newDoc = await db.collection(`tenants/${store.state.tenantID}/roles`).doc();
      copyDoc.ID = newDoc.id;
      await newDoc.set(copyDoc); 
      await this.saveSettings(copyDoc.ID, this.copyRoleName)
      store.state.alertMessage = "add";
    }

    onBack() {
      this.$router.push({path: "/settings/role-management"});
    }
  }
