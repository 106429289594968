
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import RelatedFieldCopy from "@/components/Settings/Actions/relatedFieldCopy.vue";
import { RelatedFieldCopyAction } from "@/components/Settings/Actions/actions";

@Component({
  components: {
    AddEmailTemplate,
    AddFieldUpdate,
    RelatedFieldCopy
  },
})
export default class AddNewActions extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop() existingAction;
  @Prop() openExistingAction;
  typeOfFieldAction = [
    { name: "inRecord", title: "InRecord", label: "In Record" },
    { name: "childToParent", title: "ChildToParent", label: "Child To Parent" },
    { name: "parentToChild", title: "ParentToChild", label: "Parent To Child" },
  ];
  isActionTrue = true;
  showModulesList: Array<any> = [];
  showModules: Array<any> = [];
  options = this.showModules;
  relatedFieldCopy = new RelatedFieldCopyAction();
  relatedFieldCopies = [];

  async mounted() {        
    this.getActionTypeFunction()
    if(this.openExistingAction == true){
      await this.relatedFieldCopy.import(this.existingAction.ID, this.dynamicModules)
    }    
    let showModules = {};
    for (const modules in this.dynamicModules) {
      if (this.dynamicModules[modules].pluralName != undefined) {
        showModules = {
          label: this.dynamicModules[modules].pluralName,
          name: this.dynamicModules[modules].id,
        };
      } else {
        showModules = {
          label: this.dynamicModules[modules].name,
          name: this.dynamicModules[modules].id,
        };
      }
      this.showModules.push(showModules);
    }
    if(this.relatedFieldCopy.childModule["name"] != ""){
      this.showFieldsOfType()
    }
  }

  // Filter function
  filterFn(val, update) {
    if (val === "") {
      this.options = this.showModules;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.showModules.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  // to get the options for a related field
  showFieldsOfType() {
    this.showModulesList = [];
    for (const field in this.dynamicModules[this.relatedFieldCopy.childModule["name"]].fields) {
      if (this.dynamicModules[this.relatedFieldCopy.childModule["name"]].fields[field].type == "relatedField") {
        const showFields = {
          label: this.dynamicModules[this.relatedFieldCopy.childModule["name"]].fields[field].label,
          name: this.dynamicModules[this.relatedFieldCopy.childModule["name"]].fields[field].name,
        };        
        this.showModulesList.push(showFields);
      }
    }
  }

  // Emit function to get the existing related field copies
  getRelatedFieldCopies(value: any) {
    this.relatedFieldCopies = value;
    this.relatedFieldCopy.relatedFieldCopy = value
  }

  // emit function to save action in actionHeader.vue
  @Emit("getActionType")
  getActionTypeFunction() {
      const map = {
        value: this.relatedFieldCopy,
        type: "RelatedFieldCopy"
      }      
      return map   
  }
}
