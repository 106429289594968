class EventBusImpl {
  events: Record<string, ((data?: unknown) => void)[]>;

  constructor() {
    this.events = {};
  }

  $on(eventName: string, fn: () => void) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }

  $emit(eventName: string, data?: unknown) {
    //TODO check the large amount in this array
    if (this.events[eventName]) {
      this.events[eventName].forEach((fn) => {
        fn(data);
      });
    }
  }

  $off(eventName: string, fn: () => void) {
    if (this.events[eventName]) {
      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }
}

export const EventBus = new EventBusImpl();
