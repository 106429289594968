
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import store from "@/store";
import db from "@/db";
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";

@Component({
  mixins: [CrudMixinVue],
})
export default class RestoreDeletedRecords extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  columns = [
    {
      name: "name",
      label: this.$t("deletedRecords.fields.name"),
      field: "name",
      align: "left",
      sortable: true,
    },
    {
      name: "deletedBy",
      label: this.$t("deletedRecords.fields.deletedBy"),
      field: "modified",
      align: "center",
      sortable: true,
    },
    {
      name: "deletedAt",
      label: this.$t("deletedRecords.fields.deletedAt"),
      field: "modified",
      align: "center",
      sortable: true,
    },
    {
      name: "restore",
      label: "",
      field: "modified",
      align: "center",
      sortable: true,
    },
  ];
  loading = true;
  selectedModule = { label: "", value: "" };
  deletedRecords = [];

  async mounted() {
    this.selectedModule.label = this.modulesList[0].label;
    this.selectedModule.value = this.modulesList[0].value;
    if (this.selectedModule.value !== "") {
      await this.loadDeletedRecords();
      this.loading = false;
    }
  }

  async loadDeletedRecords() {
    if (this.selectedModule.value !== "") {
      const firePath = `tenants/${store.state.tenantID}/modules/${this.selectedModule.value}/deletedRecords`;
      const res = await db.collection(firePath).get();
      const docs: any = [];
      res.forEach((doc) => {
        const rec: any = doc.data();
        rec.id = doc.id;
        (docs as any).push(rec as any);
      });
      this.deletedRecords = docs;
    }
  }

  async restoreRecord(record: any) {
    const firePath = `tenants/${store.state.tenantID}/modules/${this.selectedModule.value}/records`;
    record.restoredAt = new Date();
    record.restoredBy = store.state.currentUser;
    db.collection(firePath)
      .doc(record.ID)
      .set(record)
      .then(() => {
        const firePathDeletedRecord = `tenants/${store.state.tenantID}/modules/${this.selectedModule.value}/deletedRecords/${record.ID}`;
        db.doc(firePathDeletedRecord)
          .delete()
          .then(() => {
            this.$q.notify({
              message: `${this.$t("common.messages.recordRestored")}`,
              color: "positive",
            });
            this.loadDeletedRecords()
          })
          .catch(() => {
            this.$q.notify({
              message: `${this.$t(
                "common.messages.errorWhileRestoringRecord"
              )}`,
              color: "negative",
            });
          });
      })
      .catch(() => {
        this.$q.notify({
          message: `${this.$t("common.messages.errorWhileRestoringRecord")}`,
          color: "negative",
        });
      });
  }

  get modulesList() {
    const modulesList = Array<{ label: string; value: string }>();
    Object.keys(this.dynamicModules).forEach((module) => {
      modulesList.push({
        label: this.getModuleTitle(module),
        value: module,
      });
    });
    return modulesList;
  }

  converDate(isostr: any) {
    if (!isostr) {
      return "";
    }
    const date = new Date(isostr.seconds * 1000);
    return date.toLocaleString("nl-Nl");
  }
}
