import FieldFactory from "@/components/Fields/Helpers/FieldFactory";
import db from "@/db";
import store from "@/store";
import { defineStore } from "pinia";
import Module from "../Models/Module";
import Modules from "../Models/Modules";
import {doc, setDoc} from "firebase/firestore";
import Utils from "@/utils/Utils";


async function saveModuleInfoFields(moduleID: string, fields) {
  const ref = doc(db, `tenants/${store.state.tenantID}/users/${store.state.userID}/modules/${moduleID}`)
  const dbResponse = await setDoc(ref, {'always-shown-fields': fields}, {merge: true}).then(() => {
    store.state.alertMessage = "update";
    return true
  }).catch((err) => {
    console.log("Error while updating module info fields: " + err);
    return false
  })
  return dbResponse
}

async function fetchUserModuleInfoFields(userID: string) {
  // TODO rewrite database queries
  const userModuleInfoFields = await db.collection(`tenants/${store.state.tenantID}/users/${userID}/modules`).get();
  return userModuleInfoFields.docs
}

async function fetchOrganizationModuleInfoFields() {
  const organizationModuleInfoFields = await db.collection(`tenants/${store.state.tenantID}/settings/`).doc('default-always-shown').get();
  return organizationModuleInfoFields.exists ? organizationModuleInfoFields.data() : null 
}

async function loadModules(): Promise<any> {
  const modules = []
  const snapshot = await db.collection(`tenants/${store.state.tenantID}/modules`).get();

  for (const doc of snapshot.docs) {
    modules[doc.id] = doc.data() as Module;
  }
  const fieldFactory = new FieldFactory();
  for (const module in modules) {
    const moduleDoc = modules[module];
    for (const f in moduleDoc.fields) {
      const field = moduleDoc.fields[f];
      moduleDoc.fields[f] = fieldFactory.getFieldBasedOnType(field);
    }
  }
  return modules;
}

async function loadModuleInfoFields(userID: string): Promise<any> {
  const modulesInfoFields = []
  const userModuleInfoFields = await fetchUserModuleInfoFields(userID)
  const organizationModuleInfoFields = await fetchOrganizationModuleInfoFields()
  for (const doc of userModuleInfoFields) {
    const docData = doc.data() as Module;
    if(organizationModuleInfoFields && organizationModuleInfoFields[doc.id] && Object.keys(organizationModuleInfoFields[doc.id]).length) {
      modulesInfoFields[doc.id] = organizationModuleInfoFields[doc.id];
    }
    if(docData['always-shown-fields']) {
      modulesInfoFields[doc.id] = docData['always-shown-fields'];
    }
  }
  return modulesInfoFields
}

export const useModulesStore = defineStore("modules", {
  state: (): {
    modules: Modules;
    modulesInfoFields: Modules;
  } => ({
    modules: {},
    modulesInfoFields: {},
  }),
  getters: {
    async initModules() {
      this.modules = await loadModules()
      this.modulesInfoFields = await loadModuleInfoFields(store.state.userID); 
    }
  },
  actions: {
    getModule(moduleID: string) {
      return this.modules[moduleID];
    },
    getDropDownValues(moduleID: string) {
      return this.modules[moduleID].dropDownValues;
    },
    getField(moduleID: string, fieldID: string) {
      if(this.modules[moduleID] && this.modules[moduleID].fields&& this.modules[moduleID].fields[fieldID]) {
        return this.modules[moduleID].fields[fieldID];
      }
      return null
    },
    getFields(moduleID: string) {
      return this.modules[moduleID].fields;
    },
    getMandatoryFields(moduleID: string) {
      const mandatoryFields = []
      const fields = this.getFields(moduleID)
      for(const f in fields) {
        if(fields[f] && fields[f].mandatory) {
          mandatoryFields.push(fields[f]);
        }
      }
      return mandatoryFields
    },
    getModuleInfoFields(moduleID: string) {
      // TODO create interface
      if(this.modulesInfoFields[moduleID]) {
        return this.modulesInfoFields[moduleID]
      }
    },
    checkIfSectionDisabled(moduleID: string, fieldID: string, rowData: any) {
      const module = this.getModule(moduleID)
      const sectionID = module.fields[fieldID]['section']
      if(module.sections[sectionID] && module.sections[sectionID].hidden) {
        return "HIDDEN"
      }
      if(module.sections[sectionID] && module.sections[sectionID].disabled) {
        return "DISABLED"
      }
      if(sectionID && sectionID && module.sections[sectionID] && module.sections[sectionID].fieldWiseControl) {
        const fieldWiseControl = module.sections[sectionID].fieldWiseControl
        const field = fieldWiseControl.field
        const value = rowData[field]
        if(!Utils.isEmpty(value)  && fieldWiseControl && fieldWiseControl.values) {
          if(fieldWiseControl.values[value] === "DISABLED") {
            return "DISABLED"
          }
          if(fieldWiseControl.values[value] === "HIDDEN") {
            return "HIDDEN"
          }
        }
      }
      return false
    },
    setModuleInfoFields(moduleID: string, fields) {
      this.modulesInfoFields[moduleID] = fields;
      saveModuleInfoFields(moduleID, fields)
    }
  },
});

export default useModulesStore;
