import Column from "./Column";
import VueI18n from '@/i18n'

export default class Columnns {

    getTableColumns(): Column[] {
        const frenchLabel: any = VueI18n.t('moduleTranslations.headers.french')
        const germanLabel: any = VueI18n.t('moduleTranslations.headers.german')
        const englishLabel: any = VueI18n.t('moduleTranslations.headers.english')
        const dutchLabel: any = VueI18n.t('moduleTranslations.headers.dutch')
        const columns = [
            {
                name: 'dutch',
                label: dutchLabel,
                field: 'dutch',
                align: 'left',
                sortable: true,
            },
            {
                name: 'english',
                label: englishLabel,
                field: 'english',
                align: 'left',
                sortable: true,
            },
            {
                name: 'german',
                label: germanLabel,
                field: 'german',
                align: 'left',
                sortable: true,
            },
            {
                name: 'french',
                label: frenchLabel,
                field: 'french',
                align: 'left',
                sortable: true,
            }
        ]
        return columns
    }
}