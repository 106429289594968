
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import RoboticProcessDetailsDrawerTriggers from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/RoProDetailsDrawers/RoProDetailsDrawerTriggers.vue";
import RoboticProcessDetailsDrawerConditions from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/RoProDetailsDrawers/RoProDetailsDrawerConditions.vue";
import RoboticProcessDetailsDrawerActions from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/RoProDetailsDrawers/RoProDetailsDrawerActions.vue";
import AddNewAction from "@/components/Settings/Actions/addNewAction.vue";
import draggable from "vuedraggable";
import RoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/RoboticProcess";
import Types from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/Types";
import store from "@/store";
import db from "@/db";
import TypeOfActions from "@/components/Settings/Actions/typeOfActions";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import AddCopyProductLines from "@/components/Settings/Actions/addCopyProductLines.vue";
import AddRecord from "@/components/Settings/Actions/addRecord.vue";
import RelatedFieldCopy from "@/components/Settings/Actions/relatedFieldCopy.vue";
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddRelatedFieldCopy from "@/components/Settings/Actions/addRelatedFieldCopy.vue";

@Component({
  mixins: [CrudMixinVue],
  components: {
    draggable,
    RoboticProcessDetailsDrawerTriggers,
    RoboticProcessDetailsDrawerConditions,
    RoboticProcessDetailsDrawerActions,
    AddNewAction,
    AddFieldUpdate,
    AddCopyProductLines,
    AddRecord,
    RelatedFieldCopy,
    AddEmailTemplate,
    AddRelatedFieldCopy,
  },
})
export default class AddRoboticProcess extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) existingRoPro;
  @Prop({ required: true }) showCreateDialog;
  @Prop() selectedModule;
  @Prop() showTriggerAndCondition;
  columns = [
    {
      name: "name",
      label: this.$t("email.headers.name"),
      field: "name",
      align: "left",
      sortable: true,
    },

    {
      name: "type",
      label: this.$t("email.headers.type"),
      field: "type",
      align: "left",
      sortable: true,
    },
    {
      name: "module",
      label: this.$t("email.headers.module"),
      field: "module",
      align: "left",
      sortable: true,
    },
    {
      name: "selectable",
      label: "",
      field: "selectable",
      align: "left",
      sortable: true,
    },
    {
      name: "editor",
      label: "",
      field: "editor",
      align: "center",
      sortable: true,
    },
    {
      name: "delete",
      label: "",
      field: "delete",
      align: "center",
      sortable: true,
    },
    {
      name: "copy",
      label: "",
      field: "copy",
      align: "center",
      sortable: true,
    },
    {
      name: "modified",
      label: this.$t("email.headers.modifiedAt"),
      field: "modified",
      align: "center",
      sortable: true,
    },
  ];
  pagination = {
    rowsPerPage: 20,
  };
  tab = "triggers";
  loading = false;
  actionTab = "";
  showAddActionDialog = false;
  showAddAction = false;
  dialog = false;
  maximizedToggle = false;
  showActionInDrawer = false;
  showActionInRopro = false;
  showConditionInDrawer = false;
  showConditionInRoPro = false;
  showDetails = false;
  showTriggerInRoPro = false;
  FieldsToExecute = null;
  triggerFields = [];
  showDetailsTrigger = false;
  showDetailsCondition = false;
  showDetailsAction = false;
  detailType = "";
  DetailTypeConditions = "";
  selectedDetailView = {};
  roPro = new RoboticProcess();
  roProTypes = new Types().availableTriggers;
  TypeOfActions = new TypeOfActions().TypeOfActions;
  roProFieldUpdateActions = [];
  roProCopyProductLineActions = [];
  roProCreateRecordActions = [];
  roProRelatedFieldCopyActions = [];
  roProSendEmailActions = [];
  roProAction = [];
  options = this.roProTypes;
  existingAction = [];
  selectedTypeOfAction = "";
  editAction = false;
  closeStepFive = false;
  deleteOldFieldDialog = false;
  oldFieldList: any = []
  operatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThanOrEqualTo'), value: ">=" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThanOrEqualTo'), value: "<=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThan'), value: ">" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThan'), value: "<" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEmpty'), value: "isNotEmpty" },
    { label: this.$t('roboticProcessBuilder.fields.isEmpty'), value: "isEmpty" }
  ];
  createDialog = false;
  systemFields: Array<string> = []

  getFirePath() {
    return `modules/${this.selectedModule.value}/roboticProcesses`;
  }

  mounted() {
    this.roPro.type = {
      name: "Bij het wijzigen van een of meerdere velden",
      type: "FieldTrigger"
    }
    // check if we are creating a new ropro if not then we set everything for an existing ropro
    this.createDialog = this.showCreateDialog    
    if(this.showCreateDialog == false) {
      this.roPro = this.existingRoPro;
      this.oldFieldList = this.roPro.oldFieldList
      this.dialog = true;
      this.maximizedToggle = true;
      this.showTriggerInRoPro = this.showTriggerAndCondition;
      this.midStepper = 4; 
      this.step = 5;

      const module = this.dynamicModules[this.selectedModule.value]
      this.checkTriggerFieldExist(module, this.existingRoPro.fields)

      if(this.oldFieldList.length > 0) {
        this.deleteOldFieldDialog = true
      }
    }

    for(const criteriaPattern in this.roPro.criteriaPattern) {
      if(this.roPro.criteriaPattern[criteriaPattern].value == "and") {
        this.roPro.criteriaPattern[criteriaPattern].label = this.$t("roboticProcessBuilder.headers.And")
      } else if(this.roPro.criteriaPattern[criteriaPattern].value == "or") {
        this.roPro.criteriaPattern[criteriaPattern].label = this.$t("roboticProcessBuilder.headers.Or")
      } 
    }
  } 

  checkTriggerFieldExist(module, fields) {
    for(const field in fields) {
      const relatedField = fields[field].split(".")[0]
      for(const f in module.fields) {
        if(module.fields[f].name == relatedField){
          fields[field] = module.fields[f].name            
        }
      }
      const firstCharacters = fields[field].substring(0, 2)
      
      if(firstCharacters == "__") {
        this.systemFields.push(fields[field])
        const index = fields.indexOf(fields[field], 0)
        fields.splice(index, 1)
      } else {
        if(module.fields[fields[field]] == undefined) {
          this.oldFieldList.push(fields[field])
          const index = fields.indexOf(fields[field], 0)
          fields.splice(index, 1)
        }        
      }
    }
  }

  checkConditionFieldExist(module, conditions) {
    for(const con in conditions) {
      const condition = conditions[con]      
      const relatedField = condition.field.split(".")[0]
      for(const f in module.fields) {
        if(module.fields[f].name == relatedField){
          condition.field = module.fields[f].name            
        }
      }
      const firstCharacters = condition.field.substring(0, 2)
      const index = conditions.indexOf(condition, 0)
      console.log(conditions[index].field, "condition");
      // conditions.splice(index, 1)
      if(firstCharacters == "__") {
        this.systemFields.push(condition.field)
        const index = conditions.indexOf(condition, 0)
        conditions.splice(index, 1)
      } else if(module.fields[condition.field] == undefined) {
        this.oldFieldList.push(condition.field)
        const index = conditions.indexOf(condition, 0)
        conditions.splice(index, 1)
      }        
    }
  }

  closeDeleteOldFieldDialog() {
    this.deleteOldFieldDialog = false
  }

  getCriteriaPatternLabel(criteriaPattern) {
    let label: any = null
    if(criteriaPattern != undefined) {
      if(criteriaPattern.value == "and") {
        label = this.$t("roboticProcessBuilder.headers.And")
      } else if(criteriaPattern.value == "or") {
        label = this.$t("roboticProcessBuilder.headers.Or")
      } 
    }
    return label
  }

  async loadActions(actionTabTitle) {
    const firePath =
      "/tenants/" +
      store.state.tenantID +
      "/actions/" +
      actionTabTitle +
      "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });

    this.roProAction = docs.map((d) => {
      return {
        ID: d.ID,
        title: d.title,
        description: d.description,
        type: d.type,
      };
    });
  }
  
  onRoProCreate() {
    this.showCreateDialog = false
    this.dialog = true;
    this.maximizedToggle = true;
    this.showTriggerInRoPro = this.showTriggerAndCondition;    
  }

  setDetailsTrigger(details, type) {
    this.selectedDetailView = details;
    this.detailType = type;
    this.DetailTypeConditions = type;
    this.showDetailsTrigger = true;
    this.showDetailsCondition = false;
    this.showDetailsAction = false;
    let index = 0;
    for (const i in this.roPro.actions) {
      this.roPro.actions[i].order = index;
      index += 1;
    }
  }

  setDetailsCondition(details, type) {
    this.selectedDetailView = details;
    this.detailType = type;
    this.DetailTypeConditions = type;
    this.showDetailsTrigger = false;
    this.showDetailsCondition = true;
    this.showDetailsAction = false;
  }

  setDetailsAction(details, type) {
    this.selectedDetailView = details;
    this.detailType = type;
    this.DetailTypeConditions = type;
    this.showDetailsTrigger = false;
    this.showDetailsCondition = false;
    this.showDetailsAction = true;
  }

  setTrigger(selectedTrigger) {    
    this.roPro.type = selectedTrigger;
    this.selectedDetailView = selectedTrigger;
    this.toggleCondition(this.roPro.conditions);
    this.toggleTrigger();
  }

  setActionsHeader(selectedActions) {
    this.roPro.actions = selectedActions;
    this.selectedDetailView = selectedActions;
  }
  // to show the Trigger in the Robotic process
  toggleTrigger() {
    this.showActionInDrawer = true;
    this.showConditionInDrawer = true;
    this.showDetails = true;
    this.showTriggerInRoPro = true;
    this.tab = "actions";
  }

  // to show the Condition in the Robotic process
  toggleCondition(selectedConditionName) {
    this.showConditionInRoPro = true;
    this.showActionInDrawer = true;
    this.roPro.conditions = selectedConditionName;
  }
  // to show the Action in the Robotic process
  toggleAction(selectedAction, index) {
    this.showActionInRopro = true;
    let actionType = "";
    if (this.actionTab["title"] == "FieldUpdates") {
      actionType = "FieldUpdate";
    } else if (this.actionTab["title"] == "CopyProductLines") {
      actionType = "CopyProductLine";
    } else if (this.actionTab["title"] == "CreateRecords") {
      actionType = "CreateRecord";
    } else if (this.actionTab["title"] == "SendEmails") {
      actionType = "SendEmail";
    } else if (this.actionTab["title"] == "RelatedFieldCopies") {
      actionType = "RelatedFieldCopy";
    }
    this.roPro.actions.push({
      title: selectedAction.title,
      description: selectedAction.description,
      order: index,
      parameters: {},
      type: actionType,
      ID: selectedAction.ID,
    });
  }

  // Function to convert timestamp to normal date
  converDate(isostr) {
    if (!isostr) {
      return '';
    }
    const date = new Date(isostr.seconds*1000);    
    return date.toLocaleString('nl-Nl')
  }

  get modulesList() {
    const modulesList = Array<{ label: string; value: string }>();
    Object.keys(this.dynamicModules).forEach((module) => {
      modulesList.push({
        label: this.getModuleTitle(module),
        value: module,
      });
    });
    return modulesList;
  }

  deleteActionFromRoPro(index) {
    this.roPro.actions.splice(index, 1);
  }

  changeActionOrder() {
    let index = 0;
    for (const i in this.roPro.actions) {
      this.roPro.actions[i].order = index;
      index += 1;
    }
  }

  async openAction(selectedAction) {
    for (const action in this.roProAction) {
      if (this.roProAction[action]["ID"] == selectedAction.ID) {
        this.existingAction = this.roProAction[action];
      }
    }
    if (this.actionTab["title"] == "SendEmails") {
      this.selectedTypeOfAction = "sendEmail";
      this.editAction = true;
    } else if (this.actionTab["title"] == "RelatedFieldCopies") {
      this.selectedTypeOfAction = "relatedFieldCopy";
      this.editAction = true;
    } else if (this.actionTab["title"] == "CreateRecords") {
      this.selectedTypeOfAction = "createRecord";
      this.editAction = true;
    } else if (this.actionTab["title"] == "CopyProductLines") {
      this.selectedTypeOfAction = "copyProductLine";
      this.editAction = true;
    } else if (this.actionTab["title"] == "FieldUpdates"){
      this.selectedTypeOfAction = "fieldUpdate"
      this.editAction = true
    }
  }

  async saveRoboticProcess() {
    const failedSaving: any = this.$t('common.messages.failedSaving')
    if (this.roPro.validateJSONData() == true) {
      let roProExport = new RoboticProcess();
      roProExport = await this.roPro.export(this.systemFields, this.dynamicModules[this.selectedModule.value].fields)
      
      await this.saveRecord(roProExport)
      store.state.alertMessage = "add";
      this.dialog = false
      this.getShowAddRoProBuilder()
    } else {
      this.$q.notify({
          message: failedSaving,
          color: "negative",
          icon: "warning",
        });    
    }
    const list = []
    return this.roPro.export(list, this.dynamicModules[this.selectedModule.value].fields);
  }

  closeDialog() {
    this.roPro.actions = [];
    this.roPro.conditions = [];
    this.roPro.title = '';
    this.roPro.description = '';
    this.createDialog = false
    this.closeAddRoProBuilderDialog()
  }

  // emit function to set the value to false
  @Emit("closeEditor")
  closeAddRoProBuilderDialog() {     
    return this.createDialog;
  }
  // emit function to set the value to false
  @Emit("openEditor")
  getShowAddRoProBuilder() {    
    this.step > 4
    return false;
  }

  data() {
    return {
      midStepper: 1,
      step: 1,
    };
  }
  getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
    return `${firstRowIndex}-${endRowIndex} ${this.$t(
      "table.misc.of"
    )} ${totalRowsNumber}`;
  }

  getCheckboxValue(filter) {
    if(filter == true) {
      return this.$t('roboticProcessBuilder.messages.true')
    } else {
      return this.$t('roboticProcessBuilder.messages.false')
    }
  }

  getOperatorLabel(conditionOperator) {    
      for(const operatorOption in this.operatorOptions) {
        if(this.operatorOptions[operatorOption].value == conditionOperator) {
          return this.operatorOptions[operatorOption].label
        }
      }
  }
  loopTroughStepper() {
    if(this.midStepper < this.step){
      this.midStepper += 1
    } else {
      null
    }
  }
}
