
  import {date} from "quasar";
  import { Timestamp } from '@firebase/firestore';
  import {mapActions, mapState} from "vuex";
  import { EventBus } from "@/event-bus";
  import store from "./../../../store";
  import Permission from "@/common/helpers/Permission";
  import jexl from 'jexl';
  import Utils from "@/components/utils/Utils"
  import db from "@/db";
  import cloneDeep from "lodash/cloneDeep"

  export default {
    name: "BaseExtend",
    props: {
      value: null, options: null, label: null, name: null , formData: null, formName: null,
      relatedModule: null, relatedFieldCopy: null, relatedByField: null, refreshFieldOnChange: null,
      relatedQuery: null, relatedToByQuery: null, relatedToField: null,
      subFormField: null, subRecordField: null, lineType: null, sectionLabel: null,
      sectionColumns: null, customButtons: null, fieldDisabled: null, fieldReadonly: null, subFormMode: null,
      // TODO refactor to the field and section
      field: null, section: null, alwaysMandatory: null
    },
    components: {},
    data() {
      return {
        noValid: false,
        noValidMessage: '',
        soptions: null,
        copyCurrentValue: null,
        relatedOptions: [],
        needFocusedToNextField: false
      };
    },
    methods: {
      ...mapActions("query", ["queryRelatedFieldOptions"]),
      fieldWiseControl(prop) {
        if(this.section && this.section.name && this.formData.dynamicModule.sections[this.section.name] && this.formData.dynamicModule.sections[this.section.name].fieldWiseControl) {
          const fieldWiseControl = this.formData.dynamicModule.sections[this.section.name].fieldWiseControl
          const field = fieldWiseControl.field
          const value = this.formData.data[field] 
          if(!Utils.isEmpty(value)  && fieldWiseControl && fieldWiseControl.values) {
            if(fieldWiseControl.values[value] === prop) {
              return true
            }
          }
        }
        return false
      },
      async getDataForDropDownExpression(dropdownExpression) {
        const data = dropdownExpression.split('.');
        const formData = cloneDeep(this.formData.data);
        for(const val of data) {
          const field = val.split(' ')[val.split(' ').length -1]
          if(!field.includes("'")) {
            const relatedModule = this.formData.dynamicModule.fields[field].relatedModule
            let relatedFieldData = formData[field]
            if(!Utils.isEmpty(formData[field].ID)) {
              relatedFieldData = await this.getRecord(relatedModule, formData[field].ID)
            }
            formData[field] = relatedFieldData
          }
        }
        return formData
      },
      async getRecord(relatedModule, relatedRecord) {
        const doc = await db.collection(`/tenants/${store.state.tenantID}/modules/${relatedModule}/records`).doc(relatedRecord).get().catch((err) => {
          console.log('Error while getting doc: ', err)
        })
        if(doc.exists) {
          return doc.data()
        }
        return {}
      },
      onFieldChange(val) {
        return val;
      },
      cleanModel() {
        if (this.isReadonly) {
          return false;
        }
        this.model = '';
      },
      async filterAutocomplete(val, update) {
        let filterOptions = (this.options || this.relatedOptions)
        if(this.field.dropdownExpression) {
          let value = null
          if(this.field.dropdownExpression.includes('.')) {
            const formData = await this.getDataForDropDownExpression(this.field.dropdownExpression)
            value = await jexl.eval(this.field.dropdownExpression, formData).catch(() => {
              return null
            })
          } else {
            value = await jexl.eval(this.field.dropdownExpression, this.formData.data).catch(() => {
              return null
            })
          }
          if(!Utils.isEmpty(value)) {
            filterOptions = this.formData.dropDownValues[value]
            const translatedDropdownOptions = []
            for(const dropdownValue of filterOptions) {
              const translationKey = `${this.formData.module}.${this.field.name}.${dropdownValue}`
              if(store.state && store.state.dropDownMap && store.state.dropDownMap[translationKey]) {
                translatedDropdownOptions.push(store.state.dropDownMap[translationKey])
              } else {
                translatedDropdownOptions.push(dropdownValue)
              }
            }
            filterOptions = translatedDropdownOptions
          } else {
            filterOptions = []
          }
        }
        if(this.field.name == 'status' && this.field.metaData){
          filterOptions = await this.getStatusDropDownValues()
        }
        update(() => {
          const needle = val.toLocaleLowerCase();
          this.soptions = filterOptions.filter(
            (v) => {
              const name = v.name ? v.name : v;
              return String(name).toLocaleLowerCase && String(name).toLocaleLowerCase().indexOf(needle) > -1
            }
          );
        });
      },
      async getStatusDropDownValues() {
        let statuses = []
        let validateOnOrder = true
        for(const s in this.field.metaData) {
          const status = this.field.metaData[s]
          if(!status.order) {
            validateOnOrder = false
          }
          statuses.push(status)
        }
        if(validateOnOrder) {
          statuses = await this.sortList(statuses, 'order')
        }
        const dropDownValues = []
        for(const status of statuses) {
          const translationKey = this.formData.module  + '.' + this.field.name + '.' + status.label
          if(store.state && store.state.dropDownMap && store.state.dropDownMap[translationKey]) {
            dropDownValues.push(store.state.dropDownMap[translationKey])
          } else {
            dropDownValues.push(status.label)
          }
        }
        return dropDownValues
      },
      sortList(fields, keyToSort) {
        return fields.sort((a,b) => {
          const ak = a ? (a[keyToSort] || '') : ''
          const bk = b ? (b[keyToSort] || '') : ''
          return (parseInt(ak) > parseInt(bk)) ? 1 : ((parseInt(bk) > parseInt(ak)) ? -1 : 0)
        })
      },
      toDateValue(val) {
        if (val && typeof val.toDate === "function") {
          return date.formatDate(val.toDate(), "YYYY-MM-DD");
        }
        if (val && val instanceof Date) {
          return date.formatDate(val, "YYYY-MM-DD");
        }
        return val;
      },
      toDateTimeValue(val) {
        if (val && typeof val.toDate === "function") {
          return date.formatDate(val.toDate(), store.state.dateTimeMask);
        }
        if (val && val instanceof Date) {
          return date.formatDate(val, store.state.dateTimeMask);
        }
        return val;
      },
      toTimestampValue(val) {
        if (store.state.dateTimeMask === "DD-MM-YYYY HH:mm") {
          val = date.extractDate(val, "DD-MM-YYYY HH:mm");
          val = date.formatDate(val, "YYYY-MM-DD HH:mm");
        }
        return Timestamp.fromMillis(Date.parse(val));
      },
      validate() {
        const result = this.isValid();
        this.noValid = !result.valid;
        this.noValidMessage = this.noValid ? result.errorMessage : '';
        return result.valid;
      },
      resetValidation() {
        this.noValid = false;
        this.noValidMessage = '';
      },
      async loadRelatedOptionsByCommand() {
        if (this.loadRelatedFieldQueryOptions) {
          await this.loadRelatedFieldQueryOptions();
        }
      },
      addValidateToForm() {
        this.$emit('addFieldToValidationList', {
          name: this.name,
          validate: this.validate,
          resetValidation: this.resetValidation,
          loadRelatedOptions: this.loadRelatedOptionsByCommand
        });
      },
      isValid() {
        const fieldName = this.name;
        const field = this.formData.dynamicFields[fieldName];
        const errorMessage = 'Please select field';
        const value = this.formData.data[this.name];
        if (field.mandatory || this.alwaysMandatory) {
          const noValid = value === undefined || value === null || value === '' || (Object.prototype.hasOwnProperty.call(value, 'ID') && !value.ID);
          if (noValid) {
            const result = {valid: false, errorMessage: field.mandatoryErrorMessage || errorMessage}
            return result;
          }
        }

        if (field.min) {
          if (String(value).length < Number(field.min)) {
            const result = {valid: false, errorMessage : field.minErrorMessage || errorMessage}
            return result;
          }
        }

        if (field.max) {
          if (String(value).length > Number(field.max)) {
            const result = {valid: false, errorMessage : field.maxErrorMessage || errorMessage}
            return result;
          }
        }

        return {valid: true, errorMessage : '' };
      },
      refreshAfterCopy() {
        //
      },
      focusToNextField(){
        //  temporary disable go to next field
        /*if (this.needFocusedToNextField && !this.formData.data.ID) {
          this.$emit('focusToNextField', this.name)
        }*/
      },
      onFieldFocus() {
        // open the dropdown
        setTimeout(() => {
          if (typeof this.$refs[this.name]?.showPopup === 'function') {
            const theFirst = this.field.column === 1 && this.field.order === 1;
            if (!theFirst && !this.modalFormID) {
              this.$refs[this.name].showPopup();
            }
          }
        }, 250)
      },
      updateFieldValueAfterCopy(data) {
        if (data.name === this.name && this.formData.module === data.module) {
          this.refreshAfterCopy(data.value);
        }
      }
    },
    mounted() {
      this.soptions = this.options || this.relatedOptions;
      this.addValidateToForm();
    },
    computed: {
      ...mapState(["PORTAL", "portalViewHidden"]),
      model: {
        get() {
          if(store.state.dropDownMap[`${this.formData.module}.${this.field.name}.${this.value}`]) {
            return store.state.dropDownMap[`${this.formData.module}.${this.field.name}.${this.value}`]
          }
          return this.value;
        },
        set(val) {
          this.formData.editingFields.push(this.name)
          this.onFieldChange(val);
          this.$emit('input', val)
        }
      },
      // RBAC
      isModuleShow() {
        return this.formData.guard.checkModule(this.module);
      },
      isAddShow() {
        return !this.isSectionReadonly && !this.formData.guard.hide('create', this.module);
      },
      isDeleteShow() {
        return !this.isSectionReadonly && !this.formData.guard.hide('delete', this.module);
      },
      isAddDisabled() {
        return !this.formData.guard.check('create', this.module);
      },
      isDeleteDisabled() {
        return !this.formData.guard.check('delete', this.module);
      },
      isDisabled() {
        const isDisabled = this.fieldDisabled || (this.field ? this.field.disabled : false);
        if(isDisabled) return isDisabled
        const fieldWiseControl = this.fieldWiseControl('DISABLED')
        if(fieldWiseControl) return fieldWiseControl
        return false
      },
      isReadonly() {
        if (this.isLocked) {
          return true;
        } else {
          return this.fieldReadonly || (this.field ? this.field.readonly : false);
        }
      },
      isLocked: {
        cache: false,
        get () {
          const res = this.field ? this.field.locked : false;
          return res;
        }
      },
      isSectionHidden() {
        return this.section.hidden;
      },
      isSectionDisabled() {
        return this.section.disabled;
      },
      isSectionReadonly() {
        if (this.isSectionLocked) {
          return true;
        } else {
          return this.section.readonly || this.isSectionDisabled || !this.hasWritePermission;
        }
      },
      isSectionLocked() {
        return this.section ? this.section.locked : false;
      },
      hasWritePermission() {
        return Permission.section(this.section, 'write');
      },
      isDense() {
        return this.field ? this.field.dense : false;
      },
      isAutofocused() {
        return !this.formData.data.ID && this.section && this.field
          ? this.section.order === 1 && this.field.order === 1 && this.field.column === 1
          : false;
      },
      cLabel() {
        let label = this.label ? this.label : this.$t(this.formData.model + '.fields.' + this.name);
        if (store.state.debugMode) {
          label = `${label} (${this.name})-(${this.field.order})`;
        }
        return label;
      },
      hasErrorOrEmpty() {
        return this.noValid || this.showMandatoryEmpty;
      },
      showMandatoryEmpty() {
        const fieldName = this.name;
        const field = this.formData.dynamicFields[fieldName];
        if (!field) {
          return false;
        }
        const value = this.formData.data[this.name];
        const noValid = value === undefined || value === null || value === '' || (Object.prototype.hasOwnProperty.call(value, 'ID') && !value.ID);
        return (field.mandatory || this.alwaysMandatory) && noValid;
      },
      isShowMandatoryEmpty() {
        return this.showMandatoryEmpty && !this.noValid;
      },
      hiddenParts() {
        if (this.PORTAL) {
          return this.portalViewHidden;
        } else {
          return {};
        }
      }
    },
    watch: {
      'formData.focusedFieldName': {
        handler(value) {
          if (value === this.name) {
            if (typeof this.$refs[this.name].focus === 'function') {
              this.$refs[this.name].focus();
            } else {
              //TODO:: make set focus for 'checkbox' field
            }
            this.formData.focusedFieldName = '';
          }
        },
        deep: true
      },
    },
    beforeMount() {
      EventBus.$on('needToUpdateFieldValueAfterCopy',this.updateFieldValueAfterCopy);
    },
    beforeDestroy() {
      EventBus.$off('needToUpdateFieldValueAfterCopy', this.updateFieldValueAfterCopy);
    },
    destroyed() {
      delete this.queryRequestData;
    }
  }
