
import {Component, Prop, Vue, Emit} from 'vue-property-decorator';
import {State} from "vuex-class";
import store from '@/store';
import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
import UnlayerTools, {UnlayerSettings} from "@/components/Settings/Sections/Templates/EmailTemplates/Models/UnlayerTools";
import TemplateConstructor from '@/components/Settings/Sections/Templates/EmailTemplates/Components/TemplateConstructor.vue';
import ListValues from "@/components/Mixin/ListValues";
// import TagsBlock from "@/components/Parts/TagsBlock.vue";

class CurrentTemplateClass {
    id = null;
    ID = null;
    module = '';
    type = '';
    name = '';
    design = null;
    userID = '';
    from = '';
    subject = '';
    to = '';
    cc = '';
    bcc = '';
    tags = [];
  }

  class SelectedRecordClass {
    ID = "";
    name = "";
  }

  @Component({
    components: {TemplateConstructor, BaseFieldRelated}
  })


export default class OpenEmailEditor extends Vue {
    @State("tenantID") tenantID;
    @State("dynamicModules") dynamicModules;
    @Prop(Boolean) isUserEdit;
    @Prop(Boolean) showCreateTemplateDialog;
    @Prop(Boolean) showOpenEditorDialog;
    @Prop() currentTemplateClass;
    newTemplateName = "";
    selectedModule = '';
    selectedRecord = new SelectedRecordClass();
    currentTemplate = new CurrentTemplateClass();
    templates = [];
    templates2 = [];
    userTemplates = [];
    tenantTemplates = [];
    globalTemplates = [];
    mergeTags = {};
    formData = {};
    inputJson = null;
    designTags = {};
    designTagsDB = {};
    showModalEditor = false;
    showCreateDialog = false
    showEditDialog = false
    recordSelectKey = 1;
    settings = new UnlayerSettings();

    async mounted() {        
        if(this.showOpenEditorDialog){
            this.showEditDialog = this.showOpenEditorDialog
            this.showCreateDialog = false;
            this.currentTemplate = this.currentTemplateClass
            for(const module of this.modulesList) {
              if(module.name == this.currentTemplate.module) {
                this.selectedModule = module
              }
            }
            if (this.selectedModule) {
                this.formData = await PdfTools.getFormDataForPDF(this.selectedModule['name']);
                this.mergeTags = this.getTagsFormData(this.formData);
            } else {
                this.formData = {};
            }
        }   
        if(this.showCreateTemplateDialog) {
          this.showCreateDialog = this.showCreateTemplateDialog
            this.showEditDialog = false;
        }     
    }

    async onTemplateCreate() {
      this.currentTemplate = new CurrentTemplateClass();
      this.currentTemplate.module = this.selectedModule["name"] || '';
      
      
      await this.saveTemplate(this.currentTemplate);
      this.eraseTheFormFields();
      store.state.alertMessage = "add";
      this.showCreateDialog = false;
      this.getOpenedCreateDialogStatus()
    }

    async getAllTemplates() {
      (this.globalTemplates as any) = await UnlayerTools.getGlobalTemplates();
      (this.tenantTemplates as any) = await UnlayerTools.getTenantTemplates();
      (this.userTemplates as any) = await UnlayerTools.getUserTemplates();
      this.templates = [].concat(this.globalTemplates, this.tenantTemplates, this.userTemplates);
    }

    async getSettings() {
      (this.settings as any) = await UnlayerTools.getSettings();
    }

    async saveTemplate(template) {
      const currentDesign = JSON.parse(JSON.stringify(template));
      currentDesign.name = this.newTemplateName;
      currentDesign.updatedAt = new Date().toISOString();
      currentDesign.userID = this.isUserEdit ? store.state.userID : 'tenant';
      delete(currentDesign.type);
      const res = await UnlayerTools.saveTemplate(currentDesign);
      await this.getAllTemplates();
      return res;
    }

    async saveTemplateConstructor(template) {
      const currentDesign = JSON.parse(JSON.stringify(template));
      currentDesign.updatedAt = new Date().toISOString();
      delete(currentDesign.type);
      const res = await UnlayerTools.saveTemplate(currentDesign);
      store.state.alertMessage = "update";
      await this.getAllTemplates();
      return res;
    }

    prepareTags(template) {
        this.currentTemplate = template;
        this.currentTemplate.tags = Array.isArray(template.tags) ? template.tags : [];
    }
    saveTags(tags) {
        const tData = {tags: tags.map(t => t.trim()) || []};
        this.saveTemplateRename(tData)
    }
    async saveTemplateRename(tData) {
      const saveData = {
        ID: this.currentTemplate.ID,
        userID: this.currentTemplate.userID,
        ...tData
      };
      
      await UnlayerTools.saveTemplate(saveData);
      store.state.alertMessage = "update";
      await this.getAllTemplates();
      this.currentTemplate = { ...this.currentTemplate, ...tData };
    }

    async onRecordSelect() {
      await this.updateTemplate();
      if (this.selectedModule) {
        this.currentTemplate.module = this.selectedModule['name'];
        
      }
      if (this.selectedModule && this.selectedRecord) {
        const formData = await PdfTools.getFormDataForPDF(this.currentTemplate.module, this.selectedRecord.ID);
        const inputJson = await PdfTools.getInputJsonForPDF(formData);
        this.mergeTags = this.getTagsFormData(this.formData);
        
        this.onTemplateEdit(inputJson);
      } else {
        this.onTemplateEdit();
      }
      this.selectedRecord = new SelectedRecordClass;
    }

    async onSelectModule() {
      this.formData = await PdfTools.getFormDataForPDF(this.selectedModule['name']);
      this.recordSelectKey++;
      this.selectedRecord = new SelectedRecordClass;
    }

    onTemplateEdit(inputJson = null) {
      this.inputJson = inputJson;
      this.showModalEditor = true;
    }

    async updateTemplate() {
      const saveData = {
        ID: this.currentTemplate.ID,
        userID: this.currentTemplate.userID,
        module: this.selectedModule['name'] || '',
      };
      await UnlayerTools.saveTemplate(saveData).then(() => { this.getAllTemplates(); });
    }

    onCancelSelectedRecordDialog(){
      this.eraseTheFormFields();
      this.selectedRecord = new SelectedRecordClass();
      this.currentTemplate = new CurrentTemplateClass();
      this.getOpenedEditorStatus()
    }

    onCancelCreateDialog(){
      this.eraseTheFormFields();
      this.getOpenedCreateDialogStatus()
    }

    eraseTheFormFields() {
      this.newTemplateName = '';
      this.selectedModule = '';
    }

    getTagsFormData(formData) {
      const fields: any = ListValues.getValidColumns(formData.dynamicFields);
      const tags  = {};
      
      for(const fname in fields) {
        let sname = '';
        // eslint-disable-next-line no-prototype-builtins
        if (formData.data[fname] && Object.prototype.hasOwnProperty.call(formData.data[fname], 'name')) {
          sname = '.name';
        }
        tags[fname] = {name: fields[fname].label, value: '{{' + fname + sname + '}}'}
      }
      
      return tags;
    }

    get modulesList() {
      const modulesList: any = []
      let showModules = {};
      for (const modules in this.dynamicModules) {
        if (this.dynamicModules[modules].pluralName != undefined) {
          showModules = {
            label: this.dynamicModules[modules].pluralName,
            name: this.dynamicModules[modules].id,
          };
        } else {
          showModules = {
            label: this.dynamicModules[modules].name,
            name: this.dynamicModules[modules].id,
          };
        }
        modulesList.push(showModules);
      }
      return modulesList
    }

    get curTenantID() {
      return this.tenantID;
    }

    closeEmailEditor(value: any) {
        this.showModalEditor = value
        this.getOpenedEditorStatus()
    }

    @Emit("emailEditorOpened")
    getOpenedEditorStatus() {
        this.showEditDialog = false
        return false
    }
    @Emit("openCreateDialogStatus")
    getOpenedCreateDialogStatus() {
        this.showCreateDialog = false
        return false
    }
}
