

export default class Column {
    getBaseColumn() {
        return {
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            menuTabs: ['generalMenuTab'],
            minWidth: 75
        }
    }

}