
  import {Component, Vue} from "vue-property-decorator";
  import { State } from "vuex-class";
  import QPdfviewer from "@/components/Settings/Sections/Templates/PDFgenerator/Helpers/QPdfviewer";
  import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
  import Utils from "@/components/utils/Utils";
  import DeleteDocTemplateRequest from "@/dto/api/DeleteDocTemplateRequest.dto";
  import CreateDocTemplateRequest from "@/dto/api/CreateDocTemplateRequest.dto";
  import UpdateDocTemplateRequest from "@/dto/api/UpdateDocTemplateRequest.dto";
  import RenderDocTemplateRequest from "@/dto/api/RenderDocTemplateRequest.dto";
  import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
  import PreviewFileDialog from "@/components/Parts/PreviewFileDialog.vue"
import sortList from "@/common/helpers/utilities";
import store from "@/store";

  class CurrentTemplateClass {
    ID = null
    name = null;
    fileName = null;
    fileType = null;
    module = null;
    tags: Array<string> = []
  }

  class SelectedRecordClass {
    ID = "";
    name = "";
  }

  @Component({
    components: {QPdfviewer, BaseFieldRelated, PreviewFileDialog}
  })
  export default class DocTemplates extends Vue {
    @State("tenantID") tenantID;
    @State("dynamicModules") dynamicModules;

    templates = [];
    columns = [
      {
        name: 'name',
        label: this.$t('pdf.headers.name'),
        field: 'name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'module',
        label: this.$t('pdf.headers.module'),
        field: 'module',
        align: 'left',
        sortable: true,
      },
      {
        name: 'fileName',
        label: this.$t('pdf.headers.filename'),
        field: 'fileName',
        align: 'left',
        sortable: true,
      },
      {
        name: 'update',
        label: '',
        field: 'update',
        align: 'center',
      },
      {
        name: 'download',
        label: '',
        field: 'download',
        align: 'center',
      },
      {
        name: 'render',
        label: '',
        field: 'render',
        align: 'center',
      },
      {
        name: 'delete',
        label: '',
        field: 'delete',
        align: 'center',
      }
    ];
    pagination = {
      rowsPerPage: 20
    };
    loading = false;
    showCreateDialog = false;
    showUpdateDialog = false;
    showDeleteDialog = false;
    showRenderDialog = false;
    newTemplateName = null;
    base64 = null;
    fileName = null;
    fileType = 'docx';
    currentTemplate = new CurrentTemplateClass();
    selectedModule = null;
    formData = {};
    availableModules = [];
    selectedRecord = new SelectedRecordClass();
    renderedTemplate = null  
    get relatedModule() {
      return this.currentTemplate.module;
    }
    showFileDialog = false
    previewFileDialog = new PreviewFileDialog()
    maximizedToggle = true


    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }


    convertFileToBase64(uploadedFile){
      const file = new Blob(uploadedFile)
      this.getBase64(file).then((data) => {
        this.base64 = data
        this.base64 = this.base64.replace("data:application/octet-stream;base64,", '')
      });
    }

    onFileRejected () {
        this.$q.notify({type: 'negative', message: 'File did not pass validation constraints'})
    }

    validateTemplateData() {
      let validData = true
      if(Utils.isEmpty(this.newTemplateName)) { 
        this.$q.notify({ message: `Verplicht veld 'Naam' niet gevuld.`, color: 'negative'})
        validData = false
      }
      if (Utils.isEmpty(this.fileName)) { 
        this.$q.notify({ message: `Verplicht veld 'Bestandsnaam' niet gevuld.`, color: 'negative'})
        validData = false
      }
      if (Utils.isEmpty(this.fileType)) { 
        this.$q.notify({ message: `Verplicht veld 'Bestandstype' niet gevuld.`, color: 'negative'})
        validData = false
      }      
      if (this.selectedModule == null) { 
        this.$q.notify({ message: `Verplicht veld 'Module' niet gevuld.`, color: 'negative'})
        validData = false
      }
      if (Utils.isEmpty(this.base64)) { 
        this.$q.notify({ message: `Verplicht veld 'Bestand' niet gevuld.`, color: 'negative'})
        validData = false
      }
      return validData
    }

    clickCreateTemplate() { 
      this.currentTemplate = new CurrentTemplateClass()
      this.fileName = ''
      this.newTemplateName = ''
      this.showCreateDialog = true
    }

    async createTemplate(): Promise<void> {
      const templateData: CreateDocTemplateRequest = { 
        name: this.newTemplateName,
        fileName: this.fileName,
        fileType: this.fileType,
        module: this.selectedModule['value'],
        file: this.base64
      }
      const valid = this.validateTemplateData()
      if (valid) { 
        await this.$store.dispatch('api/createDocTemplate', templateData).then(() => {
            this.$q.notify({ message: `Template aangemaakt.`, color: 'positive'})
          this.getAllTemplates();
        }).catch(err => {
          console.log(err, "error");
          this.$q.notify({ message: `Fout bij het aanmaken van een nieuwe template.`, color: 'negative'})
        });
        this.showCreateDialog = false
      }
      this.currentTemplate = new CurrentTemplateClass()
    }

    cancelCreateTemplate(){
      this.newTemplateName = '';
    }

    async getAllTemplates(): Promise<void> {
        this.templates = []
        const templates = await this.$store.dispatch('api/getDocTemplates').then((templates) => {
          if(templates && templates.data) return templates.data
          else return []
        }).catch(err => {
            console.log(err, "error");
        });
        for(const template of templates) {
          if(template.module === this.selectedModule['value']) {
            this.templates.push(template)
          }
        } 
    }

    clickUpdateTemplate(props){
      this.showUpdateDialog = true;
      this.currentTemplate = props.row;

      this.newTemplateName = this.currentTemplate.name
      this.fileName = this.currentTemplate.fileName
      this.fileType = this.currentTemplate.fileType
    }

    async updateTemplate() { 
      const templateData: UpdateDocTemplateRequest = {
        templateID: this.currentTemplate.ID,
        name: this.newTemplateName,
        fileName: this.fileName,
        fileType: this.fileType,
        module: this.selectedModule['id']
      }
      if(!Utils.isEmpty(this.base64)) { 
        templateData.file = this.base64
      }
      await this.$store.dispatch('api/updateDocTemplate', templateData).then(() => {
        this.$q.notify({ message: `Template is aangepast.`, color: 'positive'})
        this.getAllTemplates();
      }).catch(err => {
        console.log(err, "error");
        this.$q.notify({ message: `Fout bij het aanmaken van een nieuwe template.`, color: 'negative'})
      });

    }

    cancelUpdateTemplate(){
      this.newTemplateName = '';
    }

    clickDeleteTemplate(props){
      this.showDeleteDialog = true;
      this.currentTemplate = props.row;
    }

    async deleteTemplate() {
      const data: DeleteDocTemplateRequest = {
        templateID: this.currentTemplate.ID
      }
      await this.$store.dispatch('api/deleteDocTemplate', data).then(() => {
        this.$q.notify({ message: `Template succesvol verwijderd.`, color: 'positive'})
        this.getAllTemplates();
      }).catch(err => {
        console.log(err, "error");
        this.$q.notify({ message: `Fout tijdens het verwijderen van template.`, color: 'negative'})
      });        
    }

    cancelDeleteTemplate(){
      this.currentTemplate = new CurrentTemplateClass();
    }


    async getFormData() {
      this.formData = await PdfTools.getFormDataForPDF(this.currentTemplate.module);
    }

    async clickRenderTemplate(props) {
      this.currentTemplate = props.row      
      await this.getFormData();
      this.showRenderDialog = true
    }

    async renderTemplate() {
      const formData = await PdfTools.getFormDataForPDF(this.currentTemplate.module, this.selectedRecord.ID);
      const dataToRender = await PdfTools.getInputJsonForPDF(formData);  
          
      const data: RenderDocTemplateRequest = {
        templateID: this.currentTemplate.ID,
        dataToRender: dataToRender
      }
      this.renderedTemplate = await this.$store.dispatch('api/renderDocTemplate', data).then((result) => {
        this.$q.notify({ message: `Template renderen succesvol afgerond.`, color: 'positive'})
        return result.data
        }).catch(err => {
        console.log(err, "error");
        this.$q.notify({ message: `Fout tijdens het verwijderen van template.`, color: 'negative'})
      });        
      this.showFileDialog = true
      this.selectedRecord = new SelectedRecordClass();
      this.currentTemplate = new CurrentTemplateClass();
    }

    cancelRenderTemplate(){
      this.selectedRecord = new SelectedRecordClass();
      this.currentTemplate = new CurrentTemplateClass();
    }

    async mounted() {
      this.selectedModule = this.modulesList[0]
      this.availableModules = this.getModulesList()
      this.loading = true;
      await this.getAllTemplates();
      this.loading = false;
    }

    get curTenantID() {
      return this.tenantID;
    }

    get modulesList() {
      const modulesList = Array<{ label: string; value: string }>();
      Object.keys(this.dynamicModules).forEach((module) => {
        modulesList.push({
          label: this.getModuleTitle(module),
          value: module,
        });
      });
      return sortList(modulesList, 'label');
    }

    getModulesList() {
      const modules = []
      for(const module in this.dynamicModules) {
        modules.push(this.dynamicModules[module]);
      }
      return sortList(modules, 'pluralName')
    }

    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    }

    getModuleTitle(moduleID) {
      const translationKey = `modules.${moduleID}.pluralName`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        return store.state.fieldTranslations[translationKey]
      }
      const moduleTitle = this.dynamicModules && this.dynamicModules[moduleID] ? this.dynamicModules[moduleID] : {} ;
      return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : module
    }

  }
