

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";

  export default {
    name: "BaseFieldCheckbox",
    components: {},
    mixins: [ BaseExtend ],
    data() {
      return {
        needFocusedToNextField: true
      };
    },
    methods: {
      currentFieldBeingEdited(field) {
        this.formData['editingFields'].push(field)
      }
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          if (!val) {
            val = false;
          }
          this.$emit('input', val);
        },
      }
    }
  };
