import Vue from "vue";
import Router from "vue-router";
import firebase from "./firebaseApp"
import { getAuth } from "firebase/auth"
import store from "@/store";
import {uid} from "quasar";
import Trace from "@/components/Helpers/Trace";
import AbModel from "@/components/Models/AbModel";
import ReleaseNotesRouter from "@/components/Settings/Sections/General/ReleaseNotes/Router/Router"
import PortalSettingsRouter from "@/components/Settings/Sections/General/PortalSettings/Router/Router"
import OrganisationSettingsRouter from "@/components/Settings/Sections/General/OrganisationSettings/Router/Router"
import DefaultColumnsRouter from "@/components/Settings/Sections/BaseSettings/DefaultColumns/Router/Router"
import DefaultOrderInfoRouter from "@/components/Settings/Sections/BaseSettings/DefaultOrderInfo/Router/Router"
import DefaultPlanboardSettingsRouter from "@/components/Settings/Sections/BaseSettings/DefaultPlanboardSettings/Router/Router"
import DefaultModuleTranslationsRouter from "@/components/Settings/Sections/BaseSettings/DefaultModuleTranslations/Router/Router"
import ImportDataRouter from "@/components/Settings/Sections/DataManagement/ImportData/Router/Router"
import DeletedRecordsRouter from "@/components/Settings/Sections/DataManagement/DeletedRecords/Router/Router"
import SyncElasticSearchRouter from "@/components/Settings/Sections/DataManagement/SyncElasticSearch/Router/Router"
import AuditLogsExportRouter from "@/components/Settings/Sections/DataManagement/AuditLogsExport/Router/Router"
import EmailConfigurationRouter from "@/components/Settings/Sections/CommunicationChannels/EmailConfiguration/Router/Router"
import SMSConfigurationRouter from "@/components/Settings/Sections/CommunicationChannels/SMSConfiguration/Router/Router"
import UserConfigurationRouter from "@/components/Settings/Sections/UserManagement/UserConfiguration/Router/Router"
import RoleManagementRouter from "@/components/Settings/Sections/UserManagement/RoleManagement/Router/Router"
import DocGeneratorRouter from '@/components/Settings/Sections/Templates/DocGenerator/Router/Router'
import ReportsTemplatesRouter from '@/components/Settings/Sections/Templates/ReportsTemplates/Router/Router'
import PdfTemplatesRouter from '@/components/Settings/Sections/Templates/PDFgenerator/Router/Router'
import EmailTemplatesRouter from '@/components/Settings/Sections/Templates/EmailTemplates/Router/Router'
import RoboticProcessBuilderRouter from '@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Router/Router'
import StatusConfigurationRouter from '@/components/Settings/Sections/ProcessOptimization/StatusConfiguration/Router/Router'
import ExactOnlineRouter from '@/components/Settings/Sections/Marketplace/ExactOnline/Router/Router'
import TwinfieldRouter from '@/components/Settings/Sections/Marketplace/Twinfield/Router/Router'
import CreateNotificationsRouter from '@/components/Settings/Sections/PersonalSettings/CreateNotifications/Router/Router'
import Settings from "@/components/Settings/Models/Settings";

Vue.use(Router);
const auth = getAuth(firebase.app())

const router = new Router({
  mode: "history",
  guard: null,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./layouts/mainLayout"),
      children: []
    },
    /*
    {
       path: "/test",
       name: "test",
       component: () => import("./components/Test"),
    },
     */
    {
      path: "/login",
      name: "login",
      component: () => import("./components/LoginEmail"),
    },
    {
      path: "/reset",
      name: "reset",
      component: () => import("./components/ResetPassword"),
    },
    {
      path: "/signin",
      name: "signin-through-email",
      component: () => import("./components/SignInThroughEmail.vue"),
    },
    {
      path: "/sign-up",
      name: "sign up",
      component: () => import("./components/SignUp"),
    },
    {
      path: "/related/:moduleName/:action/:id?",
      name: "related",
      component: () => import("./components/Forms/DynamicForm"),
      props: (route) => ({
        ID: route.params.id,
        action: route.params.action,
        dynModuleName: route.params.moduleName,
      }),
    },
    {
      path: "/views",
      name: "dynamicView",
      component: () => import("./layouts/portalLayout"),
      children: [
        {
          path: "/views/:viewName/:moduleName",
          name: "dynamicView.list",
          component: () => import("./components/Views/DynamicList"),
          props: (route) => ({
            viewName: route.params.viewName,
            dynModuleName: route.params.moduleName,
          }),
        },
        {
          path: "/views/:viewName/:moduleName/:action/:id?",
          name: "dynamicView.form",
          component: () => import("./components/Views/DynamicView"),
          props: (route) => ({
            ID: route.params.id,
            action: route.params.action,
            viewName: route.params.viewName,
            dynModuleName: route.params.moduleName,
          }),
        }
      ]
    },
    {
      path: "/modules",
      component: () => import("./layouts/moduleLayout"),
      children: (function () {
        const modules = [
          {
            name: "TemplateConstructor",
            path: "template-constructor",
            list: import("./components/Settings/Sections/Templates/EmailTemplates/Components/TemplateConstructor"),
          },
          {
            name: "nylas-web-hook",
            list: import("./components/Nylas/NylasWebHook"),
          },
          {
            name: "dynamicModules",
            path: "modules/:moduleName",
            list: import("./components/Forms/DynamicList"),
            form: import("./components/Forms/DynamicForm"),
          },
        ];
        return modules
          .map((m) => {
            return {
              path: "/" + (m.path || m.name),
              name: m.name,
              component: () => m.list,
              props: (route) => ({
                dynModuleName: route.params.moduleName,
              }),
              children: m.children,
            };
          })
          .concat(
            modules.map((m) => {
              return {
                path: "/" + (m.path || m.name) + "/:action/:id?",
                name: m.name + ".form",
                component: () => m.form,
                props: (route) => ({
                  ID: route.params.id,
                  action: route.params.action,
                  dynModuleName: route.params.moduleName,
                }),
              };
            })
          )
          .concat([
            {
              path: "/builder/:moduleName",
              name: "builder",
              component: () => import("./components/Builder/BuilderPage"),
              list: import("./components/Builder/BuilderPage"),
            },
            {
              path: "/settings",
              name: "settings",
              component: () => import("./components/Settings/SettingsPage"),
              props: (route) => ({
                userType: route.params.userType,
                action: route.params.action,
              }),
            },
            {
              path: "/planboard",
              name: "planboard",
              component: () => import("./components/Planboard/PlanBoard"),
            },
            {
              path: "/dashboards",
              name: "dashboards",
              component: () => import("./components/Dashboards/Dashboard"),
            },
            {
              path: "/planboard_test",
              name: "planboard-test",
              component: () => import("./components/Planboard/Test"),
            },
            {
              path: "/settings/custom-translations",
              name: "custom-translations",
              component: () => import("./components/CustomTranslations/CustomTranslations"),
            },
            ...ReleaseNotesRouter,
            ...PortalSettingsRouter,
            ...OrganisationSettingsRouter,
            ...UserConfigurationRouter,
            ...RoleManagementRouter,
            ...StatusConfigurationRouter,
            ...RoboticProcessBuilderRouter,
            ...ImportDataRouter,
            ...DeletedRecordsRouter,
            ...AuditLogsExportRouter,
            ...SyncElasticSearchRouter,
            ...PdfTemplatesRouter,
            ...EmailTemplatesRouter,
            ...DocGeneratorRouter,
            ...ReportsTemplatesRouter,
            ...EmailConfigurationRouter,
            ...SMSConfigurationRouter,
            ...DefaultColumnsRouter,
            ...DefaultPlanboardSettingsRouter,
            ...DefaultModuleTranslationsRouter,
            ...DefaultOrderInfoRouter,
            ...ExactOnlineRouter,
            ...TwinfieldRouter,
            ...CreateNotificationsRouter
          ]);
      })(),
    },
    {
      path: '/401',
      component: () => import("./components/401"),
    },
    {
      path: '*',
      component: () => import("./components/404"),
    },
  ],
});

const waitForGuard = async (ms = 500, triesLeft = 20) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      if (store.state.guard) {
        resolve(store.state.guard);
        clearInterval(interval);
      } else if (triesLeft <= 1) {
        console.error('No router guard');
        reject();
        clearInterval(interval);
      }
      triesLeft--;
    }, ms);
  });
}

const waitForLicManager = async (ms = 500, triesLeft = 20) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      if (store.state.licenseManager) {
        resolve(store.state.licenseManager);
        clearInterval(interval);
      } else if (triesLeft <= 1) {
        console.error('No router licenses manager');
        reject();
        clearInterval(interval);
      }
      triesLeft--;
    }, ms);
  });
}

const waitForPortal = async (ms = 500, triesLeft = 20) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      if (store.state.PORTAL) {
        resolve(store.state.PORTAL);
        clearInterval(interval);
      } else if (triesLeft <= 1) {
        console.error('No Portal loaded');
        reject();
        clearInterval(interval);
      }
      triesLeft--;
    }, ms);
  });
}

router.beforeEach(async (to, from, next) => {
  let url = document.location.href
  if(document.location.href.includes('/modules')) {
    const baseUrl = url.split('/modules')[0]
    url = baseUrl + to.path
  }
  if(document.location.href.includes('/settings')) {
    const baseUrl = url.split('/modules')[0]
    url = baseUrl + to.path
  }
  window['strum']('routeChange', url);
  if(to.name == 'home' || to.name && to.name.includes('dynamicModules') || to.path.includes('/settings')){
    if(!auth.currentUser && !store.state.tenantID) next({path:'/login'})
  }
  if (to.name && to.name.includes('dynamicModules') || to.path.includes('/settings')) {
    if(store.state.tenantID === 'need_to_login'){
      next();
    }
  }
  if(to.path.includes('/settings/') && !store.state.ACL.includes('admin')) {
    if (!router.guard) {
      router.guard = await waitForGuard()
    }
    if (!router.licenseManager) {
      router.licenseManager = await waitForLicManager()
    }

    let allow = true;
    let module = to.params.moduleName;
    if (!module) {
      module = to.name;
    }
    if (!router.guard.checkSetting(module)) {
      allow = false;
      console.log('Router guard', module, 'NO');
    }

    for(const setting of store.state.allSettings) {
      if(module.includes(setting.name) || setting.noRoleRequired) {
        allow = true
      }
    }

    if (allow) {
      next();
    } else {
      next({ path: '/401' });
    }
  }

  if (to.name && (to.name.includes('dynamicModules') || ['dashboards', 'planboard'].includes(to.name))) {
    if (!router.guard) {
      router.guard = await waitForGuard()
    }
    if (!router.licenseManager) {
      router.licenseManager = await waitForLicManager()
    }
    let allow = true;
    let module = to.params.moduleName;
    if (!module) {
      module = to.name;
    }
    if (!router.guard.checkModule(module)) {
      allow = false;
      console.log('Router guard', module, 'NO');
    }

    if (!router.licenseManager.checkUserLicensePerModule(module)) {
      allow = false;
      console.log('Licenses manager', module, 'NO');
    }

    if (allow) {
      next();
    } else {
      next({ path: '/401' });
    }
  }

  // check if the edited record exist
  if (to.name && to.name.includes('dynamicModules') && to.params.action === 'edit') {
    if(store.state.tenantID === 'need_to_login'){
      next();
    }
  }

  // check the permission
  if (to.name && to.name.includes('dynamicModules')) {
    if (!router.guard) {
      router.guard = await waitForGuard()
    }
    const module = to.params.moduleName;
    if (router.guard.checkModule(module)) {
      next();
    } else {
      next({ path: '/401' });
    }
  }

  if (to.name && to.name.includes('dynamicView')) {
    if (store.state.PORTAL === null) {
      const portal = await waitForPortal()
    }
  }
  // TODO check the current view and custom buttons
  next();
});

router.afterEach((to, from) => {
  Trace.startTransaction({ name: to.name, memory: true })
});

Trace.init(router);

export default router;
