import firebase from "./firebaseApp";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "./store";

const auth = getAuth(firebase.app());
//TODO Create test for login en log out to test function below
onAuthStateChanged(auth, user => {
  if (user) {
    store.commit(`user/SET_PROFILE`, user);
  } else {
    store.commit(`user/LOGOUT`);
  }
});