import store from "@/store";
import Column from "../../Models/Column";
import {FieldType} from "../../Models/FieldType";
import { RelatedField, RelatedFieldQuery, RelatedValueQuery } from '../../Models/FieldTypes/RelatedField';
import { RelatedFieldValue } from "../../Models/FieldValueTypes/RelatedFieldValue";
import { ValueGetterParams } from "ag-grid-enterprise";
import {useRecordsStore} from "@/components/Records/Store/RecordStore"
import useModulesStore from "@/components/Modules/Store/ModuleStore";
import { uuid } from "vue-uuid";
import Utils from "@/utils/Utils";

export default class RelatedFieldImpl implements RelatedField {
    relatedModule: string;
    name: string;
    defaultValue: RelatedFieldValue;
    primaryResultField: string | null;
    secondaryResultField: string | null;
    copyToNewForm: string[] | null;
    relatedFieldCopy: string[] | null;
    query: RelatedFieldQuery | RelatedValueQuery[];
    ID: string;
    column: any;
    order: number;
    mandatory: boolean;
    section: string;
    disabled: boolean;
    readOnly: boolean | null | undefined;
    hidden: boolean | null | undefined;
    type: FieldType;
    label: string;
  constructor(
    field: RelatedField,
) {
    this.relatedModule = field.relatedModule
    this.name = field.name
    this.defaultValue = field.defaultValue
    this.primaryResultField = field.primaryResultField
    this.secondaryResultField = field.secondaryResultField
    this.copyToNewForm = field.copyToNewForm
    this.relatedFieldCopy = field.relatedFieldCopy
    this.query = field.query
    this.ID = field.ID
    this.column = field.column
    this.order = field.order
    this.mandatory = field.mandatory
    this.section = field.section
    this.disabled = field.disabled
    this.readOnly = field.readOnly
    this.hidden = field.hidden
    this.type = field.type
    this.label = field.label
  }
    canAggregate(): false {
        return false;
    }

    getColumn(column: any, moduleID: string): any {
        const baseColumn = new Column().getBaseColumn()
        const additionalProperties = {
            field: column.name,
            headerName: this.getLabel(moduleID),
            editable: !this.disabled && !this.readOnly && !this.hidden && this.section ? true : false,
            filter: 'agTextColumnFilter',
            filterValueGetter(params: ValueGetterParams) {
                const field = params.colDef.field
                return params.data[field].name
            },
            cellRenderer: 'relatedFieldRenderer',
            cellEditor: 'relatedFieldEditor',
        }
        return {
            ...baseColumn,
            ...additionalProperties
        }
    }

    getLabel(moduleID: string) {
        if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
            return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
          }
        return this.label
    }

    getFormattedValue(value: any, moduleID?: string) {
        if(typeof value === 'string') {
            return value
        }
        if(value && value.name) {
            return value.name
        }
        return value
    }

    getUnformattedValue(value: any) {
        return  {
            ID: value.ID,
            name: value.name
        }
    }
    
    getFieldType(): FieldType {
        return FieldType.RELATEDFIELD
    }

    async executeRelatedFieldCopy(selectedValue, relatedModuleID: string) {
        const recordsStore = useRecordsStore()
        const moduleStore = useModulesStore()
        const doc = await recordsStore.getDoc(relatedModuleID, selectedValue.ID)
        const data = {}
        for(const relatedCopyField in this.relatedFieldCopy) {
            const relatedField = moduleStore.getField(this.relatedModule, relatedCopyField)
            if(!relatedField) {
                continue
            }
            for(const dataField of this.relatedFieldCopy[relatedCopyField]) {
                if(relatedField['type'] === FieldType.SUBFORM) {
                    const subFormData = []
                    for(const subFormRecord of doc[relatedCopyField]) {
                        subFormRecord.ID = uuid.v1()
                        subFormData.push(subFormRecord)
                    }
                    data[dataField] = subFormData
                } else {
                    data[dataField] = doc[relatedCopyField]
                }
            }
        }
        return data
    }

}
