
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import FieldUpdate from "@/components/Settings/Actions/fieldUpdate.vue";
import FieldMerge from "@/components/Settings/Actions/fieldMerge.vue";
import AddRoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/AddRoboticProcess.vue";
import RelatedFieldCopy from "@/components/Settings/Actions/relatedFieldCopy.vue";
import { CreateRecordAction } from "@/components/Settings/Actions/actions";

@Component({
  components: {
    AddEmailTemplate,
    AddFieldUpdate,
    FieldUpdate,
    FieldMerge,
    AddRoboticProcess,
    RelatedFieldCopy
  },
})
export default class AddNewActions extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  @Prop() existingAction;
  @Prop() openExistingAction;
  typeOfFieldAction = [
    { name: "inRecord", title: "InRecord", label: "In Record" },
    { name: "childToParent", title: "ChildToParent", label: "Child To Parent" },
    { name: "parentToChild", title: "ParentToChild", label: "Parent To Child" },
  ];
  isActionTrue = true;
  isSourceModuleTrue = false;
  toggledSelectField = false;
  selectedChildTypes = false;
  selectedFieldsTypes = false;
  selectedParentTypes = false;
  childModuleSelected = false;
  parentModuleSelected = false;
  selectedTypeOfFieldAction = "";
  addTitleInDialog = "";
  addDescriptionInDialog = "";
  selectedChildModule = "";
  selectedParentModule = "";
  selectedFieldTypes = "";
  showModulesList: Array<any> = [];
  showModules: Array<any> = [];
  options = this.showModules;
  refOptions = this.showModulesList;
  createRecord = new CreateRecordAction();
  fieldsToUpdate = [];
  fieldsToMerge = [];

  async mounted() {
    this.getActionTypeFunction()     
    if(this.openExistingAction == true){      
      await this.createRecord.import(this.existingAction.ID, this.dynamicModules)
      this.showFieldsOfType()
    }        
    let showModules = {};
    for (const modules in this.dynamicModules) {
      if (this.dynamicModules[modules].pluralName != undefined) {
        showModules = {
          label: this.dynamicModules[modules].pluralName,
          name: this.dynamicModules[modules].id,
        };
      } else {
        showModules = {
          label: this.dynamicModules[modules].name,
          name: this.dynamicModules[modules].id,
        };
      }
      this.showModules.push(showModules);
    }
  }
  // Filter function 
  filterFn(val, update) {
    if (val === "") {
      this.options = this.showModules;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.showModules.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  // Filter function
  filterRef(val, update) {
    if (val === "") {
      this.refOptions = this.showModulesList;
    }
    update(() => {
      const Reference = val.toLowerCase();
      this.refOptions = this.showModulesList.filter(
        (v) => v.label.toLowerCase().indexOf(Reference) > -1
      );
    });
  }

  // Function to check wich type the action has
  selectedChildType() {
    if (this.selectedTypeOfFieldAction["name"] == "childToParent") {
      this.selectedChildTypes = true;
      this.childModuleSelected = true;
      this.selectedParentTypes = false;
      this.parentModuleSelected = false;
    } else if (this.selectedTypeOfFieldAction["name"] == "parentToChild") {
      this.selectedChildTypes = false;
      this.childModuleSelected = false;
      this.selectedParentTypes = true;
      this.parentModuleSelected = true;
    } else {
      this.selectedChildTypes = false;
      this.childModuleSelected = false;
      this.selectedParentTypes = false;
      this.parentModuleSelected = false;
    }
  }

  // to get the options for a related field
  showFieldsOfType() {
    this.showModulesList = [];
    for (const field in this.dynamicModules[
      this.createRecord.childModule["name"]
    ].fields) {
      if (this.dynamicModules[this.createRecord.childModule["name"]].fields[field].type == "relatedField") {
        const showFields = {
          label: this.dynamicModules[this.createRecord.childModule["name"]]
            .fields[field].label,
          name: this.dynamicModules[this.createRecord.childModule["name"]]
            .fields[field].name,
        };
        this.showModulesList.push(showFields);
      }
    }
  }

  // Emit function to get the exisiting field updates
  getFieldUpdates(value: any) {
    this.fieldsToUpdate = value;
    this.createRecord.fieldUpdates = this.fieldsToUpdate
  }

  // Emit function to get the exisiting field merges
  getFieldMerges(value: any) {
    this.fieldsToMerge = value;
    this.createRecord.fieldMerges = this.fieldsToMerge
  }

  // emit function to save action in actionHeader.vue
  @Emit("getActionType")
  getActionTypeFunction() {
      const map = {
        value: this.createRecord,
        type: "CreateRecord"
      }
      return map   
  }
}
