import { vuexfireMutations } from "vuexfire";
import { getAuth, signOut } from "firebase/auth";
import store from "../store";
import firebase from "../firebaseApp";

const auth = getAuth(firebase.app());

const SET_PROFILE = "SET_PROFILE";
const LOGOUT = "LOGOUT";

const state = {
  profile: {},
  loggedIn: false,
};

const getters = {
  profile: ({ profile }) => profile,
  loggedIn: ({ loggedIn }) => loggedIn,
};

const mutations = {
  [SET_PROFILE](state, profile) {
    state.loggedIn = true;
    state.profile = {
      name: "",
      picture: "",
      uid: profile.uid,
      email: profile.email
    };
    store.commit("SET_USER", state.profile);
  },

  [LOGOUT](state) {
    state.loggedIn = false;
    state.profile = {};
    store.commit("SET_USER", state.profile);
  },

  ...vuexfireMutations,
};

const actions = {

  async logout() {
    try {
      // await unlink(auth.currentUser, 'microsoft.com')
      await signOut(auth)
    } catch (error) {      
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
