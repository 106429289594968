import store from "@/store";
import Column from "../../Models/Column";
import {FieldType} from "../../Models/FieldType";
import { CheckboxField } from '../../Models/FieldTypes/CheckboxField';

export default class CheckboxFieldImpl implements CheckboxField {
    name: string;
    ID: string;
    column: number;
    order: number;
    mandatory: boolean | null | undefined;
    section: string;
    disabled: boolean | null | undefined;
    readOnly: boolean | null | undefined;
    hidden: boolean | null | undefined;
    type: FieldType;
    label: string;
    defaultValue: boolean | null;

    constructor(
        field: CheckboxField
    ) {
        this.name = field.name
        this.ID = field.ID
        this.column = field.column
        this.order = field.order
        this.mandatory = field.mandatory
        this.section = field.section
        this.disabled = field.disabled
        this.readOnly = field.readOnly
        this.hidden = field.hidden
        this.type = field.type
        this.label = field.label
        this.defaultValue = field.defaultValue
    }

    canAggregate(): false {
        return false;
    }

    getColumn(column: any, moduleID: string): any {
        const baseColumn = new Column().getBaseColumn()
        const additionalProperties = {
            field: column.name,
            headerName: this.getLabel(moduleID),
            editable: !this.disabled && !this.readOnly && !this.hidden ? true : false,
            cellRenderer: 'checkBoxFieldRenderer',
            cellEditor: 'checkBoxFieldEditor',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [true, false, null]
            }
        }
        return {
            ...baseColumn,
            ...additionalProperties
        }
    }

    getLabel(moduleID: string) {
        if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
            return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
        }
        return this.label
    }

    getFormattedValue(value: any, moduleID?: string) {
        return value === true ? '✓' : '';
    }

    getUnformattedValue(value: any) {
        return value
    }

    getFieldType(): FieldType {
        return FieldType.CHECKBOX
    }

}
