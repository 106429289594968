import { render, staticRenderFns } from "./RoProDetailsDrawerActions.vue?vue&type=template&id=4f01f73c&scoped=true&"
import script from "./RoProDetailsDrawerActions.vue?vue&type=script&lang=ts&"
export * from "./RoProDetailsDrawerActions.vue?vue&type=script&lang=ts&"
import style0 from "./RoProDetailsDrawerActions.vue?vue&type=style&index=0&id=4f01f73c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f01f73c",
  null
  
)

export default component.exports
import {QCardSection,QSelect,QBtn,QMarkupTable,QTr,QTooltip,QDialog,QSeparator,QCardActions} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection,QSelect,QBtn,QMarkupTable,QTr,QTooltip,QDialog,QSeparator,QCardActions})
