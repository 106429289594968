
  import { mapGetters, mapActions } from 'vuex';
  import {colors} from "quasar";
  import Design from "@/common/helpers/Design";
  import store from "@/store";

  export default {
    name: "PortalSettings",
    data: () => ({
      colorSchema: {},
      icons: {},
      images: {backgroundLogin : {downloadableURL : ''}, backgroundApp : {downloadableURL : ''}},
      showPreview: false,
      customCss: '',
    }),
    mounted() {
      // colors
      if (this.designColorSchema && Object.keys( this.designColorSchema).length) {
        this.colorSchema = JSON.parse(JSON.stringify({...this.defaultColorSchema, ...this.designColorSchema}));
      } else {
        this.colorSchema = JSON.parse(JSON.stringify({...this.defaultColorSchema}));
      }
      // icons
      if (this.designIcons && Object.keys( this.designIcons).length) {
        this.icons = JSON.parse(JSON.stringify({...this.defaultIcons, ...this.designIcons}));
      } else {
        this.icons = JSON.parse(JSON.stringify({...this.defaultIcons}));
      }
      // images
      if (this.designImages && Object.keys(this.designImages).length) {
        this.images = JSON.parse(JSON.stringify(this.designImages));
      }
      // css
      this.customCss = this.designCss;

      
    },

    computed: {
      ...mapGetters("settings", ["designColorSchema", "designIcons", "defaultColorSchema", "defaultIcons", "designImages", "designCss"]),
      imagesBackgroundLoginUrl() {
        return this.images && this.images.backgroundLogin ? this.images.backgroundLogin.downloadableURL : '';
      },
      imagesBackgroundAppUrl() {
        return this.images && this.images.backgroundApp ? this.images.backgroundApp.downloadableURL : '';
      },
      portalCss() {
        return '<style>' + this.customCss + '</style>'
      }
    },
    methods: {
      ...mapActions('settings', ["setDesignSettings"]),
      ...mapActions(['uploadFileToStorage']),
      isShowReset() {
          return this.designColorSchema && this.colorSchema[name] !== this.designColorSchema[name];
      },
      saveSettings() {
        let design = {
          colorSchema: this.colorSchema,
          icons: this.icons,
          images: this.images,
          css: this.customCss,
        }
        design = JSON.parse(JSON.stringify(design))
        this.setDesignSettings(design)
          .then(() => {
            store.state.alertMessage = "update";
          })
      },
      setDefaultColors () {
        Design.setSpecificColors(this.defaultColorSchema)
        // TODO:: if PORTAL do
        // Design.setSpecificColors(this.designColorSchema)
      },
      togglePreview () {
        if (this.showPreview) {
          Design.setSpecificColors(this.colorSchema)
        } else {
          this.setDefaultColors()
        }
      },
      setColor (name) {
        if (this.showPreview) {
          colors.setBrand(name, this.colorSchema[name])
        }
      },
      resetColor (name) {
        this.colorSchema[name] = { ...this.designColorSchema }[name];
        this.setColor(name)
      },
      setIcon1(inputFile){
        this.setIcon(inputFile, 'logo')
      },
      setIcon2(inputFile){
        this.setIcon(inputFile, 'logoMini')
      },
      setIcon(inputFile, iconName){
        const file = inputFile[0]
        const reader = new FileReader();
        reader.readAsText(file);
        const app = this
        reader.onload = function() {
          app.icons[iconName] = reader.result;
        };
        reader.onerror = function() {
          console.log('err')
          
        };
      },
      removeIcon1(){
        this.icons.logo = ''
      },
      removeIcon2(){
        this.icons.logoMini = ''
      },
      onFileRejected () {
        this.$q.notify({type: 'negative', message: 'File did not pass validation constraints'})
      },
      async setBackgroundImage(file, name) {
        file.storageRef = 'settings/design/images/';
        file.toPublic = true;
        file.onlyRef = true;
        file.addTenant = true;
        const uploadedFile = await this.uploadFileToStorage(file);
        this.$set(this.images, name, uploadedFile);
      },
      async setBackgroundLoginImage(bgImgs) {
        await this.setBackgroundImage(bgImgs[0],  'backgroundLogin');
      },
      async setBackgroundAppImage(bgImgs) {
        await this.setBackgroundImage(bgImgs[0],  'backgroundApp');
      },
      removeBackgroundLoginImage() {
        ///this.images.background = '';
      },
      removeBackgroundAppImage() {
        ///this.images.background = '';
      }
    },
    beforeDestroy () {
      this.setDefaultColors()
    }
  };
