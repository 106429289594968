import useModulesStore from "@/components/Modules/Store/ModuleStore";
import Column from "../../Models/Column";
import {FieldType} from "../../Models/FieldType";
import { DropdownField } from '../../Models/FieldTypes/DropdownField';
import { DropdownFieldValue } from "../../Models/FieldValueTypes/DropdownFieldValue";
import store from "@/store";
import { ValueGetterParams } from "ag-grid-community";

export default class DropdownFieldImpl implements DropdownField {
    name: string;
    ID: string;
    column: number;
    order: number;
    mandatory: boolean | null | undefined;
    section: string;
    disabled: boolean | null | undefined;
    readOnly: boolean | null | undefined;
    hidden: boolean | null | undefined;
    type: FieldType;
    label: string;
    defaultValue: DropdownFieldValue
    dropdownExpression: string | null | undefined;

    constructor(
        field: DropdownField
    ) {
        this.name = field.name
        this.ID = field.ID
        this.column = field.column
        this.order = field.order
        this.mandatory = field.mandatory
        this.section = field.section
        this.disabled = field.disabled
        this.readOnly = field.readOnly
        this.hidden = field.hidden
        this.type = field.type
        this.label = field.label
        this.defaultValue = field.defaultValue
        this.dropdownExpression = field.dropdownExpression
    }
    
    canAggregate(): false {
        return false;
    }

    getColumn(column: any, moduleID: string): any {
        const baseColumn = new Column().getBaseColumn()
        const additionalProperties = {
            field: column.name,
            headerName: this.getLabel(moduleID),
            editable: !this.disabled && !this.readOnly && !this.hidden ? true : false,
            cellRenderer: 'baseFieldRenderer',
            cellEditor: 'dropdownFieldEditor',
            cellEditorParams: {values: this.getDropdownValues(column.name, moduleID)},
            filter: 'agSetColumnFilter',
            filterParams: {
                values: this.getDropdownValues(column.name, moduleID),
                valueGetter: (params: ValueGetterParams) => {
                    const translationKey = `${moduleID}.dropDowns.${this.name}.${params.data[this.name]}`
                    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
                      return store.state.fieldTranslations[translationKey]
                    }
                    return params.data[this.name]
                }
            }
        }
        return {
            ...baseColumn,
            ...additionalProperties
        }
    }

    getLabel(moduleID: string) {
        if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
            return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
          }
        return this.label
    }

    getDropdownValues(columnName: string, moduleID: string): string[] {
        const moduleStore = useModulesStore()
        const dropdownValues = moduleStore.modules[moduleID].dropDownValues[columnName] ? moduleStore.modules[moduleID].dropDownValues[columnName] : []
        for(const index in dropdownValues) {
            const formattedValue = this.getFormattedValue(dropdownValues[index], moduleID)
            dropdownValues[index] = formattedValue
        }
        if(!dropdownValues.includes("")) dropdownValues.push("")
        return dropdownValues
    }

    getFormattedValue(value: any, moduleID?: string) {
        const translationKey = `${moduleID}.dropDowns.${this.name}.${value}`
        if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
          return store.state.fieldTranslations[translationKey]
        }
        return value
    }

    getUnformattedValue(value: any) {
        return value
    }

    getFieldType(): FieldType {
        return FieldType.DROPDOWN
    }

}
