export enum FieldType {
    RELATEDFIELD = "relatedField",
    TEXT = "text",
    CALCULATEDFIELD = "calculatedField",
    DATETIME = "datetime",
    DROPDOWN = "dropdown",
    PERCENTAGE = "percentage",
    NUMBER = "number",
    CHECKBOX = "checkbox",
    DECIMAL = "decimal",
    CURRENCY = "currency",
    SUBFORM = "subForm",
    LARGETEXT = "largeText",
    AUTONUMBER = "autoNumber"
}