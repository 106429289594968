import Utils from "@/components/utils/Utils"

const SelectModel = {
  customerID: "Klant", // CUS
  appointmentStatusOverWritten: false,
  appointmentStatusColors: {},
  appointmentStatusLightColors: {},
  appointmentStatusOrders: {},
  appointmentStatusValues: [],
  appointmentStatusValuesPB: [],

  appointmentStatus: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    toBePlanned: "Inplannen",
    toBeConfirmed: "Te bevestigen",
    planned: "Gepland",
    traveling: "Onderweg",
    delayed: "Vertraagd",
    inExecution: "In uitvoering",
    done: "Uitgevoerd",
    freeToPlaned: "Vrij in te plannen",
    plannedWithoutAppointment: "Gepland zonder afspraak",
    notExecuted : "Niet uitgevoerd",
    checked: "Gecontroleerd",
    partiallyInvoiced: "Deels gefactureerd",
    invoiced: "Gefactureerd",
    dragged: "Herplannen"
  },

  mandatoryAppointmentStatuses: {
    statusWhenToBePlanned: "Inplannen",
    statusWhenReleasedInPlanboard: "Gepland",
    statusWhenRemovedFromPlanboard: "Herplannen"
  },

  notDeleteAppointmentStatuses: [
    "Inplannen",
    "Te bevestigen"
  ],

  workOrderPhase: {
    toBePlanned: "Inplannen",
  },

  timeTypes: {
    verlof: "Verlof",
    ziek: "Ziek",
    arbeid: "Arbeid",
    reis: "Reis",
    training: "Training",
  },

  overwriteStatusList(appointmentModule) {
    if (appointmentModule && appointmentModule.fields) {
      const status = appointmentModule.fields['status'];
      if (status && status.planboardAppointmentStatuses) {
        if (status.planboardAppointmentStatuses.toBePlanned) {
          SelectModel.appointmentStatus.toBePlanned = status.planboardAppointmentStatuses.toBePlanned;
          SelectModel.mandatoryAppointmentStatuses.toBePlanned = status.planboardAppointmentStatuses.toBePlanned;
        }
        if (status.planboardAppointmentStatuses.planned) {
          SelectModel.appointmentStatus.planned = status.planboardAppointmentStatuses.planned;
          SelectModel.mandatoryAppointmentStatuses.planned = status.planboardAppointmentStatuses.planned;
        }
        if (status.planboardAppointmentStatuses.dragged) {
          SelectModel.appointmentStatus.dragged = status.planboardAppointmentStatuses.dragged;
          SelectModel.mandatoryAppointmentStatuses.dragged = status.planboardAppointmentStatuses.dragged;
        }
      }
      if (status && status.metaData) {
        if (status && status.planboardAppointmentStatuses) {
          if (status.planboardAppointmentStatuses.statusWhenToBePlanned) {
            SelectModel.appointmentStatus.statusWhenToBePlanned = status.planboardAppointmentStatuses.statusWhenToBePlanned;
            SelectModel.mandatoryAppointmentStatuses.statusWhenToBePlanned = status.planboardAppointmentStatuses.statusWhenToBePlanned;
          }
          if (status.planboardAppointmentStatuses.statusWhenReleasedInPlanboard) {
            SelectModel.appointmentStatus.statusWhenReleasedInPlanboard = status.planboardAppointmentStatuses.statusWhenReleasedInPlanboard;
            SelectModel.mandatoryAppointmentStatuses.statusWhenReleasedInPlanboard = status.planboardAppointmentStatuses.statusWhenReleasedInPlanboard;
          }
          if (status.planboardAppointmentStatuses.statusWhenRemovedFromPlanboard) {
            SelectModel.appointmentStatus.statusWhenRemovedFromPlanboard = status.planboardAppointmentStatuses.statusWhenRemovedFromPlanboard;
            SelectModel.mandatoryAppointmentStatuses.statusWhenRemovedFromPlanboard = status.planboardAppointmentStatuses.statusWhenRemovedFromPlanboard;
          }
        }
        const metaStatuses = {};
        const sortedStatuses = Object
        for (const statName in status.metaData) {
          const mStatus = status.metaData[statName];
          metaStatuses[statName] = mStatus.label || statName;
          if (mStatus.color === undefined) {
            mStatus.color = "#ffffff"
          }
          mStatus.color = mStatus.color.startsWith("#")? mStatus.color : '#' + mStatus.color
          this.appointmentStatusColors[mStatus.label] = mStatus.color;
          this.appointmentStatusLightColors[mStatus.label] = Utils.shadeColor(mStatus.color, 90) + '90'
          this.appointmentStatusOrders[mStatus.label] = Number(mStatus.order) || 1;
        }
        SelectModel.appointmentStatus = Object.assign(SelectModel.mandatoryAppointmentStatuses, metaStatuses);
        const availableStatuses = []
        for(const s in SelectModel.appointmentStatus) { 
          const status = SelectModel.appointmentStatus[s]
          if(!availableStatuses.includes(status)) {
            availableStatuses.push(status)
          }
        }
        SelectModel.appointmentStatus = availableStatuses
        SelectModel.appointmentStatusOverWritten = true;
        SelectModel.appointmentStatusValues = Object.values(SelectModel.appointmentStatus).sort(((a, b) => {
          return (this.appointmentStatusOrders[a] || 1) - (this.appointmentStatusOrders[b] || 1)
        }));
        SelectModel.appointmentStatusValuesPB = [ ...SelectModel.appointmentStatusValues ];
        const statusAvailableInPlanboard = []
        for (const s of status.availableInPlanboard) {
          if (!statusAvailableInPlanboard.includes(s)) {
            statusAvailableInPlanboard.push(s)
          }
        }
        SelectModel.appointmentStatusValuesPB = statusAvailableInPlanboard
        SelectModel.appointmentStatusValuesPB = SelectModel.appointmentStatusValuesPB.sort(((a, b) => {
          return (this.appointmentStatusOrders[a] || 1) - (this.appointmentStatusOrders[b] || 1)
        }));
      } else {
        SelectModel.appointmentStatusValues = Object.values(SelectModel.appointmentStatus);
        SelectModel.appointmentStatusValuesPB = { ...SelectModel.appointmentStatusValues };
      }
    }
  },
};

export default SelectModel;
