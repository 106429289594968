import {format, setCursor, event, onlyNumbers} from './utils'
import assign from './assign'
import defaults from './options'

export default function (el, binding) {

  // v-money used on a component that's not a input
  if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
    const els = el.getElementsByTagName('input')
    if (els.length !== 1) {
      // throw new Error("v-money requires 1 input, found " + els.length)
    } else {
      el = els[0]
    }
  }

  el.onkeydown = function (e) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      el.typeBackspace = 1
    } else {
      el.typeBackspace = 0
    }
    if (e.key == '.' || e.key == ',') {
      el.typeComa = e.key
    } else {
      el.typeComa = ''
    }
    el.typeKey = e.key
    
  }

  if (!binding.value) return

  const opt = assign(defaults, binding.value)

  el.oninput = function () {
    const startSelectionEnd = el.selectionEnd
    const startValue = el.value

    // remove %
    el.value = el.value.replace('%', '')
    const partsPrev = String(el.value).split(opt.decimal)


    // do any value correction if key pressed
    if (el.typeKey) {

      // fix for init value is null, no bind, no keypress
      // correct the type as 0,50 (type 5 after ",") and max 0,99
      if (el.value && partsPrev[1] && partsPrev[1].length > opt.precision) {
        partsPrev[1] = partsPrev[1].slice(0, opt.precision)
        el.value = partsPrev.join(opt.decimal)
      }

      // type 5 when field is empty or null
      if (el.value && partsPrev[0] && !partsPrev[1] && !partsPrev[2]) {
        if (el.typeBackspace) {
          el.value = el.oldValue
        } else {
          el.value = el.value + '0'.repeat(opt.precision)
          el.typeOnNull = true
        }
      }

      // type digit key before fist "0"
      if (Number(el.typeKey) > 0 && Number(onlyNumbers(el.oldValue)) === 0) {
        // type digit before delimiter
        if (Number(onlyNumbers(partsPrev[0])) > 0) {
          el.value = el.typeKey + '0'.repeat(opt.precision)
          el.typeOnNull = true
        }
      }

      // type backspace on decimal
      if (el.value && partsPrev[0] && partsPrev[1] && !partsPrev[2] && partsPrev[1].length < opt.precision) {
        el.value = el.value + '0'
      }

      if (el.typeComa) {
        el.value = el.value.replace(el.typeComa, '')
      }
    }

    // check for max value
    const maxValue = 999999999999;
    if ((Number(onlyNumbers(el.value)) / 100) > maxValue) {
      el.value = el.oldValue
    }

    el.value = format(el.value, opt)

    let newPos = startSelectionEnd;

    if (el.typeComa) {
      newPos = el.value.indexOf(opt.decimal) + 1
    }

    if (el.typeOnNull) {
      newPos = el.value.indexOf(opt.decimal);
      el.typeOnNull = false;
    }

    const countTs1 = startValue.split(opt.thousands).length
    const countTs2 = el.value.split(opt.thousands).length

    if (countTs2 > countTs1) {
      newPos++
    }

    if (countTs1 > countTs2) {
      newPos--
    }

    

    setCursor(el, newPos)
    el.oldValue = el.value
    el.typeKey = ''
    el.dispatchEvent(event('change')) // v-model.lazy
  }

  el.onfocus = function () {
    const decPos = el.value.indexOf(opt.decimal)
    const endPos = el.value.length - opt.suffix.length
    const newPos = decPos || endPos
    //setCursor(el, newPos)
    el.select();
  }

  el.oninput()
  el.dispatchEvent(event('input')) // force format after initialization
}
