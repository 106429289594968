
  import { Vue, Component } from "vue-property-decorator";
  import db from "../../../../../../db";
  import store from "../../../../../../store";
  
  @Component({})
  export default class Twinfield extends Vue {
    credentials: Array<any> = [];
  
    async mounted() {
      this.credentials = await this.getCredentials();
      await this.checkForTwinfieldRegistration();
    }
  
    async getCredentials() {
      const credentials: Array<any> = [];
      await db
        .collection(
          `tenants/${store.state.tenantID}/integrations/credentials/records`
        )
        .where("service", "==", "Twinfield")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const document = doc.data();
            document["ID"] = doc.id;
            credentials.push(document);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
  
      return credentials;
    }
  
    addCredential() {
      this.credentials.push({
        name: "",
        service: "Twinfield",
        status: "pending",
      });
    }
  
    async deleteCredentials(credential) {
      if (credential.ID) {
        this.credentials.splice(this.credentials.indexOf(credential));
        await db
          .collection(
            `tenants/${store.state.tenantID}/integrations/credentials/records`
          )
          .doc(credential.ID)
          .delete()
          .then(() => {
            const message: any = this.$t("common.messages.recordDeleted");
            this.$q.notify({ message: message, color: "warning" });
          })
          .catch((error) => {
            console.log("Error deleting document: ", credential.ID, error);
          });
      } else {
        this.credentials.splice(this.credentials.indexOf(credential));
      }
      this.credentials = await this.getCredentials();
    }
  
    async startLogin(credential) {
      await this.createIntegrationDoc(credential);
      await this.goToTwinfieldAuthPage();
    }
  
    async createIntegrationDoc(credential) {
      await db
        .collection(
          `tenants/${store.state.tenantID}/integrations/credentials/records`
        )
        .doc()
        .set(credential, { merge: true })
        .then(() => {
          const message: any = this.$t("common.messages.successfullySaved");
          this.$q.notify({ message: message, color: "positive" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    randomState(){
         return '_' + Math.random().toString(36).substr(2, 9);
       }
  
    async updateIntegrationDoc(credential) {
      await db
        .collection(
          `tenants/${store.state.tenantID}/integrations/credentials/records`
        )
        .doc(credential.ID)
        .set(credential, { merge: true })
        .then(() => {
          const message: any = this.$t("common.messages.successfullySaved");
          this.$q.notify({ message: message, color: "positive" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    async goToTwinfieldAuthPage(): Promise<any> {
      const apiUrl = "https://login.twinfield.com/auth/authentication/connect/authorize";
    
      const params = {
           'client_id': 'yellowQ',
           'redirect_uri': 'https://yellowq.app/settings/twinfield',
           'response_type': 'code',
           'scope':"openid+twf.user+twf.organisation+twf.organisationUser+offline_access",
           'state': this.randomState(),
           'nonce': this.randomState()
         };
  
      const queryParams = Object.keys(params)
        .map((p) => p + "=" + params[p])
        .join("&");
      const linkToLogin = apiUrl + "?" + queryParams;
  
      (window as any).location = linkToLogin;
    }
  
    async checkForTwinfieldRegistration() {
      if (this.$route.query.code) {
        for (const credential of this.credentials) {
          if (credential.status === "pending") {
            credential.integrationName = credential.ID;
            credential.redirectURL = `https://yellowq.app/settings/twinfield`;
            credential.code = this.$route.query.code;
            const response = await this.$store
              .dispatch("api/twinfieldRegistration", credential)
              .then((res) => {
                return res;
              })
              .catch((err) => {
                console.log(err, "error");
              });
            if (response && response.status == 200) {
              delete credential.integrationName;
              delete credential.redirectURL;
              delete credential.code;
              credential.status = "completed";
              await this.updateIntegrationDoc(credential);
            }
          }
        }
      }
    }
  }
  