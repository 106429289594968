import RoProBuilder from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/RoProBuilder.vue"
import AddRoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/AddRoboticProcess.vue"


export default [
    {
        path: "/settings/robotic-process-builder",
        name: "robotic-process-builder",
        component: RoProBuilder
    },
    {
        path: "/add-robotic-process",
        name: "add-robotic-process",
        component: AddRoboticProcess
    },
]