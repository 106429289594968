
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import store from "../../store"
import Dialog from "@/components/Parts/Dialog.vue";
import PreviewFileDialog from "../Parts/PreviewFileDialog.vue";
import axios from "axios";


@Component({
    components: {
        Dialog,
        PreviewFileDialog
    }
})


export default class BaseFieldSignature extends Vue {
    @Prop({required: true}) formData: any
    @Prop({required: true}) data: any
    @Prop() name: string
    @Prop() label: string
    @Prop() field: any
    @Prop() fieldUsedInSettings: any
    // @Prop({required: true}) module: any
    showFilePicker = false
    showFileModal = false
    selectedImage = null
    selectedImageURL = null
    imageFullWidth = false
    fullscreen = false
    imageIndex = null
    maximumImages = 10
    imageURL = ''
    showField = false

    closeFilePickerDialog() {
      this.showFilePicker = false
    }


    async openShowFileModal(imageURL: string, imageIndex: number) {
        this.showFileModal = true
        this.imageFullWidth = false
        this.imageIndex = imageIndex
        this.selectedImage = {}
        const splittedURL = imageURL.split("%2F")
        const uploadedFileID = splittedURL[splittedURL.length - 1].split("?")[0]
        this.selectedImage.fileType = ''
        this.selectedImage.fileName = uploadedFileID
        this.selectedImage.fileType = 'images/jpeg'
        this.selectedImage.downloadableURL = imageURL
        this.selectedImageURL = imageURL
    }

    downloadAttachment() {
      const executableExtensions = [
        'cbc', 'cbr', 'cbz', 'chm', 'djvu', 'epub', 'lit', 'lrf', 'mobi', 'pml', 'prc', 'snb', 'tcr',
        'csv', 'doc', 'docx', 'dot', 'dotx', 'log', 'mpp', 'mpt', 'pot', 'potx', 'pps', 'ppsx',
        'key', 'numbers', 'pages', 'dwf', 'dwfx', 'dwg', 'dwgx', 'dxf', 'azv', 'azv1', 'azv3', 'azv4',
        'mdi', 'png', 'tif', 'tiff', 'webp', '123', '12m', 'lwp', 'mwp', 'sam', 'wk1', 'wk2', 'wk3',
        'otg', 'oth', 'otp', 'ots', 'pdf', 'pot', 'pps', 'ppt', 'pptx', 'pxl', 'sgl', 'smf', 'srw',
        'ppt', 'pptx', 'pub', 'rtf', 'txt', 'vdx', 'vsd', 'vsdx', 'vst', 'vstx', 'wpd', 'wps', 'wri',
        'prn', 'ps', 'oxps', 'snp', 'xps', 'htm', 'html', 'web',
        'stc', 'sti', 'stw', 'sxc', 'sxg', 'sxi', 'sxm', 'sxw', 'vor', 'wv2', 'xls', 'xlsx' , 'eps',
        'tpz', 'eml', 'mbx', 'msg', 'oft', 'bmp', 'fax', 'gif', 'heic', 'ico', 'images', 'jpeg', 'jpg',
        'xls', 'xlsb', 'xlsx', 'xlt', 'xltx', 'mml', 'odc', 'odf', 'odg', 'odi', 'odm', 'odp', 'ods'
      ]
      axios({
        url: this.selectedImage.downloadableURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let fileName = this.selectedImage.fileName
        let fileType = this.selectedImage.fileType

        if (executableExtensions.includes(this.selectedImage.fileName.split(".")[this.selectedImage.fileName.split(".").length - 1])) {          
          fileType = this.selectedImage.fileName.split(".")[this.selectedImage.fileName.split(".").length - 1]
          const fileNameAttributes = this.selectedImage.fileName.split(".")
          fileNameAttributes.pop();
          fileName = fileNameAttributes.join(".")
        }
        fileLink.setAttribute('download', `${fileName}.${fileType}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }

    toggleImageFullWidth() {
        this.imageFullWidth = true
        document.getElementById("dialog-card").style.minWidth = "80vw";
    }

    toggleImageSmallSize() {
        this.imageFullWidth = false
        document.getElementById("dialog-card").style.minWidth = "25vw";
    }

    get cLabel() {
        let label = this.label ? this.label : this.$t(this.formData.model + '.fields.' + this.name);
        if (store.state.debugMode) {
          label = label + ' (' + this.name + ')';
        }
        return label;
    }

    @Watch('showFileModal')
    showFileModalHandler() {
        if(this.showFileModal) {
            setTimeout(() => {
                document.getElementById("dialog-card").style.minWidth = "25vw";
            }, 100);
        } else {
            document.getElementById("dialog-card").style.minWidth = "";
        }
    }

    @Watch('data')
    formDataHandler() {
        if(this.formData.data[this.name]) {
            this.showField = true
        }
    }
}
