

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";
  import store from "./../../store";

  export default {
    name: "BaseFieldDateTime",
    components: {},
    mixins: [ BaseExtend ],
    data() {
      return {
        dateTimeMask: store.state.dateTimeMask
      };
    },
    methods: {
    },
    computed: {
      model: {
        get() {
          if(this.value && this.value['seconds'] === -2209076372) {
            store.state.alertMessage = "error"
            return null
          } else {
            return this.toDateTimeValue(this.value);
          }
        },
        set(val) {
          this.formData.editingFields.push(this.name)
          if(val === "") {
            val = null
          }
          const dateTimeRegex = /^(0[1-9]|1\d|2\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})\s([01]\d|2[0-3]):([0-5]\d)$/
          const dateRegex = /^(0[1-9]|[1-2]\d|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/
          if (dateRegex.test(val) && val.length === 10 || dateTimeRegex.test(val) && val.length === 16) {
            if (val && val.length === 10) val = val + ' 00:00';
            val = this.toTimestampValue(val);
            this.$emit('input', val);
          } else {
            if(val !== null) {
              store.state.alertMessage = "wrong_date"
            }
            val = null
            this.$emit('input', val);
          }
        },
      },
    },
  };
