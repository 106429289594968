import { render, staticRenderFns } from "./TemplateConstructor.vue?vue&type=template&id=ec070df2&"
import script from "./TemplateConstructor.vue?vue&type=script&lang=ts&"
export * from "./TemplateConstructor.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateConstructor.vue?vue&type=style&index=0&id=ec070df2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QBar,QSpace,QBtn,QTooltip,QCardActions,QCardSection,QDialog,QInput,QCheckbox,QSelect,QIcon,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QBar,QSpace,QBtn,QTooltip,QCardActions,QCardSection,QDialog,QInput,QCheckbox,QSelect,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
