import db from "@/db";
import store from "@/store";
import { collection, getDocs, query, where } from "firebase/firestore";
import { defineStore } from "pinia";
import NotificationAction from "../Models/NotificationAction"
import Utils from "@/utils/Utils";
import {  deleteFirestoreDoc, setFirestoreDocData } from "@/helpers/FirestoreHelper";




async function loadNotifications(): Promise<NotificationAction[]> {
    const notifications: NotificationAction[] = []
    const ref = collection(db, `tenants/${store.state.tenantID}/settings/notificationRules/records`);
    const q = query(ref, where('userID', '==', store.state.currentUser.ID))
    const docs = await getDocs(q).then(sn => { return sn.docs.map(doc => doc.data())});
    if(docs.length) {
        for(const doc of docs) {
            notifications.push(doc as NotificationAction)
        }
    }
    return notifications
}
  

export const useCreateNotificationsStore = defineStore('createNotifications', {
    state: (): {
        notifications: NotificationAction[];
    } => ({
        notifications: []
    }),
    getters: {
        async initCreateNotifications() {
            this.notifications = await loadNotifications()
        }
    },
    actions: {
        async createNotificationAction(notificationAction: NotificationAction) {
            if(!Utils.isEmpty(notificationAction)) {
                const dbResponse = await setFirestoreDocData(`tenants/${store.state.tenantID}/settings/notificationRules/records`, notificationAction);
                return dbResponse;
            }
        },
        async deleteNotificationAction(notificationActionID: string) {
            if(!Utils.isEmpty(notificationActionID)) {
                const dbResponse = await deleteFirestoreDoc(`tenants/${store.state.tenantID}/settings/notificationRules/records/${notificationActionID}`);
                return dbResponse;
            }
        },
        async getNotifications() {
            return await loadNotifications()
        }
    }
})