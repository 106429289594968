
import { Vue, Component } from "vue-property-decorator";

import db from "@/db";
import store from "@/store";
import SMSConfig from "../Models/SMSConfig";
import { DocumentSnapshot } from "@firebase/firestore-types";

import * as lodash from "lodash";

Component.registerHooks(["beforeRouteLeave"]);
@Component({})
export default class SMSConfiguration extends Vue {
  SMSConfigOld = new SMSConfig("", false);
  SMSConfig = new SMSConfig("", false);

  async getSMSSettings() {
    await db
      .doc(`/tenants/${store.state.tenantID}/settings/SMS`)
      .get()
      .then((doc: DocumentSnapshot) => {
        if (doc.exists) {
          const docData = doc.data();
          const phoneNumber = docData["phoneNumber"]
            ? docData["phoneNumber"]
            : "";
          const active = docData["active"] ? docData["active"] : false;
          this.SMSConfig = new SMSConfig(phoneNumber, active);
          this.SMSConfigOld = new SMSConfig(phoneNumber, active);
        } else {
          this.SMSConfig = new SMSConfig("", false);
        }
      })
      .catch(() => {
        this.SMSConfig = new SMSConfig("", false);
      });
  }

  async updateSMSSettings(): Promise<void> {
    this.SMSConfigOld = this.SMSConfig;
    return await db
      .doc(`/tenants/${store.state.tenantID}/settings/SMS`)
      .set(this.SMSConfig.toJSON(), {merge: true})
      .then(() => {
        this.$q.notify({
          message: `${this.$t("common.messages.recordChanged")}`,
          color: "positive",
        });
      });
  }

  async mounted() {
    await this.getSMSSettings();
  }

  async beforeRouteLeave(to, from, next) {
    let showAlert = false;
    if (!lodash.isEqual(this.SMSConfigOld.toJSON(), this.SMSConfig.toJSON())) {
      showAlert = true;
    }

    if (showAlert) {
      const message: any = this.$t("common.messages.unsavedWarning");
      const answer = window.confirm(message);
      if (answer) {
        next();
      }
    } else next();
  }
}
