
import { Component, Vue } from "vue-property-decorator";
import ChangeLogs from "../../../../../../../CHANGELOG.md"
import VueMarkdown from "vue-markdown";

@Component({
  components: {VueMarkdown}
})


export default class ReleaseNotes extends Vue {
  step = 1
  markdown: any = []
  markdownHeaders = []

  mounted() {
    this.markdown = this.getMarkdown()
    this.markdownHeaders = this.getMarkdownHeaders(this.getMarkdown())
  }
  
  getMarkdown() {
    const markdown = ChangeLogs.split('[')
    markdown.splice(0, 1)
    return markdown
  }
  
  getMarkdownHeaders(markdown) {
    const markdownHeaders = []
    for(const i in markdown) {
      const title = markdown[i].split('\n')[0]
      markdownHeaders.push(title)
    }
    return markdownHeaders
  }
}
