import store from "@/store";
import db from "@/db";

function getField(fieldName, dynamicModules , module) {
    let field = { label: "", name: "", type: "", fieldType: "" };
    if (!fieldName) {
      return field;
    }
    const fieldNameNoID = fieldName.replace(".ID", "");

    field =
      dynamicModules &&
      module &&
      dynamicModules[module] &&
      dynamicModules[module].fields &&
      dynamicModules[module].fields[fieldNameNoID]
        ? Object.assign(
            {},
            dynamicModules[module].fields[fieldNameNoID]
          )
        : field;
    return field;
  }
  export default async function getFilterValueRelatedField(filter, value, dynamicModules, module) {    
    const relatedModule = getField(filter, dynamicModules, module)["relatedModule"]    
    const firePath = "/tenants/" + store.state.tenantID + "/modules/" + relatedModule + "/records/";          
    const ref = db.collection(firePath).doc(value)
    let name = ""
    await ref.get().then(async doc => {
        if (doc.exists) {
            name = doc.data()['name'];
        } else {
            console.log("Document not found");
        }
    }).catch(err => {
        throw new Error(err);
    });    
    return name
  }