import db from "../db";
import store from "../store";
import firebase from "@/firebaseApp";
import { getAuth } from "firebase/auth";

import axios, {AxiosRequestConfig} from "axios";
import urls from "@/urls";
import ResetPasswordRequestInfo from "@/dto/api/ResetPasswordRequestInfo.dto";
import DeleteDocTemplateRequest from "@/dto/api/DeleteDocTemplateRequest.dto";
import CreateDocTemplateRequest from "@/dto/api/CreateDocTemplateRequest.dto";
import UpdateDocTemplateRequest from "@/dto/api/UpdateDocTemplateRequest.dto";
import RenderDocTemplateRequest from "@/dto/api/RenderDocTemplateRequest.dto";

const auth = getAuth(firebase.app());
const state = {
  //apiUrlCF: process.env.YELLOWQ_API_URL_CF || 'https://europe-west3-bitplatform-production.cloudfunctions.net',
  // apiUrlCF: process.env.YELLOWQ_API_URL_CF || 'https://europe-west3-bitplatform-develop.cloudfunctions.net/userapp/api/v1/ping',
  apiUrlCF: process.env.YELLOWQ_API_URL_CF || 'https://europe-west3-bitplatform-develop.cloudfunctions.net',
  apiUrlCF2: process.env.YELLOWQ_API_URL_CF2 || 'https://us-central1-bitplatform-develop.cloudfunctions.net',

  apiUrlGAE: process.env.YELLOWQ_API_URL_GAE || 'https://gae-dataimportexport-dot-bitplatform-production.appspot.com',
  //apiUrlGAE: process.env.YELLOQ_API_URL_GAE || 'https://yellowq-a7rdfa6a.ew.gateway.dev',


  jwtReportToken: null,
};

const getters = {
};

const SET_JWT_REPORT_TOKEN = "SET_JWT_REPORT_TOKEN";

const mutations = {
  [SET_JWT_REPORT_TOKEN](state, jwtReportToken) {
    state.jwtReportToken = jwtReportToken;
  },
};

const actions = {

  // User API

  async pingUserApp() {
    const API_URL = urls.apiUrlCF + '/userapp/api/v1/ping';
    // const API_URL = urls.apiUrlCF_LOCAL + '/userapp/api/v1/ping';
    const firebaseToken = await auth.currentUser.getIdToken();
    try {
      const res = await axios.get(API_URL, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async getPasswordResetLink({state}, emailAddress: string) {
    const API_URL =  `${urls.apiUrlCF}/userapp/api/v1/users/resetPassword`;
    // const API_URL =  `${state.apiUrlCF_LOCAL}/userapp/api/v1/users/resetPassword`;
    // const API_URL =  `http://localhost:5001/bitplatform-develop/europe-west3/userapp/api/v1/users/resetPassword`;
    //http://localhost:5001/bitplatform-develop/europe-west3/userapp/api/v1/users/G4k9RHg9wIen548D0eodsHlylqI2/resetpassword
    const request: AxiosRequestConfig = {
      url: API_URL,
      headers: {"Content-Type": "application/json"},
      method: "POST",
      params:{email: emailAddress}
    }
    const passwordResetted = await axios(request).then(res =>{
      return true
    }).catch(error => {
      console.log(error, 'Error while resetting password.')
      return false
    });
    return passwordResetted
  },

  async signUpNewPortalUser({state, dispatch}, userData) {
    await dispatch('pingUserApp');
    const firebaseToken = await auth.currentUser.getIdToken();
    userData.portalUser = true;
    userData.tenant = store.state.tenantID;
    const API_URL =  `${urls.apiUrlCF}/userapp/api/v1/users/signup`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/userapp/api/v1/users/signup`;
    const res = await axios.post(API_URL, userData, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    const data = res ? res.data : '';
    await actions.getPasswordResetLink({state}, userData.emailAddress)
    return data;
  },


  // GAE API
  async initSettings({ dispatch, commit }) {
    //let jwtReportToken = await dispatch('getJwtReportToken');
    
    //if (!jwtReportToken) {
      //jwtReportToken = await dispatch('refreshJwtReportToken');
    //}
    
    //return jwtReportToken;
  },

  async getJwtReportToken({state, commit}) {
    const doc = await db.collection("tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/tokens/").doc('jwt-report-token').get();
    const jwt = doc && doc.data() ? doc.data().value : null;
    commit("SET_JWT_REPORT_TOKEN", jwt);
    return jwt;
  },

  async refreshJwtReportToken({state, commit}) {
    const firebaseToken = await auth.currentUser.getIdToken();
    let jwt = '';
    try {
      const res = await axios.get(urls.getAccessTokenURL, {headers: {Authorization: 'Bearer ' + firebaseToken}});
      jwt = res.data ? res.data.token : '';
      if (!jwt) {
        console.error(res);
      }
    } catch (err) {
      console.error(err);
    }
    const data = {value: jwt || ''};
    await db.collection("tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/tokens/").doc('jwt-report-token').set(data);
    commit("SET_JWT_REPORT_TOKEN", jwt);
    return jwt;
  },

  async ping({state, dispatch}) {
    const API_URL = state.apiUrlGAE + '/v1/ping';
    try {
      const res = await axios.get(API_URL, {headers: {Authorization: 'Bearer ' + state.jwtReportToken}});
      return res;
    } catch (error) {
      try {
        const refreshToken = await dispatch('refreshJwtReportToken');
        return Boolean(refreshToken);
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  },

  async getAuditLogForRecord({state, dispatch}, params){
    const module = params.module;
    const record = params.record;
    const firebaseToken = await auth.currentUser.getIdToken();
    // url: `http://127.0.0.1:5001/bitplatform-production/europe-west3/auditLogTest/api/v1/auditLogsByRecord/${record}`,
    try {
      const config: AxiosRequestConfig = {
        method: 'GET',
        headers: {Authorization: 'Bearer ' + firebaseToken},
        url: `${urls.apiUrlCF}/auditLogTest/api/v1/auditLogsByRecord/${record}`,
          params: {
            "tenant": store.state.tenantID,
            "module": module
          }
      }
      const res = await axios(config);
      return res && res.data ? res.data : [];
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  async getAuditLogs({state, dispatch}, params){
    const firebaseToken = await auth.currentUser.getIdToken();
    console.log("Params: ", params, firebaseToken);
    
    const module = params.module;
    try {
      const config: AxiosRequestConfig = {
          method: 'GET',
          url: `${urls.apiUrlCF}/auditLog/api/v1/auditLogs`,
          headers: {Authorization: 'Bearer ' + firebaseToken},
          params: {
            "tenant": store.state.tenantID,
            "module": module,
            "startDate": params.startDate,
            "endDate": params.endDate
          }
      }
      const res = await axios(config);
      return res && res.data ? res.data : [];
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  async exportModule({state, dispatch}, params) {
    const module = params.module;
    if (!module) {
      return null;
    }
    try {
     const config: AxiosRequestConfig = {
				method: 'POST',
				//url: `https://gae-dataimportexport-dot-bitplatform-production.appspot.com/v1/reports/${params.type}s`,
				url: `${state.apiUrlGAE}/v1/reports/${params.type}s`,
				data: {
					"moduleId": params.module,
					"reportTemplateId": params.reportID
				},
				headers: {
					'Authorization': 'Bearer ' + await auth.currentUser.getIdToken()
        },
        params: {
          tenantId: store.state.tenantID
        }
			};
      // const config = {
      //   method: 'POST',
      //   url: state.apiUrlGAE + '/v1/reports/modules',
      //   headers: {
      //      Authorization: 'Bearer ' + state.jwtReportToken,
      //     //  'Access-Control-Allow-Origin': 'http://localhost:8080'
      //     //'x-apigateway-api-userinfo':state.jwtReportToken
      //   },
      //   data:{
      //     moduleId: params.module,
      //     reportTemplateId: "n8wV8r2czvFrJ3v5jqrv",
      //     reportType:"json",
      //     tenantId: state.tenantID,
      //   },

      // };
      
      if (params.responseType) {
        config.responseType = params.responseType;
      }
      const res = await axios(config);
      
      return res && res.data ? res.data : [];
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  async getAllImports({state}) {
    const API_URL = `${state.apiUrlGAE}/v1/jobs/search`;
    try {
      const config: AxiosRequestConfig = {
        method: 'POST',
        url: API_URL,
        headers: {
          'Authorization': 'Bearer ' + await auth.currentUser.getIdToken()
        },
        params: {
          tenantId: store.state.tenantID
        }
      };
      const res = await axios(config);
      return res && res.data ? res.data : []
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  async bulkImport({state, dispatch}, params) {
    const API_URL = `${state.apiUrlGAE}/v1/bulk/modules/${params.module}/records`;
    try {
      const config: AxiosRequestConfig = {
        method: 'POST',
        url: API_URL,
        data: params.data,
        headers: {
          'Authorization': 'Bearer ' + await auth.currentUser.getIdToken()
        },
        params: {
          tenantId: store.state.tenantID
        }
      };
      const res = await axios(config);
      return res && res.data ? res.data : {}
    } catch (error) {
      console.log(error);
      return {};
    }
  },

  async downloadImports({state, dispatch}, params) {
    const API_URL =  `${state.apiUrlGAE}/v1/modules/${params.module}/template?reportType=${params.reportType}`;
    try {
      const res = await axios.get(API_URL, {
          headers: {'Authorization': 'Bearer ' + await auth.currentUser.getIdToken()},
          params: { tenantId: store.state.tenantID }
        });
      return res && res.data ? res.data : []
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // DocGenerator API
  async pingDocGenerator() {
    // const API_URL = urls.apiUrlCF + '/docGenerator/api/v1/ping';
    const API_URL = urls.apiUrlCF_LOCAL + '/docGenerator/api/v1/ping';
    const firebaseToken = await auth.currentUser.getIdToken();
    try {
      const res = await axios.get(API_URL, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async createDocTemplate({state}, createDocTemplateRequest: CreateDocTemplateRequest) { 
    const firebaseToken = await auth.currentUser.getIdToken();
    const API_URL =  `${urls.apiUrlCF}/docGenerator/api/v1/docs/templates`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/docGenerator/api/v1/docs/templates`;
    try {
      return await axios.post(API_URL, createDocTemplateRequest, {headers: {Authorization: 'Bearer ' + firebaseToken, tenantID: store.state.tenantID}});
    } catch (error) {
      console.log(error);
    }
  },

  async getDocTemplates() { 
    const API_URL = urls.apiUrlCF + '/docGenerator/api/v1/docs/templates';
    // const API_URL = urls.apiUrlCF_LOCAL + '/docGenerator/api/v1/docs/templates';
    const tenantID = store.state.tenantID;
    const firebaseToken = await auth.currentUser.getIdToken();
    try {
      return await axios.get(API_URL, {headers: {Authorization: 'Bearer ' + firebaseToken, tenantID: tenantID}});      
    } catch (error) {
      console.log(error);
    }
  },

  async updateDocTemplate({state}, updateDocTemplateRequest: UpdateDocTemplateRequest) {
    const firebaseToken = await auth.currentUser.getIdToken();
    const API_URL =  `${urls.apiUrlCF}/docGenerator/api/v1/docs/templates/${updateDocTemplateRequest.templateID}`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/docGenerator/api/v1/docs/templates/${updateDocTemplateRequest.templateID}`;
    try {
      return await axios.put(API_URL, updateDocTemplateRequest, {headers: {Authorization: 'Bearer ' + firebaseToken, tenantID: store.state.tenantID}});
    } catch (error) {
      console.log(error);
    }
  },

  async deleteDocTemplate({state}, deleteDocTemplateRequest: DeleteDocTemplateRequest) { 
    const firebaseToken = await auth.currentUser.getIdToken();
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      // url: `${urls.apiUrlCF_LOCAL}/docGenerator/api/v1/docs/templates/${deleteDocTemplateRequest.templateID}`,
      url: `${urls.apiUrlCF}/docGenerator/api/v1/docs/templates/${deleteDocTemplateRequest.templateID}`,
      headers: {
        Authorization: 'Bearer ' + firebaseToken,
        tenantID: store.state.tenantID
      },
    }
    try {
      return await axios(config)  
    } catch (error) {
      console.log(error);
    }
  },

  async renderDocTemplate({state}, renderDocTemplateRequest: RenderDocTemplateRequest) { 
    const firebaseToken = await auth.currentUser.getIdToken();
    const API_URL =  `${urls.apiUrlCF}/docGenerator/api/v1/docs/render/${renderDocTemplateRequest.templateID}`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/docGenerator/api/v1/docs/render/${renderDocTemplateRequest.templateID}`;
    try {
      return await axios.post(API_URL, renderDocTemplateRequest, {headers: {Authorization: 'Bearer ' + firebaseToken, tenantID: store.state.tenantID}});
    } catch (error) {
      console.log(error);
    }
  },

  async createNewCumulioIntegration() {
    const API_URL =  `${urls.apiUrlCF}/dashboards/api/v1/dashboards/integration`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/dashboards/api/v1/dashboards/integration`;
    const firebaseToken = await auth.currentUser.getIdToken(); 
    try {
      return await axios.post(API_URL, {}, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async getCumulioAuth() {
    const API_URL =  `${urls.apiUrlCF}/dashboards/api/v1/dashboards/auth`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/dashboards/api/v1/dashboards/auth`;
    const firebaseToken = await auth.currentUser.getIdToken();        
    try {
      return await axios.post(API_URL, {}, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async getCumulioDashboards() {
    const API_URL =  `${urls.apiUrlCF}/dashboards/api/v1/dashboards`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/dashboards/api/v1/dashboards`;
    const firebaseToken = await auth.currentUser.getIdToken();    
    try {
      return await axios.get(API_URL, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async getCumulioDashboardsByUserID({state}, userData) {
    const API_URL =  `${urls.apiUrlCF}/dashboards/api/v1/dashboards/${userData.userID}`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/dashboards/api/v1/dashboards/${userData.userID}`;
    const firebaseToken = await auth.currentUser.getIdToken();    
    try {
      return await axios.get(API_URL, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async associateDashboardToUser({state}, request: any) {
    const API_URL =  `${urls.apiUrlCF}/dashboards/api/v1/dashboards/${request.dashboardID}/associate`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/dashboards/api/v1/dashboards/${request.dashboardID}/associate`;
    const firebaseToken = await auth.currentUser.getIdToken();    
    try {
      return await axios.post(API_URL, request, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },
  async dissociateDashboardToUser({state}, request: any) {
    const API_URL =  `${urls.apiUrlCF}/dashboards/api/v1/dashboards/${request.dashboardID}/dissociate`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/dashboards/api/v1/dashboards/${request.dashboardID}/dissociate`;
    const firebaseToken = await auth.currentUser.getIdToken();    
    try {
      return await axios.post(API_URL, request, {headers: {Authorization: 'Bearer ' + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async exactRegistration({state}, request: any) {
    const API_URL =  `https://gae-integration-service-dot-bitplatform-production.appspot.com/v1/setup/exactOnline`;
    // const API_URL =  `http://localhost:8080/v1/setup/exactOnline`;
    const firebaseToken = await auth.currentUser.getIdToken();
    try {
      return await axios.post(API_URL, request, {headers: {Authorization: "Bearer " + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async twinfieldRegistration({state}, request: any) {
    const API_URL =  `https://gae-integration-service-dot-bitplatform-production.appspot.com/v1/setup/twinfield`;
    // const API_URL =  `http://localhost:8080/v1/setup/exactOnline`;
    const firebaseToken = await auth.currentUser.getIdToken();
    try {
      return await axios.post(API_URL, request, {headers: {Authorization: "Bearer " + firebaseToken}});
    } catch (error) {
      console.log(error);
    }
  },

  async signup({state, dispatch}, data) {
    const API_URL =  `${urls.apiUrlCF}/signup/api/v1/signup`;
    // const API_URL =  `${urls.apiUrlCF_LOCAL}/signup/api/v1/signup`;
    const res = await axios.post(API_URL, data);
    const responseData = res ? res.data : '';
    return responseData;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
