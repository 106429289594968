
  import { Component, Vue } from "vue-property-decorator";
  import { State } from "vuex-class";
  import {mapGetters} from "vuex";
  import db from "@/db";
  import store from "@/store";
  import ActionInfo from "@/components/Parts/ActionInfo.vue";
  import { Timestamp } from '@firebase/firestore';

  @Component({
    components: {ActionInfo},
  })
  export default class RolesAdd extends Vue {
      @State("dynamicModules") dynamicModules;
      messageOk = false
      messageError = false
      modules: any = []
      roleName = ""
      allTogglesTrue = false
      pdfTemplates = false
      defaultColumns = false
      defaultPlanboard = false
      email = false
      emailTemplates = false
      importData = false
      organization = false
      reportTemplates = false
      roleManagement = false
      userConfiguration = false
      restoreDeletedRecord = false
      auditLogsExport = false
      statusConfiguration = false
      orderInfo = false
      roboticProcessBuilder = false
      documentTemplates = false
      hideOrDisable = ""
      hideOrDisableOptions = [this.$t('admin.role.hide'), this.$t('admin.role.disable')]
      changedAt: Timestamp = Timestamp.now()
    
    mounted() {
      this.getRoleInfo();
    }

    computed() {
      mapGetters(["translate"])
    }
    
      async getRoleInfo() {      
        for(const module in this.dynamicModules) {
          if(this.dynamicModules[module].id !== "demo") {
            this.modules.push({
              label: this.dynamicModules[module].pluralName,
              id: this.dynamicModules[module].id,
              createPermissions: false,
              deletePermissions: false,
              readPermissions: false,
              lockPermissions: false,
              unlockPermissions: false,
              statusOverwrite: false,
              updatePermissions: false
            });
          }
        }       
      }

      setRowFalse(module) {
        if(module.readPermissions == false) {
          module.deletePermissions = false
          module.lockPermissions = false
          module.unlockPermissions = false
          module.statusOverwrite = false
          module.updatePermissions = false
        }
      }

      setAllTogglesTrue() {
        for(const module in this.modules) {
          if(this.modules[module] != undefined) {
            this.modules[module].createPermissions = this.allTogglesTrue 
            this.modules[module].deletePermissions = this.allTogglesTrue 
            this.modules[module].lockPermissions = this.allTogglesTrue 
            this.modules[module].readPermissions = this.allTogglesTrue 
            this.modules[module].unlockPermissions = this.allTogglesTrue 
            this.modules[module].statusOverwrite = this.allTogglesTrue 
            this.modules[module].updatePermissions = this.allTogglesTrue 
          }
        }
        this.pdfTemplates = this.allTogglesTrue 
        this.defaultColumns = this.allTogglesTrue 
        this.defaultPlanboard = this.allTogglesTrue 
        this.email = this.allTogglesTrue 
        this.emailTemplates = this.allTogglesTrue 
        this.importData = this.allTogglesTrue 
        this.organization = this.allTogglesTrue 
        this.reportTemplates = this.allTogglesTrue 
        this.roleManagement = this.allTogglesTrue 
        this.userConfiguration = this.allTogglesTrue 
        this.restoreDeletedRecord = this.allTogglesTrue
        this.auditLogsExport = this.allTogglesTrue
        this.statusConfiguration = this.allTogglesTrue
        this.orderInfo = this.allTogglesTrue
        this.roboticProcessBuilder = this.allTogglesTrue
        this.documentTemplates = this.allTogglesTrue
      }

      getExportValues(modulePermission) {
        if(modulePermission == true) {
          return "ALLOWED"
        } else {
          return "NOT_ALLOWED"
        }
      }

      getHideOrDisable(value) {
        if(value == "Verbergen" || value == "Hide") {
          return "HIDE"
        } else {
          return "DISABLE"
        }
      }

      saveSettings() {
        if(this.roleName != "") {
          const doc = db.collection(`tenants/${store.state.tenantID}/roles`).doc()
          doc.set(Object.assign({}, {
              ID: doc.id,
              name: this.roleName,
              hideOrDisableUI: this.getHideOrDisable(this.hideOrDisable),
              changedAt: this.changedAt
            }))
            .catch(() => {
              this.messageError = true;
              setTimeout(() => this.messageError = false, 5000);
          });
          
          this.modules.forEach(module => {
            db.collection(`tenants/${store.state.tenantID}/roles/${doc.id}/modulePermissions`).doc(module.id)
              .set(Object.assign({}, {
                createPermissions: this.getExportValues(module.createPermissions),
                deletePermissions: this.getExportValues(module.deletePermissions),
                readPermissions: this.getExportValues(module.readPermissions),
                lockPermissions: this.getExportValues(module.lockPermissions),
                unlockPermissions: this.getExportValues(module.unlockPermissions),
                statusOverwrite: this.getExportValues(module.statusOverwrite),
                updatePermissions: this.getExportValues(module.updatePermissions),
                ID: module.id
              }))
              .catch(() => {
                this.messageError = true;
                setTimeout(() => this.messageError = false, 5000);
              });
          });
            db.collection(`tenants/${store.state.tenantID}/roles/${doc.id}/modulePermissions`).doc('demo')
              .set(Object.assign({}, {
                createPermissions: "ALLOWED",
                deletePermissions: "ALLOWED",
                readPermissions: "ALLOWED",
                lockPermissions: "ALLOWED",
                unlockPermissions: "ALLOWED",
                statusOverwrite: "ALLOWED",
                updatePermissions: "ALLOWED",
                ID: "demo"
              }))
              .catch(() => {
                this.messageError = true;
                setTimeout(() => this.messageError = false, 5000);
              });
          db.collection(`tenants/${store.state.tenantID}/roles/${doc.id}/modulePermissions`).doc(`settings`)
            .set(Object.assign({}, {
              "pdf-templates": this.getExportValues(this.pdfTemplates),
              "default-columns": this.getExportValues(this.defaultColumns),
              "default-planboard": this.getExportValues(this.defaultPlanboard),
              "email": this.getExportValues(this.email),
              "email-templates": this.getExportValues(this.emailTemplates),
              "import-data": this.getExportValues(this.importData),
              "portal-settings": this.getExportValues(this.organization),
              "report-templates": this.getExportValues(this.reportTemplates),
              "role-management": this.getExportValues(this.roleManagement),
              "user-configuration": this.getExportValues(this.userConfiguration),
              "audit-logs-export": this.getExportValues(this.auditLogsExport),
              "deleted-records": this.getExportValues(this.restoreDeletedRecord),
              "status-configuration": this.getExportValues(this.statusConfiguration),
              "order-info": this.getExportValues(this.orderInfo),
              "robotic-process-builder": this.getExportValues(this.roboticProcessBuilder),
              "document-templates": this.getExportValues(this.documentTemplates)
            }))
            .then(() => {
              this.$router.push({path: "/settings/role-management"});
            })
            .catch(() => {
              this.messageError = true;
              setTimeout(() => this.messageError = false, 5000);
            });
          this.$q.notify({ message: `${this.$t('common.messages.recordChanged')}`, color: 'positive'})
        } else {
          this.$q.notify({ message: `${this.$t('common.messages.operationNotValidated')}`, color: 'negative', icon: 'warning'})
        }
      }

      onBack() {
        this.$router.push({path: "/settings/role-management"});
      }
  }
