
export default class TypeOfActions {
    TypeOfActions = [
    { name: "fieldUpdate", title: "FieldUpdates", label: "Veld Update" },
    {
      name: "copyProductLines",
      title: "CopyProductLines",
      label: "Kopiëer Producten",
    },
    { name: "createRecords", title: "CreateRecords", label: "Maak Record" },
    {
      name: "relatedFieldCopies",
      title: "RelatedFieldCopies",
      label: "Gerelateerde veld kopie",
    },
    { name: "sendEmails", title: "SendEmails", label: "Verstuur Email" },
    { name: "sendSMS", title: "SendSMSes", label: "Verstuur SMS" },
    { name: "generatePDF", title: "GeneratePDFs", label: "Maak PDF" },
  ];
  }