import db from "@/db"
import store from "@/store"

export default class DatabaseHelper {

    async saveTranslations(data: any, docID: string ) {
        const response = await db.collection(`tenants/${store.state.tenantID}/settings/translations/languages`).doc(docID).set(data, {merge: true})
        .then(() => {
            return true
        })
        .catch((err) => {
            console.log(err);
            return false
        })
        return response
    }


    async readTranslations(docID: string) {
        const translationsDoc = await db.collection(`tenants/${store.state.tenantID}/settings/translations/languages`).doc(docID).get()
  
        if(translationsDoc.exists) {
          return translationsDoc.data()
        }
        return {}
      }

}