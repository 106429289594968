
  import { mapState, mapActions } from 'vuex';
  import ListValues from '@/components/Mixin/ListValues'
  import PlanBoardModel from "@/components/Planboard/PlanBoardModel";

  export default {
    name: "DefaultPlanboardTaskSettings",
    data: () => ({
      viewSettings: null,
    }),
    mounted() {
      this.getViewSettings();
    },

    computed: {
      ...mapState(["dynamicModules", "defaultViewSettingsRaw"]),

      dynamicModuleWO() {
        return this.dynamicModules ? this.dynamicModules['workOrders'] : {};
      },
      dynamicModuleAP() {
        return this.dynamicModules ? this.dynamicModules['appointments'] : {};
      },
      dynFieldsAll() {
        const woFields = this.dynamicModuleWO ? this.dynamicModuleWO.fields : {};
        const apFields = this.dynamicModuleAP ? this.dynamicModuleAP.fields : {};
        const locFields = ListValues.locFields() || [];
        const sharedFields = {}

        for (const fName in woFields) {
          const wo =  { ...woFields[fName], ...{label: woFields[fName].label + ' (' + ListValues.singularTitle('workOrders') + ')' } }
          this.$set(sharedFields, fName + '.workOrders', wo)
        }
        for (const fName in apFields) {
          const ap = { ...apFields[fName], ...{label: apFields[fName].label + ' (' + ListValues.singularTitle('appointments') + ')' } }
          this.$set(sharedFields, fName + '.appointments', ap)
        }
        for (const fName in locFields) {
          const locWo = { ...locFields[fName], ...{label: locFields[fName].label + ' (' + ListValues.singularTitle('workOrders') + ')' } }
          const locAp = { ...locFields[fName], ...{label: locFields[fName].label + ' (' + ListValues.singularTitle('appointments') + ')' } }
          this.$set(sharedFields, fName + '.workOrders', locWo)
          this.$set(sharedFields, fName + '.appointments', locAp)
        }
        return sharedFields;
      }
    },
    methods: {
      ...mapActions(["updateDefaultViewSettings"]),

      getViewSettings() {
        this.viewSettings = PlanBoardModel.userViewSettings(
          this.defaultViewSettingsRaw,
          ListValues.getValidColumns(this.dynFieldsAll)
        );
      },
      saveSettings() {
          this.updateDefaultViewSettings(this.viewSettings)
      },
    },
  };
