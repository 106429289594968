import functions from "@/functions";
import store from "@/store";
import {asyncForEach} from "@/common/helpers/async-for-each";
import db from "@/db";

const state = {
  doPrint: 1,
  downloadableURL: '',
};

const getters = {};

const actions = {

  async runAction({ state }, params) {
    const {buttonAction, module, ID} = params;
    const runAction = functions.httpsCallable('runAction');

    //TODO dev mode
    //functions.useEmulator('localhost', 5001);

    const actions = buttonAction.actions.sort((a, b) => {
      return a.order - b.order;
    });

    buttonAction.loading = true;

    const tenantID = store.state.tenantID;
    const parameters = {};
    parameters.ID = ID;
    parameters.module = module;
    const results = [];
    return new Promise((resolve, reject)  => {
      asyncForEach(actions, async (action) => {
        const data = {ID: action.ID, type: action.type, tenantID, parameters};
        await runAction(data).then(res => {
          const resData = res.data;
          results.push(resData);
          // TODO not only null is error, add error result
          if (!resData) {
            reject(resData);
          }
          
          if (buttonAction.type === 'PrintAction') {
            state.downloadableURL = resData && resData[0] && resData[0].attachments
            && resData[0].attachments[0] ? resData[0].attachments[0].downloadableURL : '';
            
            state.doPrint++;
          }
        }).catch(err => {
          buttonAction.loading = false;
          console.log(err);
          reject(err);
        });
      }).then(res => {
        buttonAction.loading = false;
        resolve(results);
      });
    });
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
