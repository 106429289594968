
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import store from "@/store";
import { Timestamp } from '@firebase/firestore';
import db from "@/db";
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
import AddRoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/AddRoboticProcess.vue";
import RoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/RoboticProcess";
import Dialog from "@/components/Parts/Dialog.vue";

class User {
  ID = "";
  name = "";
}

class CurrentTemplateClass {
  ID = null;
  name = "";
  type = "";
  module = "";
  fileType = "";
  fileName = "";
  fields = Array<{}>();
  reportFilters = Array<{}>();
  skipHeaders = false;
  createdAt: any = Timestamp.now();
  changedAt: any = Timestamp.now();
  createdBy: User = new User();
  changedBy: User = new User();
}

@Component({
  mixins: [CrudMixinVue],
  components: { Dialog, AddRoboticProcess },
})
export default class RoProBuilder extends Vue {
  [x: string]: any;
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  @Action("api/exportModule") exportModule;
  loading = true
  showCreateDialog = false;
  showLabelListDialog = false;
  showDeleteDialog = false;
  showEditDialog = false;
  showCopyDialog = false;
  currentTemplate = new CurrentTemplateClass();
  selectedModuleData = [];
  selectedLabelFields = Array<{ key: string; name?: string }>();
  editReportData = [];
  showAddRoProBuilder = false;
  maximizedToggle = false;
  existingRoPro = [];
  filteredRoPros = [];
  filter = '';
  selectedRoProModule = {label: "", value: ""};
  roPro = new RoboticProcess();
  deletionReason = "";
  showAddDialog = false
  getFirePathModule: any = null;
  midStepper = 4;
  operatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThanOrEqualTo'), value: ">=" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThanOrEqualTo'), value: "<=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThan'), value: ">" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThan'), value: "<" },
  ];
  existingRoProActions = {}

  // function to set the selectedRoproModule to the first module from the list with the available modules
  async mounted() {
    this.selectedRoProModule.label = this.modulesList[0].label
    this.selectedRoProModule.value = this.modulesList[0].value
    this.getFirePathModule = this.selectedRoProModule.value   
    await this.getActionsFromFirestore()
    if(this.selectedRoProModule.value != "") {
      await this.getExistingRoPros()
    }
    this.getFirePath()
  }

  // function to load the existing ropros
  async loadRoPros() {
    if(this.selectedRoProModule["value"] != "") {
    const firePath = `tenants/${store.state.tenantID}/modules/${this.selectedRoProModule.value}/roboticProcesses`
    this.getFirePathModule = this.selectedRoProModule.value    
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    for(const doc in docs) {
      for(const condition in docs[doc].conditions) {
        if(this.getField(docs[doc].conditions[condition].field).type == 'relatedField') {
          await this.getRelatedValue(docs[doc].conditions[condition]).then((response) => {
            docs[doc].conditions[condition].name = response
          })
        }
      }
      console.log("docs: ", docs);
    }
    this.existingRoPro = docs.map((d) => {
      return {
        ID: d.ID,
        title: d.title,
        description: d.description,
        active: d.active,
        fields: d.fields,
        type: d.type,
        actions: d.actions,
        conditions: d.conditions,
        criteriaPattern: d.criteriaPattern,
        changedAt: d.changedAt,
        when: d.when,
      };
    }); 
    this.filteredRoPros = this.existingRoPro
    }    
    this.getFirePath()
  }

  // function that calls the function to load the ropros and set loading to false
  async getExistingRoPros() { 
    this.loadRoPros();    
    this.loading = false
  }

  getAction(action) {
    const type = `${action.type}s`    
    if(type in this.existingRoProActions == false) {
      return {
        title: 'Not a valid action'
      }
    }
    
    for(const a of this.existingRoProActions[type]){
      if(a.ID == action.ID ){        
        return a
      }
    }
    return action
  }

  async getActionsFromFirestore() {
    const firePath = `tenants/${store.state.tenantID}/actions`    
    const res = await db.collection(firePath).get();
    
    const actionTypes: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (actionTypes as any).push(rec as any);
    });
    
    for(const actionType in actionTypes) {
      const type = actionTypes[actionType].id
      
      const firePath = `tenants/${store.state.tenantID}/actions/${type}/records`     
      const res = await db.collection(firePath).get();
      const actions: any = []
      res.forEach((doc) => {
        const rec: any = doc.data();
        rec.id = doc.id;
        (actions as any).push(rec as any);
      });
      this.existingRoProActions[type] = actions
    }    
  }

  // function that executes when creating a new ropro
  onTemplateCreate() {
    this.loading = false
    this.roPro = new RoboticProcess();
    this.showAddRoProBuilder = true;
    this.showAddDialog = true
  }

  // function that opens the ropro
  async openEditor(props) {
    this.roPro = new RoboticProcess()
    this.currentTemplate = new CurrentTemplateClass();    
    this.currentTemplate.ID = props.ID;
    for (const template in this.existingRoPro) {
      if (this.existingRoPro[template]["ID"] == this.currentTemplate.ID) {
        await this.roPro.import(this.existingRoPro[template], this.dynamicModules, this.selectedRoProModule.value);
        this.roPro.title = this.existingRoPro[template]["title"]
      }
    }
    this.showAddRoProBuilder = true
    this.showAddDialog = false    
  }

  // function that opens the delete dialog
  async onClickDeleteBtn(props) {
    this.showDeleteDialog = true;
    this.currentTemplate = props;
  }

  // function that opens the copy dialog
  onClickCopyBtn(props) {
    this.getFirePath()
    this.showCopyDialog = true;
    this.currentTemplate = new CurrentTemplateClass();
    this.currentTemplate.name = props.title;
    this.currentTemplate.ID = props.ID;
  }

  // function that deletes the ropro from firebase
  async onTemplateDelete() {
    this.getFirePath()
    const copyDoc = await this.getRecord(this.currentTemplate.ID);
    copyDoc.deletedBy = store.state.currentUser;
    copyDoc.deletedAt = Timestamp.now();
    copyDoc.deletionReason = this.deletionReason;       
    if (copyDoc) {
      const ref =  "tenants/" + store.state.tenantID + "/modules/" + this.getFirePathModule + "/deletedRoboticProcesses";
      await db.collection(ref).doc(copyDoc.ID).set(copyDoc);
    }
      
    await this.deleteRecord(this.currentTemplate);
    await this.getAllList();
    this.currentTemplate = new CurrentTemplateClass();
    store.state.alertMessage = "delete";
    this.existingRoPro = []
    this.getExistingRoPros()
    this.deletionReason = "";
  }

  // function that gets the firebase path
  getFirePath() {
    if(this.getFirePathModule === null || this.getFirePathModule === undefined) {
      const firePath = 'modules/';      
      return firePath
    }
    const firePath = 'modules/' + this.getFirePathModule + '/roboticProcesses';
    return firePath
  }

  // function that copies the existing ropro
  async onTemplateCopy() {
    this.getFirePath()
    const copyDoc = await this.getRecord(this.currentTemplate.ID);
    copyDoc.title = this.currentTemplate.name;
    copyDoc.ID = ""    
    await this.saveRecord(copyDoc);
    store.state.alertMessage = "add";
    this.existingRoPro = []
    this.getExistingRoPros()
  }

  canEditTemplate() {
    return true;
  }

  // when canceling the create dialog the currentTemplate has ben set to a new CurrentTemplateClass
  onCancelCreateDialog() {
    this.currentTemplate = new CurrentTemplateClass();
  }

  // when canceling the copy dialog the currentTemplate has ben set to a new CurrentTemplateClass
  onCancelCopyDialog() {
    this.currentTemplate = new CurrentTemplateClass();
  }

  // when canceling the delete dialog the currentTemplate has ben set to a new CurrentTemplateClass
  onCancelDeleteDialog() {
    this.currentTemplate = new CurrentTemplateClass();
  }

  // function that gets the list with modules
  get modulesList() {
    const modulesList = Array<{ label: string; value: string }>();
    Object.keys(this.dynamicModules).forEach((module) => {
      modulesList.push({
        label: this.getModuleTitle(module),
        value: module,
      });
    });
    return modulesList;
  }

  // Function to convert timestamp to normal date
  converDate(isostr) {    
    if (!isostr) {
      return '';
    }
    if(!isostr.seconds) {
      isostr = {
        seconds: Date.parse(isostr)
      }
      return new Date(isostr.seconds).toLocaleString('nl-Nl')
    }
    const date = new Date(isostr.seconds*1000);    
    return date.toLocaleString('nl-Nl')
  }

  // function that sets showAddRoProBuilder to false 
  closeAddRoProBuilderDialog(value: any) {
    this.showAddRoProBuilder = value
    this.showAddDialog = value
  }

// function that sets showAddRoProBuilder to false 
  async getShowAddRoProBuilder(value: any) {
    await this.loadRoPros()    
    this.showAddRoProBuilder = value
  }

  getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
    return `${firstRowIndex}-${endRowIndex} ${this.$t(
      "table.misc.of"
    )} ${totalRowsNumber}`;
  }

  getOperatorLabel(conditionOperator) {    
      for(const operatorOption in this.operatorOptions) {
        if(this.operatorOptions[operatorOption].value == conditionOperator) {          
          return this.operatorOptions[operatorOption].label
        }
      }
  }

  getCriteriaPatternLabel(criteriaPattern) {
    let label: any = null
    if(criteriaPattern != undefined) {
      if(criteriaPattern.value == "and") {
        label = this.$t("roboticProcessBuilder.headers.And")
      } else if(criteriaPattern.value == "or") {
        label = this.$t("roboticProcessBuilder.headers.Or")
      } 
    }
    return label
  }

  async getRelatedValue(condition) {
    return await this.getRelatedRecords(condition).then((records) => {
      for(const record of records) {
        if(record.ID == condition.value) {
          return record.name
        }
      }
    })
  }

  async getRelatedRecords(condition) {
    const field = this.getField(condition.field.split(".")[0])
    const module = this.dynamicModules[field.relatedModule]
    const firePath = `tenants/${store.state.tenantID}/modules/${module.id}/records`    
    const res = await db.collection(firePath).get();
    const records: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (records as any).push(rec as any);
    });    
    return Promise.resolve(records); 
  }

  getField(field) {    
    if (field.startsWith("__")) {
      return {
        label: ''
      };
    }
    field = field.split(".")[0];
    const f = this.dynamicModules[this.selectedRoProModule.value].fields[field]
    if(f == undefined){
      return {
        label: ''
      };
    }
    return f
  }

    filterOptions (val) {
    if (val !== "") {
      this.filteredRoPros = []
      for(const roPro in this.existingRoPro) {   
        if(this.existingRoPro[roPro].title && this.existingRoPro[roPro].title.toLowerCase().includes(val.toLowerCase())){
          this.filteredRoPros.push(this.existingRoPro[roPro]);
        }
        if(this.existingRoPro[roPro].description && this.existingRoPro[roPro].description.toLowerCase().includes(val.toLowerCase())){
          this.filteredRoPros.push(this.existingRoPro[roPro]);
        }
        if(this.existingRoPro[roPro].actions.length > 0) {
          for(const a in this.existingRoPro[roPro].actions){
            const action = this.getAction(this.existingRoPro[roPro].actions[a])
            if( action.title && action.title.toLowerCase().includes(val.toLowerCase())) {
              this.filteredRoPros.push(this.existingRoPro[roPro]);
            }
          }
        }
        if(this.existingRoPro[roPro].conditions.length > 0) {
          for(const c in this.existingRoPro[roPro].conditions) {
            const condition = this.existingRoPro[roPro].conditions[c]
            const field = this.getField(condition.field)
            const operator = this.getOperatorLabel(condition.operator)
            let value;            
            if(field.type == "relatedField") {
              value = condition.name
            } else if(field.type == "datetime") {
              value = this.converDate(condition.value)              
            } else {
              value = condition.value
            }
            const conditionString = `${field.label} ${operator} ${value}`
            if(conditionString.toLowerCase().includes(val.toLowerCase())) {
              this.filteredRoPros.push(this.existingRoPro[roPro]);
            }
          }
        }
      }
      return this.filteredRoPros
    } else {
      this.filteredRoPros = this.existingRoPro
      return this.filteredRoPros
    }
  }
}
