
import { Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";
import sortList from "@/common/helpers/utilities";

@Component({
  components: { DynamicFieldSingle },
})
export default class ConditionTable extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) fieldsToUpdate!: any;
  @Prop({ required: true }) module;
  @Prop() parentModule;
  @Prop() fieldUpdate;
  @Prop() fieldMerge;
  @Prop() fieldOptions;
  @Prop() fieldOptionsSecondModule;
  @Prop() parentToChild;
  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };

  // Function to convert timestamp to normal date
  converDate(isostr) {
    if (!isostr) {
      return '';
    }
    const date = new Date(isostr.seconds*1000);    
    return date.toLocaleString('nl-Nl')
  }
  // Function to check wich delete function to use
  getDeleteFunction(field1, field2, index) {
    if (this.fieldMerge == true) {
      this.deleteConditionFromFieldsToMerge(field1, field2, index);
    } else if (this.fieldMerge == false) {
      this.deleteConditionFromFieldsToUpdate(field1, index);
    }
  }

  // Function to delete the fieldmerges from the table
  deleteConditionFromFieldsToMerge(field1, field2, index) {
    if (this.parentToChild == false) {
      const field = this.dynamicModules[this.parentModule.name].fields[field2];
      
      
      this.fieldOptionsSecondModule.push({
        label: field.label,
        value: field.name,
        type: field.type,
        fieldType: field.fieldType,
        index: 0,
      });
      let ind = 0;
      for (const field in this.fieldOptionsSecondModule) {
        this.fieldOptionsSecondModule[field].index = ind;
        ind++;
      }
      this.fieldOptionsSecondModule = sortList(this.fieldOptionsSecondModule, 'label');

      const f = this.dynamicModules[this.module.name].fields[field1];
      
      
      this.fieldOptions.push({
        label: f.label,
        value: f.name,
        type: f.type,
        fieldType: f.fieldType,
        index: 0,
      });
      this.fieldOptions = sortList(this.fieldOptions, 'label');
      let i = 0;
      for (const field in this.fieldOptions) {
        this.fieldOptions[field].index = i;
        i++;
      }
      this.fieldsToUpdate.splice(index, 1);
    } else if (this.parentToChild == true) {
      const field = this.dynamicModules[this.module.name].fields[field2];
      this.fieldOptionsSecondModule.push({
        label: field.label,
        value: field.name,
        type: field.type,
        fieldType: field.fieldType,
        index: 0,
      });
      let ind = 0;
      for (const field in this.fieldOptionsSecondModule) {
        this.fieldOptionsSecondModule[field].index = ind;
        ind++;
      }

      const f = this.dynamicModules[this.module.name].fields[field1];
        
      this.fieldOptions.push({
        label: f.label,
        value: f.name,
        type: f.type,
        fieldType: f.fieldType,
        index: 0,
      });
      this.fieldOptions = sortList(this.fieldOptions, 'label');
      let i = 0;
      for (const field in this.fieldOptions) {
        this.fieldOptions[field].index = i;
        i++;
      }
      this.fieldsToUpdate.splice(index, 1);
      this.fieldOptionsSecondModule = sortList(this.fieldOptionsSecondModule, 'label');
    }
  }

  // Function to delete the field updates from the table
  deleteConditionFromFieldsToUpdate(fieldToUpdate, index) {
    if (this.parentToChild == false) {
      const field = this.dynamicModules[this.module.name].fields[fieldToUpdate];      
      this.fieldOptions.push({
        label: field.label,
        value: field.name,
        type: field.type,
        fieldType: field.fieldType,
        index: 0,
      });
      this.fieldOptions = sortList(this.fieldOptions, 'label');
      let i = 0;
      for (const field in this.fieldOptions) {
        this.fieldOptions[field].index = i;
        i++;
      }
      this.fieldsToUpdate.splice(index, 1);
    } else if (this.parentToChild == true) {
      const field = this.dynamicModules[this.parentModule.name].fields[
        fieldToUpdate
      ];
      this.fieldOptions.push({
        label: field.label,
        value: field.name,
        type: field.type,
        fieldType: field.fieldType,
        index: 0,
      });
      this.fieldOptions = sortList(this.fieldOptions, 'label');
      let i = 0;
      for (const field in this.fieldOptions) {
        this.fieldOptions[field].index = i;
        i++;
      }
      this.fieldsToUpdate.splice(index, 1);
    }
  }
}
