import db from "../db";
import store from "../store";
import { firestoreAction } from "vuexfire";
import SelectModel from "../components/Models/SelectModel";
import { Timestamp } from '@firebase/firestore';
import moment from "moment";
import Vue from "vue";

const state = {
  loading: 0, // subscribe is in the process
  filtersRaw: {},
  planboardPeriod: {},
  appointmentsT: [],
  ordersByID: {},
  ordersT: [],
  appointmentsR: [],
  planboardUsers: [],
  countLoad: 0,
  refreshCounter: 1,
};

const getters = {
  appointments(appsParam) {
    const wIDs = [];
    let apps = appsParam.map((a) => {
      if (a && a.workOrder && a.workOrder.ID && !a.wo) {
        const wo = state.ordersT.find((w) => w.ID === a.workOrder.ID);
        Vue.set(a, 'wo', wo);
        
        if (!a.wloaded) {
          wIDs.push(a.workOrder.ID);
        }
      }
      return a;
    });

    if (wIDs.length && state.countLoad < 5) {
      const params = {
        module: 'workOrders',
        IDs: wIDs
      };

      // stop after 5 times
      //state.countLoad++;

      store.dispatch('query/elasticQueryRecordsByIDs', params).then(res => {
        if (res && res.docs) {
          res.docs.forEach(d => {
            Vue.set(state.ordersByID, d.ID, d);
            
          })
          Vue.set(state, 'ordersT', Object.values(state.ordersByID));
        }
        wIDs.map(id => {
          const ap = appsParam.map((a) => {

            if (a.workOrder && a.workOrder.ID === id) {

              a.wloaded = true;
              const wo = state.ordersByID[id];
              
              Vue.set(a, 'wo', wo);
            }
          });
        });
      });
    }
    apps = apps.filter((a) => {
      // TODO
      return true || a.wo;
    });
    return apps;
  },
  appointmentsTasks() {
    if (state.refreshCounter) {
      
    }
    const apps = state.appointmentsT;
    return getters.appointments(apps);
  },
  appointmentsOrders() {
    if (state.refreshCounter) {
      
    }
    const apps = state.appointmentsR;
    return getters.appointments(apps);
  }
};

const actions = {

  async refreshAppointmentsOrders({ state }, woID) {
    state.appointmentsR.forEach(a => {
      if (a.wo && a.wo.ID === woID) {
        a.wo = null;
        a.wloaded = false;
      }
    });
    state.appointmentsT.forEach(a => {
      if (a.wo && a.wo.ID === woID) {
        a.wo = null;
        a.wloaded = false;
      }
    });
    state.refreshCounter++;
  },

  bindAppointments: firestoreAction(({ state, bindFirestoreRef }) => {

    let appointmentsT = db.collection("tenants/" + store.state.tenantID + "/modules/" + "appointments" + "/records");

    const appointmentStatus = SelectModel.appointmentStatusOverWritten ? SelectModel.appointmentStatusValuesPB : SelectModel.appointmentStatus;
    
    let inValues = Object.values(appointmentStatus).filter(status => {
      return ![SelectModel.mandatoryAppointmentStatuses.statusWhenToBePlanned,
        SelectModel.mandatoryAppointmentStatuses.statusWhenRemovedFromPlanboard].includes(status);
    })

    if (inValues.length > 10) {
      console.error('Status count more that 10', inValues);
      inValues = inValues.slice(10);
    }

    
    appointmentsT = appointmentsT.where("status", "in", inValues);

    if (state?.planboardPeriod?.start) {
      let filterTaskStart = moment(state.planboardPeriod.start, 'DD-MM-yyyy HH:mm');
      let filterTaskEnd = moment(state.planboardPeriod.end, 'DD-MM-yyyy HH:mm');

      

      filterTaskStart = Timestamp.fromDate(filterTaskStart.toDate());
      filterTaskEnd = Timestamp.fromDate(filterTaskEnd.toDate());

      
      
      appointmentsT = appointmentsT
        .where("plannedStartDateTime", ">=", filterTaskStart)
        .where("plannedStartDateTime", "<=", filterTaskEnd);
    }
    bindFirestoreRef("appointmentsT", appointmentsT);

  }),

  bindOrders: firestoreAction(({ rootState, bindFirestoreRef }) => {
    const appointmentsR = db.collection("tenants/" + store.state.tenantID + "/modules/" + "appointments" + "/records")
      //.where("status", "==", SelectModel.mandatoryAppointmentStatuses.toBePlanned);
      .where("status", "in", [
        SelectModel.mandatoryAppointmentStatuses.statusWhenToBePlanned,
        SelectModel.mandatoryAppointmentStatuses.statusWhenRemovedFromPlanboard
      ]);
    bindFirestoreRef("appointmentsR", appointmentsR);

    const resourcesModule = rootState.settings.planboardSchedulerSettings &&
    rootState.settings.planboardSchedulerSettings.resourcesModule ?
      rootState.settings.planboardSchedulerSettings.resourcesModule : 'users'
    const planboardUsers = db.collection("tenants/" + store.state.tenantID + "/modules/" + resourcesModule + "/records")
      .where("availableForPlanning", "==", true);
    bindFirestoreRef("planboardUsers", planboardUsers).then(() => {
    });

  }),

  unbindAppointments: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("appointmentsT");
  }),
  unbindOrders: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("appointmentsR");
    unbindFirestoreRef("planboardUsers");
  }),

  async getRelatedRecordsFromElastic(module, IDs) {
    return new Promise((resolve) => {
      return store.dispatch('query/getElasticRecordsByIDs', {
        module,
        filter: {
          filterData: IDs
        },
      }).then((res) => {
        resolve(res);
      });
    });
  },

  updatePlanBoardDate({ state, dispatch }, period) {
    state.planboardPeriod = period
    
    dispatch("bindAppointments");
  },

  initPlanBoardFilters({ state, dispatch }, type = 'init') {
    const doc = db.collection(
      "tenants/" +
      store.state.tenantID +
      "/settings/" +
      "user-filters" +
      "/users"
    );
    doc
      .doc(store.state.userID)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          state.filtersRaw = snapshot.data();
          if (!state.filtersRaw.tasks) state.filtersRaw.tasks = {};
          if (!state.filtersRaw.tasks.period) state.filtersRaw.tasks.period = {};

          // TODO set current period today
          const curPeriod = {
            start: moment().format('DD-MM-yyyy 00:00'),
            end: moment().format('DD-MM-yyyy 23:59')
          };

          if (!state.filtersRaw.orders) state.filtersRaw.orders = {};
          if (!state.filtersRaw.orders.period) state.filtersRaw.orders.period = {};

          if (!state.filtersRaw.mapTasks) state.filtersRaw.mapTasks = {};
          if (!state.filtersRaw.mapTasks.period) state.filtersRaw.mapTasks.period = {};
        }

        if(!state.planboardPeriod.start) state.planboardPeriod.start = moment().format('DD-MM-yyyy 00:00')
        if(!state.planboardPeriod.end) state.planboardPeriod.end = moment().format('DD-MM-yyyy 23:59')
        dispatch("bindAppointments");

        if(type === 'init') {
          dispatch("bindOrders");
        }
      });
  },

  initPlanBoardFiltersMap({ state, dispatch }) {
    const doc = db.collection(
      "tenants/" +
      store.state.tenantID +
      "/settings/" +
      "user-filters" +
      "/users"
    );
    doc
      .doc(store.state.userID)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          state.filtersRaw = snapshot.data();
          if (!state.filtersRaw.tasks) state.filtersRaw.tasks = {};
          if (!state.filtersRaw.tasks.period) state.filtersRaw.tasks.period = {};
          if (!state.filtersRaw.orders) state.filtersRaw.orders = {};
          if (!state.filtersRaw.orders.period) state.filtersRaw.orders.period = {};
          if (!state.filtersRaw.mapTasks) state.filtersRaw.mapTasks = {};
          if (!state.filtersRaw.mapTasks.period) state.filtersRaw.mapTasks.period = {};
        }
        dispatch("bindAppointments");
      });
  },

  updatePlanBoardFilters({ state, dispatch }, filters = {}) {
    if (
      !state.filtersRaw.tasks &&
      !state.filtersRaw.orders &&
      !state.filtersRaw.mapTasks
    ) {
      state.filtersRaw = { ...state.filtersRaw, ...filters };
    }
    if (state.filtersRaw.tasks && filters.tasks) {
      state.filtersRaw.tasks = { ...state.filtersRaw.tasks, ...filters.tasks };
    }
    if (state.filtersRaw.orders && filters.orders) {
      state.filtersRaw.orders = {
        ...state.filtersRaw.orders,
        ...filters.orders,
      };
    }
    if (state.filtersRaw.mapTasks && filters.mapTasks) {
      state.filtersRaw.mapTasks = {
        ...state.filtersRaw.mapTasks,
        ...filters.mapTasks,
      };
    }
    const doc = db.collection(
      "tenants/" +
      store.state.tenantID +
      "/settings/" +
      "user-filters" +
      "/users"
    );
    doc
      .doc(store.state.userID)
      .set(state.filtersRaw)
      .then(() => {});
    dispatch("initPlanBoardFilters", 'update');
  },
  updatePlanBoardFiltersMap({ state, dispatch }, filters = {}) {
    if (
      !state.filtersRaw.tasks &&
      !state.filtersRaw.orders &&
      !state.filtersRaw.mapTasks
    ) {
      state.filtersRaw = { ...state.filtersRaw, ...filters };
    }
    if (state.filtersRaw.tasks && filters.tasks) {
      state.filtersRaw.tasks = { ...state.filtersRaw.tasks, ...filters.tasks };
    }
    if (state.filtersRaw.orders && filters.orders) {
      state.filtersRaw.orders = {
        ...state.filtersRaw.orders,
        ...filters.orders,
      };
    }
    if (state.filtersRaw.mapTasks && filters.mapTasks) {
      state.filtersRaw.mapTasks = {
        ...state.filtersRaw.mapTasks,
        ...filters.mapTasks,
      };
    }
    const doc = db.collection(
      "tenants/" +
      store.state.tenantID +
      "/settings/" +
      "user-filters" +
      "/users"
    );
    doc
      .doc(store.state.userID)
      .set(state.filtersRaw)
      .then(() => {});
    dispatch("initPlanBoardFiltersMap");
  },

  async getRecordsByIDs({ state }, params) {
    const module = params.module;
    const ids = params.ids;
    const res = await db.collection("tenants/" + store.state.tenantID + "/modules/" + module + "/records")
      .where('ID', 'in', ids)
      .get();
    const docs = [];
    res.forEach((doc) => {
      const rec = doc.data();
      docs.push(rec);
    });
    return docs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
