import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";

import { enableVersionListener, disableVersionListener } from "./version-controller";

import firebase from "./firebaseApp";
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth"
import db from "./db";
import { storagePublic } from '@/storage';

import i18n from "./i18n";
import router from "./router";
import SettingsList from "@/components/Settings/Models/Settings.json"

import actions from "./store/actions";
import user from "./store/user";
import query from "./store/query.ts";
import subscribe from "./store/subscribe";
import dynamic from "./store/dynamic";
import settings from "./store/settings";
import product from "./store/product";
import portal from "./store/portal";
import api from "./store/api";
import AbModel from "@/components/Models/AbModel";
import {asyncForEach} from "@/common/helpers/async-for-each";
import RBAC from "@/components/Settings/Sections/UserManagement/RoleManagement/Models/RBAC";
import LicenseManager from "@/components/Licenses/LicenseManager";
import {isObject, isString} from "lodash";
import { ModuleHelperV2 } from '@yellowq-software/email';
import ListValues from "@/components/Mixin/ListValues";
import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
import SelectModel from "@/components/Models/SelectModel";

const UPDATE_DATA = "UPDATE_DATA";
const SET_LOADING = "SET_LOADING";
const SET_INIT = "SET_INIT";
const SET_USER = "SET_USER";                  // auth user, google
const SET_USER_MODULE = "SET_USER_MODULE";    // currentUser modules/users
const SET_USER_ROOT = "SET_USER_ROOT";        // from /user
const SET_TENANT = "SET_TENANT";
const SET_TENANT_TIMEZONE = "SET_TENANT_TIMEZONE";
const SET_DYN_MODULES = "SET_DYN_MODULES";
const SET_VIEWS_MODULES = "SET_VIEWS_MODULES";
const SET_USER_VIEWS = "SET_USER_VIEWS";
const SET_START_PAGE = "SET_START_PAGE";
const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";
const SET_LINE_MODULES = "SET_LINE_MODULES";
const SET_LICENSES_MODULES = "SET_LICENSES_MODULES";
const SET_ALL_MODULES = "SET_ALL_MODULES";
const SET_LOADED = "SET_LOADED";
const SET_PORTAL_TENANT = "SET_PORTAL_TENANT";
const SET_USER_SELECT_EMAIL_SETTINGS = "SET_USER_SELECT_EMAIL_SETTINGS";
const SET_USER_ROLES = "SET_USER_ROLES";
const SET_USER_LICENSES = "SET_USER_LICENSES";
const SET_SETTINGS = "SET_SETTINGS";

const SET_DROPDOWN_TRANSLATIONS = "SET_DROPDOWN_TRANSLATIONS";
const SET_FIELD_TRANSLATIONS = "SET_FIELD_TRANSLATIONS";

const SET_WEB_APP_VERSION = "SET_WEB_APP_VERSION";

const SET_PORTAL = "SET_PORTAL";
const SET_LANGUAGE = "SET_LANGUAGE";
const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    webAppVersion: null,
    licenseManager: null,
    guard: null,
    PORTAL: null,
    portalDesignLoaded: false,
    userID: "",
    ACL: [],
    userProfile: {},
    tenantID: 'need_to_login',
    portalTenantID: null,
    storage: firebase.storage,
    timezone: '',
    useSummerOffset: false, // +1 hour after 31 oct
    loading: true,
    initialization: false, // TODO when will be done portal backend fix it
    userSettings: {},
    userViewSettingsRaw: {},
    defaultViewSettingsRaw: {},
    currentDate: new Date().toString("yyyy-MM-dd"),
    currentUser: {ID: "", name:""}, // current user from Users module by authID
    currentUserFull: {roles: [], rolePermission: {}, licensesPerUser: []},  // full current user
    currentAccount: {},   // account for the portal view
    rootUser: null,       // /user form root
    alertMessage: "none",
    customMessage: {
      color: 'positive', // warning, negative
      text: ''
    },
    holidaysRaw: [],

    moneyFormat : {
      decimal: ',',
      thousands: '.',
      prefix: "€ ",
      precision: 2,
      masked: false
    },
    percentageFormat : {
      decimal: ',',
      thousands: '.',
      precision: 2,
      suffix: '%',
      masked: false
    },
    decimalFormat : {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    },
    softDelete: true, // use obsolete = true
    sourceForRecordLists: 'elastic-search', // 'firestore-query', 'firestore-subscribe' and 'elastic-search'
    useElasticRelatedQuery: true, // related fields and related lists
    allModules: {},
    dynamicModules: {},
    lineModules: {},
    viewsModules: {}, // portal
    portalViewSettings: {
      title: ''
    }, // portal settings
    portalViewName: '', // portal
    startPageAfterLogin: '',
    portalViewHidden: null, // portal hidden parts
    portalPermissions: null, // portal permissions
    portalCustomTabs: null, // portal menu tabs
    dateMask: "DD-MM-YYYY",
    dateTimeMask: "DD-MM-YYYY HH:mm",
    dateTimeMaskSort: "YYYY-MM-DD HH:mm",
    loadingControl: new Set(['user', 'modules']),
    licenses: null,
    modulesWithLicensesPerUser: null, // list of modules with per user license
    traceON: false, // use Sentry trace and performance log
    traceOneMinute: true, // use Sentry trace memory each minute
    debugMode: false // show field name in the label
  },

  getters: {
    language() {
      return i18n.locale;
    },
    locale() {
      return i18n.locale;
    },
    translate() {
      return i18n.messages[i18n.locale];
    },
    getCurrentLoggedInUser(state) {
      const currentUser = state.currentUser;
      if (currentUser && currentUser.ID && currentUser.name) {
        return currentUser;
      } else {
        return {ID: "", name:""};
      }
    },
    async getPreferences(state) {
      if(state && state.userID) {
        const preferenceDoc = await db.collection("tenants/" + state.tenantID + "/users/" + state.userID +  "/settings").doc('preferences').get();
        if(preferenceDoc.exists) {
          return preferenceDoc.data()
        }
      }
      return null
    },
    dynamicModulesData(state) {
      return state.dynamic;
    },
    systemVariables(state) {
      const vars = {
        '$currentUser': state.currentUser,
        'account': state.currentAccount,
      }
      return vars;
    },
  },

  mutations: {
    [SET_USER](state, profile) {
      state.userID = profile.uid;
      state.userProfile = profile;
      if (state.userID) {
        this.dispatch("setTenant");
      } else {
        this.dispatch("closeTenant");
      }
    },
    [SET_SETTINGS](state) {
      const allSettings = []
      for(const setting of SettingsList){
        allSettings.push(setting)
      }
      state.allSettings = allSettings
    },
    [SET_WEB_APP_VERSION] (state, webAppVersion) {
      state.webAppVersion = webAppVersion
    },
    [SET_USER_MODULE](state, curUser) {
      state.currentUser = { ID: curUser.ID, name: curUser.name };
      state.currentUserFull = curUser;
    },

    [SET_USER_ROLES](state, roles) {
      state.currentUserFull.rolePermission = roles;
      state.guard = new RBAC();
    },

    [SET_USER_LICENSES](state, licenses) {
      state.currentUserFull.licensesPerUser = licenses;
      if (state.modulesWithLicensesPerUser) {
        state.licenseManager = new LicenseManager();
      }
    },

    [SET_FIELD_TRANSLATIONS](state, translations) {
      if(translations){
        state.fieldTranslations = translations
      } else {
        state.fieldTranslations = null
      }
    },

    [SET_DROPDOWN_TRANSLATIONS](state, translations) {
      if(translations){
        state.dropDownTranslations = translations.dropDownValues
        state.dropDownMap = translations.dropDowns
      } else {
        state.dropDownTranslations = null
        state.dropDownMap = null
      }
    },

    [SET_LANGUAGE](state, language){
      if(language) {
        if(language === "nl-NL") {
          state.language = "NLD"
        } else if(language === "en-GB") {
          state.language = "GBR"
        } else {
          state.language = language
        }
      } else {
        state.language = null
      }
    },

    [UPDATE_LANGUAGE](state, language){
      if(language) {
        this.dispatch('setLanguage', language)
      }
    }, 

    [SET_USER_ROOT](state, rootUser) {
      state.rootUser = rootUser;
    },

    [SET_TENANT](state, tenant) {
      if (tenant) {
        state.tenantID = tenant.ID;
        state.storage = firebase.app().storage(tenant.bucket);
        state.licenses = tenant.licenses
      } else {
        state.tenantID = null;
        state.storage = null;
        state.licenses = null;
      }
    },

    [SET_TENANT_TIMEZONE](state, timezone) {
      state.timezone = timezone;
    },

    [SET_PORTAL_TENANT](state, portalTenantID) {
      state.portalTenantID = portalTenantID;
      state.tenantID = portalTenantID;
    },

    [SET_INIT](state, initialization) {
      state.initialization = initialization;
    },

    [SET_PORTAL](state, portal) {
      state.PORTAL = portal;
      state.portalDesignLoaded = true;
    },

    [SET_LOADING](state, loading) {
      state.loading = loading;
    },

    [SET_LOADED](state, loading) {
      state.loadingControl.delete(loading);
      if (!state.loadingControl.size) {
        state.loading = false;
        state.initialization = true;
      }
    },

    [SET_VIEWS_MODULES](state, modules) {
      state.viewsModules = modules;
      const permissions = {};
      for(const moduleName in state.viewsModules) {
        const module = state.viewsModules[moduleName];
        permissions[moduleName] = module.permissions || 0;
      }
      state.portalPermissions = permissions;
      this.commit(SET_LOADED, 'modules');
    },

    [SET_USER_VIEWS](state, settings) {
      state.portalViewSettings = {...state.portalViewSettings, ...settings};
      state.portalViewHidden = settings;
      state.portalViewName = settings && settings.currentView ? settings.currentView.value : 'x';
      state.portalCustomTabs = settings && settings.customTabs ? settings.customTabs : {};
      state.PORTAL.currentView = state.portalViewName;
      state.PORTAL.portalCustomTabs = state.portalCustomTabs;
    },

    [SET_START_PAGE](state, spage) {
      state.startPageAfterLogin = spage;
    },

    [SET_USER_ACCOUNT](state, account) {
      state.currentAccount = account;
    },

    [SET_USER_SELECT_EMAIL_SETTINGS](state, data) {
      state.userSettings.emails = data;
    },

    [SET_ALL_MODULES](state, allModules) {
      state.allModules = allModules;
      this.commit(SET_LOADED, 'modules');
    },

    [SET_DYN_MODULES](state, dynamicModules) {
      if (state.PORTAL) {
        // filter modules
        const visibleModules = {};
        for (const vModName in state.viewsModules || []) {
          if (dynamicModules[vModName]) {
            const dMobule = dynamicModules[vModName]
            visibleModules[vModName] = dMobule;
          }
        }
        state.dynamicModules = visibleModules;
      } else {
        state.dynamicModules = dynamicModules;
      }
      SelectModel.overwriteStatusList(state.dynamicModules['appointments']);
    },

    [SET_LINE_MODULES](state, lineModules) {
      state.lineModules = lineModules;
    },

    [SET_LICENSES_MODULES](state, licModules) {
      state.modulesWithLicensesPerUser = licModules;
      state.licenseManager = new LicenseManager();
    },

    [UPDATE_DATA](state, data) {
      state.techniciansList = data.techniciansList;
      state.counts = data.counts;
    },
    ...vuexfireMutations,
  },

  actions: {
    async getFieldTranslations({state, commit}) {
      let lang = "NLD"
      if(i18n.locale === 'nl-NL') {
        lang = 'NLD'
      } else if(i18n.locale === 'en-GB') {
        lang = "GBR"
      } else if (i18n.locale === 'fr-FR') {
        lang = "FRA"
      } else if (i18n.locale === 'de-DE') {
        lang = "DEU"
      }
      const translations = await db.collection(`tenants/${store.state.tenantID}/settings/translations/languages`).doc(lang).get().then((res) => {
        return res.data() ? res.data() : {}
      })
      const dropDownValues = {}
      const dropDowns = {}
      for (const key of Object.keys(translations)) {
        const module = key.split('.')[0]
        const type = key.split('.')[1]
        const field = key.split('.')[2]
        if(type === 'dropDowns'){
          if (typeof dropDownValues[module + '.' + field] !== "object"){
            dropDownValues[module + '.' + field] = []
          } 
          dropDownValues[module + '.' + field].push(translations[key])
          dropDowns[module + '.' + field + '.' + key.split('.')[3]] = translations[key]
        }

      }
      const values = {
        dropDownValues: dropDownValues,
        dropDowns: dropDowns,
      }
      commit(SET_FIELD_TRANSLATIONS, translations)
      commit(SET_DROPDOWN_TRANSLATIONS, values)
    },
    async setTenant({ state, commit, dispatch }) {
      const ref = doc(db, `users/${state.userID}`);
      getAuth(firebase.app())
      const logUser = (await getDoc(ref)).data();

      if (!logUser) {
        console.error("User not found /users", state.userID);
      }

      if (logUser && logUser.accessWebApp !== false) {
        commit(SET_USER_ROOT, logUser);
        await dispatch("portal/initPortalSettings");
        if (state.PORTAL) {
          await dispatch("dynamic/bindViewsModules");
        } else {
          const tenantID = logUser.tenant;
          const ref = doc(db, `tenants/${tenantID}`);
          const tenant = (await getDoc(ref)).data();
          if (tenant) {
            tenant.ID = tenantID;
          }
          commit(SET_TENANT, tenant);
        }

        if (state.tenantID) {
          await dispatch("initUserSettings");
          if(!state.PORTAL.account) {
            await dispatch("initUserRoles");
          }
          await dispatch("settings/initSettings");
          await dispatch("api/initSettings");
          await dispatch("dynamic/bindRecordLists");
          const moduleHelper = new ModuleHelperV2(firebase.firestore(), state.tenantID);
          const timezone = await moduleHelper.getTimeZone('Europe/Amsterdam');
          commit(SET_TENANT_TIMEZONE, timezone);
          enableVersionListener()
          ListValues.init();
          PdfTools.init();
        } else {
          await this.dispatch("closeTenant");
        }
      } else {
        alert("You don't have acces to our web application. Please contact our support team.")
        await this.dispatch("closeTenant");
      }
    },
    async closeTenant({ commit }) {
      disableVersionListener()
      await user.actions.logout();
      commit(SET_INIT, false);
      commit(SET_TENANT, null);
      commit(SET_START_PAGE, '');
    },
    async setLanguage({ state, commit, dispatch }, language) {
      i18n.locale = language;
      await db.collection("tenants/" + state.tenantID + "/settings/" + "user-language" + "/users").doc(this.state.userID).set({ language });
      commit(SET_LANGUAGE, language)
      await dispatch('getFieldTranslations')
      router.go(0)
    },
    setPreferences({ state }, preferences) {
      db.collection("tenants/" + state.tenantID + "/users/" + state.userID +  "/settings")
        .doc('preferences')
        .set(preferences, {merge: true});
    },

    async initUserSettings({ state, dispatch }) {

      if (state.userProfile.email) {
        const result = await db.collection("tenants/" + state.tenantID + "/modules/users/records").where("ID", "==", state.userID).get();
        const users = [];
        result.forEach((doc) => {
          users.push(doc.data());
        });
        if (users.length) {
          const curUser = users[0];
          this.commit(SET_USER_MODULE, curUser);
        }
        if (users.length && users[0].ACL) {
          this.state.ACL = users[0].ACL;
        }  
        this.commit(SET_LOADED, 'user');
        this.commit(SET_SETTINGS)
      }

      const usersResult = await db.collection("users").where("authID", "==", state.userID).get();
      const users = [];
      usersResult.forEach((doc) => {
        users.push(doc.data());
      });

      const docL = db.collection(
        "tenants/" + state.tenantID + "/settings/" + "user-language" + "/users"
      );
      await docL
        .doc(this.state.userID)
        .get()
        .then((snapshot) => {
          i18n.locale = snapshot.data() ? snapshot.data().language : "nl-NL";
          store.state.language = snapshot.data() ? snapshot.data().language : "nl-NL";
        });

      const docV = db.collection(
        "tenants/" + state.tenantID + "/settings/" + "user-view" + "/users"
      );
      docV
        .doc(this.state.userID)
        .get()
        .then((snapshot) => {
          this.state.userViewSettingsRaw = snapshot.data();
        });

      const docDefaultV = db.collection(
        "tenants/" + state.tenantID + "/settings/"
      );
      docDefaultV
        .doc("default-planboard-view")
        .get()
        .then((snapshot) => {
          this.state.defaultViewSettingsRaw = snapshot.data();
        });

      const docHolidays = db.collection(
        "tenants/" + state.tenantID + "/admin/"
      );
      docHolidays
        .doc("holidays")
        .get()
        .then((snapshot) => {
          this.state.holidaysRaw = snapshot.data();
        });
        await dispatch('getFieldTranslations')

      const docSettings = db.collection(
        "tenants/" + state.tenantID + "/users/" + state.userID + "/settings"
      );
      docSettings
        .doc("emails")
        .get()
        .then((snapshot) => {
          this.state.userSettings.emails = snapshot.data();
        });
    },

    async initUserRoles({ state }) {
      const dataRoles = {};
      if (!state.currentUserFull.roles) {
        this.commit(SET_USER_ROLES, {});
      } else {
        const userRoleIDs = state.currentUserFull.roles.map(r => r.ID).filter(r => r);
        if (userRoleIDs.length > 10) {
          console.error('No more 10 items in the user roles');
        }
        const firePath = "tenants/" + store.state.tenantID + "/roles";
        const resData = await db.collection(firePath).where('ID', 'in', userRoleIDs).get();
        const userRoles = [];
        resData.forEach(doc => {
          userRoles.push(doc.data());
        });
        await asyncForEach(userRoles, async (dataDoc) => {
          const dataRole = await db.collection(firePath + '/' + dataDoc.ID + '/modulePermissions').get();
          dataDoc.modulePermissions = {};
          dataRole.forEach(doc => {
            const roleDoc = doc.data();
            const mID = roleDoc.ID || doc.id;
            dataDoc.modulePermissions[mID] = roleDoc;
          });
          dataRoles[dataDoc.ID] = dataDoc;
        });
        this.commit(SET_USER_ROLES, dataRoles);
      }
    },

    initStorage({ state }) {
      const docTenant = db.collection("tenants");
      docTenant
        .doc(state.tenantID)
        .get()
        .then((snapshot) => {
          if (snapshot.data()) {
            this.state.storage = firebase.app().storage(snapshot.data().bucket);
          }
        });
    },

    sendNotification({ state }, notification) {
      db.collection("tenants/" + state.tenantID + "/notifications")
        .add(notification)
        .then(() => {
          alert(` '${notification.message}' Notification was sent`);
        })
        .catch(() => {});
    },

    updateUserViewSettings: function ({ state }, fields) {
      const userFields = { ...fields };
      const docV = db.collection(
        "tenants/" + state.tenantID + "/settings/" + "user-view" + "/users"
      );
      docV
        .doc(this.state.userID)
        .set(userFields)
        .then(() => {
          docV.doc(this.state.userID).get().then((snapshot) => {
              this.state.userViewSettingsRaw = snapshot.data();
            });
        });
    },

    updateDefaultViewSettings: function ({ state }, fields) {
      const userFields = { ...fields };
      const docDefaultV = db.collection(
        "tenants/" + state.tenantID + "/settings"
      );
      docDefaultV
        .doc("default-planboard-view")
        .set(userFields)
        .then(() => {
          docDefaultV.doc("default-planboard-view").get().then((snapshot) => {
            this.state.defaultViewSettingsRaw = snapshot.data();
          });
        });
    },

    getUserEmailsConfig({ state }) {
      return new Promise((resolve) => {
        return db
          .collection(
            "tenants/" +
              state.tenantID +
              "/users/" +
              state.userID +
              "/settings/"
          )
          .doc("emails")
          .get()
          .then((resp) => {
            if (resp.data() && typeof resp.data() !== "undefined") {
              resolve(resp.data());
            }
          })
          .catch(() => resolve({}));
      });
    },

    setUserEmailsConfig({ state }, emailsConfig) {
      return new Promise((resolve) => {
        return db
          .collection(
            "tenants/" +
              state.tenantID +
              "/users/" +
              state.userID +
              "/settings/"
          )
          .doc("emails")
          .set(emailsConfig)
          .then(() => resolve({}))
          .catch(() => resolve({}));
      });
    },

    resetAlertMessage({ state }) {
      state.alertMessage = "none";
      state.customMessage = {};
    },

    showAlertMessage({ state }, msg) {
      if (isString(msg)) {
        state.alertMessage = msg;
      } else if (isObject(msg)) {
        state.alertMessage = 'custom';
        state.customMessage = msg;
      }
    },

    copyWorkOrder({ state }, workOrder) {
      db.collection(
        "tenants/" + state.tenantID + "/modules/" + "workOrders" + "/records"
      ).add(workOrder);
    },

    uploadFileToStorage({ state }, file) {
      const docID = db.collection("tenants").doc().id;
      return new Promise((resolve) => {
        const metadata = {
          createdBy: {ID: "", name: ""},
          createdAt: null,
          ID: docID,
          fileName: file.name,
          fileType: file.type,
          fileSize: null,
          downloadableURL: '',
          stage: file.stage || 0,
          tags: []
        };
        new AbModel().setCreated(metadata);

        let childPath;
        if (file.onlyRef) {
          if (file.addTenant) {
            childPath = `/tenants/${state.tenantID}/${file.storageRef}${docID}`;
          } else {
            childPath = `/${file.storageRef}${docID}`;
          }
        } else {
          childPath = `/tenants/${state.tenantID}/modules/${file.storageRef}${docID}`;
        }

        let uploadTask;
        if (file.toPublic) {
           uploadTask = storagePublic.ref().child(childPath);
        } else {
           uploadTask = state.storage.ref().child(childPath);
        }

        if (file.base64PDF) {
          uploadTask = uploadTask.putString(file.base64PDF, 'base64', {contentType: file.type});
        } else {
          uploadTask = uploadTask.put(file, {});
        }

        uploadTask.on(
          "state_changed",
          (sp) => {
            //TODO show procents
            if (file.procentShowCallBack) {
              file.procentShowCallBack(sp);
            }
          },
          null,
          () => {
            uploadTask.snapshot.ref.getMetadata().then((spMetadata) => {
              metadata.fileSize = spMetadata.size;

              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                metadata.downloadableURL = downloadURL;
                return resolve(metadata);
              });

            });
          }
        );
      });
    },
    async getFileUrlFromStorage({ state, dispatch }, file) {
      let url = '';
      let filePath = `/tenants/${state.tenantID}/modules/${file.storageRef}${file.fileName}`;
      url = await dispatch('getFileDownloadURL', filePath);
      if (!url) {
        filePath = `/tenants/${state.tenantID}/modules/${file.storageRef}${file.ID}`;
        url = await dispatch('getFileDownloadURL', filePath);
      }
      return url;
    },
    async getFileDownloadURL({ state }, filePath) {
      return new Promise((resolve) => {
        state.storage.ref().child(filePath).getDownloadURL()
          .then((url) => {
            return resolve(url);
          })
          .catch((e) => {
            console.error(e.message);
            return resolve();
          });
      });
    },
    deleteFileFromStorage({ state }, filePath) {
      return new Promise((resolve) => {
        state.storage
          .ref()
          .child(`/tenants/${state.tenantID}/modules/${filePath}`)
          .delete()
          .then(() => {
            return resolve();
          })
          .catch(() => {
            return resolve();
          });
      });
    },
  },

  modules: {
    user,
    query,
    subscribe,
    dynamic,
    settings,
    product,
    portal,
    api,
    actions,
  },
});

export default store;
