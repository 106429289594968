
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import FieldUpdate from "@/components/Settings/Actions/fieldUpdate.vue";
import FieldMerge from "@/components/Settings/Actions/fieldMerge.vue";
import AddRoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/AddRoboticProcess.vue";
import RelatedFieldCopy from "@/components/Settings/Actions/relatedFieldCopy.vue";
import { FieldUpdateAction } from "@/components/Settings/Actions/actions";

@Component({
  components: {
    AddEmailTemplate,
    FieldUpdate,
    FieldMerge,
    AddRoboticProcess,
    RelatedFieldCopy
  },
})
export default class AddNewActions extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop() existingAction;
  @Prop() openExistingAction;
  typeOfFieldAction = [
    { name: "inRecord", title: "InRecord", label: "In Record" },
    { name: "childToParent", title: "ChildToParent", label: "Child To Parent" },
    { name: "parentToChild", title: "ParentToChild", label: "Parent To Child" },
  ];
  fieldUpdate = new FieldUpdateAction();
  selectedChildTypes = false;
  selectedFieldsTypes = false;
  selectedParentTypes = false;
  childModuleSelected = false;
  parentModuleSelected = false;
  showModulesList: Array<any> = [];
  showModules: Array<any> = [];
  options = this.showModules;
  refOptions = this.showModulesList;
  propModuleFieldUpdate = {};
  isTypeParentToChild = false;
  fieldsToUpdate = this.fieldUpdate.fieldUpdates;
  fieldsToMerge = this.fieldUpdate.fieldMerges;
  showFieldUpdate = false;
  showFieldMerge = false

  async mounted() {          
    this.getActionTypeFunction()     
    this.modulesList()
    if(this.openExistingAction == true){    
      await this.fieldUpdate.import(this.existingAction.ID, this.dynamicModules)
      this.showFieldsOfType()
      this.selectedChildType()
      this.fieldUpdate.referenceField = this.dynamicModules[this.fieldUpdate.childModule['name']].fields[this.fieldUpdate.referenceField['name']]      
    }    
  }

  // function to get the modules list
  modulesList() {    
    let showModules = {};
    this.showModules = [];
    for (const modules in this.dynamicModules) {
      if (this.dynamicModules[modules].pluralName != undefined) {
        showModules = {
          label: this.dynamicModules[modules].pluralName,
          name: this.dynamicModules[modules].id,
        };
      } else {
        showModules = {
          label: this.dynamicModules[modules].name,
          name: this.dynamicModules[modules].id,
        };
      }
      this.showModules.push(showModules);
    }
  }

  // Function to filter 
  filterFn(val, update) {
    if (val === "") {
      this.options = this.showModules;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.showModules.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  // Function to filter
  filterRef(val, update) {    
    if (val === "") {
      this.refOptions = this.showModulesList;
    }
    update(() => {
      const Reference = val.toLowerCase();
      this.refOptions = this.showModulesList.filter(
        (v) => v.label.toLowerCase().indexOf(Reference) > -1
      );
    });    
  }

  // Function to check if parent to child or child to parent
  selectedChildType() {
      if (this.fieldUpdate.type.name == "parentToChild") {
        this.isTypeParentToChild = true;
      } else if (this.fieldUpdate.type["name"] == "childToParent") {
        this.isTypeParentToChild = false;
      }
      if(this.existingAction.type == "ParentToChildren") {
        this.isTypeParentToChild = true;
      } else {
        this.isTypeParentToChild = false;
      }
  }

  // Get all the fields for the fields with type relatedField
  showFieldsOfType() {
    this.showModulesList = [];
      for (const field in this.dynamicModules[this.fieldUpdate.childModule["name"]].fields) {
        if (this.dynamicModules[this.fieldUpdate.childModule["name"]].fields[field].type == "relatedField") {
          const showFields = {
            label: this.dynamicModules[this.fieldUpdate.childModule["name"]]
              .fields[field].label,
            name: this.dynamicModules[this.fieldUpdate.childModule["name"]]
              .fields[field].name,
          };
          this.showModulesList.push(showFields);
        }
      }
    this.updateData()
  }

  updateData() {
    this.showFieldUpdate = true
    this.showFieldMerge = true 
  }

  // Emit function to get the field updates
  getFieldUpdates(value: any) {
    this.fieldsToUpdate = value;
    this.fieldUpdate.fieldUpdates = value
  }

  // Emit function to get the field merges
  getFieldMerges(value: any) {
    this.fieldsToMerge = value;
    this.fieldUpdate.fieldMerges = value
  }

  // emit function to save action in actionHeader.vue
  @Emit("getActionType")
  getActionTypeFunction() {
      const map = {
        value: this.fieldUpdate,
        type: "FieldUpdate"
      }
      return map   
  }
}
