

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";

  export default {
    name: "BaseFieldAutoNumber",
    components: {},
    mixins: [ BaseExtend ],
    data() {
      return {
      };
    },
    methods: {

    },
    computed: {
      model: {
        get() {
          return this.value;
        },
      },
    },
  };
