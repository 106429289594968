

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";

  export default {
    name: "BaseFieldNumber",
    components: {},
    mixins: [ BaseExtend ],
    data() {
      return {
        refreshKey: 1
      };
    },
    methods: {
      cleanModel() {
        if (this.isReadonly) {
          return false;
        }
        this.model = null;
      },
      refreshAfterCopy(value) {
        this.model = value;
        this.refreshKey++;
      },
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.formData.editingFields.push(this.name)
          if (!val && val !== 0) {
            val = null;
            if (this.$refs[this.name]) {
              this.$refs[this.name].$el.getElementsByTagName('input')[0].value = null;
            }
          } else {
            val =  Number(val);
          }
          this.$emit('input', val);
        },
      },
      getKey() {
        return (this.name ?? 'element') + this.refreshKey;
      },
    },
  };
