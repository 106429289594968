
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import store from "@/store";
import { Timestamp } from '@firebase/firestore';
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
import db from "@/db";

class User {
  ID = "";
  name = "";
}

class CurrentTemplateClass {
  ID = null;
  name = "";
  type = "";
  module = "";
  exportType = "";
  fileType = "";
  fileName = "";
  fields = Array<{}>();
  reportFilters = Array<{}>();
  skipHeaders = false;
  csvDelimiter = ',';
  createdAt: any = Timestamp.now();
  changedAt: any = Timestamp.now();
  createdBy: User = new User();
  changedBy: User = new User();}

@Component({
  mixins: [CrudMixinVue],
})

export default class UserConfiguration extends Vue {
  [x: string]: any;
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  @Action("api/exportModule") exportModule;
  userColumns = [
    {
        name: 'name',
        label: this.$t('userConfiguration.headers.user'),
        field: 'name',
        align: 'left',
        sortable: true,
    },
    {
        name: 'role',
        label: this.$t('userConfiguration.fields.role'),
        field: 'role',
        align: 'left',
        sortable: false,
    },
  ]
  currentTemplate = new CurrentTemplateClass();
  dataUsers: any = []
  filter = ""
  filterRole = ""
  users: any = []

  async mounted() {
    this.users = []
    const users = await db.collection(`tenants/${store.state.tenantID}/modules/users/records`).get()
    users.forEach(doc => {
      const usersDoc = doc.data();
        this.users.push({ID: `${usersDoc.ID}`, name: usersDoc.name || usersDoc.ID, roles: usersDoc.roles ? usersDoc.roles : [{ID: "", name: "No role defined"}]});
    })
  }

  async getUsers() {
    return this.users
  }

  // Filter the list on alphabetic order
  filterUsers (val) {
    const data = []
    if (val !== "") {
      this.users.forEach(doc => {
          const usersDoc = doc 
          if(usersDoc.name && usersDoc.name.toLowerCase().includes(val.toLowerCase())) {
            data.push({ID: `${usersDoc.ID}`, name: usersDoc.name || usersDoc.ID, roles: usersDoc.roles});
          }
      })
    }
    return data
  }

  // Filter the list on alphabetic order
  filterRoles (val, data) {
    const filteredData = []
    if (val !== "") {
      data.forEach(doc => {
          if(doc.roles && doc.roles[0].name.toLowerCase().includes(val.toLowerCase())) {
            filteredData.push({ID: `${doc.ID}`, name: doc.name || doc.ID, roles: doc.roles});
          }
      })
    } else {
      return data
    }
    return filteredData
  }

  // Filter the list on alphabetic order
  getFilteredData() {
    const userData: any = this.filterUsers(this.filter)
    const roleFilterData = userData.length ? userData : this.users
    
    const roleData: any = this.filterRoles(this.filterRole, roleFilterData)
    let data = roleData
    
    if (!data.length) {
      data = this.users
    }
    
    return data
  }

  get filteredData () {
    const data = this.getFilteredData()
    return data
  }

  getFirePath() {
    return "modules/users/records";
  }

  onRowClick(row) {
    this.$router.push({path: "/settings/user-configuration/edit/" + row.ID});
  }

  openEditor(props) {
    this.currentTemplate = new CurrentTemplateClass();
    this.currentTemplate.ID = props.row.ID;
    this.showAddReportsTemplates = true;
  }
}
