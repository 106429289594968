export default class SMSConfig {
    phoneNumber: string
    active: boolean
    constructor(phoneNumber: string, active: boolean) {
        this.phoneNumber = phoneNumber
        this.active = active
    }
    toJSON(){
        return {
          phoneNumber: this.phoneNumber,
          active: this.active
        }
    }
}