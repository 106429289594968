import AbModel, {Fields} from '@/components/Models/AbModel';
import store from '@/store';
import db from '@/db';
import Utils from '../utils/Utils';
import Organisation from "../Dto/Fields/Organisation"

export interface FormData {
  form: string;
  module: string;
  data: Fields;
  callbackAfterSave: object | null | Promise<void>;
  callbackAfterDelete: object | null | Promise<void>;
  callbackAlert: boolean;
  callbackAfterSaveRead: object | null | Promise<void>;
}

export default class FormDataTools {
  module = '';
  model = new AbModel();
  formData = new class FormDataClass implements FormData {
    form = "";
    module = "";
    data = new Fields();
    callbackAfterSave = null;
    callbackAfterDelete = {};
    callbackAlert = false;
    callbackAfterSaveRead: any = null;
    dropDownValues = {};
    relatedFields = {};
    dynamicFields = {};
    dynamicModule = {};
    dynamicSections = {};
    dynAutoNumbers = {};
    unsubscribeData = null;
  };

  constructor(module: string) {
    this.module = module;
    this.model.setDynModuleName(this.module);
  }

  async load(ID = '') {
    const record = await this.model.load(ID);
    this.formData.dynamicFields = this.model.dynFields();
    this.formData.dynamicModule = this.model.dynModule();
    this.formData.dynamicSections = this.model.dynSections();
    this.formData.dynAutoNumbers = this.model.dynAutoNumbers();
    this.formData.dropDownValues = this.model.dropDownValues;

    (this.formData.data as any) = this.getAllFields();

    if (ID) {
      this.formData.data = { ...this.formData.data, ...record };
    }


    if (ID) {
      // add related records
      const queries = [];
      for(const df in this.formData.dynamicSections) {
        const sec = this.formData.dynamicSections[df];
        if (sec.type === 'productLines') {
          //this.formData.data[df] = await this.loadProductLineData(ID, sec);
          queries.push(this.loadProductLineData(ID, sec));
        }
        if (sec.type === 'relatedRecords') {
          //this.formData.data[df] = await this.loadRelatedData(ID, sec);
          queries.push(this.loadRelatedData(ID, sec));
        }
      }
      const relatedData = await Promise.all(queries);
      if (relatedData) {
        for (const relatedDataKey in relatedData) {
          Object.keys(relatedData[relatedDataKey]).forEach(df => this.formData.data[df] = relatedData[relatedDataKey][df]);
        }
        
      }
    }

    if (ID) {
      // all related fields
      const queries = [];
      const relatedFields: any = {};
      for (const df in this.formData.dynamicFields) {
        const field = this.formData.dynamicFields[df];
        if (field.type === 'relatedField') {
          relatedFields[df] = field;
          relatedFields[df].value = this.formData.data[df];
        }
      }

      queries.push(this.loadRelatedFieldsData(relatedFields));

      const relatedData = await Promise.all(queries);
      if (relatedData && relatedData[0]) {
        Object.keys(relatedData[0]).forEach(df => this.formData.data[df] = relatedData[0][df]);
        
      }
    }
    
    const organisation: Organisation = await this.getOrganisation()

    let languageCode = undefined;
    if(!Utils.isEmpty(organisation) && !Utils.isEmpty(organisation.defaultLanguageCode)) {
      languageCode = organisation.defaultLanguageCode;
    }

    if(!Utils.isEmpty(this.formData.data['account']) && !Utils.isEmpty(this.formData.data['account']['languageCode'])) {
      languageCode = this.formData.data['account']['languageCode'];
    }
    const customTranslations = await this.getCustomTranslations(languageCode)
    if(!customTranslations.length) {
      this.formData.data['customTranslations'] = await this.getCustomTranslations(languageCode)
    } else {
      this.formData.data['customTranslations'] = await this.getCustomTranslations("NLD")
    }
    this.formData.data['organization'] = organisation
    
  }

  async getCustomTranslations(languageCode: string | undefined) {
    if(languageCode === undefined){
      return {}
    }
    try {
      const doc = await db.doc(`/tenants/${store.state.tenantID}/settings/customTranslations/translations/${languageCode}`).get();
      if(doc.exists) {
        return doc.data()
      }
      return {}
    } catch (error) {
      return {}
    }
  }

  async getOrganisation(): Promise<Organisation> {
    try {
      const doc = await db.doc(`/tenants/${store.state.tenantID}/settings/organization`).get();
      if(doc.exists) {
        return doc.data() as Organisation
      }
      return {} as Organisation
    } catch (error) {
      return {} as Organisation
    }
  }

  getFormData() {
    return this.formData;
  }

  getAllFields() {
    const mFields = this.model.fields();
    const dFields = this.convertDynamicFields(this.model.dynFields(), this.model.dynSections());
    return { ...mFields, ...dFields };
  }

  getDefValueForType(ftype) {
    const types = {
      text: '',
      checkbox: false,
      number: null,
      date: null,
      datetime: null,
      dropdown: null,
      relatedField: {ID: '', name: ''},
      noType: null,
      subForm: [],
      relatedMultiSelect: [],
      attachment: [],
      media: [],
    };
    return types[ftype];
  }

  convertDynamicFields(fields, sections) {
    const result = {};

    for(const field in fields) {
      const ftype = fields[field].type || 'noType';
      const tVal = this.getDefValueForType(ftype);
      const value = tVal === false ? false
          : tVal === '' ? ''
              : tVal || null;

      result[field] = value;
    }
    // add sections fields
    for(const sec in sections) {
      if (sections[sec].type === 'attachments') {
        result[sec] = [];
      }
      if (sections[sec].type === 'media') {
        result[sec] = [];
      }
    }
    return result;
  }

  async loadProductLineData(relatedID, sec) {
    const module = sec.lineType;
      const param = {
        filter: {relatedQuery: {record: relatedID}},
        module: module,
      };
      const res = await store.dispatch('query/getRecordsFirestore', param);
      const data = res ? res.docs : [];
      return {[module] : data};
  }

  async loadRelatedData(relatedID, sec) {
    if (!relatedID) {
      return [];
    }
    const module = sec.relatedModule;
    const param = {
      filter: {relatedQuery: {[sec.relatedByField + '.ID']: relatedID}},
      module: module,
    };
    // 
    const res = await store.dispatch('query/getRecordsFirestore', param);
    const data = res.docs ?? [];
    return {[module] : data};
  }

  async loadRelatedFieldsData(relatedFields){
    const data = {};
    for (const df in relatedFields) {
      const field = relatedFields[df];
     // 
      if (field.value !== null && field.value.ID) {
        data[df] = await this.model.getByID(field.value.ID, field.relatedModule);
      } else {
        data[df] = field.value;
      }
    }
    return data;
  }

}
