import * as numeral from 'numeral';
import {date} from "quasar";
import store from "../../store";
import firebase from '@/firebaseApp';
import { Timestamp } from '@firebase/firestore';

import Handlebars from "handlebars";
import MomentHandler from "handlebars.moment"
import moment from "moment";

MomentHandler.registerHelpers(Handlebars)

import { ConverterHelperV2 } from '@yellowq-software/email'

export default class ListValues {

  static converter = new ConverterHelperV2(firebase);

  static serviceDocFields = ['ID', 'createdAt', 'changedAt', 'createdBy', 'createdBy', 'changedBy'];

  static init() {
    const tz = store.state.timezone;
    ListValues.converter.setTimeZone(tz);
    if (store.state.useSummerOffset) {
      ListValues.converter.useSummerTimeOffset();
    }
    
  }

  static getField(fieldName, module) {
    const mData = store.state.allModules[module] || {};
    const dynFields = {...mData.fields, ...ListValues.locFields()};
    return dynFields[fieldName] || '';
  }

  static toCheckbox(val) {
    return val === true ? '✓' : '';
  }

  static toCurrency(val) {
    return numeral(val / 10000).format('$-0.00');
  }

  static toNumber(val) {
    return val;
  }

  static toPercentage(val) {
    let res: any = Number((val / 10000).toFixed(2));
    res = numeral(res).format('0.00') + '%';
    return res;
  }

  static toDecimal(val) {
    return numeral(val).format('0.00');
  }

  static toDateTime(val, forSorting = false) {
    const dataMask = forSorting ? store.state.dateTimeMaskSort : store.state.dateTimeMask;
    let res = val;
    if (val && typeof val.toDate === "function") {
      res = date.formatDate(val.toDate(), dataMask);
    } else if (val && val.seconds) {
      const ts = Timestamp.fromMillis(val.seconds * 1000);
      res = date.formatDate(ts.toDate(), dataMask);
    }
    return res;
  }

  static toFieldFormat(value, field) {
    const type = ListValues.getRealFieldType(field);
    if (type === 'checkbox') {
      return ListValues.toCheckbox(value);
    }
    if (type === 'currency') {
      return ListValues.toCurrency(value);
    }
    if (type === 'decimal') {
      return ListValues.toDecimal(value);
    }
    if (type === 'number') {
      return ListValues.toNumber(value);
    }
    if (type === 'percentage') {
      return ListValues.toPercentage(value);
    }
    if (ListValues.isDateType(type)) {
      return ListValues.toDateTime(value);
    }
    return value;
  }

  static getDigitalTypes() {
    return ['currency', 'decimal', 'number', 'percentage'];
  }

  static getDigitalTypes10K() {
    return ['currency', 'percentage'];
  }

  static getDateTypes() {
    return ['date', 'datetime'];
  }

  static isDigitalType(field) {
    return ListValues.getDigitalTypes().includes(ListValues.getRealFieldType(field));
  }

  static isDigitalType10K(field) {
    // the value stored in the DB multiple 10 000
    return ListValues.getDigitalTypes10K().includes(ListValues.getRealFieldType(field));
  }

  static isDateType(field) {
    return ListValues.getDateTypes().includes(ListValues.getRealFieldType(field));
  }

  static getRealFieldType(field) {
    let type = field ? field.type : '';
    if (type === 'calculatedField') {
      type = field.fieldType;
    }
    return type;
  }

  static transformOneByModule(value, module, fieldName) {
    const mData = store.state.allModules[module];
    const dynFields = {...mData.fields, ...ListValues.locFields()};
    return ListValues.transform([{[fieldName] : value}], dynFields)[0][fieldName];
  }

  static transform(values, dynFieldTypes, customSorting = false) {
    return values.map((v) => {

      // only one time transform
      if (v._transformed) {
        return v;
      }
      v._transformed = true;

      for (const field in v) {
        const type = ListValues.getRealFieldType(dynFieldTypes[field])

        // [Checkbox web UI](https://www.notion.so/Checkbox-web-UI-e51973ed7d354879aaf9eacd0c5a55ef)
        if (type === 'checkbox') {
          if (v[field] === null) {
            v[field] = '';
          } else {
            v[field] = ListValues.toCheckbox(v[field]);
          }
        }

        // [Currency field web UI](https://www.notion.so/Currency-field-web-UI-4576b5f641ca46b18a130c4261d1c185)
        if (type === 'currency') {
          if (customSorting) {
            v[field + '_sort'] = v[field] === null ? 0 : v[field];
          }
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] === 0 || v[field] && Number.isInteger(v[field])) {
            v[field] = ListValues.toCurrency(v[field]);
          }
        }

        // [Decimal web UI](https://www.notion.so/Decimal-web-UI-5e05d48373964ef5aa6566a3e1fafe53)
        if (type === 'decimal') {
          if (customSorting) {
            v[field + '_sort'] = v[field] === null ? 0 : v[field];
          }
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] || v[field] === 0) {
            v[field] = ListValues.toDecimal(v[field]);
          }
        }

        // [Number web UI](https://www.notion.so/Number-web-UI-7e6148b62a154b4b967abbd5b26a78dc)
        if (type === 'number') {
          if (customSorting) {
            v[field + '_sort'] = v[field] === null ? 0 : v[field];
          }
          if (v[field] === null) {
            v[field] = '-';
          } else {
            v[field] = ListValues.toNumber(v[field]);
          }
        }

        // Percentage
        if (type === 'percentage') {
          if (customSorting) {
            v[field + '_sort'] = v[field] === null ? 0 : v[field];
          }
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] === 0 || v[field] && Number.isInteger(v[field])) {
            v[field] = ListValues.toPercentage(v[field]);
          }
        }

        // [Date web UI](https://www.notion.so/Date-web-UI-e93dd11ddca6452daac6c7ec824baedd)
        // [Date time web UI](https://www.notion.so/Date-time-web-UI-12af75cac0694ffc9812814091471a19)
        if (type === 'datetime' || type === 'date') {
          if (customSorting) {
            v[field + '_sort'] = v[field] === null ? '1970-01-01 00:00' : ListValues.toDateTime(v[field], true);
          }
          if (v[field] === null) {
            v[field] = '-';
          } else {
            v[field] = ListValues.toDateTime(v[field]);
          }
        }

        // [Dropdown web UI](https://www.notion.so/Dropdown-web-UI-194ca6cdeae34c1d95fc309bf457014a)
        if (type === 'dropdown') {
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] && Object.prototype.hasOwnProperty.call(v[field], "name")) {
            v[field] = v[field].name;
          }
        }

        // [Related field web UI](https://www.notion.so/Related-field-web-UI-e8236a12d49648feb1a71f42bb398efb)
        if (type === 'relatedField') {
          if (v[field] === null || (v[field] && v[field].ID === '')) {
            v[field] = '-';
          } else if (v[field] && Object.prototype.hasOwnProperty.call(v[field], "name")) {
            v[field + '_ID'] = v[field] ? v[field].ID : '';
            v[field] = v[field] ? v[field].name : '';
          }
        }

        // [Text web UI](https://www.notion.so/Text-web-UI-7d160745f2294fcdb68eaad399dab82f)
        if (type === 'text') {
          if (v[field] === null || v[field] === '') {
            v[field] = '-';
          }
        }

      }

      return v;
    });
  }

  static customSort(rows, sortBy, descending) {
    const data = [...rows]
    if (sortBy) {
      const sortByField = data[0] && Object.prototype.hasOwnProperty.call(data[0],sortBy + '_sort') ? sortBy + '_sort' : sortBy;
      data.sort((a, b) => {
        const x = descending ? b[sortByField] : a[sortByField];
        const y = descending ? a[sortByField] : b[sortByField];
        const res = x > y ? 1 : (x === y ? 0 : -1);
        
        return res;
      })
    }
    return data
  }

  // TODO fix it move to the fields when will work form builder
  static locFields() {
    const lf = {
      createdAt: {label: 'Created At', type: 'datetime', order: 1},
      createdBy: {label: 'Created By', type: 'relatedField', order: 1},
      changedAt: {label: 'Changed At', type: 'datetime', order: 1},
      changedBy: {label: 'Changed By', type: 'relatedField', order: 1},
    }
    return lf;
  }

  static fieldsNoAutoSave(afield) {
    return ['changedAt', 'changedBy'].includes(afield);
  }

  static allTypingField(type) {
    return [
      'text', 'autoNumber', 'number', 'percentage', 'currency', 'date', 'datetime',
      'dropdown', 'status', 'relatedField', 'relatedMultiSelect', 'checkbox',
      'largeText', 'decimal', 'calculatedField', 'image', 'signature'
    ].includes(type);
  }

  static bigTypingField(type) {
    return ['text', 'largeText', 'number', 'percentage', 'currency', 'decimal'].includes(type);
  }

  static relatedQueryTypingField(type) {
    return ['dropdown', 'status', 'relatedField', 'relatedMultiSelect', 'checkbox'].includes(type);
  }

  static getValidColumns(fields) {
    const secFields = ['attachments', 'media', 'subForm', 'subrecord', 'relatedMultiSelect'];
    const hiddenFields: Array<string> = []
    for (const f in fields){
      if (fields[f].hidden) {
        hiddenFields.push(fields[f].name);
      }
    }
    return Object.fromEntries(
        Object.entries(fields)
        .filter((e: any) => secFields.indexOf(e[1].type) === -1 && !hiddenFields.includes(e[0]))
        .sort((a: any, b: any) => { return (a[1].label ?? a[0]).toLowerCase().localeCompare((b[1].label ?? b[0]).toLowerCase()) })
    );
  }

  static convertToObject(data, dateType = 'timestamp', notNeedJsonStringifyFields = []) {
    const saveTs = {};
    const saveFields: any = {};

    notNeedJsonStringifyFields.map((f) => {
      if (data[f]) {
        saveFields[f] = data[f];
      }
    });

    for (const f in data) {
      if (data[f] && typeof data[f].toDate === "function") {
        saveTs[f] = data[f];
      }
    }

    data = JSON.parse(JSON.stringify(data));

    for (const f in saveTs) {
      data[f] = saveTs[f];
    }

    for (const f in saveFields) {
      data[f] = saveFields[f];
    }

    for (const f in data) {
      if (data[f] && Object.prototype.hasOwnProperty.call(data[f],'seconds') && Object.prototype.hasOwnProperty.call(data[f], 'nanoseconds')) {
        data[f] = this.fromObjToTimestamp(data[f], dateType);
      } else {
        if (Array.isArray(data[f]) || this.isObject(data[f])) {
          if (Array.isArray(data[f])) {
            data[f].map(d => {
              this.convertElementDate(d, dateType);
            });
          } else {
            this.convertElementDate(data[f], dateType);
          }
        }
      }
    }
    return data;
  }

  static convertElementDate(d, dateType) {
      for (const v in d) {
        if (d[v] && Object.prototype.hasOwnProperty.call(d[v], 'seconds') && Object.prototype.hasOwnProperty.call(d[v], 'nanoseconds')) {
          d[v] = this.fromObjToTimestamp(d[v], dateType);
        }
      }
    }

  static fromObjToTimestamp(o, dateType = 'timestamp') {
    const sec = o.seconds
    const timestamp: any = Timestamp.fromMillis(sec * 1000);
    if (dateType === 'timestamp') {
      return timestamp.replace('T', ' ').replace('Z', '');
    }
    if (dateType === 'ISO-8601') {
      return timestamp.toDate().toISOString().replace('T', ' ').replace('Z', '');
    }
  }

  static transformDiv10k(values, formData) {

    const dynFieldTypes = formData.dynamicFields;
    const dynSections = formData.dynamicSections;
    const dynModules = store.state.allModules;

    for (const field in values) {
      let dynFieldsSub = {};

      if (Array.isArray(values[field]) || this.isObject(values[field])) {
        if (dynFieldTypes[field] && dynFieldTypes[field].type === 'relatedField') {
          dynFieldsSub = dynModules[dynFieldTypes[field].relatedModule]
          ? dynModules[dynFieldTypes[field].relatedModule].fields
          : {};
          
        }
        if (dynFieldTypes[field] && dynFieldTypes[field].type === 'subForm') {
          dynFieldsSub = dynFieldTypes[field]['fields'];
        }
        if (dynSections[field] && dynSections[field].type === 'relatedRecords') {
          dynFieldsSub = dynModules[dynSections[field].relatedModule]
            ? dynModules[dynSections[field].relatedModule].fields
            : {};
        }
        if (dynSections[field] && dynSections[field].type === 'productLines') {
          dynFieldsSub = dynModules[dynSections[field].lineType]
          ? dynModules[dynSections[field].lineType].fields
          : {};
        }

        if (Array.isArray(values[field])) {
          // array types
          values[field].map(d => {
            this.convElement10k(d, dynFieldsSub);
          });
        } else {
          // object type
          this.convElement10k(values[field], dynFieldsSub);
        }
      } else {
        // not array
        if (!values._t10k) {
          const type = dynFieldTypes[field] ? dynFieldTypes[field].type : '';
          if (type === 'currency' || type === 'percentage') {
            values[field] = Number(Number(values[field] / 10000).toFixed(2));
          }
        }
      }
    }
    values._t10k = true;
    return values;
  }

  static convElement10k(d, dynFieldsSub) {
    if (this.isObject(d) && !d._t10ka) {
      d._t10ka = true;
      for (const v in d) {
        const df = dynFieldsSub[v] ? dynFieldsSub[v] : {};
        if (this.isDigitalType10K(df)) {
          d[v] = Number(Number(d[v] / 10000).toFixed(2));
        }
      }
    }
  }

  static isObject(obj) {
    return obj === Object(obj);
  }

  static parseToSeconds(dateStr) {
    let val = (dateStr.length === 10) ? dateStr + ' 00:00' : dateStr;
    val = date.extractDate(val, "DD-MM-YYYY HH:mm");
    val = date.formatDate(val, "YYYY-MM-DD HH:mm");
    val = Date.parse(val);
    return val > 0 ? val / 1000 : 0;
  }

  static elasticTransformFields(values, module) {
    const mData = store.state.allModules[module];
    const dynFields = {...mData.fields, ...ListValues.locFields()};
    for (const field in values) {
      const dfield = dynFields[field] || {};
      if (ListValues.isDateType(dfield)) {
        if (values[field] instanceof Object) {
          values[field].name = ListValues.toDateTime(values[field]);
        }
      }
    }
    return values;
  }

  static elasticSortFields(field, module) {
    
    const mData = store.state.allModules[module];
    if (!mData) {
      return null;
    }
    const dynFields = {...mData.fields, ...ListValues.locFields()};
    const dynField = dynFields[field];
    if (dynField) {
      if (ListValues.isDateType(dynField)) {
        return field + ".seconds";
      } else if (dynField.type === 'relatedField') {
        return field + ".name.keyword";
      } else if (ListValues.isDigitalType(dynField) || dynField.type === 'checkbox') {
        return field;
      }
    } else {
      return null;
    }
    return field + ".keyword";
  }

  static elasticFilterLikeFields(field, module) {
    const mData = store.state.allModules[module] || {};
    const dynFields = {...mData.fields, ...ListValues.locFields()};
    const dynField = dynFields[field];
    if (dynField && (dynField.type === 'relatedField' || ListValues.isDateType(dynField))) {
      return field + ".name";
    }
    if (dynField && (dynField.type === 'dropdown')) {
      return field + ".keyword";
    }
    return field;
  }

  static getRangeConditions() {
    return {
      gte: '>=',
      lte: '<=',
      gt: '>',
      lt: '<',
    }
  }

  static getRange(value, dField) {
    
    let range = value.replace(/,/g, '.');
    const conds = ListValues.getRangeConditions();
    range = range.replace(/ {3}/g, ' ').replace(/ {2}/g, ' ');
    Object.keys(conds).forEach(c => { range = range.replace(conds[c] + ' ', conds[c]) });
    range = range.replace(/ /g, '&').replace(/and/g, '&');
    range = range.split('&').filter(r => r);

    const result: any = {};
    Object.keys(conds).forEach(c => {
      if (range[0] && range[0].includes(conds[c])) {
        let val = range[0].replace(conds[c], '').trim();
        if (ListValues.isDigitalType(dField)) {
          if (ListValues.isDigitalType10K(dField)) {
            val = Number(val) * 10000;
          }
        } else {
          val = ListValues.parseToSeconds(val);
        }
        if (val) {
          result[c] = val;
        }
      }
    });

    if (range[1]) {
      Object.keys(conds).forEach(c => {
        if (range[1].includes(conds[c])) {
          let val = range[1].replace(conds[c], '').trim();
          if (ListValues.isDigitalType(dField)) {
            if (ListValues.isDigitalType10K(dField)) {
              val = Number(val) * 10000;
            }
          } else {
            val = ListValues.parseToSeconds(val);
          }
          if (val) {
            result[c] = val;
          }
        }
      });
    }

    if (!Object.keys(result).length) {
      const val = range[0] ? range[0].trim() : '';
      let val1 = val;
      let val2 = val;

      if (ListValues.isDigitalType(dField)) {
        if (ListValues.isDigitalType10K(dField)) {
          val1 = Number(val) * 10000;
        }
      } else {
        val1 = ListValues.parseToSeconds(val);
      }

      if (ListValues.isDigitalType(dField)) {
        if (ListValues.isDigitalType10K(dField)) {
          val2 = Number(val) * 10000;
        }
      } else {
        val2 = ListValues.parseToSeconds(val) + 24 * 60 * 60;
      }

      if (ListValues.isDigitalType(dField)) {
        if (val1 || val1 === 0) {
          result.gte = val1;
        }
        if (val2 || val2 === 0) {
          result.lte = val2;
        }
      } else {
        if (val1) {
          result.gte = val1;
        }
        if (val1 && val2) {
          result.lte = val2;
        }
      }
    }
    
    
    return result;
  }

  static elasticFilterLikeValue(value) {
    // elastic search only by A-Z 0-9
    const val =  value.replace(/[\W_]+/g," ");
    return val;
  }

  static emailReplace(val) {
    const res = val.replace(/ /g, ',').replace(/;/g, ',').split(',').filter(v => v).join(', ');
    return res;
  }

  static getEmailAddresses(emailList, docData) {
    const itsArray = Array.isArray(emailList);
    const emailListArr = !itsArray
      ? ListValues.emailReplace(emailList || '').split(',')
      : emailList;
    const res = (emailListArr).map(em => {
      if (docData[em.trim()]) {
        return docData[em.trim()];
      } else {
        return (em.includes('@') || em.includes('{{') || em.includes('{%')) ? em : null;
      }
    }).filter(em => em);
    return ListValues.emailReplace(ListValues.render(res.join(', '), docData));
  }

  static render(val, docData) {
    const fTxt =  Handlebars.compile(val || '');
    return fTxt(docData);
  }

  static getCurrentUserName() {
    return store.state.currentUser && store.state.currentUser.ID && store.state.currentUser.name ? store.state.currentUser.name : '';
  }

  static singularTitle(module) {
    const mData = store.state.allModules[module];
    return mData ? mData.singularName || mData.name : module;
  }

  // fix the ID / name for q-select
  static fixQSelectData(data) {
    return data.map(d => {
      if (!d.name) {
        d.name = '-'
      }
      return d;
    })
  }

  //default values

  static dateAddTypeExists(defValue) {
    const types = [
      'year', 'quarter', 'month', 'week',
      'day', 'hour', 'minute', 'second'
    ];
    let res = false;
    types.forEach(t => {
      res = defValue.includes(t) || res;
    })
    return res;
  }

  static getDateTimeDefaultValue(defValue) {
    if (defValue === null || defValue === 'null' || defValue === '' || defValue === undefined) {
      return null;
    }
    if (defValue === 'now' || defValue === 'currenttime'|| defValue === 'current') {
      return Timestamp.now();
    }
    if (typeof defValue === 'string' && this.dateAddTypeExists(defValue)) {
      
      const dvalues = defValue.replace(/;/g, ',').split(',');
      let mdate: any = moment();
      let setdate = false;
      dvalues.forEach(dv => {
        const strAdd = dv.split(' ').filter(d => d);
        
        if (strAdd.length > 1) {
          mdate = mdate.add(strAdd[0], strAdd[1]);
          setdate = true;
        }
      });
      if (setdate) {
        const res = Timestamp.fromDate(mdate.toDate());
        
        return res;
      }
    }
    return defValue;
  }

  static getContentType(fileName) {
    const defType = 'application/octet-stream';
    const mimeTypes = {
      msg: 'application/vnd.ms-outlook'
    };
    const ext = fileName.split('.').pop();
    const res = mimeTypes[ext] || defType;
    
    return res;
  }

}
