
import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import store from "@/store";
import { Timestamp } from '@firebase/firestore';
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";
import ListValues from '@/components/Mixin/ListValues'
import GetRelatedFieldValue from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/GetRelatedFieldValue"
import sortList from "@/common/helpers/utilities"

class User {
  ID = "";
  name = "";
}

class CurrentTemplateClass {
  ID = null;
  name = "";
  type = "";
  module = "";
  exportType = "";
  fileType = "";
  fileName = "";
  fields = Array<{}>();
  reportFilters = Array<{}>();
  skipHeaders = false;
  csvDelimiter = ',';
  createdAt: Timestamp = Timestamp.now();
  changedAt: Timestamp = Timestamp.now();
  createdBy: User = new User();
  changedBy: User = new User();
}

class SelectClass {
  dateRange = false;
  numberRange = false;
  field = false;
}

class NewFilterClass{
 selectedField = {
   label: "",
    value: "",
    type: "",
 };
 typeOfValueField = "";
 dataTypeOfSelectedField = "";
 enterValue = "";
 from = "";
 to = "";
 showValueField = false;
 dateFilterStartCheckBox = false;
 dateFilterEndCheckBox = false;
}

@Component({
  mixins: [CrudMixinVue],
  components: {DynamicFieldSingle},
})
export default class AddReportsTemplates extends Vue {
  [x: string]: any;
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  @Prop(Object) propCurrentTemplate;

  selectedModule = {
    value: "",
    label: "",
  };

  addedFieldList = Array<{
    label: string;
    defaultValue: string;
    sourceKey: string;
    type: string;
    fieldType: string;
    canSaveDefaultValue: boolean;
  }>();

  editReportData = {};

  moduleOptions = Array<{ label: string; value: string }>();
  fieldOptions = Array<{ label: string; value: string; type: string; fieldType: string }>();
  currentTemplate = new CurrentTemplateClass();
  test: any = false;

  filterOptions = [
    { label: "Date Range Filter", value: "dateRange", dBType: 'range' },
    { label: "Number Range Filter", value: "numberRange", dBType: 'range' },
    { label: "Field Filter", value: "field", dBType: 'operator' }
  ];

  comparisonOperatorOptions = [
    { label: "==", value: "==" },
    { label: "!=", value: "!=" },
    { label: ">=", value: ">=" },
    { label: "<=", value: "<=" },
    { label: ">", value: ">" },
    { label: "<", value: "<" },
  ];

  comparisonBooleanOperatorOptions = [
    { label: "==", value: "==" },
    { label: "!=", value: "!=" }
  ];

  selectedFilter = { label: "", value: "", dBType: "" };
  selectedOperator = { value: "", label: "" };
  startDate = "";
  selectClass = new SelectClass();

  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };

  newFilter = new NewFilterClass();

  getFirePath() {
    return "reports/templates/records";
  }

  async mounted() {
    Object.keys(this.dynamicModules).forEach((module) => {
      this.moduleOptions.push({
        label: this.getModuleTitle(module),
        value: module,
      });
    });

    if (!this.propCurrentTemplate.ID && this.propCurrentTemplate.type == "journal") {
      this.currentTemplate.type = this.propCurrentTemplate.type;
      this.currentTemplate.name = this.propCurrentTemplate.name;
      this.currentTemplate.module = this.propCurrentTemplate.module;
      this.currentTemplate.fileType = this.propCurrentTemplate.fileType;
      this.currentTemplate.skipHeaders = this.propCurrentTemplate.skipHeaders;
      this.currentTemplate.csvDelimiter = this.propCurrentTemplate.csvDelimiter;

      this.selectedModule = {
        value: this.propCurrentTemplate.module,
        label: this.getModuleTitle(this.propCurrentTemplate.module)
      };
    } else if (!this.propCurrentTemplate.ID && this.propCurrentTemplate.type == "module") {
      const fields = this.dynamicModules[this.propCurrentTemplate.module].fields;
      Object.keys(fields).forEach((field) => {
        if (fields[field].label && fields[field].type != "subForm" && fields[field].type != "media" && fields[field].type != "attachment") {
          this.fieldOptions.push({
            label: fields[field].label,
            value: fields[field].name,
            type: fields[field].type,
            fieldType: fields[field].fieldType,
          });
        }
      });
      this.fieldOptions = sortList(this.fieldOptions, 'label')
      this.currentTemplate.type = this.propCurrentTemplate.type;
      this.currentTemplate.name = this.propCurrentTemplate.name;
      this.currentTemplate.module = this.propCurrentTemplate.module;
      this.currentTemplate.fileType = this.propCurrentTemplate.fileType;
      this.currentTemplate.skipHeaders = this.propCurrentTemplate.skipHeaders;
      this.currentTemplate.csvDelimiter = this.propCurrentTemplate.csvDelimiter;
      this.selectedModule = {
        value: this.propCurrentTemplate.module,
        label: this.getModuleTitle(this.propCurrentTemplate.module)
      };
    } else {
      this.editReportData = await this.getRecord(this.propCurrentTemplate.ID);
      this.currentTemplate.module = this.editReportData["module"];
      this.selectedModule = {
        value: this.editReportData["module"],
        label: this.getModuleTitle(this.editReportData["module"]),
      };

      const fields = this.dynamicModules[this.editReportData["module"]].fields;
      Object.keys(fields).forEach((field) => {
        if (fields[field].label && fields[field].type != "subForm" && fields[field].type != "media" && fields[field].type != "attachment") {
          this.fieldOptions.push({
            label: fields[field].label,
            value: fields[field].name,
            type: fields[field].type,
            fieldType: fields[field].fieldType,
          });
        }
      });
      this.fieldOptions = sortList(this.fieldOptions, 'label')
      this.editReportData["fields"].forEach((field) => {
        let canSaveDefaultValue = false;
        if (Object.prototype.hasOwnProperty.call(field, 'defaultValue')) {
          canSaveDefaultValue = true;
        }
        const sourceKey = field.sourceKey.replace('.name', '').replace('.ID', '');
        let addField;
        if (fields[sourceKey]) {
          addField = {
            label: field.label,
            defaultValue: field.defaultValue,
            sourceKey: field.sourceKey,
            type: fields[sourceKey].type,
            fieldType: fields[sourceKey].fieldType,
            canSaveDefaultValue: canSaveDefaultValue,
            notExist: false
          }
        } else {
          addField = {
            label: field.label,
            defaultValue: field.defaultValue,
            sourceKey: field.sourceKey,
            type: '',
            fieldType: '',
            canSaveDefaultValue: false,
            notExist: true
          }
          
        }
        this.addedFieldList.push(addField);
      });
      this.currentTemplate.ID = this.editReportData["ID"];
      this.currentTemplate.type = this.editReportData["type"];
      this.currentTemplate.name = this.editReportData["name"];
      this.currentTemplate.fileType = this.editReportData["fileType"];
      this.currentTemplate.fileName = this.editReportData["fileName"];
      this.currentTemplate.skipHeaders = this.editReportData["skipHeaders"];
      this.currentTemplate.csvDelimiter = this.editReportData["csvDelimiter"];
      this.currentTemplate.reportFilters = this.editReportData["reportFilters"] || [];
      this.currentTemplate.createdAt = this.editReportData["createdAt"];
      this.currentTemplate.changedAt = this.editReportData["changedAt"];
      this.currentTemplate.createdBy = this.editReportData["createdBy"];
      this.currentTemplate.changedBy = this.editReportData["changedBy"];
    }
  }

  async templateSave() {
    this.addedFieldList.forEach((field) => {
      const fieldObj = {
        label: field.label,
        defaultValue: field.defaultValue !== undefined ? field.defaultValue : '',
        sourceKey: field.sourceKey,
        type: this.getReportFieldDataType(field),
      };
      if (!field.canSaveDefaultValue) delete fieldObj.defaultValue;
      this.currentTemplate.fields.push(fieldObj);
    });

    this.currentTemplate.module = this.selectedModule.value;
    this.currentTemplate.exportType = this.currentTemplate.fileType;
    this.currentTemplate.reportFilters.forEach(e => {
      delete (e as any).index;
      delete (e as any).dateFilterStartCheckBox;
      delete (e as any).dateFilterEndCheckBox;
      delete (e as any).popUpEditVModel;
    });
    await this.saveRecord(this.currentTemplate);
    this.currentTemplate = new CurrentTemplateClass();
    store.state.alertMessage = "add";
    // await this.getAllList();
    this.windowClose()
  }

  onSaveButtonClick () {
    //eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    this.$refs.templateName.validate();
    //eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    this.$refs.templateFileType.validate();
    //eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    this.$refs.templateFileName.validate();
    //eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    for(const filter of this.currentTemplate.reportFilters) {
      if(filter['value'] && filter['value']['ID']) { 
        filter['value'] = filter['value']['ID']
      }
    }
    if (this.$refs.templateName['hasError'] || this.$refs.templateFileType['hasError'] || this.$refs.templateFileName['hasError']) {
      this.$q.notify({ message: 'Field(s) not valid', color: 'negative', icon: 'warning' })
    } else {
      this.templateSave()
    }
  }

  onSelectModule() {
    this.fieldOptions = [];
    this.addedFieldList = [];
    this.currentTemplate.reportFilters = [];
    this.initFilter();

    const fields = this.dynamicModules[`${this.selectedModule.value}`].fields;
    Object.keys(fields).forEach((field) => {
      if (fields[field].label) {
        this.fieldOptions.push({
          label: fields[field].label,
          value: fields[field].name,
          type: fields[field].type,
          fieldType: fields[field].fieldType,
        });
      }
    });
  }

  onSelectLabelField(index) {
    console.log(this.fieldOptions[index])
    let sourceKey = this.fieldOptions[index].value
    if(this.fieldOptions[index].type == 'relatedField') sourceKey = this.fieldOptions[index].value + '.name'
    this.addedFieldList.push({
      label: this.fieldOptions[index].label,
      defaultValue: "",
      sourceKey: sourceKey,
      type: this.fieldOptions[index].type,
      fieldType: this.fieldOptions[index].fieldType,
      canSaveDefaultValue: false
    });
  }

  getReportFieldDataType(field){
    const realType = this.getFieldType(field);
    let result = 'unknownType';
    switch (realType){
      case 'text': result = 'text'; break;
      case 'autoNumber': result = 'text'; break;
      case 'number': result = 'number'; break;
      case 'decimal': result = 'number'; break;
      case 'percentage': result = 'percentage'; break;
      case 'currency': result = 'currency'; break;
      case 'date': result = 'date'; break;
      case 'datetime': result = 'datetime'; break;
      case 'dropdown': result = 'text'; break;
      case 'relatedField': result = 'text'; break;
      case 'relatedMultiSelect': result = 'text'; break;
      case 'checkbox': result = 'boolean'; break;
      case 'largeText': result = 'text'; break;
    }
    return result
  }

  onSelectFilter() {
    this.selectClass = new SelectClass();
    this.selectClass[this.selectedFilter.value] = true;
    this.initFilter();
    this.scrollToEndOfPage()
  }

  onFilterFieldSelect(selectedField) {
    this.initFilter();
    this.newFilter.selectedField = Object.assign({}, selectedField);
    if(this.newFilter.selectedField.type === 'relatedField') {
      this.newFilter.selectedField.value = this.newFilter.selectedField.value + '.ID';
    }
    this.newFilter.typeOfValueField = this.getFieldType(this.newFilter.selectedField);
    this.newFilter.dataTypeOfSelectedField = this.objectOfType[this.newFilter.typeOfValueField];
    this.newFilter.showValueField = true;
  }

  addFilterToReportFilters() {
    // if (this.currentTemplate.reportFilters.length >= 10) {
    //   alert('maxFiltersCount = 10');
    //   return
    // }
    let filterObj = {};
    if (this.selectedFilter.dBType === 'operator') {
      filterObj = {
        type: this.selectedFilter.dBType,
        fieldName: this.newFilter.selectedField.value,
        operator: this.selectedOperator.value,
        value: this.newFilter.enterValue,
      };
    } else if (this.selectedFilter.dBType === 'range'){
      filterObj = {
        type: this.selectedFilter.dBType,
        fieldName: this.newFilter.selectedField.value,
        from: this.newFilter.from,
        to: this.newFilter.to,
      };
    }

    this.currentTemplate.reportFilters.push(filterObj);
    this.initFilter();
    this.scrollToEndOfPage();
  }

  deleteFilterFromReportFilters(index) {
    this.currentTemplate.reportFilters = this.currentTemplate.reportFilters.filter((e, i) => i !== index)
  }

  deleteFromAddedFieldList(index) {
    this.addedFieldList = this.addedFieldList.filter((e, i) => i !== index)
  }

  getField(fieldName) {
    let field = {label: "", name: "", type: "", fieldType: ""};
    if (!fieldName) {
      
      return field
    }
    const fieldNameNoID = fieldName.replace('.ID', '');

    field =
      this.dynamicModules &&
      this.currentTemplate &&
      this.currentTemplate.module &&
      this.dynamicModules[this.currentTemplate.module] &&
      this.dynamicModules[this.currentTemplate.module].fields &&
      this.dynamicModules[this.currentTemplate.module].fields[fieldNameNoID]
        ? Object.assign({}, this.dynamicModules[this.currentTemplate.module].fields[fieldNameNoID])
        : field;

    if (field.type === 'relatedField') field.label = field.label + '.ID';
    return field
  }

  getFieldType (field) {
    let result = field.type;
    if (field.type === 'autoNumber') result = 'text';
    if (field.type === 'calculatedField') result = ListValues.getRealFieldType(field);
    if (!result) console.log('field.type is unknown');
    return result
  }

  getOperatorOptions(fieldType) {
    return  fieldType === 'boolean' || fieldType === 'text' || fieldType === 'map'
      ? this.comparisonBooleanOperatorOptions
      : this.comparisonOperatorOptions;
  }

  _getExistingFilters(type) {
    const filters = Array<{}>();
    this.currentTemplate.reportFilters.forEach((e, index) => {
      const filterType = (e as any).type === type;
      if (filterType) {
        const filter = e;
        // Set reportFilters index in filtered array. Used for filter delete
        this.$set(filter, 'index', index);
        // Set checkbox properties for q-popup-edit dialog in 'range' filters list
        this.$set(filter, 'dateFilterStartCheckBox', (filter as any).from === '$lastRunOn' );
        this.$set(filter, 'dateFilterEndCheckBox', (filter as any).to === '$currentTime' );
        this.$set(filter, 'popUpEditVModel', false );
        filters.push(filter)
      }
    });
    return filters
  }
  get existingRangeFilters() { return this._getExistingFilters('range') }
  get existingFieldFilters() { return this._getExistingFilters('operator') }

  getFieldOptionsByType() {
    let fieldOptions = this.fieldOptions;
    switch (this.selectedFilter.value) {
      case 'numberRange':
        fieldOptions = this.fieldOptions.filter(e =>  this.objectOfType[this.getFieldType(e)] === 'number'); break;
      case 'dateRange':
        fieldOptions = this.fieldOptions.filter(e => this.objectOfType[this.getFieldType(e)] === 'timestamp'); break;
      case 'field':
        fieldOptions = this.fieldOptions; break;
      default: fieldOptions = this.fieldOptions; break;
    }
    return fieldOptions
  }

  initFilter() {
    this.selectedOperator = { value: "", label: "" };
    this.newFilter = new NewFilterClass();
  }

  onDateStartCheckBoxClick(){
    this.newFilter.from = '$lastRunOn';
  }

  onDateEndCheckBoxClick(){
    this.newFilter.to = '$currentTime';
  }

  windowClose() {
    this.$emit('onModalClose')
  }

  getFilterValue(filter, field) {
    /*
    // show 'true' and 'false' value in the filters table
    // otherwise it is shown '✓' and ''
    let result = null;
    if (this.getField(filter.fieldName).type === 'checkbox') {
      result = filter.value
    } else {
      result = ListValues.transformOneByModule(filter[field], this.currentTemplate.module, filter.fieldName)
    }
    return result*/
    if(filter.fieldName.includes(".ID")) {
      // return this.getRelatedField(filter)
      return ListValues.transformOneByModule(filter[field], this.currentTemplate.module, filter.fieldName)
    } else {
      return ListValues.transformOneByModule(filter[field], this.currentTemplate.module, filter.fieldName)
    }
  }

  scrollToEndOfPage() {
    const el = document.getElementById('end-of-page');
    if (el) el.scrollIntoView({block: "end", behavior: "smooth"});
  }

  async getRelatedField(filter) { 
    const value = await GetRelatedFieldValue(filter.fieldName, filter.value, this.dynamicModules, this.currentTemplate.module)
    return value
  }

  get showNewRangeFilterAddButton(){
    return this.newFilter.showValueField && (this.selectClass.numberRange || (this.newFilter.from && this.newFilter.to))
  }

  get showNewFieldFilterAddButton(){
    if (!this.newFilter.showValueField || !this.selectedOperator.value) {
      return false
    }

    let result = true;
    if (this.newFilter.dataTypeOfSelectedField === 'boolean' && this.newFilter.enterValue === '') {
      result = false
    } else if (this.newFilter.dataTypeOfSelectedField === 'timestamp' && !this.newFilter.enterValue) {
      result = false
    }
    return result
  }
}
