
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import Table from "@/components/Settings/Actions/relatedFieldCopyTable.vue";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";
import sortList from "@/common/helpers/utilities";

class NewRelatiedFieldCopyClass {
  selectedField = {
    label: "",
    value: "",
    type: "",
  };
  targetField = [];
  typeOfValueField = "";
  dataTypeOfSelectedField = "";
  enterValue = "";
  from = "";
  to = "";
  showValueField = false;
  showTargetField = false;
  dateFilterStartCheckBox = false;
  dateFilterEndCheckBox = false;
}

@Component({
  components: { Table, DynamicFieldSingle },
})
export default class RelatedField extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) selectedModule;
  @Prop({ required: true }) existingRelatedFieldCopies;
  relatedField = new NewRelatiedFieldCopyClass();
  fieldOptions = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
    index: number;
  }>();
  targetFieldOptions = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
    index: number;
  }>();
  fieldsTypeRelatedField = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
    relatedModule: string;
    index: number;
  }>();
  existingRelatedFields = Array<{}>();
  options = this.fieldsTypeRelatedField;
  optionsSecond = this.fieldOptions;
  optionsThird = this.targetFieldOptions;
  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };

  async mounted() {
    for(const existingRelatedFieldCopy in this.existingRelatedFieldCopies) {
      this.existingRelatedFields.push(this.existingRelatedFieldCopies[existingRelatedFieldCopy])
    }
    
    const fields = this.dynamicModules[this.selectedModule.name].fields;
    Object.keys(fields).forEach((field) => {
      if (
        this.dynamicModules[this.selectedModule.name].fields[field].type ==
        "relatedField"
      ) {
        this.fieldsTypeRelatedField.push({
          label: fields[field].label,
          value: fields[field].name,
          type: fields[field].type,
          fieldType: fields[field].fieldType,
          relatedModule: fields[field].relatedModule,
          index: 0,
        });
      }
    });
    this.fieldsTypeRelatedField = sortList(this.fieldsTypeRelatedField, 'label');
  }

  // To get the options for the selected after selecting the related field
  onFieldSelect() {
    this.fieldOptions = [];
    const fields = this.dynamicModules[this.relatedField.selectedField["relatedModule"]].fields;
    
    Object.keys(fields).forEach((field) => {
      this.fieldOptions.push({
        label: fields[field].label,
        value: fields[field].name,
        type: fields[field].type,
        fieldType: fields[field].fieldType,
        index: 0,
      });
    });
    this.fieldOptions = sortList(this.fieldOptions, 'label');
    let ind = 0;
    for (const field in this.fieldOptions) {
      this.fieldOptions[field].index = ind;
      ind++;
    }
    const sourceFieldList = this.fieldOptions;
    if (this.existingRelatedFields.length > 0) {
      for (const relatedFieldCopies in this.existingRelatedFields) {
        if (this.existingRelatedFields[relatedFieldCopies]["field"] == this.relatedField.selectedField.value) {
          for (const field in sourceFieldList) {
            if (sourceFieldList[field].label == this.existingRelatedFields[relatedFieldCopies]["value"].label) {
              sourceFieldList[field].index = this.existingRelatedFields[relatedFieldCopies]["value"].index;
              this.fieldOptions.splice(sourceFieldList[field].index, 1);
            }
          }
        }
      }
    }
    this.fieldOptions = sortList(this.fieldOptions, 'label');
    let i = 0;
    for (const field in this.fieldOptions) {
      this.fieldOptions[field].index = i;
      i++;
    }
    this.relatedField.showValueField = true;
    this.fieldOptions = sourceFieldList;
  }

  // Function to add existingRelatedFields to the table
  addExistingRelatedFields(selectedField, index, selectedEnterValue) {
    if (selectedField == this.existingRelatedFields["field"]) {
      this.fieldOptions = sortList(this.fieldOptions, 'label');
    } else {
      let fieldMap = {};
      fieldMap = {
        field: this.relatedField.selectedField.value,
        value: this.relatedField.enterValue,
        targetField: this.relatedField.targetField,
        type: this.relatedField.typeOfValueField,
      };
      this.existingRelatedFields.push(fieldMap);
      this.relatedField.enterValue = "";
      this.relatedField.targetField = [];
      let i = 0;
      this.fieldOptions = sortList(this.fieldOptions, 'label');
      for (const field in this.fieldOptions) {
        this.fieldOptions[field].index = i;
        i++;
      }
      this.fieldOptions.splice(selectedEnterValue.index, 1);
      
      this.sendRelatedFieldCopies();
    }
  }

  // Emit function to return the existingRelatedFields
  @Emit("relatedFieldCopies")
  sendRelatedFieldCopies() {
    return this.existingRelatedFields;
  }

  // Function to get the option for the target field
  getTargetField() {
    this.targetFieldOptions = [];
    const fields = this.dynamicModules[this.selectedModule.name].fields;
    Object.keys(fields).forEach((field) => {
      if (
        this.dynamicModules[this.selectedModule.name].fields[field].type ==
        this.relatedField.enterValue["type"]
      ) {
        this.targetFieldOptions.push({
          label: fields[field].label,
          value: fields[field].name,
          type: fields[field].type,
          fieldType: fields[field].fieldType,
          index: 0,
        });
      }
    });

    let ind = 0;
    this.targetFieldOptions = sortList(this.targetFieldOptions, 'label');
    for (const field in this.targetFieldOptions) {
      this.targetFieldOptions[field].index = ind;
      ind++;
    }

    let targetFieldList = this.targetFieldOptions;
    if (this.existingRelatedFields.length > 0) {
      // let i = 0
      let ind = 0;
      for (const relatedFieldCopies in this.existingRelatedFields) {
        if (
          this.existingRelatedFields[relatedFieldCopies]["value"].type ==
          this.relatedField.enterValue["type"]
        ) {
          for (const field in targetFieldList) {
            if (
              targetFieldList[field].value ==
              this.existingRelatedFields[relatedFieldCopies]["targetField"][ind]
                .value
            ) {
              let i = 0;
              targetFieldList = sortList(targetFieldList, 'label');
              for (const field in targetFieldList) {
                targetFieldList[field].index = i;
                i++;
              }
              targetFieldList.splice(targetFieldList[field].index, 1);
              if (
                ind <
                this.existingRelatedFields[relatedFieldCopies]["targetField"]
                  .length -
                  1
              ) {
                ind++;
              }
            }
          }
        }
      }
    }
    let i = 0;
    targetFieldList = sortList(targetFieldList, 'label');
    for (const field in targetFieldList) {
      targetFieldList[field].index = i;
      i++;
    }
    this.relatedField.showTargetField = true;
    this.targetFieldOptions = targetFieldList;
  }

  // Filter function for the first select
  filterRelated(val, update) {
    if (val === "") {
      this.options = this.fieldsTypeRelatedField;
    }
    update(() => {
      const ref = val.toLowerCase();
      this.options = this.fieldsTypeRelatedField.filter(
        (v) => v.label.toLowerCase().indexOf(ref) > -1
      );
    });
  }

  // Filter function for the second select
  secondFilterRelated(val, update) {
    if (val === "") {
      this.optionsSecond = this.fieldOptions;
    }
    update(() => {
      const ref = val.toLowerCase();
      this.optionsSecond = this.fieldOptions.filter(
        (v) => v.label.toLowerCase().indexOf(ref) > -1
      );
    });
  }

  // Filter function for the third select
  thirdFilterRelated(val, update) {
    if (val === "") {
      this.optionsThird = this.targetFieldOptions;
    }
    update(() => {
      const ref = val.toLowerCase();
      this.optionsThird = this.targetFieldOptions.filter(
        (v) => v.label.toLowerCase().indexOf(ref) > -1
      );
    });
  }
}
