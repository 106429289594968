
import { Component, Vue, Prop } from "vue-property-decorator";
import draggable from "vuedraggable";
import ConditionTable from "../ConditionTable.vue";
import { Timestamp } from '@firebase/firestore';
import { State } from "vuex-class";
import ListValues from "@/components/Mixin/ListValues";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";
import sortList from "@/common/helpers/utilities";

class User {
  ID = "";
  name = "";
}

class CurrentTemplateClass {
  ID = null;
  name = "";
  type = "";
  module = "";
  fileType = "";
  fileName = "";
  fields = Array<{}>();
  roboticProcess = Array<{}>();
  skipHeaders = false;
  createdAt: any = Timestamp.now();
  changedAt: any = Timestamp.now();
  createdBy: User = new User();
  changedBy: User = new User();
}

class SelectClass {
  dateRange = false;
  numberRange = false;
  field = false;
}

class NewFilterClass {
  selectedField = {
    label: "",
    value: "",
    type: "",
  };
  typeOfValueField = "";
  dataTypeOfSelectedField = "";
  enterValue = "";
  from = "";
  to = "";
  showValueField = false;
  dateFilterStartCheckBox = false;
  dateFilterEndCheckBox = false;
}

@Component({
  components: { draggable, ConditionTable, DynamicFieldSingle },
})
export default class RoboticProcessDetailsDrawer extends Vue {
  @Prop({ required: true }) readonly DetailsDrawerHeaders!: any;
  @Prop({ required: true }) readonly DetailType!: string;
  @Prop({ required: true }) readonly DetailTypeConditions!: string;
  @Prop({ required: true }) module!: string;
  @Prop({ required: true }) roPro;
  @State("dynamicModules") dynamicModules;

  currentTemplate = new CurrentTemplateClass();
  selectClass = new SelectClass();
  detailsTab = "detailsCondition";
  filterOptions = [
    { label: "Field Filter", value: "field", dBType: "operator" },
  ];
  selectedFilter = { label: "Field Filter", value: "field", dBType: "operator" };
  selectedOperator = { value: "", label: "" };
  newFilter = new NewFilterClass();
  fieldOptions = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
  }>();
  selectFieldOptions: any = []
  // Map for the comparisonOperators
  comparisonOperatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThanOrEqualTo'), value: ">=" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThanOrEqualTo'), value: "<=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThan'), value: ">" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThan'), value: "<" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEmpty'), value: "isNotEmpty" },
    { label: this.$t('roboticProcessBuilder.fields.isEmpty'), value: "isEmpty" }
  ];
  comparisonBooleanOperatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEmpty'), value: "isNotEmpty" },
    { label: this.$t('roboticProcessBuilder.fields.isEmpty'), value: "isEmpty" }
  ];
  // Map for the object types
  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };
  criteriaPatterns = {};
  criteriaOptions = [ 
    { label: this.$t("roboticProcessBuilder.headers.And"), value: "and" }, 
    { label: this.$t("roboticProcessBuilder.headers.Or"), value: "or"}, 
   ];
  operatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThanOrEqualTo'), value: ">=" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThanOrEqualTo'), value: "<=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThan'), value: ">" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThan'), value: "<" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEmpty'), value: "isNotEmpty" },
    { label: this.$t('roboticProcessBuilder.fields.isEmpty'), value: "isEmpty" }
  ];
  // Function to load in all conditions
  async mounted() {
    const fields = this.dynamicModules[this.module].fields;
    Object.keys(fields).forEach((field) => {
      if (fields[field].label) {
        this.fieldOptions.push({
          label: fields[field].label,
          value: fields[field].name,
          type: fields[field].type,
          fieldType: fields[field].fieldType,
        });
      }
    });
    for(const criteriaPattern in this.roPro.criteriaPattern) {
      if(this.roPro.criteriaPattern[criteriaPattern].value == "and") {
        this.roPro.criteriaPattern[criteriaPattern].label = this.$t("roboticProcessBuilder.headers.And")
      } else if(this.roPro.criteriaPattern[criteriaPattern].value == "or") {
        this.roPro.criteriaPattern[criteriaPattern].label = this.$t("roboticProcessBuilder.headers.Or")
      } 
    }
  }

  getCheckboxValue(filter) {
    if(filter == true) {
      return this.$t('roboticProcessBuilder.messages.true')
    } else {
      return this.$t('roboticProcessBuilder.messages.false')
    }
  }

  getOperatorLabel(conditionOperator) {    
    for(const operatorOption in this.operatorOptions) {
      if(this.operatorOptions[operatorOption].value == conditionOperator) {
        return this.operatorOptions[operatorOption].label
      }
    }
  }

  // Function to filter
  filterSelectField(val, update) {
    if (val === "") {
      this.selectFieldOptions = this.fieldOptions;
    }
    update(() => {
      const Reference = val.toLowerCase();
      this.selectFieldOptions = this.fieldOptions.filter(
        (v) => v.label.toLowerCase().indexOf(Reference) > -1
      );
    });
    sortList(this.selectFieldOptions, "label")
  }


// Filter the field select
  onFilterFieldSelect(selectedField) {
    this.initFilter();
    this.newFilter.selectedField = Object.assign({}, selectedField);
    if (this.newFilter.selectedField.type === "relatedField") {
      this.newFilter.selectedField.value =
        this.newFilter.selectedField.value + ".ID";
    }
    this.newFilter.typeOfValueField = this.getFieldType(
      this.newFilter.selectedField
    );
    this.newFilter.dataTypeOfSelectedField = this.objectOfType[
      this.newFilter.typeOfValueField
    ];
    this.newFilter.showValueField = true;
  }

// Function to get the type of a field
  getFieldType(field) {
    let result = field.type;
    if (field.type === "autoNumber") result = "text";
    if (field.type === "calculatedField")
      result = ListValues.getRealFieldType(field);
    if (!result) console.log("field.type is unknown");
    return result;
  }
  // Function to show the operatorOptions
  getOperatorOptions(fieldType) {
    return fieldType === "boolean" ||
      fieldType === "text" ||
      fieldType === "map"
      ? this.comparisonBooleanOperatorOptions
      : this.comparisonOperatorOptions;
  }

  onSelectFilter() {
    this.selectClass = new SelectClass();
    this.selectClass[this.selectedFilter.value] = true;
    this.initFilter();
    this.scrollToEndOfPage();
  }
  initFilter() {
    this.selectedOperator = { value: "", label: "" };
    this.newFilter = new NewFilterClass();
  }
  // Function to scroll to the end of the page
  scrollToEndOfPage() {
    const el = document.getElementById("end-of-page");
    if (el) el.scrollIntoView({ block: "end", behavior: "smooth" });
  }
// Function to show the fieldfilter button
  showNewFieldFilterAddButton(operator) {    
    if(operator.value == 'isEmpty' || operator.value == 'isNotEmpty'){
      return true
    }
    if (!this.newFilter.showValueField || !this.selectedOperator.value) {
      return false;
    }
    let result = true;
    console.log(this.newFilter.enterValue, "new filter value");
    console.log(typeof this.newFilter.enterValue == "string");
    
    if ( this.newFilter.dataTypeOfSelectedField === "boolean" && typeof this.newFilter.enterValue == "string") {
      result = false;
    } else if (
      this.newFilter.dataTypeOfSelectedField === "timestamp" &&
      !this.newFilter.enterValue
    ) {
      result = false;
    } else if (
      this.newFilter.dataTypeOfSelectedField === "map" &&
      this.newFilter.enterValue == ""
    ) {
      result = false;
    } else if (
      this.newFilter.dataTypeOfSelectedField === "text" &&
      this.newFilter.enterValue == ""
    ) {
      result = false;
    }
    return result;
  }
// Function to filter with max of 10
  addFilterToReportFilters() {
    if (this.currentTemplate.roboticProcess.length >= 10) {
      alert("maxFiltersCount = 10");
      return;
    }
    let filterObj = {};
    let index = 0;
    for (const condition in this.roPro["conditions"]) {
      
      index++;
    }
    if (this.selectedFilter.dBType === "operator") {
        const field2 = this.newFilter.selectedField.value.split(".")[0]                
        for(const f in this.dynamicModules[this.module].fields) {
          if(this.dynamicModules[this.module].fields[f].name == field2){
            this.newFilter.selectedField.value = field2          
          }
        }  
        
      if(this.newFilter.typeOfValueField != "relatedField"){
        filterObj = {
          type: this.selectedFilter.dBType,
          field: this.newFilter.selectedField.value,
          operator: this.selectedOperator.value,
          value: this.newFilter.enterValue,
          rule: index,
        };
      } else {
        filterObj = {
          type: this.selectedFilter.dBType,
          field: this.newFilter.selectedField.value,
          operator: this.selectedOperator.value,
          value: this.newFilter.enterValue["ID"],
          name: this.newFilter.enterValue["name"],
          rule: index,
        };
      }
    } else if (this.selectedFilter.dBType === "range") {
      filterObj = {
        type: this.selectedFilter.dBType,
        field: this.newFilter.selectedField.value,
        from: this.newFilter.from,
        to: this.newFilter.to,
      };
    }
    index++;
    
    this.currentTemplate.roboticProcess.push(filterObj);
    this.roPro["conditions"].push(filterObj);
    this.initFilter();
    this.scrollToEndOfPage();
  }

  // Function to convert timestamp to normal date
  converDate(isostr) {
    if (!isostr) {
      return '';
    }
    const date = new Date(isostr.seconds*1000);
    return date.toLocaleString('nl-Nl')
  }
}
