import { render, staticRenderFns } from "./PdfTemplates.vue?vue&type=template&id=5f88e59d&"
import script from "./PdfTemplates.vue?vue&type=script&lang=ts&"
export * from "./PdfTemplates.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QIcon,QSeparator,QSpace,QSelect,QTable,QTr,QTd,QTooltip,QPopupEdit,QInput,QDialog,QCard,QCardSection,QCardActions,QBar,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QSeparator,QSpace,QSelect,QTable,QTr,QTd,QTooltip,QPopupEdit,QInput,QDialog,QCard,QCardSection,QCardActions,QBar})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
