
  import {uuid} from 'vue-uuid';
  export default {
    name: "BaseModalForms",
    props: {
      name: null, formData: null, modalFormSave: null, resetModalForm: null, showForm: null, relatedModule: null,
      modalFormID: null, lineForm: null, sectionLabel: null, lineSection: null, modalFormObjData: null
    },
    components: {
      DynamicForm: () => import('@/components/Forms/DynamicForm'),
      DynamicFormProductLine: () => import('@/components/Forms/DynamicFormProductLine')
    },

    data() {
      return {
        dynamicShowModalForm: false,
        currentRefreshKey: ''
      };
    },
    methods: {
      saveDataToFormBuffer(dataToSave) {
        this.$emit('saveDataToFormBuffer', dataToSave);
      },
      saveTheForm(needCallbackAlert = true) {
        this.$emit('saveTheForm', needCallbackAlert);
      },
      refreshTheList() {
        this.$emit("refreshTheList");
      },
      setAutoNumberName(data) {
        this.$emit("setAutoNumberName", data);
      }
    },
    watch: {
      showForm(val) {
        this.dynamicShowModalForm = val;
        this.currentRefreshKey = uuid.v1();
      }
    }
  };
