import { render, staticRenderFns } from "./DocGenerator.vue?vue&type=template&id=1d7bd068&"
import script from "./DocGenerator.vue?vue&type=script&lang=ts&"
export * from "./DocGenerator.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QIcon,QSeparator,QSpace,QSelect,QTable,QTr,QTd,QDialog,QCard,QCardSection,QInput,QUploader,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QSeparator,QSpace,QSelect,QTable,QTr,QTd,QDialog,QCard,QCardSection,QInput,QUploader,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
