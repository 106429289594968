

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";
  import {mapState} from "vuex";

  export default {
    name: "BaseFieldDecimal",
    components: {},
    mixins: [ BaseExtend ],
    data() {
      return {
        cModel:  this.value === null ? null : Math.round(this.value * 100),
        refreshKey: 1
      };
    },
    methods: {
      currentFieldBeingEdited(field) {
        this.formData['editingFields'].push(field)
      },
      cleanModel() {
        if (this.isReadonly) {
          return false;
        }
        this.cModel = null;
      },
      refreshAfterCopy(value) {
        this.cModel = value === null ? null : Math.round(value * 100),
        this.refreshKey++;
      }
    },
    watch: {
      cModel(newValue) {
        let value = '';
        if (!newValue && newValue !== 0) {
          value = null;
          // eslint-disable-next-line
          // @ts-ignore: Unreachable code error
          if (this.$refs[this.name]) {
            this.$refs[this.name].$el.getElementsByTagName('input')[0].value = null;
          }
        } else {
          value = String(newValue).replace(/[^0-9-]/g, '') || '0';
          value = Number(value) / 100;
        }
        this.$emit('input', value);
      }
    },
    computed:{
      ...mapState(["decimalFormat"]),
      getKey() {
        return (this.name ?? 'element') + this.refreshKey;
      },
      getDecimalFormat() {
        return this.value === null ? '' : this.decimalFormat;
      }
    },
  };
