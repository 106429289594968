
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import functions from '../../../functions';
import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
import { UnlayerSettings } from "@/components/Settings/Sections/Templates/EmailTemplates/Models/UnlayerTools";
import TemplateConstructor from "@/components/Settings/Sections/Templates/EmailTemplates/Components/TemplateConstructor.vue";
import { CreatePdfAction } from "@/components/Settings/Actions/actions";


class CurrentTemplateClass {
  id = null;
  ID = null;
  module = "";
  type = "";
  name = "";
  design = null;
  userID = "";
  subject = "";
  to = "";
  cc = "";
  bcc = "";
}

class SelectedRecordClass {
  ID = "";
  name = "";
}

@Component({
  components: { TemplateConstructor, BaseFieldRelated },
})

export default class AddCreatePDF extends Vue {
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  @Action("settings/getPDFTemplatesData") getPDFTemplatesData;
  @Prop(Boolean) isUserEdit;
  @Prop() public props!: any;
  @Prop() existingAction;
  @Prop() module;
  @Prop({required: true}) openExistingAction;
  createPdf = new CreatePdfAction();
  columns = [
    {
      name: "name",
      label: this.$t("email.headers.name"),
      field: "name",
      align: "left",
      sortable: true,
    },
    {
      name: "module",
      label: this.$t("email.headers.module"),
      field: "module",
      align: "left",
      sortable: true,
    },
    {
      name: "filename",
      label: this.$t("pdf.headers.filename"),
      field: "filename",
      align: "left",
      sortable: true,
    },
    {
      name: "selectable",
      label: "",
      field: "selectable",
      align: "left",
      sortable: true,
    },
    {
      name: "modified",
      label: this.$t("email.headers.modifiedAt"),
      field: "modified",
      align: "center",
      sortable: true,
    },
  ];
  pagination = {
    rowsPerPage: 20,
  };

  selectEmailTemplate = true;
  showCreateDialog = false;
  showDeleteDialog = false;
  showCopyDialog = false;
  newTemplateName = "";
  showModalEditor = false;
  showSelectRecordDialog = false;
  currentTemplate = new CurrentTemplateClass();
  inputJson = null;
  selectedModule = "";
  selectedRecord = new SelectedRecordClass();
  formData = {};
  record = {};
  loading = false;
  mergeTags = {};
  designTags = {};
  designTagsDB = {};
  settings = new UnlayerSettings();
  templates: any = [];
  allTemplates = [];
  templates2 = [];
  userTemplates = [];
  tenantTemplates = [];
  globalTemplates = [];
  currentDesign: any = null;
  recordSelectKey = 1;
  showModules: Array<any> = [];
  options = this.showModules;
  templateOptions = this.templates;
  hideSelected = true;

  async mounted() {
    this.getActionTypeFunction()
    this.loading = true;
    this.loading = false;
    if(this.openExistingAction == true){
        await this.createPdf.import(this.existingAction.ID, this.dynamicModules, this.allTemplates)
        await this.getAllTemplates()
        await this.getModuleTemplates()
    }

    let index: any = 0    
    for(index in this.allTemplates) {
        if(this.allTemplates[index]["id"] == this.createPdf.selectedTemplate.id) {
            this.createPdf.selectedTemplate = this.allTemplates[index]
        } 
    }

    let showModules = {};
    for (const modules in this.dynamicModules) {
      if (this.dynamicModules[modules].pluralName != undefined) {
        showModules = {
          label: this.dynamicModules[modules].pluralName,
          name: this.dynamicModules[modules].id,
        };
      } else {
        showModules = {
          label: this.dynamicModules[modules].name,
          name: this.dynamicModules[modules].id,
        };
      }
      this.showModules.push(showModules);
    }

    if(this.existingAction) {
      this.hideSelected = false
    }
    this.createPdf.childModule = { 
      label: this.module.label, 
      name: this.module.value
    }
    
    if(this.createPdf.childModule.name) {
      this.getModuleTemplates()
    }
  }

    async getAllTemplates(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.tenantID,
        request: 'getAllTemplates'
      };      
      await generatePDF(options).then((res: any) => {
        const data = res.data;        
        this.getPDFTemplatesData().then((res) => {
          this.allTemplates = data.map((t: any) => {              
            if (t && res) {
              t.filename = res[String(t.id)] ? res[String(t.id)].filename : '';              
              return t;
            }
          });
        });
    
      }).catch(error => {
        console.log('error: ', error);
        this.templates = [];
      });
    this.filterSelectedTemplate()
    }

    // gets templates based on the module that is selected
    async getModuleTemplates(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.tenantID,
        request: 'getModuleTemplates',
        module: this.createPdf.childModule.name,
      };
      
      await generatePDF(options).then((res: any) => {
        const data = res.data;        
        this.getPDFTemplatesData().then((res) => {
          this.templates = data.map((t: any) => {              
            if (t && res) {                
              t.filename = res[String(t.id)] ? res[String(t.id)].filename : '';
              return t;
            }
          });
        });  
          
      }).catch(error => {
          console.log('error: ', error);
        this.templates = [];
      });
    }
    
    filterSelectedTemplate() {     
        //TODO what's the use of this function?   
        for(const template in this.allTemplates){
            
        }
    }

  // function to get the tenant id
  get curTenantID() {
    return this.tenantID;
  }
  // function to get the modules
  get modulesList() {
    return Object.keys(this.dynamicModules);
  }

  // function to convert the date
  converDate(isostr) {
    if (!isostr) {
      return "";
    }
    const date = new Date(isostr);
    return date.toLocaleString("nl-Nl");
  }

  selectTemplateName(props) {
    this.createPdf.selectedTemplate = {
      id: props.row.id,
      name: props.row.name,
      modified: props.row.modified,
      owner: props.row.owner,
      tags: props.row.tags,
      filename: props.row.filename
    };
  }

  convertName(type) {
    if (type === "global") {
      return "YellowQ";
    } else {
      return type;
    }
  }

  // Function to get the title of the module
  getModuleTitle(module) {
    const moduleTitle =
      this.dynamicModules && this.dynamicModules[module]
        ? this.dynamicModules[module]
        : {};
    return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : module;
  }

  // function to get the pagination label
  getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
    return `${firstRowIndex}-${endRowIndex} ${this.$t(
      "table.misc.of"
    )} ${totalRowsNumber}`;
  }

  // filter function on select
  filterModuleList(val, update) {
    if (val === "") {
      this.options = this.showModules;      
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.showModules.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }
  // filter function for available templates
  filterAvailableTemplates(val, update) {      
    if (val === "") {
      this.templateOptions = this.templates;      
    }
    update(() => {
      const needle = val.toLowerCase();
      this.templateOptions = this.templates.filter(
        (v) => v.name.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  // emit function to save action in actionHeader.vue
  @Emit("getActionType")
  getActionTypeFunction() {    
      const map = {
        value: this.createPdf,
        type: "GeneratePDF"
      }
      return map   
  }
}
