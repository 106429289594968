import {FieldType} from "../../Models/FieldType";
import * as numeral from 'numeral';
import { DecimalField } from '../../Models/FieldTypes/DecimalField';
import { DecimalFieldValue } from "../../Models/FieldValueTypes/DecimalFieldValue";
import Column from "../../Models/Column";
import store from "@/store";


export default class DecimalFieldImpl implements DecimalField {

    name: string;
    ID: string;
    column: number;
    order: number;
    mandatory: boolean | null | undefined;
    section: string;
    disabled: boolean | null | undefined;
    readOnly: boolean | null | undefined;
    hidden: boolean | null | undefined;
    type: FieldType;
    label: string;
    defaultValue: DecimalFieldValue
    min: number | null;
    max: number | null;
    constructor(
        field: DecimalField
    ) {
        this.name = field.name
        this.ID = field.ID
        this.column = field.column
        this.order = field.order
        this.mandatory = field.mandatory
        this.section = field.section
        this.disabled = field.disabled
        this.readOnly = field.readOnly
        this.hidden = field.hidden
        this.type = field.type
        this.label = field.label
        this.defaultValue = field.defaultValue
        this.min = field.min
        this.max = field.max
    }

    canAggregate(): true {
        return true;
    }

    getColumn(column: any, moduleID: string, typeIsRelatedRecords?: boolean): any {
        const baseColumn = new Column().getBaseColumn()
        const additionalProperties = {
            field: column.name,
            headerName: this.getLabel(moduleID),
            editable: !this.disabled && !this.readOnly && !this.hidden ? true : false,
            cellEditor: 'decimalFieldEditor',
            cellRenderer: 'baseFieldRenderer',
            filter: 'agNumberColumnFilter',
            filterParams: {
                numberParser: (text: string) => {
                    if(typeIsRelatedRecords) {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    } else {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                }
            },
            aggFunc: 'sum',
        }
        return {
            ...baseColumn,
            ...additionalProperties
        }
    }

    getLabel(moduleID: string) {
        if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
            return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
          }
        return this.label
    }

    getFormattedValue(value: any, moduleID?: string) {
        if(typeof value === 'string') {
            return value
        }
        return numeral(value).format('0.00');
    }

    getUnformattedValue(value: any) {
        return numeral(value)._value;
    }

    getFieldType(): FieldType {
        return FieldType.DECIMAL
    }

}
