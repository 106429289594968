import moment from "moment";
import SelectModel from "../Models/SelectModel";
import { date } from "quasar";
import { DayPilot } from "daypilot-pro-vue";
import ListValues from '@/components/Mixin/ListValues'

const PlanBoardModel = {
  tasks: (items, params = {}) => {
    //console.time('tasks');
    const filters = {
      urgency: (params.filters.tasks.filters.urgency || [])
        .filter((e) => e.checked)
        .map((e) => e.name),
      status: (params.filters.tasks.filters.status || [])
        .filter((e) => e.checked)
        .map((e) => e.name),
      workOrderType: (params.filters.tasks.filters.type || [])
        .filter((e) => e.checked)
        .map((e) => e.name),
    };

    const plannedItems = items.filter(t => {
      if ( !t.plannedStartDateTime || !t.plannedEndDateTime ||
        (t.plannedStartDateTime && typeof t.plannedStartDateTime.toDate !== "function") ||
        (t.plannedEndDateTime && typeof t.plannedEndDateTime.toDate !== "function") ||
        params.resourcesModule === 'undefined' || !t[params.resourcesModule] || !t[params.resourcesModule].length
      ) {
        return false
      } else {
        return true
      }
    })

    // multi users appointment - 3 users -> 3 appointments, ID = ID_||_user.ID
    const appMulti = [];
    const appOne = plannedItems.filter((t) => {
      if (t[params.resourcesModule].length > 1) {
        t[params.resourcesModule].map((m) => {
          const newM = { ...t };
          newM.id = t.id + '_||_' + (m && m.ID ? m.ID : m);
          newM.resource = m;
          newM.join = t.ID
          appMulti.push(newM);
        });
        return false;
      } else {
        const rv = t[params.resourcesModule][0];
        t.resource =  (rv && rv.ID ? rv.ID : rv);
        return true;
      }
    });

    const result = appOne.concat(appMulti)
      .map((t) => {
        const appAndWo = PlanBoardModel.getAppAndWo(t)
        const appAndWoTransformed = ListValues.transform([appAndWo || {}], params.dynFields)[0];
        /*if (!t.wo) {
          wo._transformed = false;
        }*/
        t.text = PlanBoardModel.tasksText(appAndWoTransformed, params.fields);
        t.textBalloon = PlanBoardModel.tasksTextBalloon(appAndWoTransformed, params.fields);
        t.start = new DayPilot.Date(t.plannedStartDateTime.toDate(), true);
        t.end = new DayPilot.Date(t.plannedEndDateTime.toDate(), true);
        const color = PlanBoardModel.statusColors(t);
        t.color = color;
        t.barBackColor = color;
        t.lightColor = PlanBoardModel.statusLightColors(t);
        return t;
      })
      .filter((t) => {
        if (!t) return false;
        const urgency =
          filters.urgency.length > 0
            ? filters.urgency.includes(t.wo.urgency)
            : true;
        const status =
          filters.status.length > 0 ? filters.status.includes(t.status) : true;
        const type =
          filters.workOrderType.length > 0
            ? filters.workOrderType.includes(t.wo.workOrderType)
            : true;
        return urgency && status && type;
      });

    //console.timeEnd('tasks');
    return result;
  },

  mapTasks: (items, params = {}) => {
    const filters = {
      urgency: (params.filters.mapTasks.filters.urgency || [])
        .filter((e) => e.checked)
        .map((e) => e.name),
      status: (params.filters.mapTasks.filters.status || [])
        .filter((e) => e.checked)
        .map((e) => e.name),
    };
    const result = items.filter((t) => {
      let status =
        filters.status.length > 0 ? filters.status.includes(t.status) : true;
      if (
        t.status === "Uitgevoerd" &&
        t.changedAt &&
        new Date() - t.changedAt.seconds * 1000 > 60 * 60 * 24 * 30 * 1000
      )
        status = false;

      const urgency =
        filters.urgency.length > 0
          ? filters.urgency.includes(t.wo.urgency)
          : true;
      
      return urgency && status; //&& final
    });
    return result;
  },

  tasksText: (t, fields) => {
    if (!t) return "";
    const fieldsArr = [];
    for (const i in fields) {
      fieldsArr.push(fields[i]);
    }

    const str1 = fieldsArr
      .filter((f) => {
        return f.row == 1;
      })
      .sort((a, b) => {
        return a.place - b.place;
      })
      .map((f) => {
        const tfield =  (t[f.field] && Object.prototype.hasOwnProperty.call(t[f.field], 'name')) ? t[f.field].name : t[f.field];
        return tfield;
      });

    const str2 = fieldsArr
      .filter((f) => {
        return f.row == 2;
      })
      .sort((a, b) => {
        return a.place - b.place;
      })
      .map((f) => {
        const tfield =  (t[f.field] && Object.prototype.hasOwnProperty.call(t[f.field], 'name')) ? t[f.field].name : t[f.field];
        return tfield;
      });

    let result = "";
    const exportToImg = false;
    if (exportToImg) {
      result = `${str1.join(" | ")} \n ${str2.join(" | ")}`;
    } else {
      //result = str1.join(" | ") + "<br> &nbsp;" + str2.join(" | ");
      result = `${str1.join(" | ")} \n ${str2.join(" | ")}`;
    }
    result = result || t.work_order_number;

    return result;
  },

  tasksTextBalloon: (t, fields) => {
    if (!t) return "";
    const fieldsArr = [];
    for (const i in fields) {
      fieldsArr.push(fields[i]);
    }
    const balloon = fieldsArr
      .filter((f) => {
        return f.balloonRow;
      })
      .sort((a, b) => {
        return a.balloonRow - b.balloonRow;
      })
      .map((f) => {
        let value = '';
        if (t[f.field] && Object.prototype.hasOwnProperty.call(t[f.field], 'name')) {
          value = t[f.field].name === null ? '-' : t[f.field].name;
        } else {
          value = t[f.field] === null ? '-' : t[f.field];
        }
        if (value === undefined) value = '-';
        const tfield =  f.name + ': ' + '<strong>' + String(value).substr(0, 500) + '</strong>';
        return tfield;
      });

    let result = "";
    balloon.forEach(e => {
      result = result
        + '<div style="padding: 2px 15px; font-size: 14px">'
        + e
        + '</div>'
    })
    result = '<div style="' + '' +
      'margin-top: 15px;' +
      'min-width: 200px;' +
      'max-width: 700px;' +
      //'max-height: 800px;' +
      //'overflow-y: scroll;' +
      '">' + result + '</div>' || t.name;

    return result;
  },

  resourses: (technics, params = { tasks: [] }, userViewID) => {
    //console.time('resourses');
    const groups = new Set(technics.map((t) => userViewID ? t.planBoardUserGroup || '' : t.planBoardGroup));
    const orderGroups = [...groups];
    orderGroups.push(orderGroups.splice(orderGroups.indexOf('-'), 1)[0]);

    const result = [];
    orderGroups.forEach((g) => {
      result.push({
        id: g,
        name: g,
        children: technics
          .filter((t) => userViewID ? t.planBoardUserGroup === g : t.planBoardGroup === g)
          .map((technician) => {
            let hours = 0;
            let count = 0;
            const resName = technician.name ?? technician.userName;
            const result = {
              id: technician.id,
              name: resName,
              html: '<a href="javascript:void(0)" style="text-decoration: none; color: white" class="show-modal-form-default_' + technician.id + '">' + resName + '</a>',
              user: technician,
            };
            
            result.tasks = params.tasks.filter(
              (t) => t && t.resource == technician.id
            );
            result.tasks.map((t) => {
              count++;
              hours += moment
                .duration(
                  moment(t.end.toString()).diff(moment(t.start.toString()))
                )
                .hours();
            });
            if (count > 0) {
              hours = hours || 1;
              result.columns = [{ html: `<div> ${count} / ${hours}</div>` }];
            } else {
              result.columns = [{ html: `<div></div>` }];
            }
            result.count = count;
            return result;
          }),
        expanded: true,
      });
    });
    //console.timeEnd('resourses');
    return result;
  },

  techniciansList: (technicsGroups) => {
    const res = [];
    const result = [];
    technicsGroups.forEach((g) => {
      res.push(g.children);
    });
    res.forEach((r) => {
      result.push(...r);
    });

    result.forEach((r) => {
      r.tasksAll = r.tasks.filter((t) => !t.sent || t.changed);
      r.tasksChanged = r.tasks.filter((t) => t.sent && t.changed);
    });

    return result;
  },

  orders: (items, params) => {
    return items
      .map((i) => {
        const aID = i.ID;
        const oID = i.wo ? i.wo.ID : "";
        const woName = i.wo ? i.wo.name : "";
        const r = { ...i.wo, ...i, ...PlanBoardModel.getAppAndWo(i) };
        r.ID = aID;
        r.appID = aID;
        r.orderID = oID;
        r.orderName = woName;
        r._transformed = false;
        
        return r;
      });
  },

  filters: (items, params = {}) => {
    const filter = PlanBoardModel.getEmptyFilter(params);
    const filters = {
      orders: Object.assign({}, filter, items.orders),
      tasks: Object.assign({}, filter, items.tasks),
      mapTasks: Object.assign({}, filter, items.mapTasks),
    };
    return filters;
  },

  getEmptyFilter: (params) => {
    return {
      filters: {
        status: (params.filtersDropDownValuesAppointments.status || []).map(
          (e) => {
            e.checked = false;
            return e;
          }
        ),
        urgency: (params.filtersDropDownValuesWorkOrders.urgency || []).map(
          (e) => {
            e.checked = false;
            return e;
          }
        ),
        group: [],
        type: (params.filtersDropDownValuesWorkOrders.workOrderType || []).map(
          (e) => {
            e.checked = false;
            return e;
          }
        ),
        finalDate: 100,
      },
      period: { start: "", end: "" },
    };
  },

  userViewSettings: (settingsRaw, dynFieldsAll) => {
    const loadedSettings = {};
    const initSettings = [];
    const appAndWoFields = PlanBoardModel.getAppAndWoFields(dynFieldsAll)
    for (const column in appAndWoFields) {
      if (appAndWoFields[column]){
        initSettings.push({
          field : column,
          name: appAndWoFields[column].label || appAndWoFields[column].name,
          order: appAndWoFields[column].order || '9999',
          place: '',
          row: '',
          balloonRow: ''
        });
      }
    }

    if (settingsRaw) {
      for (const s in settingsRaw) {
        if (settingsRaw[s] && settingsRaw[s].field) {
          loadedSettings[settingsRaw[s].field] = settingsRaw[s];
        }
      }
    }

    const result = initSettings.map(i => {
      if (i.field && loadedSettings[i.field]) {
        i.place = loadedSettings[i.field].place;
        i.row = loadedSettings[i.field].row;
        i.balloonRow = loadedSettings[i.field].balloonRow || '';
      }
      return i;
    });
    
    return result;
  },

  statusColors: (wo) => {
    const status = wo.status;
    let color = "#6c757d";
    if (SelectModel.appointmentStatusOverWritten) {
      color = SelectModel.appointmentStatusColors[status] || color;
    } else {
      if (status === SelectModel.appointmentStatus.toBePlanned)
        color = "#6c757d90";
      if (status === SelectModel.appointmentStatus.toBeConfirmed)
        color = "#CDCDCD90";
      if (status === SelectModel.appointmentStatus.planned) color = "#c27bc990";
      if (status === SelectModel.appointmentStatus.traveling) color = "#2ebfb190";
      if (status === SelectModel.appointmentStatus.delayed) color = "#FF000090"
      if (status === SelectModel.appointmentStatus.inExecution)
        color = "#0000FF90";
      if (status === SelectModel.appointmentStatus.done) color = "#00800090";
      if (status === SelectModel.appointmentStatus.notExecuted) color = "#d22129";
      if (status === SelectModel.appointmentStatus.checked) color = "#eb8f0e90";
      if (status === SelectModel.appointmentStatus.partiallyInvoiced) color = "#8c714c90";
      if (status === SelectModel.appointmentStatus.invoiced) color = "#FFFFFF";
      if (status === SelectModel.appointmentStatus.plannedWithoutAppointment) color = "#F8B13390";
      // addition status
      if (wo.warning) color = "#FF8C0090"; // some time for end
    }
    return color;
  },

  statusLightColors: (wo) => {
    const status = wo.status;
    let color = "#F6EAFFB0";
    if (SelectModel.appointmentStatusOverWritten) {
      color = SelectModel.appointmentStatusLightColors[status] || color;
    } else {
      if (status === SelectModel.appointmentStatus.toBePlanned) color = "#A1AAB2B0";
      if (status === SelectModel.appointmentStatus.toBeConfirmed) color = "#FFFFFFD0";
      if (status === SelectModel.appointmentStatus.planned) color = "#F6EAFFB0";
      if (status === SelectModel.appointmentStatus.traveling) color = "#DBFFFFB0";
      if (status === SelectModel.appointmentStatus.delayed) color = "#FFD2D7B0"
      if (status === SelectModel.appointmentStatus.inExecution) color = "#C6E0FFB0";
      if (status === SelectModel.appointmentStatus.done) color = "#E0FFE0B0";
      if (status === SelectModel.appointmentStatus.notExecuted) color = "#FFD2D7D0";
      if (status === SelectModel.appointmentStatus.checked) color = "#FBD8ABB0";
      if (status === SelectModel.appointmentStatus.partiallyInvoiced) color = "#C3AC9B90";
      if (status === SelectModel.appointmentStatus.invoiced) color = "#FFFFFF";
      if (status === SelectModel.appointmentStatus.plannedWithoutAppointment) color = "#f5bb5390";
      // addition status
      if (wo.warning) color = "#FFDEC8B0"; // some time for end
    }
    return color;
  },

  holidays: (holidays) => {
    const dates =
      holidays && holidays.dates && typeof holidays.dates !== "undefined"
        ? holidays.dates
        : [];
    return dates.map((h) => {
      if (h && typeof h.toDate === "function") {
        const obj = {};
        const holidayDate = date.formatDate(h.toDate(), "YYYY-MM-DD HH:mm");
        obj.start = holidayDate;
        obj.end = holidayDate;
        obj.backColor = "#fff2cc";
        obj.headerColor = "#fff2cc";
        return obj;
      }
    });
  },
  getAppAndWo(appointment){
    const appAndWo = {};
    if (appointment.wo) {
      for (const fName in appointment.wo) {
        appAndWo[fName + '.workOrders'] = appointment.wo[fName]
      }
    }
    for (const fName in appointment) {
      if (fName !== 'wo') {
        appAndWo[fName + '.appointments'] = appointment[fName]
      }
    }
    return appAndWo
  },
  getAppAndWoFields(allFields) {
    if (allFields) {
      const appAndWoFields = JSON.parse(JSON.stringify(allFields))
      for (const fName in allFields) {
        if (!fName.includes('.workOrders') && !fName.includes('.appointments')) {
          delete appAndWoFields[fName]
        }
      }
      return appAndWoFields
    }
  }
};

export default PlanBoardModel;
