
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import store from "@/store";
import sortList from "@/common/helpers/utilities"

class JobClass {
  module = "";
  file = "";
  operationType = "";
}

class DownloadClass {
  module = "";
  reportType = "";
}

class ImportClass {
  ID = "";
  operationType = "";
  jobDetails = {};
  status = "";
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  //createdBy: User = new User();
  //changedBy: User = new User();
  error = {};
}

@Component({
  mixins: [],
  components: {}
})
export default class ImportData extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  @Action("api/getAllImports") getAllImports;
  @Action("api/bulkImport") bulkImport;
  @Action("api/downloadImports") downloadImports;
  columns = [
    {
      name: "operationType",
      label: this.$t("importData.headers.operationType"),
      field: "operationType",
      align: "left",
      sortable: true,
    },
    {
      name: "module",
      label: this.$t("importData.headers.module"),
      field: "module",
      align: "left",
      sortable: true,
      style: "max-width: 300px;"
    },
    {
      name: "fileName",
      label: this.$t("importData.headers.fileName"),
      field: "fileName",
      align: "left",
      sortable: true,
      style: "max-width: 300px;"
    },
    {
      name: "status",
      label: this.$t("importData.headers.status"),
      field: "status",
      align: "left",
      sortable: true,
    },
    /*{
      name: "delete",
      label: "",
      field: "delete",
      align: "center",
      sortable: true,
    },*/
    {
      name: "updatedAt",
      label: this.$t("importData.headers.updatedAt"),
      field: "updatedAt",
      align: "center",
      sortable: true,
    },
    {
      name: "createdAt",
      label: this.$t("importData.headers.createdAt"),
      field: "createdAt",
      align: "center",
      sortable: true,
    },
  ];
  showCreateDialog = false;
  showDownloadDialog = false;
  showDeleteDialog = false;
  currentImport = new ImportClass();
  currentJob = new JobClass();
  currentDownload = new DownloadClass();
  listData = Array<{}>();
  loading = false;
  pagination = { rowsPerPage: 20};
  operationTypeOptions = [
    {name: 'New', value: 'insert'},
    {name: 'Update', value: 'update'}
  ];

  async onImportCreate() {
    const formData = new FormData();
    formData.append('operation', this.currentJob.operationType);
    formData.append('moduleRecordFile', this.currentJob.file);
    formData.append('csvDelimiter', '~');
    const params = {
      data: formData,
      module: this.currentJob.module
    };
    const result = await this.bulkImport(params);
    if (result) {
      this.$q.notify({ message: 'Import is created', color: 'positive'})
    } else {
      this.$q.notify({ message: 'Error', color: 'negative'})
    }

    this.currentJob = new JobClass();
    await this.getAllList();
  }

  addFileToJob(file){
    this.currentJob.file = file[0];
  }

  removeFileFromJob(){
    this.currentJob.file = '';
  }

  onFileRejected () {
    this.$q.notify({type: 'negative', message: 'File did not pass validation constraints'})
  }

  async getAllList() {
    this.listData = await this.getAllImports();
    
  }

  onClickDeleteBtn(props) {
    this.showDeleteDialog = true;
    this.currentImport = props.row;
  }

  async onImportDelete() {
    //await this.deleteRecord(this.currentImport);
    //await this.getAllList();
    store.state.alertMessage = "delete";
    this.currentImport = new ImportClass();
  }

  canEditImport() {
    return true;
  }

  onCancelCreateDialog() {
    this.showCreateDialog = false;
    this.currentJob = new JobClass();
  }

  onCancelDownloadDialog() {
    this.showDownloadDialog = false;
    this.currentDownload = new DownloadClass();
  }

  onCancelDeleteDialog() {
    this.showCreateDialog = false;
    this.currentJob = new JobClass();
  }

  get modulesList() {
    const modulesList = Array<{ label: string; value: string }>();
    Object.keys(this.dynamicModules).forEach((module) => {
      modulesList.push({
        label: this.getModuleTitle(module),
        value: module,
      });
    });
    return sortList(modulesList, "label")
  }

  getModuleTitle(moduleID) {
    const translationKey = `modules.${moduleID}.pluralName`
    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
      return store.state.fieldTranslations[translationKey]
    }
    const moduleTitle = this.dynamicModules && this.dynamicModules[moduleID] ? this.dynamicModules[moduleID] : {} ;
    return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : moduleID
  }

  async mounted() {
    this.loading = true;
    await this.getAllList();
    this.loading = false;
  }

  getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
    return `${firstRowIndex}-${endRowIndex} ${this.$t(
      "table.misc.of"
    )} ${totalRowsNumber}`;
  }

  async onImportDownload() {
    const params = {
      module: this.currentDownload.module,
      reportType: this.currentDownload.reportType
    };
    const result = await this.downloadImports(params);
    if (result) {
      let blobData;
      switch (this.currentDownload.reportType) {
        case "JSON": blobData = new Blob([JSON.stringify(result)], {type: "application/json",});
        break;
        case "CSV": blobData = new Blob([result], { type: "application/csv" });
        break;
        case "EXCEL": blobData = new Blob([result]);
        break;
      }
      this.downloadFile(blobData, this.getFileName(this.currentDownload));
      this.$q.notify({ message: 'Download', color: 'positive'})
    } else {
      this.$q.notify({ message: 'Error', color: 'negative'})
    }

    this.currentDownload = new DownloadClass();
  }

  downloadFile(blobData, fileName) {
    const fileURL = window.URL.createObjectURL(blobData);
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  }

  getFileName(template) {
    let ext = "txt";
    if (template.reportType === "JSON") {
      ext = "json";
    }
    if (template.reportType === "CSV") {
      ext = "csv";
    }
    if (template.reportType === "EXCEL") {
      ext = "xlsx";
    }
    return "downloadImport-" + template.module + "." + ext;
  }
}
