import store from "@/store"



export default class TranslationConverter {

  convertToTableData(rowData: Record<string, any>, configKey: string, languages: Record<string, any>, moduleID: string) {
    const tableData = []
    for(const f in rowData) {
      const field = rowData[f]
      
      if(!field.hidden && configKey !== "dropDowns") {
        const map = {name: field.name}
        for(const language in languages) {
          map[language] = languages[language][`${moduleID}.${configKey}.${field.name}`]
        }
        tableData.push(map)
      }
    }
    return tableData
  }

  convertDropDownDataToTableData(rowData: Record<string, any>, configKey: string, languages: Record<string, any>, moduleID: string) {
    const tableData = []
    for(const f in rowData) {
      const field = rowData[f]
      if(field.type === "dropdown" && !field.hidden) {
        for(const index in store.state.dynamicModules[moduleID].dropDownValues[field.name]) {
          const dropDownOption = store.state.dynamicModules[moduleID].dropDownValues[field.name][index]
          const map = {name: `${field.name}.${dropDownOption}`}
          for(const language in languages) {
            map[language] = languages[language][`${moduleID}.${configKey}.${field.name}.${dropDownOption}`]
          }
          tableData.push(map)
        }
      }
    }
    return tableData
  }

  convertToDatabaseData(translations: any, configKey: string, language: string, moduleID: string) {
    const databaseData = {}
    for(const translation of translations) {
      databaseData[`${moduleID}.${configKey}.${translation.name}`] = translation[language]
    }
    return databaseData
  }
}