import Ajv from "ajv";
import configSendEmailAction from "./Validator/JSON-Schemas/configSendEmailAction.json";
import configSendSMSAction from "./Validator/JSON-Schemas/configSendSMSAction.json";
import configRelatedFieldCopyAction from "./Validator/JSON-Schemas/configRelatedFieldCopyAction.json";
import configFieldUpdateAction from "./Validator/JSON-Schemas/configFieldUpdateAction.json";
import configCreateRecordAction from "./Validator/JSON-Schemas/configCreateRecordAction.json";
import configCopyProductLinesAction from "./Validator/JSON-Schemas/configCopyProductLinesAction.json";
import configCreatePDFAction from "./Validator/JSON-Schemas/configCreatePDFAction.json";
import GetRelatedFieldValue from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/GetRelatedFieldValue"
import store from "@/store";
import db from "@/db";

export class FieldUpdateAction {
  ID = "";
  active = true;
  module = {label: "", name: ""};
  childModule = {label: "", name: ""};
  description = "";
  fieldMerges = {};
  fieldUpdates = {};
  parameters = false;
  parentModule = {label: "", name: ""};
  referenceField = {label: "", name: ""};
  title = "";
  type = {label: "", name: "", title: ""};

  async import(ID, modules) {
    let actions = []
    const firePath =
    "tenants/" +
    store.state.tenantID +
    "/actions/FieldUpdates" +
    "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    actions = docs.map((d) => {
      return { 
        ID: d.ID, 
        title: d.title, 
        description: d.description, 
        type: d.type, 
        childModule: d.childModule, 
        parentModule: d.parentModule, 
        module: d.module, 
        linkToParent: d.linkToParent, 
        referenceField: d.referenceField, 
        fieldUpdates: d.fieldUpdates,
        fieldMerges: d.fieldMerges,
        active: d.active
      };
    });

    for(const action in actions) {
      if(ID == actions[action]["ID"]) {        
      for(const module in modules) {
        this.ID = actions[action]["ID"]
        this.active = actions[action]["active"]
        this.description = actions[action]["description"]
        this.title = actions[action]["title"]
        this.referenceField.name = actions[action]["referenceField"]        
        for (const module in modules) {
          if(modules[module] == this.childModule.name) {
            modules[this.childModule.name].fields
          }
        }        

        if(actions[action]["type"] == "ChildToParent") {
          this.type.label = "Child To Parent"
          this.type.name = "childToParent"
          this.type.title = "ChildToParent"
        } else if (actions[action]["type"] == "ParentToChildren") {
          this.type.label = "Parent To Child"
          this.type.name = "parentToChild"
          this.type.title = "ParentToChild"
        } else if (actions[action]["type"] == "InRecord") {
          this.type.label = "In Record"    
          this.type.name = "inRecord"
          this.type.title = "InRecord"
        }        

        if(this.type.label == "Child To Parent") {
          if(actions[action]["childModule"] == modules[module].id) {
            this.module["label"] = modules[module].pluralName
            this.module["name"] = modules[module].id
          }
          if(actions[action]["childModule"] == modules[module].id) {
            this.childModule["label"] = modules[module].pluralName
            this.childModule["name"] = modules[module].id
          }
          if(actions[action]["parentModule"] == modules[module].id) {
            this.parentModule["label"] = modules[module].pluralName
            this.parentModule["name"] = modules[module].id
          }
        } else if (this.type.label == "Parent To Child") {
          if(actions[action]["parentModule"] == modules[module].id) {
            this.module["label"] = modules[module].pluralName
            this.module["name"] = modules[module].id
          }
          if(actions[action]["childModule"] == modules[module].id) {
            this.childModule["label"] = modules[module].pluralName
            this.childModule["name"] = modules[module].id
          }
          if(actions[action]["parentModule"] == modules[module].id) {
            this.parentModule["label"] = modules[module].pluralName
            this.parentModule["name"] = modules[module].id
          }
        } else if (this.type.label == "In Record") {
          if(actions[action]["module"] == modules[module].id) {
            this.module["label"] = modules[module].pluralName
            this.module["name"] = modules[module].id
            this.parentModule["label"] = modules[module].pluralName
            this.parentModule["name"] = modules[module].id
            this.childModule["label"] = modules[module].pluralName
            this.childModule["name"] = modules[module].id
          }
        }
      }
        if(this.type.name == "childToParent") {
          for(const field in modules[this.childModule.name].fields) {
            if(modules[this.childModule.name].fields[field].name == this.referenceField.name) {
              this.referenceField.label = modules[this.childModule.name].fields[field].label
            }
          }
        } else if (this.type.name == "parentToChild") {
          for(const field in modules[this.parentModule.name].fields) {
            if(modules[this.parentModule.name].fields[field].name == this.referenceField.name) {
              this.referenceField.label = modules[this.parentModule.name].fields[field].label
            }
          }
        }

        if(this.type.name == "childToParent" || this.type.name == "inRecord") {
          const fieldUpdateList: any = [];
          for(const fieldUpdate in actions[action]["fieldUpdates"]){                                
            if(modules[this.parentModule.name] != undefined){              
              if(modules[this.parentModule.name].fields[fieldUpdate] != undefined){
                const map = {
                  field: "",
                  value: "",
                  type: ""
                }
                const value = actions[action]["fieldUpdates"][fieldUpdate]
                if (modules[this.parentModule.name].fields[fieldUpdate].type != "relatedField") {
                  map.field = fieldUpdate
                  map.value = value
                  map.type = modules[this.parentModule.name].fields[fieldUpdate].type
                } else {
                  map.field = fieldUpdate
                  map.value = value
                  map["name"] = await GetRelatedFieldValue(fieldUpdate, value, modules, this.parentModule.name)
                  map.type = modules[this.parentModule.name].fields[fieldUpdate].type
                }
                fieldUpdateList.push(map)
              }
            }
          }
          const fieldMergeList: any = [];
          let index = 0
          for(const fieldMerge in actions[action]["fieldMerges"]){
            if(modules[this.childModule.name].fields[fieldMerge] != undefined){
              const map = {
                field: "",
                value: {
                  label: "",
                  value: "",
                  type: "",
                  index: index
                },
                type: ""
              }
              const value = actions[action]["fieldMerges"][fieldMerge]
              
              map.field = fieldMerge
              if(modules[this.parentModule.name].fields[value] != undefined) {
                map.value.label = modules[this.parentModule.name].fields[value].label
                map.value.value = modules[this.parentModule.name].fields[value].name
                map.value.type = modules[this.parentModule.name].fields[value].type
              }
              map.type = modules[this.childModule.name].fields[fieldMerge].type
              fieldMergeList.push(map)
              index ++
            }
          }          
          this.fieldUpdates = fieldUpdateList
          this.fieldMerges = fieldMergeList
        } else if (this.type.name == "parentToChild") {
          const fieldUpdateList: any = [];   
          for(const fieldUpdate in actions[action]["fieldUpdates"]){
            if(modules[this.childModule.name] != undefined){              
              if(modules[this.childModule.name].fields[fieldUpdate] != undefined){
              const map = {
                  field: "",
                  value: "",
                  type: ""
                }
                const value = actions[action]["fieldUpdates"][fieldUpdate]                
                if (modules[this.childModule.name].fields[fieldUpdate].type != "relatedField") {
                  map.field = fieldUpdate
                  map.value = value
                  map.type = modules[this.childModule.name].fields[fieldUpdate].type
                } else {
                  map.field = fieldUpdate
                  map.value = value
                  map["name"] = await GetRelatedFieldValue(fieldUpdate, value, modules, this.childModule.name)
                  map.type = modules[this.childModule.name].fields[fieldUpdate].type
                }
                fieldUpdateList.push(map)
              }
            }
          }          
          this.fieldUpdates = fieldUpdateList

          
          const fieldMergeList: any = [];
          let index = 0
          for(const f in actions[action]["fieldMerges"]) {
            const v = modules[this.childModule.name].fields[actions[action]["fieldMerges"][f]]
            if(modules[this.parentModule.name].fields[f] != undefined){
              const map = {
                field: f,
                value: {
                  label: v.label,
                  value: v.name,
                  type: v.type,
                  index: index
                },
                type: modules[this.parentModule.name].fields[f].type
              }
              fieldMergeList.push(map)
              index ++
            }
          }
          
          
          this.fieldMerges = fieldMergeList  
        }
      }
    }
  }
  validateJsonData() {
    const fieldUpdate = {};
    for (const update in this.fieldUpdates) {
      const field = this["fieldUpdates"][update]["field"];
      const value = this["fieldUpdates"][update]["value"];
      fieldUpdate[field] = value;
    }

    const fieldMerge = {};
    for (const update in this.fieldMerges) {
      const field = this["fieldMerges"][update]["field"];
      const value = this["fieldMerges"][update]["value"]["value"];
      fieldMerge[field] = value;
    }

    if (this.type["title"] == "ParentToChild") {
      this.type["title"] = "ParentToChildren";
    }
        const data = JSON.parse(
          JSON.stringify({
            ID: this.ID,
            active: this.active,
            module: this.module.name,
            childModule: this.childModule.name,
            description: this.description,
            fieldMerges: fieldMerge,
            fieldUpdates: fieldUpdate,
            parentModule: this.parentModule.name,
            referenceField: this.referenceField["name"],
            title: this.title,
            type: this.type["title"],
          })
        );
    const ajv = new Ajv({ allErrors: true });
    // require("ajv-errors")(ajv /*, {singleError: true} */)
    const configValidator = ajv.compile(configFieldUpdateAction);

    const configValid = configValidator(data);
    if (!configValid) {
      for (const error in configValidator.errors) {
        console.error(configValidator.errors[error].message);
      }
      return false
    } else {
      return true
    }
  }

  export() {
    const fieldUpdate = {};
    for (const update in this.fieldUpdates) {
      const field = this["fieldUpdates"][update]["field"];
      const value = this["fieldUpdates"][update]["value"];
      fieldUpdate[field] = value;
    }

    const fieldMerge = {};
    for (const update in this.fieldMerges) {
      const field = this["fieldMerges"][update]["field"];
      const value = this["fieldMerges"][update]["value"]["value"];
      fieldMerge[field] = value;
    }

    if (this.type.title == "ParentToChild") {
      this.type.title = "ParentToChildren";
    }
        const data = JSON.parse(
          JSON.stringify({
            ID: this.ID,
            active: this.active,
            module: this.module.name,
            childModule: this.childModule.name,
            description: this.description,
            fieldMerges: fieldMerge,
            fieldUpdates: fieldUpdate,
            parentModule: this.parentModule.name,
            referenceField: this.referenceField["name"],
            title: this.title,
            type: this.type["title"],
          })
        );
        return data
  }
}

export class CopyProductLinesAction {
  ID = "";
  active = true;
  module = {label: "", name: ""};
  childModule = {label: "", name: ""};
  description = "";
  criteriaPattern = [];
  fieldMerges = {};
  fieldUpdates = {};
  fieldMergesParent = {};
  fieldUpdatesParent = {};
  parameters = false;
  parentModule = {label: "", name: ""};
  title = "";
  query: Array<any> = [];

  async import(ID, modules) {
    let actions = []
    const firePath =
    "tenants/" +
    store.state.tenantID +
    "/actions/CopyProductLines" +
    "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    actions = docs.map((d) => {
      return { 
        ID: d.ID, 
        title: d.title, 
        description: d.description, 
        type: d.type, 
        childModule: d.from, 
        parentModule: d.to, 
        module: d.module, 
        linkToParent: d.linkToParent, 
        referenceField: d.referenceField, 
        fieldUpdates: d.fieldUpdates,
        fieldMerges: d.fieldMerges,
        fieldUpdatesParent: d.fieldUpdatesParent,
        fieldMergesParent: d.fieldMergesParent,
        active: d.active,
        query: d.query,
        criteriaPattern: d.criteriaPattern
      };
    });

    for(const action in actions) {      
      if(ID == actions[action]["ID"]) {
        this.ID = actions[action]["ID"]
        this.active = actions[action]["active"]
        this.description = actions[action]["description"]
        this.title = actions[action]["title"]
        for (const module in modules) {
          if(actions[action]["childModule"] == modules[module].id) {
            this.childModule.name = modules[module].id
            this.childModule.label = modules[module].pluralName
            this.module.name = modules[module].id
            this.module.label = modules[module].pluralName
          }
          if(actions[action]["parentModule"] == modules[module].id) {
            this.parentModule.name = modules[module].id
            this.parentModule.label = modules[module].pluralName
          }
        }
        const fieldUpdateList: any = [];
        for(const fieldUpdate in actions[action]["fieldUpdates"]){
          if(modules[this.childModule.name] != undefined){
            if(modules[this.childModule.name].fields[fieldUpdate] != undefined){
              const map = {
                field: "",
                value: "",
                type: ""
              }
              const value = actions[action]["fieldUpdates"][fieldUpdate]
              if (modules[this.childModule.name].fields[fieldUpdate].type != "relatedField") {
                map.field = fieldUpdate
                map.value = value
                map.type = modules[this.childModule.name].fields[fieldUpdate].type
              } else {
                map.field = fieldUpdate
                map.value = value
                map["name"] = await GetRelatedFieldValue(fieldUpdate, value, modules, this.childModule.name)
                map.type = modules[this.childModule.name].fields[fieldUpdate].type
              }
              fieldUpdateList.push(map)
            }
          }
        }
        const fieldMergeList: any = [];
        let index = 0
        for(const fieldMerge in actions[action]["fieldMerges"]){
          if(modules[this.childModule.name].fields[fieldMerge] != undefined){
            const map = {
              field: "",
              value: {
                label: "",
                value: "",
                type: "",
                index: index
              },
              type: ""
            }
            const value = actions[action]["fieldMerges"][fieldMerge]
            
            map.field = fieldMerge
            if(modules[this.parentModule.name].fields[fieldMerge] != undefined) {
              map.value.label = modules[this.parentModule.name].fields[fieldMerge].label
              map.value.value = modules[this.parentModule.name].fields[fieldMerge].name
              map.value.type = modules[this.parentModule.name].fields[fieldMerge].type
            }
            map.type = modules[this.childModule.name].fields[fieldMerge].type
            fieldMergeList.push(map)
            index ++
          }
        }

        const fieldUpdateParentList: any = [];
        for(const fieldUpdate in actions[action]["fieldUpdatesParent"]){
          if(modules[this.parentModule.name] != undefined){
            if(modules[this.parentModule.name].fields[fieldUpdate] != undefined){
              const map = {
                field: "",
                value: "",
                type: ""
              }
              const value = actions[action]["fieldUpdatesParent"][fieldUpdate]
              if (modules[this.parentModule.name].fields[fieldUpdate].type != "relatedField") {
                map.field = fieldUpdate
                map.value = value
                map.type = modules[this.parentModule.name].fields[fieldUpdate].type
              } else {
                map.field = fieldUpdate
                map.value = value
                map["name"] = await GetRelatedFieldValue(fieldUpdate, value, modules, this.parentModule.name)
                map.type = modules[this.parentModule.name].fields[fieldUpdate].type
              }            
              fieldUpdateParentList.push(map)
            }
          }
        }

        const fieldMergeParentList: any = [];
        let ind = 0
        for(const fieldMerge in actions[action]["fieldMergesParent"]){  
          if(modules[this.parentModule.name].fields[fieldMerge] != undefined){
            const map = {
              field: "",
              value: {
                label: "",
                value: "",
                type: "text",
                index: ind
              },
              type: "text"
            }
            const value = actions[action]["fieldMergesParent"][fieldMerge]            
            map.field = fieldMerge
            
            if(modules[this.childModule.name].fields[value] != undefined) {
              map.value.label = modules[this.childModule.name].fields[value].label
              map.value.value = modules[this.childModule.name].fields[value].name
              map.value.type = modules[this.childModule.name].fields[value].type
            }
            map.type = modules[this.parentModule.name].fields[fieldMerge].type            
            fieldMergeParentList.push(map)
            
            ind ++
          }
        }

        for ( const q in actions[action]["query"]) {
          actions[action]["query"][q].field = actions[action]["query"][q].field.split(".")[0]
          const query = actions[action]["query"][q]
          if(modules[this.module.name].fields[query.field].type == "relatedField") {
            query.name = await GetRelatedFieldValue(query["field"], query["value"], modules, this.module.name)
          }
          if(query.filterType == "dynamicField") {
            query.value = query.value.replaceAll(" ", "").replace("{%", "").replace("%}", "")
          }
        }
        console.log(actions[action]["query"], "queries");
        

        this.query = actions[action]["query"]
        this.criteriaPattern = actions[action]["criteriaPattern"]
        
        const copyProductLines = {
          "fieldUpdates": fieldUpdateList,
          "fieldMerges": fieldMergeList,
          "fieldUpdatesParent": fieldUpdateParentList,
          "fieldMergesParent": fieldMergeParentList,
        }
        
        this.fieldUpdates = fieldUpdateList
        this.fieldMerges = fieldMergeList  
        this.fieldUpdatesParent = fieldUpdateParentList
        this.fieldMergesParent = fieldMergeParentList
        return copyProductLines
      }
    }
  }

  validateJsonData() {
    const criteriaPatterns = {};
    let index = 0;
    for (const criteriaPattern in this.criteriaPattern) {
      criteriaPatterns[index.toString()] =
        this.criteriaPattern[criteriaPattern];
      index += 1;
    }

    const fieldUpdate = {};
    for (const update in this.fieldUpdates) {
      const field = this["fieldUpdates"][update]["field"];
      const value = this["fieldUpdates"][update]["value"];
      fieldUpdate[field] = value;
    }

    const fieldMerge = {};
    for (const update in this.fieldMerges) {
      const field = this["fieldMerges"][update]["field"];
      const value = this["fieldMerges"][update]["value"]["value"];
      fieldMerge[field] = value;
    }

    const fieldUpdateParent = {};
    for (const update in this.fieldUpdatesParent) {
      const field = this["fieldUpdatesParent"][update]["field"];
      const value = this["fieldUpdatesParent"][update]["value"];
      fieldUpdateParent[field] = value;
    }

    const fieldMergeParent = {};
    for (const update in this.fieldMergesParent) {
      const field = this["fieldMergesParent"][update]["field"];
      const value = this["fieldMergesParent"][update]["value"]["value"];
      fieldMergeParent[field] = value;
    }
    const data = JSON.parse(
      JSON.stringify({
        ID: this.ID,
        active: this.active,
        module: this.module.name,
        childModule: this.childModule.name,
        description: this.description,
        fieldUpdates: fieldUpdate,
        fieldMerges: fieldMerge,
        fieldUpdatesParent: fieldUpdateParent,
        fieldMergesParent: fieldMergeParent,
        parentModule: this.parentModule.name,
        title: this.title,
        query: this.query,
        criteriaPattern: criteriaPatterns,
      })
    );
    const ajv = new Ajv({ allErrors: true });
    // require("ajv-errors")(ajv /*, {singleError: true} */)
    const configValidator = ajv.compile(configCopyProductLinesAction);

    const configValid = configValidator(data);
    if (!configValid) {
      for (const error in configValidator.errors) {
        console.error(configValidator.errors[error].message);
      }
      return false
    } else {
      return true
    }
  }

  export(modules) {
    const criteriaPatterns = {};
    let index = 0;
    for (const criteriaPattern in this.criteriaPattern) {
      criteriaPatterns[index.toString()] =
        this.criteriaPattern[criteriaPattern];
      index += 1;
    }

    const fieldUpdate = {};
    for (const update in this.fieldUpdates) {
      const field = this["fieldUpdates"][update]["field"];
      const value = this["fieldUpdates"][update]["value"];
      fieldUpdate[field] = value;
    }

    const fieldMerge = {};
    for (const update in this.fieldMerges) {
      const field = this["fieldMerges"][update]["field"];
      const value = this["fieldMerges"][update]["value"]["value"];
      fieldMerge[field] = value;
    }

    const fieldUpdateParent = {};
    for (const update in this.fieldUpdatesParent) {
      const field = this["fieldUpdatesParent"][update]["field"];
      const value = this["fieldUpdatesParent"][update]["value"];
      fieldUpdateParent[field] = value;
    }

    const fieldMergeParent = {};
    for (const update in this.fieldMergesParent) {
      const field = this["fieldMergesParent"][update]["field"];
      const value = this["fieldMergesParent"][update]["value"]["value"];
      fieldMergeParent[field] = value;
    }

    for(const q in this.query) {
      const query = this.query[q];
      const field = modules[this.module.name].fields[this.query[q].field]
      if(field.type == "relatedField" && field.name.includes(".ID") == false) {
        query.field = field.name + ".ID"
      }
      if (query.filterType == "dynamicField") {
          query.value = "{%" + this.query[q].value + "%}"
      }
      
      delete query['name']
    }

    const data = JSON.parse(
      JSON.stringify({
        ID: this.ID,
        active: this.active,
        module: this.module.name,
        from: this.childModule.name,
        description: this.description,
        fieldUpdates: fieldUpdate,
        fieldMerges: fieldMerge,
        fieldUpdatesParent: fieldUpdateParent,
        fieldMergesParent: fieldMergeParent,
        to: this.parentModule.name,
        title: this.title,
        query: this.query,
        criteriaPattern: criteriaPatterns,
      })
    );
    return data;
  }
}

export class Query {
  rule = 0;
  value = "";
  operator = "";
  field = "";
}

export class CreateRecordAction {
  ID = "";
  active = true;
  module = {label: "", name: ""};
  childModule = {label: "", name: ""};
  description = "";
  fieldMerges = {};
  fieldUpdates = {};
  parameters = false;
  parentModule = {label: "", name: ""};
  referenceField = {label: "", name: ""};
  title = "";
  linkToParent = false;

  async import(ID, modules) {
    let actions = []
    const firePath =
    "tenants/" +
    store.state.tenantID +
    "/actions/CreateRecords" +
    "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    actions = docs.map((d) => {
      return { 
        ID: d.ID, 
        title: d.title, 
        description: d.description, 
        type: d.type, 
        childModule: d.childModule, 
        parentModule: d.parentModule, 
        module: d.module, 
        linkToParent: d.linkToParent, 
        referenceField: d.linkToParentField, 
        fieldUpdates: d.fieldUpdates,
        fieldMerges: d.fieldMerges,
        active: d.active };
    });
    for(const action in actions) {
      if(ID == actions[action]["ID"]) {
        for(const module in modules) {
          if(actions[action]["module"] == modules[module].id) {
            this.module["label"] = modules[module].pluralName
            this.module["name"] = modules[module].id
          }
          if(actions[action]["childModule"] == modules[module].id) {
            this.childModule["label"] = modules[module].pluralName
            this.childModule["name"] = modules[module].id
          }
          if(actions[action]["parentModule"] == modules[module].id) {
            this.parentModule["label"] = modules[module].pluralName
            this.parentModule["name"] = modules[module].id
          }
        }
        const fieldUpdateList: any = [];
        for(const fieldUpdate in actions[action]["fieldUpdates"]){
          if(modules[this.parentModule.name] != undefined){
            if(modules[this.parentModule.name].fields[fieldUpdate] != undefined){
              const map = {
                field: "",
                value: "",
                type: ""
              }
              const value = actions[action]["fieldUpdates"][fieldUpdate]
              if (modules[this.parentModule.name].fields[fieldUpdate].type != "relatedField") {
                map.field = fieldUpdate
                map.value = value
                map.type = modules[this.parentModule.name].fields[fieldUpdate].type
              } else {
                map.field = fieldUpdate
                map.value = value
                map["name"] = await GetRelatedFieldValue(fieldUpdate, value, modules, this.parentModule.name)
                map.type = modules[this.parentModule.name].fields[fieldUpdate].type
              }
              fieldUpdateList.push(map)
            }
          }
        }
        const fieldMergeList: any = [];
        let index = 0
        for(const fieldMerge in actions[action]["fieldMerges"]){
          if(modules[this.childModule.name].fields[fieldMerge] != undefined){
            const map = {
              field: "",
              value: {
                label: "",
                value: "",
                type: "",
                index: index
              },
              type: ""
            }
            const value = actions[action]["fieldMerges"][fieldMerge]            
            map.field = fieldMerge
            if(modules[this.parentModule.name].fields[value] != undefined) {
              map.value.label = modules[this.parentModule.name].fields[value].label
              map.value.value = modules[this.parentModule.name].fields[value].name
              map.value.type = modules[this.parentModule.name].fields[value].type
            }
            map.type = modules[this.childModule.name].fields[fieldMerge].type
            fieldMergeList.push(map)
            index ++
          }
        }
        this.referenceField.name = actions[action]["referenceField"]
        for(const field in modules[this.childModule.name].fields) {
          if(modules[this.childModule.name].fields[field].name == this.referenceField.name) {
            this.referenceField.label = modules[this.childModule.name].fields[field].label
          }
        }
        this.ID = actions[action]["ID"]
        this.active = actions[action]["active"]
        this.description = actions[action]["description"]
        this.title = actions[action]["title"]
        this.linkToParent = actions[action]["linkToParent"]
        this.fieldUpdates = fieldUpdateList
        this.fieldMerges = fieldMergeList
        const createRecord = {
          "fieldUpdates": fieldUpdateList,
          "fieldMerges": fieldMergeList
        }
        
        return createRecord
      }
    }
  }

  validateJsonData() {
    const fieldUpdate = {};
    for (const update in this.fieldUpdates) {
      const field = this.fieldUpdates[update]["field"];
      const value = this.fieldUpdates[update]["value"];
      fieldUpdate[field] = value;
    }

    const fieldMerge = {};
    for (const update in this.fieldMerges) {
      const field = this.fieldMerges[update]["field"];
      const value = this.fieldMerges[update]["value"]["value"];
      fieldMerge[field] = value;
    }

    let linkToParentField: any = null;
    if (this.referenceField != undefined) {
      linkToParentField = this.referenceField.name;
    } else {
      linkToParentField = null;
    }
    let data = {}
    if (this.linkToParent == false) {
      const validateData = JSON.parse(
        JSON.stringify({
          ID: this.ID,
          active: this.active,
          module: this.module.name,
          childModule: this.childModule.name,
          description: this.description,
          fieldMerges: fieldMerge,
          fieldUpdates: fieldUpdate,
          parentModule: this.parentModule.name,
          title: this.title,
          linkToParent: this.linkToParent,
        })
      );
      data = validateData
    } else if (this.linkToParent == true) {
      const validateData = JSON.parse(
        JSON.stringify({
          ID: this.ID,
          active: this.active,
          module: this.module.name,
          childModule: this.childModule.name,
          description: this.description,
          fieldMerges: fieldMerge,
          fieldUpdates: fieldUpdate,
          parentModule: this.parentModule.name,
          linkToParentField: linkToParentField,
          title: this.title,
          linkToParent: this.linkToParent,
        })
      );
      data = validateData
    }    
    const ajv = new Ajv({ allErrors: true });
    // require("ajv-errors")(ajv /*, {singleError: true} */)
    const configValidator = ajv.compile(configCreateRecordAction);

    const configValid = configValidator(data);
    if (!configValid) {
      for (const error in configValidator.errors) {
        console.error(configValidator.errors[error].message);
      }      
      return false
    } else {
      return true
    }
  }

  export() {    
    const fieldUpdate = {};
    for (const update in this.fieldUpdates) {
      const field = this.fieldUpdates[update]["field"];
      const value = this.fieldUpdates[update]["value"];
      fieldUpdate[field] = value;
    }

    const fieldMerge = {};
    for (const update in this.fieldMerges) {
      const field = this.fieldMerges[update]["field"];
      const value = this.fieldMerges[update]["value"]["value"];
      fieldMerge[field] = value;
    }

    let linkToParentField: any = null;
    if (this.referenceField != undefined) {
      linkToParentField = this.referenceField;
    } else {
      linkToParentField = null;
    }

    if (this.linkToParent == false) {
      const data = JSON.parse(
        JSON.stringify({
          ID: this.ID,
          active: this.active,
          module: this.module.name,
          childModule: this.childModule.name,
          description: this.description,
          fieldMerges: fieldMerge,
          fieldUpdates: fieldUpdate,
          parentModule: this.parentModule.name,
          title: this.title,
          linkToParent: this.linkToParent,
        })
      );
      return data
    } else if (this.linkToParent == true) {
      const data = JSON.parse(
        JSON.stringify({
          ID: this.ID,
          active: this.active,
          module: this.module.name,
          childModule: this.childModule.name,
          description: this.description,
          fieldMerges: fieldMerge,
          fieldUpdates: fieldUpdate,
          parentModule: this.parentModule.name,
          linkToParentField: linkToParentField["name"],
          title: this.title,
          linkToParent: this.linkToParent,
        })
      );      
      return data
    }
  }
}

export class RelatedFieldCopyAction {
  ID = "";
  active = true;
  childModule = {label: "", name: ""};
  description = "";
  relatedFieldCopy = {};
  title = "";

  async import(ID, modules) {    
    let actions = []
    const firePath =
    "tenants/" +
    store.state.tenantID +
    "/actions/RelatedFieldCopies" +
    "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    actions = docs.map((d) => {
      return { ID: d.ID, title: d.title, description: d.description, type: d.type, relatedFieldCopy: d.relatedFieldCopy, module: d.module, active: d.active };
    });
    for(const action in actions) {
      if(ID == actions[action]["ID"]) {
      for(const module in modules) {
        if(actions[action]["module"] == modules[module].id) {
          this.childModule["label"] = modules[module].pluralName
          this.childModule["name"] = modules[module].id
        }
      }
      const list: any = []
      let index = 0
      for(const relatedFieldCopy in actions[action]["relatedFieldCopy"]) {        
        const field = actions[action]["relatedFieldCopy"][relatedFieldCopy][0]
        if(modules[this.childModule.name].fields[field] != undefined) {
          const map = {
            field: "",
            value: {},
            targetField: {},
            type: ""
          }
          const splitField = relatedFieldCopy.split(".")
          map.field = splitField[0]          
          map.value = {label: modules[this.childModule.name].fields[splitField[1]].label, type: modules[this.childModule.name].fields[splitField[1]].type, value: splitField[1], index: index}
          map.targetField = {label: modules[this.childModule.name].fields[field].label, value: field, type: modules[this.childModule.name].fields[field].type, index: index}
          list.push(map)
          index ++          
        }
      }
        this.ID = actions[action]["ID"]
        this.active = actions[action]["active"]
        this.description = actions[action]["description"]
        this.title = actions[action]["title"]
        this.relatedFieldCopy = list       
      }       
    }
  }

  validateJsonData() {
    const relatedFieldCopies = {};
    let index = 0;
    for (const relatedFieldCopy in this.relatedFieldCopy) {   
          
      const relatedFieldCopyValue = [this.relatedFieldCopy[relatedFieldCopy].value.value];
      relatedFieldCopies[this.relatedFieldCopy[relatedFieldCopy].targetField.value] = relatedFieldCopyValue;
      index++;
    }
    const data = JSON.parse(
      JSON.stringify({
        ID: this.ID,
        active: this.active,
        module: this.childModule["name"],
        description: this.description,
        relatedFieldCopy: relatedFieldCopies,
        title: this.title,
      })
    );
    const ajv = new Ajv({ allErrors: true });
    // require("ajv-errors")(ajv /*, {singleError: true} */)
    const configValidator = ajv.compile(configRelatedFieldCopyAction);

    const configValid = configValidator(data);
    if (!configValid) {
      for (const error in configValidator.errors) {
        console.error(configValidator.errors[error].message);
      }
      return false
    } else {
      return true
    }
  }

  export() {    
    const relatedFieldCopies = {};
    let index = 0;
    for (const relatedFieldCopy in this.relatedFieldCopy) {
      if(this.relatedFieldCopy[relatedFieldCopy].targetField.value != undefined) {
        const relatedFieldCopyValue = [this.relatedFieldCopy[relatedFieldCopy].value.value];
        relatedFieldCopies[this.relatedFieldCopy[relatedFieldCopy].field + "." + this.relatedFieldCopy[relatedFieldCopy].targetField.value] = relatedFieldCopyValue;
      } else {
        const relatedFieldCopyValue = [this.relatedFieldCopy[relatedFieldCopy].value.value];
        relatedFieldCopies[this.relatedFieldCopy[relatedFieldCopy].field + "." + this.relatedFieldCopy[relatedFieldCopy].targetField[0].value] = relatedFieldCopyValue;
      }
      index++;
    }
    const data = JSON.parse(
      JSON.stringify({
        ID: this.ID,
        active: this.active,
        module: this.childModule.name,
        description: this.description,
        relatedFieldCopy: relatedFieldCopies,
        title: this.title,
      })
    );
    return data
  }
}

export class SendEmailAction {
  ID = "";
  active = true;
  description = "";
  title = "";
  module = "";
  childModule = {label: "", name: ""};
  selectedTemplate = { ID: "", name: "" };
  generatePDF = false;
  pdfTemplate = {id: "", name: "", modified: "", owner: true, tags: [], filename: ""}
  
  async import(ID, modules, templates) {    
    let actions = []
    const firePath =
    "tenants/" +
    store.state.tenantID +
    "/actions/SendEmails" +
    "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    actions = docs.map((d) => {
      return { ID: d.ID, title: d.title, description: d.description, type: d.type, template: d.template, generatePDF: d.generatePDF, pdfTemplate: d.pdf, module: d.module, active: d.active};
    });
    for(const action in actions) {
      if(ID == actions[action]["ID"]) {
      for(const module in modules) {
        if(actions[action]["module"] == modules[module].id) {
          this.childModule["label"] = modules[module].pluralName
          this.childModule["name"] = modules[module].id
        }
      }
      for(const template in templates) {
        if(actions[action]["template"] == templates[template].ID) {
          this.selectedTemplate["ID"] = templates[template].ID
          this.selectedTemplate["name"] = templates[template].name

        }
      }
        this.pdfTemplate.id = actions[action]["pdfTemplate"]
        this.generatePDF = actions[action]["generatePDF"]
        this.ID = actions[action]["ID"]
        this.active = actions[action]["active"]
        this.description = actions[action]["description"]
        this.title = actions[action]["title"]
      }       
    }
  }

  validateJsonData() {
    const templateID = this.pdfTemplate.id.toString()
    const data = JSON.parse(
      JSON.stringify({
        ID: this.ID,
        active: this.active,
        module: this.childModule["name"],
        description: this.description,
        title: this.title,
        template: this.selectedTemplate.ID,
        pdf: templateID
      })
    );
    
    const ajv = new Ajv({ allErrors: true });
    // require("ajv-errors")(ajv /*, {singleError: true} */)
    const configValidator = ajv.compile(configSendEmailAction);

    const configValid = configValidator(data);
    if (!configValid) {
      for (const error in configValidator.errors) {
        console.error(configValidator.errors[error].message);
      }
      return false
    } else {
      return true
    }
  }

  export() {
    const templateID = this.pdfTemplate.id.toString()
    const data = JSON.parse(
        JSON.stringify({
          ID: this.ID,
          active: this.active,
          module: this.childModule["name"],
          description: this.description,
          title: this.title,
          template: this.selectedTemplate.ID,
          pdf: templateID
        })
      );      
      return data
  }
}


export class SendSmsAction {
  ID = "";
  active = true;
  description = "";
  title = "";
  to = "";
  message = "";
  module = {label: "", name: ""};
  variablePhoneNumber = false;
  
  
  async import(ID, modules) {    
    let actions = []
    const firePath =
    "tenants/" +
    store.state.tenantID +
    "/actions/SendSMSes" +
    "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    actions = docs.map((d) => {
      return { ID: d.ID, title: d.title, description: d.description, module: d.module, type: d.type, message: d.message, to: d.to, active: d.active, variablePhoneNumber: d.variablePhoneNumber };
    });
    for(const action in actions) {
      if(ID == actions[action]["ID"]) {
        for(const module in modules) {
          if(actions[action]["module"] == modules[module].id) {
            this.module["label"] = modules[module].pluralName
            this.module["name"] = modules[module].id
          }
        }
        this.to = actions[action]["to"]
        this.ID = actions[action]["ID"]
        this.active = actions[action]["active"]
        this.description = actions[action]["description"]
        this.title = actions[action]["title"]
        this.message = actions[action]["message"]
        this.variablePhoneNumber = actions[action]["variablePhoneNumber"]
      }       
    }
  }
  
  validateJsonData() {
    const data = JSON.parse(
      JSON.stringify({
        ID: this.ID,
        active: this.active,
        description: this.description,
        title: this.title,
        module: this.module["name"],
        to: this.to,
        message: this.message
      })
      );
      
      const ajv = new Ajv({ allErrors: true });
      // require("ajv-errors")(ajv /*, {singleError: true} */)
      const configValidator = ajv.compile(configSendSMSAction);
      
      const configValid = configValidator(data);
      if (!configValid) {
        for (const error in configValidator.errors) {
          console.error(configValidator.errors[error].message);
        }
        return false
      } else {
        return true
      }
    }
    
    export() {
      const data = JSON.parse(
        JSON.stringify({
          ID: this.ID,
          active: this.active,
          module: this.module["name"],
          description: this.description,
          title: this.title,
          to: this.to,
          message: this.message
        })
        );      
        return data
      }
    }
    
export class CreatePdfAction {
  ID = "";
  active = true;
  description = "";
  title = "";
  module = "";
  childModule = {label: "", name: ""};
  selectedTemplate = {id: "", name: "", modified: "", owner: true, tags: [], filename: ""}
  
  async import(ID, modules, templates) {        
    let actions = []
    const firePath =
    "tenants/" +
    store.state.tenantID +
    "/actions/GeneratePDFs" +
    "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    actions = docs.map((d) => {
      return { ID: d.ID, title: d.title, description: d.description, type: d.type, template: d.template, module: d.module, active: d.active};
    });    
    for(const action in actions) {
      if(ID == actions[action]["ID"]) {
        for(const module in modules) {
          if(actions[action]["module"] == modules[module].id) {
            this.childModule["label"] = modules[module].pluralName
            this.childModule["name"] = modules[module].id
          }
        }
        this.selectedTemplate["id"] = actions[action]["template"]
        this.ID = actions[action]["ID"]
        this.active = actions[action]["active"]
        this.description = actions[action]["description"]
        this.title = actions[action]["title"]
      }       
    }
  }

  validateJsonData() {
    const templateID = this.selectedTemplate.id.toString()
    const data = JSON.parse(
      JSON.stringify({
        ID: this.ID,
        active: this.active,
        module: this.childModule["name"],
        description: this.description,
        title: this.title,
        template: templateID,
      })
    );

    const ajv = new Ajv({ allErrors: true });
    // require("ajv-errors")(ajv /*, {singleError: true} */)
    const configValidator = ajv.compile(configCreatePDFAction);

    const configValid = configValidator(data);
    if (!configValid) {
      for (const error in configValidator.errors) {
        console.error(configValidator.errors[error].message);
      }
      return false
    } else {
      return true
    }
  }

  export() {
    const templateID = this.selectedTemplate.id.toString()
      const data = JSON.parse(
        JSON.stringify({
          ID: this.ID,
          active: this.active,
          module: this.childModule["name"],
          description: this.description,
          title: this.title,
          template: templateID,
        })
      );      
      return data
  }
}