

import BaseExtend from "@/components/Base/Mixin/BaseExtend";
import BaseModalForms from '@/components/Base/Mixin/BaseModalForms';
import AbModel from '@/components/Models/AbModel';
import store from "@/store";
import { EventBus } from "@/event-bus";
import {uuid} from 'vue-uuid';
import ListValues from "@/components/Mixin/ListValues";
import Utils from "@/components/utils/Utils"
import debounce from "lodash/debounce";

export default {
  name: "BaseFieldRelated",
  components: { BaseModalForms },
  mixins: [ BaseExtend ],
  props: {
    fieldUsedInSettings: null
  },
  data() {
    return {
      showForm: false,
      rowRelatedOptions: [],
      relatedOptions: [],
      relatedCopyValue: null,
      unsubscribeData: null,
      needFocusedToNextField: true,
      loadingOptions: false,
      modalFormID: null,
      relatedOptionsLoaded: false,
      userDataLoaded: false,
      debouncedFilter: debounce(this.filter, 250)
    };
  },
  async beforeMount() {
    // TODO remove th EventBus, do it like the validation list
    EventBus.$on('needRelatedCopyForNewForm', this.relatedCopyForNewForm);
    EventBus.$on('needRelatedCopyNextFields', this.relatedCopyNextFields);
  },
  beforeDestroy(){
    this.debouncedFilter.cancel()
    EventBus.$off('needRelatedCopyForNewForm', this.relatedCopyForNewForm);
    EventBus.$off('needRelatedCopyNextFields', this.relatedCopyNextFields);
  },
  destroyed() {
    if (typeof this.unsubscribeData === "function")  {
      this.unsubscribeData();
    }
  },
  methods: {
    cleanModel() {
      if (this.isReadonly) {
        return false;
      }
      this.model = {ID: '', name: ''};
    },

    openModalFormIfDisabled() {
      if (this.isDisabled) {
        this.openModel();
      }
    },

    openModel() {
      const rid = this.model ? this.model.ID : null;
      if (rid) {
        this.showForm = true;
        this.modalFormID = rid;
      }
    },

    async relatedCopyForNewForm(data) {
      if (this.value && this.value.ID && data.module === this.formData.module) {
        const model = new AbModel();
        const val = await model.getByID(this.value.ID, this.relatedModule);
        if (val) {
          this.doRelatedFieldCopy(val);
        } else {
        }
      }
    },

    async relatedCopyNextFields(counter) {
      if (
        this.value && this.value.ID && this.formData.module === counter.module && counter[this.name] === 0
        && counter.subFormMode === this.subFormMode
      ) {
        const model = new AbModel();
        const val = await model.getByID(this.value.ID, this.relatedModule);
        if (val) {
          this.doRelatedFieldCopy(val);
        } else {
        }
        counter[this.name] = 1;
      }
    },

    startRelatedCopyProcess() {
      const counter = {
        module: this.formData.module,
        [this.name]: 1,
        subFormMode: this.subFormMode
        // TODO check the field for related copy for subForm (from subForm related copy, no dynamicModule field)
      }
      if (this.field && this.field.relatedFieldCopy) {
        for(const rfName in this.field.relatedFieldCopy) {
          counter[rfName] = 0;
        }
      }
      EventBus.$emit('needRelatedCopyNextFields', counter);
    },

    async getRelatedFieldData() {
      if (store.state.useElasticRelatedQuery) {
        if (this.relatedModule === 'users') {
          // autoload for users multiselect for show with names, not only IDs
          await this.loadRelatedFieldQueryOptions();
          this.userDataLoaded = true;
        }
      } else {
        if (this.relatedQuery) {
          //await this.loadRelatedFieldQueryOptions();
        } else {
          this.relatedOptions = this.formData.relatedFields[this.relatedModule];
        }
      }
    },

    addClick() {
      if (this.isReadonly) {
        return false;
      }
      this.showForm = true;
      this.modalFormID = null;
      this.formData.copyToNewForm = this.field.copyToNewForm;
    },

    resetModalForm() {
      this.showForm = false;
      setTimeout(() => {
        this.modalFormID = null;
      }, 500)
    },

    async setAutoNumberName(data) {
      this.$emit('input', { ID: data.ID, name: data.name });
      await this.setMainRelatedFieldIfEmpty();
    },

    isNameAutoNumber() {
      return this.formData.dynAutoNumbers ? Object.prototype.hasOwnProperty.call(this.formData.dynAutoNumbers, 'name') : false;
    },

    async modalFormSave(id) {
      await this.loadRelatedFieldQueryOptions();
      //await this.getRelatedFieldData();
      let newVal = (this.options || this.relatedOptions).find((e) => e.ID === id);
      if (!newVal || (this.isNameAutoNumber() && !newVal.name)) {
        newVal = await new AbModel().getByID(id, this.relatedModule);
        this.subscribeModelData(id);
      }
      this.model = newVal;
    },

    async subscribeModelData(id) {
      this.unsubscribeData = await new AbModel().subscribeDoc(id, this.relatedModule, this.onDocumentDataChanged);
    },

    onDocumentDataChanged(data) {
      this.model = data;
    },

    async onFieldChange(val) {
      const oldVal = this.model
      this.relatedCopyValue = val;
      if (val && val.ID) {
        await this.setMainRelatedFieldIfEmpty();
        if(Utils.isEmpty(oldVal) || Utils.isEmpty(oldVal.ID) || oldVal.ID !== val.ID) {
          await this.doRelatedFieldCopy(val);
        }
        this.startRelatedCopyProcess();
        this.resetValidation();
      }
    },

    // Related field copy functions
    async doRelatedFieldCopy(valForCopy) {
      if (this.relatedFieldCopy) {
        let val = valForCopy;
        const model = new AbModel();
        if (val.ID) {
          val = await model.getByID(val.ID, this.relatedModule);
        } else {
          return false;
        }
        for (const relCopy in this.relatedFieldCopy) {
          const fieldType = this.getRelatedFieldType(relCopy, this.relatedModule);
          if (fieldType) {
            if (this.relatedFieldCopy[relCopy].length) {
              for (const relCopyField in this.relatedFieldCopy[relCopy]) {
                const dataField = this.relatedFieldCopy[relCopy][relCopyField];
                if (fieldType === 'subForm') {
                  this.copySubFormValue(dataField, val, relCopy);
                } else {
                  if (val[relCopy] !== undefined) {
                    this.copyRelatedValue(dataField, val, relCopy);
                  }
                }
              }
            }
          }

        }
      }
    },

    async copySubFormValue(dataField, val, relCopy) {
      const targetType = this.getFieldType(dataField);
      if (!val || !val[relCopy]) {
        return null;
      }
      if (targetType === 'subForm') {
        // TODO check target sub form fields
        this.formData.data[dataField] = this.formData.data[dataField].concat(val[relCopy].map(e => {
          e.ID = uuid.v1();
          return e;
        }));
      }
    },

    getFieldType(dataField) {
      const dmodule = this.formData;
      const field = dmodule.dynamicFields[dataField] ?? dmodule.dynamicSections[dataField];
      if (field) {
        return field.type;
      } else {
        return '';
      }
    },

    getRelatedFieldType(relCopy, module) {
      const dmodule = store.state.dynamicModules[module];
      if (!dmodule) {
        return null;
      }
      const field = dmodule.fields[relCopy] ?? dmodule.sections[relCopy];
      return field ? field.type : '';
    },

    async setMainRelatedFieldIfEmpty() {
      if (this.relatedToByQuery) {
        const relatedTo = this.relatedToByQuery.relatedTo;
        const relatedToField = this.relatedToByQuery.field;
        if (this.formData.data[this.name].ID && this.formData.data[relatedTo] && !this.formData.data[relatedTo].ID) {
          const fullField = await new AbModel().getByID(this.formData.data[this.name].ID, this.relatedModule);
          if (fullField && fullField[relatedToField]) {
            const val = fullField[relatedToField];
            this.formData.data[relatedTo] = val;
          }
        }
      }
    },

    copyRelatedValue(toVal, value, fromVal) {
      const fieldType = this.getFieldType(toVal);
      let copyValue;
      if (value[fromVal] || value[fromVal] === 0) {
        copyValue = value[fromVal];
      } else {
        copyValue = null;
      }
      if (fieldType === 'checkbox' && !copyValue) {
        copyValue = false;
      }
      this.formData.data[toVal] = copyValue;
      EventBus.$emit('needToUpdateFieldValueAfterCopy', {name: toVal, value: copyValue, module: this.formData.module});
    },

    getRelatedIDForQuery() {
      if (this.relatedQuery) {
        this.relatedQuery.forEach((f) => {
          if (f.relatedTo) {
            if(f.relatedTo === "$originRecord"){
              f.relatedID = this.formData.data["ID"]
            }else{
              const relatedID = this.formData.data[f.relatedTo] ? this.formData.data[f.relatedTo].ID : null;
              f.relatedID = relatedID;
            }
          }
        });
      }
    },

    async loadRelatedFieldQueryOptions(searchValue) {
      this.relatedOptionsLoaded = true;
      this.loadingOptions = true;
      this.getRelatedIDForQuery();
      if (this.relatedModule) {
        const field = this.formData.dynamicFields ? this.formData.dynamicFields[this.name] : {};
        const additionalField = [];
        if (field && field.primaryResultField) additionalField.push(field.primaryResultField)
        if (field && field.secondaryResultField) additionalField.push(field.secondaryResultField)
        if (!this.fieldUsedInSettings) {
          additionalField.push('locked')
        }

        const query = [{field: 'name', value: searchValue}]
        if(field && field.primaryResultField) {
          query.push({field: field.primaryResultField, value: searchValue})
        }
        if(field && field.secondaryResultField) {
          query.push({field: field.secondaryResultField, value: searchValue})
        }
        const param = {
          query: query,
          relatedQuery: this.relatedQuery,
          module: this.relatedModule,
          additionalField: additionalField
        };
        //await new Promise(resolve => setTimeout(resolve, 3000));
        const res = await this.queryRelatedFieldOptions(param);

        this.relatedOptions = Object.freeze(res.docs);
      }
      this.loadingOptions = false;
    },

    filterAutocomplete(val, update) {
      this.debouncedFilter(val, update)
    },
    async filter(val, update) {
      if(val !== '') {
        await this.loadRelatedFieldQueryOptions(val);
      } else {
        await this.loadRelatedFieldQueryOptions()
      }
      update(() => {
        this.rowRelatedOptions = Object.freeze(this.relatedOptions);
      });
    },
    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.copyCurrentValue = val;
        this.onFieldChange(val);
        const relVal = { ID: val.ID, name: val.name };
        this.$emit('input', relVal)
      }
    },

    showOpenModel() {
      return this.model && this.model.ID;
    },

    fname() {
      return this.formName || this.name;
    },

    rowOptions () {
      if (this.loadingOptions) {
        return null;
      }
      let result = ListValues.fixQSelectData(this.rowRelatedOptions || []);
      if (!result.length) {
        const storedNames = this.formData.data['__' + this.name];
        if (storedNames) {
          result = storedNames;
        }
      }
      const field = this.formData.dynamicFields ? this.formData.dynamicFields[this.name] : {};
      result.forEach(e => {
        if (field) {
          if (Object.prototype.hasOwnProperty.call(field,'primaryResultField')) {
            e.optionsRow1 = ListValues.transformOneByModule(e[field.primaryResultField], this.relatedModule, field.primaryResultField);
          }
          if (Object.prototype.hasOwnProperty.call(field,'secondaryResultField')) {
            e.optionsRow2 = ListValues.transformOneByModule(e[field.secondaryResultField], this.relatedModule, field.secondaryResultField);
          }
        }
      })
      //return result.length ? result : null;
      // TODO need to check open popup and frozen, it start load after go and back
      return result;
    }
  },
  watch: {
    refreshFieldOnChange() {
      if (this.refreshFieldOnChange) {
        this.doRelatedFieldCopy(this.value);
        this.startRelatedCopyProcess();
      }
    },

    'formData.formChangedFieldsByTypes': {
      handler() {
        let reloadNeed = false;
        for(const fieldType in this.formData.formChangedFieldsByTypes) {
          if (ListValues.relatedQueryTypingField(fieldType)) {
            reloadNeed = true;
          }
        }
        if (reloadNeed) {
          this.relatedOptionsLoaded = false;
          //this.loadRelatedFieldQueryOptions();
        }
      },
      deep: true
    }
  }
};
