import db from "@/db";
import store from "./store";
import { Notify } from 'quasar'

let listener: any
let listenerSet = false

export function enableVersionListener(){
    listenerSet = true;
    listener =  db.collection('config').doc('webApp').onSnapshot(function(doc) {
        const data = doc.data()
        
        if(!store.state.webAppVersion) {
            store.commit("SET_WEB_APP_VERSION", data.version)
        }
        else if (data.version.localeCompare(store.state.webAppVersion, undefined, { numeric: true, sensitivity: 'base' }) === 1) {
            store.commit("SET_WEB_APP_VERSION", data.version)
            Notify.setDefaults({
                position: 'top',
                timeout: 120000,
                textColor: 'white'
            })
            Notify.create({
                progress: true,
                message: "Er is een nieuwere versie van yellowQ beschikbaar. De pagina wordt binnen 2 minuten automatisch herladen. Bij het klikken op 'sluiten' zal de pagina na verloop van tijd nog steeds worden ververst.",
                color: 'white',
                textColor: 'primary',
                multiLine: true,
                actions: [
                    { label: 'Sluiten', color: 'primary', handler: () => {} },
                    { label: 'Nu herladen', color: 'primary', handler: () => { location.reload() } }
                ]
            })
            Notify.setDefaults({
                position: 'bottom',
                timeout: 2000,
                textColor: 'white'
            })
        setTimeout(() => {
            location.reload()
        }, 120000)
    }
})
}

export function disableVersionListener() {
    if(listenerSet){
        listener()
        listenerSet = false
    }
}