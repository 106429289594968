import { Timestamp, collection, deleteDoc, doc, getDoc, getDocs, setDoc, writeBatch } from "firebase/firestore";
import db from "@/db"
import DatabaseResponse from "@/components/Records/Models/DatabaseResponse";
import store from "@/store";

export function generateUniqueID(): string {
  const ID = doc(collection(db, "tenants")).id;
  return ID
}

export function addIDToDocData(data: any){
  if(!data.ID){
    data.ID = generateUniqueID();
  }
  return data
}

export function addSystemFieldsToDocData(method: string, data: any) {
  if(method === "create") {
    data.createdBy = store.state.currentUser
    data.createdAt = Timestamp.now();
    data.changedBy = store.state.currentUser
    data.changedAt = Timestamp.now();
  } else if (method === "update") {
    data.changedBy = store.state.currentUser
    data.changedAt = Timestamp.now();
  }
  return data
}

export function addSystemFieldsToDeleteDoc(data: any) {
  data.deletedBy = store.state.currentUser
  data.deletedAt = Timestamp.now();
  return data
}

export async function setFirestoreDocData(path: string, data: any): Promise<DatabaseResponse> {
  const docData = addIDToDocData(data);
  const ref = doc(db, `${path}/${docData.ID}`)
  const dbResponse = await setDoc(ref, docData, {merge: true}).then(() => {
    return {
      error: false,
      updateTimeStamp: data.changedAt,
      docCount: 1,
      data: [data]
    }
  }).catch((err) => {
    console.log(`Error while updating doc: ${path} with error: ${err}`);
    return {
      error: true,
      errorMessage: err,
      updateTimeStamp: data.changedAt,
      docCount: 0,
      data: []
    }
  })
  return dbResponse
}

export async function createFirestoreDocs(path: string, docs: any[]): Promise<DatabaseResponse> {
  const batch = writeBatch(db);
  for (const record of docs) {
    const docData = addIDToDocData(record);
    const pathToRef = path + `/${docData.ID}`
    const createRef = doc(db, pathToRef);
    batch.set(createRef, docData, {merge:true});
  }
  const dbResponse = await batch.commit().then(() => {
    return {
      error: false,
      updateTimeStamp: Timestamp.now(),
      docCount: docs.length,
      data: docs
    }
  }).catch((err) => {
    console.log("Error while deleting table row: " + err);
    return {
      error: true,
      errorMessage: err,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  })
  return dbResponse
}
  
export async function getFirestoreDocData(path: string): Promise<DatabaseResponse> {
  const ref = doc(db, path)
  const dbResponse = await getDoc(ref).then((doc) => {
    if(doc.exists) {
      return {
        error: false,
        updateTimeStamp: Timestamp.now(),
        docCount: 1,
        data: [doc.data()]
      }
    }
    return {
      error: false,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  }).catch((err) => {
    console.log(`Error while getting firestore record from path: ${path} with error: ${err}`);
    return {
      error: false,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  })
  return dbResponse
}

export async function getFirestoreCollectionData(path: string): Promise<DatabaseResponse> {
  const ref = collection(db, path)
  const dbResponse = await getDocs(ref).then((res) => {
    const records = []
    res.forEach((doc) => {
      records.push(doc.data())
    });
    return {
      error: false,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  }).catch((err) => {
    console.log("Error while deleting table row: " + err);
    return {
      error: true,
      errorMessage: err,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  })
  return dbResponse
}

export async function deleteFirestoreDoc(path: string): Promise<DatabaseResponse> {
  const ref = doc(db, path)
  const dbResponse = await deleteDoc(ref).then(() => {
    return {
      error: false,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  }).catch((err) => {
    console.log(`Error while updating doc: ${path} with error: ${err}`);
    return {
      error: true,
      errorMessage: err,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  })
  return dbResponse
}

export async function deleteFirestoreDocs(path: string, docIDs: string[]): Promise<DatabaseResponse> {
  const batch = writeBatch(db);
  for (const docID of docIDs) {
    const deleteRef = doc(db, `${path}/${docID}`);
    batch.delete(deleteRef);
  }

  try {
    await batch.commit()
    return {
      error: false,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  } catch (error) {
    return {
      error: true,
      errorMessage: error,
      updateTimeStamp: Timestamp.now(),
      docCount: 0,
      data: []
    }
  }
}