
import {Component, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import ListValues from "@/components/Mixin/ListValues";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";

@Component({
  components: { DynamicFieldSingle },
})
export default class ConditionTable extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) conditions!: any;
  @Prop({ required: true }) module!: string;
  @Prop({ required: true }) criteriaPatterns!: boolean;
  @Prop() copyProductLines!: boolean;
  @Prop() roPro;
  fieldOptions = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
  }>();
  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };
  selectedModule = {
    value: "",
    label: "",
  };
  moduleOptions = Array<{ label: string; value: string }>();
  showIfOrCondition = false;
  comparisonOperatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThanOrEqualTo'), value: ">=" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThanOrEqualTo'), value: "<=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThan'), value: ">" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThan'), value: "<" },
  ];
  comparisonBooleanOperatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
  ];
  operatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThanOrEqualTo'), value: ">=" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThanOrEqualTo'), value: "<=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThan'), value: ">" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThan'), value: "<" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEmpty'), value: "isNotEmpty" },
    { label: this.$t('roboticProcessBuilder.fields.isEmpty'), value: "isEmpty" }
  ];
  addedFieldList = Array<{
    label: string;
    defaultValue: string;
    sourceKey: string;
    type: string;
    fieldType: string;
    canSaveDefaultValue: boolean;
  }>();

  getFirePath() {
    return "reports/templates/records";
  }

  async mounted() {
    const fields = this.dynamicModules[this.module].fields;
    Object.keys(fields).forEach((field) => {
      if (fields[field].label) {
        this.fieldOptions.push({
          label: fields[field].label,
          value: fields[field].name,
          type: fields[field].type,
          fieldType: fields[field].fieldType,
        });
      }
    });
    console.log(this.conditions);
    console.log(this.module);
    
  }

  getOperatorLabel(conditionOperator) {    
      for(const operatorOption in this.operatorOptions) {
        if(this.operatorOptions[operatorOption].value == conditionOperator) {
          return this.operatorOptions[operatorOption].label
        }
      }
  }

  windowClose() {
    this.$emit("onModalClose");
  }

  // function to get the value of the condition
  getFilterValue(filter, field) {
    return ListValues.transformOneByModule(
      filter[field],
      this.module,
      filter.fieldName
    );
  }

  getCheckboxValue(filter) {
    if(filter == true) {
      return this.$t('roboticProcessBuilder.messages.true')
    } else {
      return this.$t('roboticProcessBuilder.messages.false')
    }
  }

// function to get the field of the condition
  getField(fieldName) {
    let field = { label: "", name: "", type: "", fieldType: "" };
    if (!fieldName) {
      return field;
    }
    const fieldNameNoID = fieldName.replace(".ID", "");

    field =
      this.dynamicModules &&
      this.module &&
      this.dynamicModules[this.module] &&
      this.dynamicModules[this.module].fields &&
      this.dynamicModules[this.module].fields[fieldNameNoID]
        ? Object.assign(
            {},
            this.dynamicModules[this.module].fields[fieldNameNoID]
          )
        : field;
    return field;
  }

  // function to get the type of the selected field.
  getFieldType(field) {
    let result = field.type;
    if (field.type === "autoNumber") result = "text";
    if (field.type === "calculatedField")
      result = ListValues.getRealFieldType(field);
    if (!result) console.log("field.type is unknown");
    return result;
  }

  // function to get the operator options
  getOperatorOptions(fieldType) {
    return fieldType === "boolean" ||
      fieldType === "text" ||
      fieldType === "map"
      ? this.comparisonBooleanOperatorOptions
      : this.comparisonOperatorOptions;
  }

  // function that gets the existingconditions
  _getExistingConditions(type) {
    const conditions = Array<{}>();
    this.conditions.forEach((e, index) => {
      const filterType = (e as any).type === type;
      if (filterType) {
        const filter = e;
        // Set reportFilters index in filtered array. Used for filter delete
        this.$set(filter, "index", index);
        // Set checkbox properties for q-popup-edit dialog in 'range' filters list
        this.$set(
          filter,
          "dateFilterStartCheckBox",
          (filter as any).from === "$lastRunOn"
        );
        this.$set(
          filter,
          "dateFilterEndCheckBox",
          (filter as any).to === "$currentTime"
        );
        this.$set(filter, "popUpEditVModel", false);
        conditions.push(filter);
      }
    });
    return conditions;
  }
  get existingFieldFilters() {
    return this._getExistingConditions("operator");
  }

  get dropDownValues() {
    return (this.dynamicModules
      && this.dynamicModules[this.module]
      && this.dynamicModules[this.module].dropDownValues) ? this.dynamicModules[this.module].dropDownValues : {};
  }

  // function to specify wich delete function to use
  getDeleteFunction(index) {
    if (this.criteriaPatterns == true && this.copyProductLines == false) {
      this.deleteConditionFromRoPro(index);
    } else if (this.criteriaPatterns == true && this.copyProductLines == true) {
      this.deleteQueryFromCopyProductLines(index);
    } else {
      this.deleteFilterFromReportFilters(index);
    }
  }

  // function to delete condition
  deleteConditionFromRoPro(index) {
    if (index == this.roPro.criteriaPattern.length) {
      this.roPro["criteriaPattern"].pop();
    }
    if (index < this.roPro.conditions[this.roPro.conditions.length - 1].rule) {
      this.roPro.conditions[this.roPro.conditions.length - 1].rule -= 1;
    }
    this.conditions.splice(index, 1);
    this.roPro["criteriaPattern"].splice(index -= 1, 1);
  }

  // function to delete query from the copy product lines
  deleteQueryFromCopyProductLines(index) {
    if (index == this.roPro.criteriaPattern.length) {
      this.roPro["criteriaPattern"].pop();
    }
    if (index < this.roPro.query[this.roPro.query.length - 1].rule) {
      this.roPro.query[this.roPro.query.length - 1].rule -= 1;
    }
    this.conditions.splice(index, 1);
    this.roPro["criteriaPattern"].splice(index, 1);
  }

  // function to delete condition
  deleteFilterFromReportFilters(index) {
    this.conditions.splice(index, 1);
  }

  // function to specify the type
  getReportFieldDataType(field) {
    const realType = this.getFieldType(field);
    let result = "unknownType";
    switch (realType) {
      case "text":
        result = "text";
        break;
      case "autoNumber":
        result = "text";
        break;
      case "number":
        result = "number";
        break;
      case "decimal":
        result = "number";
        break;
      case "percentage":
        result = "percentage";
        break;
      case "currency":
        result = "currency";
        break;
      case "date":
        result = "date";
        break;
      case "datetime":
        result = "datetime";
        break;
      case "dropdown":
        result = "text";
        break;
      case "relatedField":
        result = "text";
        break;
      case "relatedMultiSelect":
        result = "text";
        break;
      case "checkbox":
        result = "boolean";
        break;
      case "largeText":
        result = "text";
        break;
    }
    return result;
  }

  // Function to convert timestamp to normal date
  converDate(isostr) {
    if (!isostr) {
      return '';
    }
    const date = new Date(isostr.seconds*1000);
    return date.toLocaleString('nl-Nl')
  }
}
