import store from "../../store";

export default class Permission {

  static check(permission = 0, action = '', msg: any = '') {
    let userPermission = false;
    switch (action) {
      case 'read':
        userPermission = permission > -1;
        break;
      case 'write':
        userPermission = permission > 0;
        break;
      case 'create':
        userPermission = permission > 1;
        break;
      default:
        console.error('Not action - ' + action);
    }
    if (!userPermission && msg) {
      store.state.alertMessage = 'no_permission';
    }
    return userPermission;
  }

  static module(module, action, msg: any = '') {
    const permission = Number(store.state.portalPermissions[module]);
    return this.check(permission, action, msg);
  }

  static section(section, action) {
    if (!Object.prototype.hasOwnProperty.call(section,'permissions')) {
      return true;
    }
    const permission = Number(section.permissions);
    return this.check(permission, action, false);
  }

}
