import store from "@/store";
import Column from "../../Models/Column";
import {FieldType} from "../../Models/FieldType";
import { CalculatedField } from '../../Models/FieldTypes/CalculatedField';
import { CalculatedFieldValue } from "../../Models/FieldValueTypes/CalculatedFieldValue";
import FieldFactory from "../FieldFactory";
import { TableType } from "@/components/Table/Models/TableType";

function getAdditionalFilter(fieldType: FieldType) {
    switch(fieldType) {
        case FieldType.PERCENTAGE:
        case FieldType.NUMBER:
        case FieldType.CURRENCY:
            return 'agNumberColumnFilter'
        default:
            return 'agTextColumnFilter'
    }
}

function getAdditionalFilterParams(fieldType: FieldType, typeIsRelatedRecords?: boolean) {
    switch(fieldType) {
        case FieldType.PERCENTAGE:
        case FieldType.CURRENCY:
            return {
                defaultOption: 'equals',
                numberParser: (text: string) => {
                    if(typeIsRelatedRecords) {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    } else {
                        return text == null ? null : parseFloat(text.replace(',', '.'))*10000;
                    }
                }
            }
        default:
            return null
    }
}

export default class CalculatedFieldImpl implements CalculatedField{

    name: string;
    ID: string;
    column: number;
    order: number;
    mandatory: boolean | null | undefined;
    section: string;
    disabled: boolean | null | undefined;
    readOnly: boolean | null | undefined;
    hidden: boolean | null | undefined;
    type: FieldType;
    label: string;
    defaultValue: CalculatedFieldValue;
    fieldType: FieldType;
    min: number | null;
    max: number | null;
    convertedField;
  constructor(
    field: CalculatedField,
) {
    this.name = field.name
    this.ID = field.ID
    this.column = field.column
    this.order = field.order
    this.mandatory = field.mandatory
    this.section = field.section
    this.disabled = field.disabled
    this.readOnly = field.readOnly
    this.hidden = field.hidden
    this.type = field.type
    this.label = field.label
    this.fieldType = field.fieldType
    this.defaultValue = field.defaultValue
    this.min = field.min
    this.max = field.max
    const fieldFactory = new FieldFactory()
    const baseField = field
    baseField.type = field.fieldType
    this.convertedField = fieldFactory.getFieldBasedOnType(baseField)
  }

    canAggregate(type: TableType): boolean {
        return this.convertedField.canAggregate(type);
    }

    //TODO fix paramater and variable types
    getColumn(column: any, moduleID: string, typeIsRelatedRecords?: boolean): any {
        const baseColumn = new Column().getBaseColumn()
        const filter = getAdditionalFilter(this.fieldType)
        const filterParams = getAdditionalFilterParams(this.fieldType, typeIsRelatedRecords)
        const additionalProperties = {
            field: column.name,
            headerName: this.getLabel(moduleID),
            cellRenderer: 'baseFieldRenderer',
            filter: filter,
            filterParams: filterParams
        }
        return {
            ...baseColumn,
            ...additionalProperties
        }
    }

    getLabel(moduleID: string) {
        if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
            return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
        }
        return this.label
    }

    getFormattedValue(value: any, moduleID?: string) {
        return this.convertedField.getFormattedValue(value)
    }

    getUnformattedValue(value: any) {
        return value
    }

    getFieldType(): FieldType {
        return FieldType.CALCULATEDFIELD
    }

}
