
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import FieldUpdate from "@/components/Settings/Actions/fieldUpdate.vue";
import FieldMerge from "@/components/Settings/Actions/fieldMerge.vue";
import FieldUpdateParent from "@/components/Settings/Actions/fieldUpdateParent.vue";
import FieldMergeParent from "@/components/Settings/Actions/fieldMergeParent.vue";
import AddRoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/AddRoboticProcess.vue";
import RelatedFieldCopy from "@/components/Settings/Actions/relatedFieldCopy.vue";
import { CopyProductLinesAction } from "@/components/Settings/Actions/actions";
import ConditionTable from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/ConditionTable.vue";
import { Timestamp } from '@firebase/firestore';
import ListValues from "@/components/Mixin/ListValues";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";
import sortList from "@/common/helpers/utilities";
import GetRelatedFieldValue from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/GetRelatedFieldValue"

class User {
  ID = "";
  name = "";
}

class CurrentTemplateClass {
  ID = null;
  name = "";
  type = "";
  module = "";
  fileType = "";
  fileName = "";
  fields = Array<{}>();
  roboticProcess = Array<{}>();
  skipHeaders = false;
  createdAt: any = Timestamp.now();
  changedAt: any = Timestamp.now();
  createdBy: User = new User();
  changedBy: User = new User();
}

class NewFilterClass {
  selectedField = {
    label: "",
    value: "",
    type: "",
  };
  typeOfValueField = "";
  dataTypeOfSelectedField = "";
  enterValue = "";
  from = "";
  to = "";
  showValueField = false;
  dateFilterStartCheckBox = false;
  dateFilterEndCheckBox = false;
}

class SelectClass {
  dateRange = false;
  numberRange = false;
  fixedField = false;
  dynamicField = false;
}

@Component({
  components: {
    AddEmailTemplate,
    AddFieldUpdate,
    FieldUpdate,
    FieldMerge,
    FieldUpdateParent,
    FieldMergeParent,
    AddRoboticProcess,
    RelatedFieldCopy,
    ConditionTable,
    DynamicFieldSingle
  },
})
export default class AddNewActions extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop() existingAction;
  @Prop() openExistingAction;
  typeOfFieldAction = [
    { name: "inRecord", title: "InRecord", label: "In Record" },
    { name: "childToParent", title: "ChildToParent", label: "Child To Parent" },
    { name: "parentToChild", title: "ParentToChild", label: "Parent To Child" },
  ];
  currentTemplate = new CurrentTemplateClass();
  copyProductLines = new CopyProductLinesAction();
  isSourceModuleTrue = false;
  toggledSelectField = false;
  selectedChildTypes = false;
  selectedFieldsTypes = false;
  selectedParentTypes = false;
  childModuleSelected = false;
  parentModuleSelected = false;
  selectedTypeOfFieldAction = "";
  addTitleInDialog = "";
  addDescriptionInDialog = "";
  showModulesList: Array<any> = [];
  showModules: Array<any> = [];
  options = this.showModules;
  refOptions = this.showModulesList;
  fieldsToUpdate = {};
  fieldsToMerge = {};
  fieldsToUpdateParent = {};
  fieldsToMergeParent = {};
  newFilter = new NewFilterClass();
  selectClass = new SelectClass();
  filterOptions = [
    { label: "Fixed veld filter", value: "fixedField", dBType: "operator" },
    { label: "Dynamisch veld filter", value: "dynamicField", dBType: "operator" },
  ];
  selectedFilter = { label: "", value: "", dBType: "" };
  selectedOperator = { value: "", label: "" };
  comparisonOperatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThanOrEqualTo'), value: ">=" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThanOrEqualTo'), value: "<=" },
    { label: this.$t('roboticProcessBuilder.fields.isGreaterThan'), value: ">" },
    { label: this.$t('roboticProcessBuilder.fields.isSmallerThan'), value: "<" },
  ];
  comparisonBooleanOperatorOptions = [
    { label: this.$t('roboticProcessBuilder.fields.isEqualTo'), value: "==" },
    { label: this.$t('roboticProcessBuilder.fields.isNotEqualTo'), value: "!=" },
  ];
  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };
  fieldOptions = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
  }>();
  criteriaOptions = ["and", "or"];
  mergeTagOptions = [];

  async mounted() {
    this.getActionTypeFunction()
    if(this.openExistingAction == true){      
      await this.copyProductLines.import(this.existingAction.ID, this.dynamicModules)
      this.showFieldsOfType()
    }
    let showModules = {};
    for (const modules in this.dynamicModules) {
      if (this.dynamicModules[modules].pluralName != undefined) {
        showModules = {
          label: this.dynamicModules[modules].pluralName,
          name: this.dynamicModules[modules].id,
        };
      } else {
        showModules = {
          label: this.dynamicModules[modules].name,
          name: this.dynamicModules[modules].id,
        };
      }
      this.showModules.push(showModules);
    }    
  }

  // Filter function
  filterFn(val, update) {
    if (val === "") {
      this.options = this.showModules;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.showModules.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  filterRef(val, update) {
    if (val === "") {
      this.refOptions = this.showModulesList;
    }
    update(() => {
      const Reference = val.toLowerCase();
      this.refOptions = this.showModulesList.filter(
        (v) => v.label.toLowerCase().indexOf(Reference) > -1
      );
    });
  }

  // Function to check if the type is parentToChild or childToParent
  selectedChildType() {
    if (this.selectedTypeOfFieldAction["name"] == "childToParent") {
      this.selectedChildTypes = true;
      this.childModuleSelected = true;
      this.selectedParentTypes = false;
      this.parentModuleSelected = false;
    } else if (this.selectedTypeOfFieldAction["name"] == "parentToChild") {
      this.selectedChildTypes = false;
      this.childModuleSelected = false;
      this.selectedParentTypes = true;
      this.parentModuleSelected = true;
    } else {
      this.selectedChildTypes = false;
      this.childModuleSelected = false;
      this.selectedParentTypes = false;
      this.parentModuleSelected = false;
    }
  }

  // to get the options for a related field
  showFieldsOfType() {
    this.showModulesList = [];
    for (const field in this.dynamicModules[
      this.copyProductLines.childModule["name"]
    ].fields) {
      if (
        this.dynamicModules[this.copyProductLines.childModule["name"]].fields[
          field
        ].type == "relatedField"
      ) {
        const showFields = {
          label: this.dynamicModules[this.copyProductLines.childModule["name"]]
            .fields[field].label,
          name: this.dynamicModules[this.copyProductLines.childModule["name"]]
            .fields[field].name,
        };
        this.showModulesList.push(showFields);
      }
    }
    const fields = this.dynamicModules[
      this.copyProductLines.childModule["name"]
    ].fields;
    Object.keys(fields).forEach((field) => {
      if (fields[field].label) {
        this.fieldOptions.push({
          label: fields[field].label,
          value: fields[field].name,
          type: fields[field].type,
          fieldType: fields[field].fieldType,
        });
      }
    });
    this.fieldOptions = sortList(this.fieldOptions, "label")
  }

// to get the field updates from the table
  getFieldUpdates(value: any) {
    this.fieldsToUpdate = value;
    this.copyProductLines.fieldUpdates = value
  }

// to get the field merges from the table
  getFieldMerges(value: any) {
    this.fieldsToMerge = value;
    this.copyProductLines.fieldMerges = value
  }

// to get the parent field updates from the table
  getFieldUpdatesParent(value: any) {
    this.fieldsToUpdateParent = value;
    this.copyProductLines.fieldUpdatesParent = value
  }

// to get the parent field merges from the table
  getFieldMergesParent(value: any) {
    this.fieldsToMergeParent = value;
    this.copyProductLines.fieldMergesParent = value
  }

  onSelectFilter() {
    this.selectClass = new SelectClass();
    this.selectClass[this.selectedFilter.value] = true;
    this.initFilter();
    this.scrollToEndOfPage();
  }

  initFilter() {
    this.selectedOperator = { value: "", label: "" };
    this.newFilter = new NewFilterClass();
  }
  
  scrollToEndOfPage() {
    const el = document.getElementById("end-of-page");
    if (el) el.scrollIntoView({ block: "end", behavior: "smooth" });
  }

  get showNewFieldFilterAddButton() {
    if (!this.newFilter.showValueField || !this.selectedOperator.value) {
      return false;
    }

    let result = true;
    if (
      this.newFilter.dataTypeOfSelectedField === "boolean" &&
      this.newFilter.enterValue === ""
    ) {
      result = false;
    } else if (
      this.newFilter.dataTypeOfSelectedField === "timestamp" &&
      !this.newFilter.enterValue
    ) {
      result = false;
    }
    return result;
  }

// function to get the type of the selected field
  getFieldType(field) {
    let result = field.type;
    if (field.type === "autoNumber") result = "text";
    if (field.type === "calculatedField")
      result = ListValues.getRealFieldType(field);
    if (!result) console.log("field.type is unknown");
    return result;
  }

// function to specify to check wich operatorOptions to use
  getOperatorOptions(fieldType) {
    return fieldType === "boolean" ||
      fieldType === "text" ||
      fieldType === "map"
      ? this.comparisonBooleanOperatorOptions
      : this.comparisonOperatorOptions;
  }

  getMergeTagOptions(selectedField) {    
    const fields = this.dynamicModules[this.copyProductLines.module.name].fields    
    const mergeTagOptions: any = []
    for(const f in fields) {
      if(fields[f].type === selectedField.type) {
        const field = {
          label: fields[f].label,
          value: fields[f].name,
        }
        mergeTagOptions.push(field)
      }
    }    
    this.mergeTagOptions = mergeTagOptions
  }

  onFilterFieldSelect(selectedField) {
    this.initFilter();
    this.newFilter.selectedField = Object.assign({}, selectedField);
    if (this.newFilter.selectedField.type === "relatedField") {
      this.newFilter.selectedField.value =
        this.newFilter.selectedField.value + ".ID";
    }
    this.newFilter.typeOfValueField = this.getFieldType(
      this.newFilter.selectedField
    );
    this.newFilter.dataTypeOfSelectedField = this.objectOfType[
      this.newFilter.typeOfValueField
    ];
    this.newFilter.showValueField = true;
    this.getMergeTagOptions(this.newFilter.selectedField)
  }

  async addFilterToReportFilters() {
    console.log(this.selectedFilter.dBType === "operator");
    console.log(this.selectClass.dynamicField);
    
    if (this.currentTemplate.roboticProcess.length >= 10) {
      alert("maxFiltersCount = 10");
      return;
    }
    let filterObj = {};
    let index = 0;
    //TODO can't we use this.copyProductLines['query'].lenght ?   
    for (const query in this.copyProductLines["query"]) {
      
      index++;
    }
    if (this.selectedFilter.dBType === "operator" && this.selectClass.fixedField) {
      const field = this.newFilter.selectedField.value.split(".")[0]
      if(this.dynamicModules[this.copyProductLines.module.name].fields[field].type == "relatedField") {
        filterObj = {
          type: this.selectedFilter.dBType,
          field: this.newFilter.selectedField.value.split(".")[0],
          operator: this.selectedOperator.value,
          filterType: this.selectedFilter.value,
          value: this.newFilter.enterValue["ID"],
          rule: index,
        };
        filterObj["name"] = await GetRelatedFieldValue(filterObj["field"], filterObj["value"], this.dynamicModules, this.copyProductLines.module.name)        
      } else {
        filterObj = {
          type: this.selectedFilter.dBType,
          field: this.newFilter.selectedField.value,
          operator: this.selectedOperator.value,
          filterType: this.selectedFilter.value,
          value: this.newFilter.enterValue,
          rule: index,
        };
      }
    } else if (this.selectedFilter.dBType === "operator" && this.selectClass.dynamicField) {
      filterObj = {
        type: this.selectedFilter.dBType,
        field: this.newFilter.selectedField.value.split(".")[0],
        operator: this.selectedOperator.value,
        value: this.newFilter.enterValue['value'],
        name: this.newFilter.enterValue['label'],
        filterType: this.selectedFilter.value,
        rule: index,
      };
    }
    console.log(filterObj);
    index++;
    this.currentTemplate.roboticProcess.push(filterObj);
    this.copyProductLines["query"].push(filterObj);
    this.initFilter();
    this.scrollToEndOfPage();
  }

  // emit function to save action in actionHeader.vue
  @Emit("getActionType")
  getActionTypeFunction() {
      const map = {
        value: this.copyProductLines,
        type: "CopyProductLine"
      }
      return map   
  }
}
