
    import { Vue, Component } from "vue-property-decorator";
    import { State } from "vuex-class";
    import { mapState } from 'vuex';
    import axios from "axios";
    import urls from "@/urls";
    import { getAuth } from "firebase/auth";
    import firebase from "@/firebaseApp";

    @Component({
      computed: {
        ...mapState(["dynamicModules"]),
      }
    })
    export default class SyncElasticSearch extends Vue {
      @State('ACL') ACL;
      @State('tenantID') tenantID;
      @State('dynamicModules') dynamicModules;
      @State('lineModules') lineModules;

      selectedModules: Array<string> = [];
      isSyncProcessing = false;
      successMessage = '';
      errorMessages: Array<string> = [];
      auth = getAuth(firebase.app());
      get modulesList() {
        return Object.keys(this.dynamicModules).concat(Object.keys(this.lineModules));
      }

      updated() {
        if (this.ACL.length && !this.ACL.includes('superAdmin')) {
          this.$router.push({ path: '/' });
        }
      }

      async syncESAll(): Promise<void> {
        this.selectedModules = this.modulesList;
        await this.syncES();
      }

      async syncES(): Promise<void> {
        this.successMessage = '';

        if(!this.selectedModules.length) {
          this.errorMessages = ['Please, select modules.'];
          return;
        }

        this.isSyncProcessing = true;
        this.errorMessages = [];

        const gaeURL = urls.SYNC_ES_URI

        axios({
          url: gaeURL,
          method: 'POST',
          data: {
            data: {
              command: {
                args: {
                  tenantId: this.tenantID,
                  moduleIds: this.selectedModules,
                }
              }
            }
          },
          headers: {
            'Authorization': 'Bearer ' + await this.auth.currentUser.getIdToken()
          },
        }).then(response => {
          this.isSyncProcessing = false;

          if(response.data.data.command.success) {
            this.successMessage = 'Success!';
          }

          response.data.data.command.modules.forEach(module => {
            if (!module.success) {
              this.errorMessages.push(`${module.id} status: ${module.success}`);
            }

            if (module.errors.length) {
              module.errors.forEach(error => {
                this.errorMessages.push(`ModuleID: ${module.id} docID: ${error.docId}, errorType: ${error.error.type}, errorReason: ${error.error.reason}`);
              });
            }
          });
        });
      }

      async dashboardSyncESAll() {
        this.selectedModules = this.modulesList;
        await this.dashboardSyncES();
      }

      async dashboardSyncES(): Promise<void> {
        this.successMessage = '';

        if(!this.selectedModules.length) {
          this.errorMessages = ['Please, select modules.'];
          return;
        }

        this.isSyncProcessing = true;
        this.errorMessages = [];

        const gaeURL = urls.DASHBOARD_SYNC_ES_URI

        axios({
          url: gaeURL,
          method: 'POST',
          data: {
            data: {
              command: {
                args: {
                  tenantId: this.tenantID,
                  moduleIds: this.selectedModules,
                }
              }
            }
          },
          headers: {
            'Authorization': 'Bearer ' + await this.auth.currentUser.getIdToken()
          },
        }).then(response => {
          this.isSyncProcessing = false;

          if(response.data.data.command.success) {
            this.successMessage = 'Success!';
          }

          response.data.data.command.modules.forEach(module => {
            if (!module.success) {
              this.errorMessages.push(`${module.id} status: ${module.success}`);
            }

            if (module.errors.length) {
              module.errors.forEach(error => {
                this.errorMessages.push(`docID: ${error.docId}, errorType: ${error.error.type}, errorReason: ${error.error.reason}`);
              });
            }
          });
        });
      }
    }
