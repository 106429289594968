import db from "../db";
import store from "../store";
import functions from "@/functions";
import ListValues from "@/components/Mixin/ListValues";
import { aggregationFiltersWrapper } from "@/common/helpers/convert";
import Query from "./Dto/Query";

const state = {};

const getters = {};

const actions = {

  async getRecords({ state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      const filter = params.filter ?? {};

      const queryLIKE = [];
      const queryLIKENOT = [];
      const queryRANGE = [];
      const queries = [];

      // columns multi select
      for (const cl in filter.columnOptionsSelected) {
        const fields = filter.columnOptionsSelected[cl];
        const queryOR = [];
        if (fields.length) {
          fields.forEach(f => {
            const eField = ListValues.elasticFilterLikeFields(cl, params.module);
            if(f || f === false) {
              queryOR.push({[eField]: f});
            } else {
              if (f === '') {
                queryLIKENOT.push({[cl]: '*'});
              }
            }
          });
          queries.push({ OR: queryOR });
        }
      }
      if(filter.optionalFilter) {
        for(const optionalFilter of filter.optionalFilter) {
          if(optionalFilter.operator === "==") {
            if(typeof optionalFilter.value === 'boolean') {
              queries.push({ OR: [{[optionalFilter.field]: optionalFilter.value}]});
            } else {
              queryLIKE.push({[optionalFilter.field]: optionalFilter.value});
            }
          } else if(optionalFilter.operator === "!=") {
            if(typeof optionalFilter.value === 'boolean') {
            queryLIKENOT.push({[optionalFilter.field]: optionalFilter.value});
            } else {
            queryLIKENOT.push({[optionalFilter.field]: optionalFilter.value.toString()});
            }
          }
        }
      }
      

      // filters for related records
      for (const cl in filter.relatedQuery) {
        queries.push({ OR: [{[cl]: filter.relatedQuery[cl]}]});
      }

      // TODO move to the backend API
      // add filter by account for Portal view
      if (store.state.PORTAL) {
        const view = store.state.viewsModules && params.module ? store.state.viewsModules[params.module] : {};
        const uAccount = store.state.currentAccount;
        const ID = uAccount && uAccount.ID ? uAccount.ID : 'NOT_FOUND';
        if (params.module === 'accounts') {
          queries.push({ OR: [{['ID']: ID }]});
        } else {
          queries.push({ OR: [{['account.ID']: ID }]});
        }
      }

      // columns filter LIKE
      for (const cl in filter.filterData) {
        const dField = ListValues.getField(cl, params.module);
        let dRange = 1;
        const val = filter.filterData[cl];
        if (ListValues.isDateType(dField)) {
          dRange = Object.keys(ListValues.getRange(val, dField)).length;
        }
        // range filter for digital and dates
        if((ListValues.isDigitalType(dField) || (ListValues.isDateType(dField) && dRange)) && val) {
          const val = filter.filterData[cl];
          const range = ListValues.getRange(val, dField);
          const field = cl;
          const eField = ListValues.isDateType(dField) ? field + '.seconds' : field;
          queryRANGE.push({[eField]: range});
          
        } else {
          const val = ListValues.elasticFilterLikeValue(filter.filterData[cl]);
          if (val) {
            const eField = ListValues.elasticFilterLikeFields(cl, params.module);
            if (val.split(' ').length > 1) {
              val.split(' ').map(el => {
                if (el) {
                  queryLIKE.push({[eField]: `*${el.toLowerCase()}*`});
                  
                }
              });
            } else {
              if (val) {
                queryLIKE.push({[eField]: `*${val.toLowerCase()}*`});
                
              }
            }
          }
        }
      }
      if (queryLIKE.length) {
        queries.push({LIKE: queryLIKE});
      }
      if (queryLIKENOT.length) {
        queries.push({LIKENOT: queryLIKENOT});
      }
      if (queryRANGE.length) {
        queries.push({RANGE: queryRANGE});
      }

      if (params.totalFields) {
        params.aggs = {};
        for (const tFieldName of params.totalFields)
          params.aggs[tFieldName] = {
            sum: {
              field: params.module + '_' + tFieldName
            }
          }
      }
      dispatch('elasticSearchQuery', {queries, ...params}).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    });
  },

  async getRecordsFirestore({ state }, params) {
    return new Promise((resolve, reject) => {
      const filter = params.filter ?? {};

      const table = "tenants/" + store.state.tenantID + "/modules/" + params.module + "/records";
      let collection: any = db.collection(table);

      // filters for related records
      for (const cl in filter.relatedQuery) {
        collection = collection.where(cl, '==', filter.relatedQuery[cl]);
      }

      const docs = [];
      collection.get().then((res) => {
        res.forEach((doc) => {
          const rec = doc.data();
          rec.id = doc.id;
          docs.push(rec);
        });
        const result = {docs, count: docs.length};
        resolve(result);
      }).catch((e) => {
        reject(e);
      })
    });
  },

  async queryRelatedFieldOptions({ state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      const module = params.module;
      const query = params.query;
      const relatedQuery = params.relatedQuery;

      const source = ["ID"];
      source.push(`${module}_name`);

      if (Object.prototype.hasOwnProperty.call(params,'additionalField') && params.additionalField.length) {
        params.additionalField.forEach(e => {
          if (e !== 'name') source.push(`${module}_${e}`);
        })
      }

      const queries = {bool: {must: []}};
      queries.bool.must.push({match: {_module: module}});
      if (relatedQuery && relatedQuery.length) {
        relatedQuery.forEach((q) => {
          const field = `${module}_${q.field}`;
          const subQuery = {};

          // only for any value, no undefined
          if (q.relatedID || q.value !== undefined) {
            if (q.relatedID) {
              subQuery[field + '.ID'] = q.relatedID;
            } else {
              subQuery[field] = q.value;
            }
            queries.bool.must.push({match: subQuery});
          }
        });
      }
      if(query && query.length) {
        const subQuery = {bool: {should: []}};
        const fields = []
        let value = ''
        query.forEach((q: Query) => {
          const field = `${module}_${q.field}`;
          fields.push(field)
          value = q.value
          // only for any value, no undefined
        });
        if (fields !== undefined && value !== undefined) {
          subQuery.bool.should.push(
            { 
              "query_string": {
                "query": "*" + value + "*",
                "default_operator": "AND",
                "fields": fields
              }
            }
          )
        }
        queries.bool.must.push(subQuery);
      }

      // TODO move to the backend API
      // add filter by account for Portal view
      if (store.state.PORTAL) {
        if (module === 'accounts') {
          const subQuery = {};
          const uAccount = store.state.currentAccount;
          const ID = uAccount && uAccount.ID ? uAccount.ID : 'NOT_FOUND';
          subQuery['ID'] = ID;
          queries.bool.must.push({match: subQuery});
          //queries.push({ OR: [{['ID']: ID }]});
        }
      }

      dispatch('elasticQueryRelatedFields', {queries, ...params, source}).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    });
  },

  async elasticSearchQuery({ state }, params) {
    return new Promise((resolve, reject) => {
      const tenantID = store.state.tenantID;
      const module = params.module;
      const size = params.size ?? 10;
      const sortBy = params.sortBy;
      const descending = params.descending;
      const cursor = params.cursor;
      const queries = params.queries;
      const aggs = params.aggs;

      const elasticQuery = functions.httpsCallable('elasticQuery');
      
      //TODO dev mode
      //functions.useEmulator('localhost', 5001);

      const query = {bool: {must: [], must_not: []}};
      query.bool.must.push({match: {_module: module}});

      if (queries) {
        for (const q of queries) {

          if(q.OR && q.OR.length) {
            const qShould = { bool: { should: [] } };
            for (const qOR of q.OR) {
              qShould.bool.should.push({ match_phrase: qOR });
            }
            query.bool.must.push(qShould);
          }

          if(q.LIKE && q.LIKE.length) {
            const qMust = { bool: { must: [] } };
            for (const qLIKE of q.LIKE) {
              
              qMust.bool.must.push({ wildcard: qLIKE });
            }
            query.bool.must.push(qMust);
          }

          // like not (must_not) for filter the empty (not existing) fields
          if(q.LIKENOT && q.LIKENOT.length) {
            for (const qLIKENOT of q.LIKENOT) {
              const keys = Object.keys(qLIKENOT)
              if(typeof qLIKENOT[keys[0]] === 'boolean') {
                query.bool.must_not.push({ match: qLIKENOT });
              } else {
                query.bool.must_not.push({ wildcard: qLIKENOT });
              }
            }
          }

          //qFilter.bool.filter.push({ range: { invoiceLines_quantity: { gte: 1, lte : 10 }}});
          //query.bool.must.push(qFilter);
          if(q.RANGE && q.RANGE.length) {
            const qFilter = { bool: { filter: [] } };
            for (const qRANGE of q.RANGE) {
              qFilter.bool.filter.push({ range: qRANGE });
            }
            query.bool.must.push(qFilter);
          }
        }
      }

      const sort = [];
      if (sortBy) {
        const desc = descending ? "desc" : "asc";
        const field = ListValues.elasticSortFields(sortBy, module);
        if (field) {
          sort.push({[field]: {"order": desc, "missing": "_last"}});
        }
      }
      sort.push({"ID.keyword": {"order": "asc"}});

      const data = {tenantID, query, sort, size, module};
      if (cursor) {
        data['search_after'] = cursor;
      }

      if (aggs) {
        data['aggs'] = aggs;
      }

      const docs = [];
      let searchAfter = [];

      elasticQuery(data).then((res) => {

        if (res.data && res.data.error) {
          store.state.alertMessage = 'error';

          (res.data.error.meta.body.error.failed_shards || []).map(sh => console.log(sh.reason));
        }

        //console.debug('elasticSearchQuery res.data', res.data);

        if (res && res.data) {
          res.data.docs.forEach(h => {
            docs.push(h._source);
            searchAfter = h.searchAfter;
          });
        }
        const count = res.data.count;

        const aggs = {};
        if (res && res.data && res.data.aggs) {
          for (const fname in res.data.aggs) {
            aggs[fname] = res.data.aggs[fname].value;
          }
        }

        resolve({docs, count, searchAfter, aggs});
      }).catch((e) => {
        reject(e);
      })
    });
  },

  getElasticRecordsByIDs({ state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      const filter = params.filter ?? [];

      const queries = {
        terms: {
          ID: filter.filterData, // columns filter IN IDs array
        }
      };
      
      dispatch('elasticSearchQueryUsingScroll', {queries, ...params}).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    });
  },

  async elasticQueryRelatedFields({ state }, params) {
    return new Promise((resolve, reject) => {
      const tenantID = store.state.tenantID;
      const module = params.module;
      const query = params.queries;
      const source = params.source;
      const size = params.size ? params.size : 50

      
      const elasticQueryRelatedFields = functions.httpsCallable('elasticQueryRelatedFields');
      //TODO dev mode
      // functions.useEmulator('localhost', 5001);

      const data = {tenantID, source, query, size, module};
      const docs = [];

      elasticQueryRelatedFields(data).then((res) => {

        // TODO fix on the CF side
        if (res.data && res.data.error) {
          store.state.alertMessage = 'error';
          (res.data.error.meta.body.error.failed_shards || []).map(sh => console.log(sh.reason));
        }

        if (res && res.data) {
          res.data.docs.forEach(h => {
            docs.push(h._source);
          });
        }
        const count = res.data.length;
        resolve({docs, count});
      }).catch((e) => {
        reject(e);
      });
    });
  },

  async elasticSearchQueryUsingScroll({ state }, params) {
    return new Promise((resolve, reject) => {
      const tenantID = store.state.tenantID;
      const module = params.module;
      const query = params.queries;
      const source = params.source;
      const size = 10000;

      
      const elasticQueryUsingScroll = functions.httpsCallable('elasticQueryUsingScroll');
      //TODO dev mode
      //functions.useEmulator('localhost', 5001);

      const data = {tenantID, source, query, size, module};
      const docs = [];

      elasticQueryUsingScroll(data).then((res) => {

        // TODO fix on the CF side
        if (res.data && res.data.error) {
          store.state.alertMessage = 'error';
          (res.data.error.meta.body.error.failed_shards || []).map(sh => console.log(sh.reason));
        }

        if (res && res.data) {
          res.data.docs.forEach(h => {
            docs.push(h._source);
          });
        }
        const count = res.data.length;
        resolve({docs, count});
      }).catch((e) => {
        reject(e);
      });
    });
  },

  async elasticQueryRecordsByIDs({ state }, params) {
    return new Promise((resolve, reject) => {
      const tenantID = store.state.tenantID;
      const module = params.module;
      const IDs = params.IDs;
      const source = params.source;
      const size = 10000;

      
      const elasticQueryRecordsByIDs = functions.httpsCallable('elasticQueryRecordsByIDs');
      //TODO dev mode
      // functions.useEmulator('localhost', 5001);

      const data = {tenantID, source, IDs, size, module};
      const docs = [];

      elasticQueryRecordsByIDs(data).then((res) => {

        // TODO fix on the CF side
        if (res.data && res.data.error) {
          store.state.alertMessage = 'error';
          (res.data.error.meta.body.error.failed_shards || []).map(sh => console.log(sh.reason));
        }

        if (res && res.data) {
          res.data.docs.forEach(h => {
            docs.push(h._source);
          });
        }
        const count = res.data.length;
        resolve({docs, count});
      }).catch((e) => {
        reject(e);
      });
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};

/*
GET _search
{
  "query": {
    "bool":{
      "must":[
        {"match":{"_module":"appointments"}},
        {"bool": {"should": [
          {"match_phrase": {"appointments_status": "uitgevoerd"}}
          ]}
        },
                {"bool": {"filter": [
          {"term": {"appointments_status": "uitgevoerd"}}
          ]}
        }
      ]
    }
  }
}
 */
