import {colors} from "quasar";
import storeSettings from "../../store/settings";

export default class Design {

  static setColors() {
    const colorSchema = storeSettings.state.designSettings.colorSchema;
    for (const name in colorSchema) {
      colors.setBrand(name, colorSchema[name])
    }
  }
  static setSpecificColors (colorSchema) {
    for (const name in colorSchema) {
      colors.setBrand(name, colorSchema[name])
    }
  }
}
