
  import {Component, Prop, Vue, Watch, Emit} from 'vue-property-decorator';
  import localeNl  from '../Helpers/nl.json';
  import {Action} from 'vuex-class';
  import ListValues from "@/components/Mixin/ListValues";
  import TagsBlock from "@/components/Parts/TagsBlock.vue";
  import store from "@/store";
  import db from "@/db";

  @Component({
    components: {TagsBlock}
  })
  export default class TemplateConstructor extends Vue {
    @Prop(Object) settings;
    @Prop(Object) mergeTags;
    @Prop(Object) designTags;
    @Prop(Object) template;
    @Prop(Object) inputJson;
    @Action('uploadFileToStorage') uploadFileToStorage;

    editor: any;
    exportedHtml: any = null;
    showModalExportHtml = false;
    showRenameDialog = false;
    renameTemplateName = '';
    emailSubject = '';
    emailFrom = '';
    emailToFields = '';
    emailCC_Fields = '';
    emailBCC_Fields = '';
    attachmentRequired = false;
    defaultTemplate = false;

    emailActionsList: any = [];
    emailAction = null;
    emailTags: Array<string> = [];

    mounted() {
      this.loadEditor();
      this.loadDesign();
      this.loadActions();
    }

    @Emit("closeEmailEditor")
    closeEmailEditorFunction() {
      return false
    }

    showRenameTemplate() {
      this.showRenameDialog = true;
      this.renameTemplateName = this.template.name;
      this.emailSubject = this.template.subject;
      this.emailFrom = this.template.from;
      this.emailToFields = this.template.to;
      this.emailCC_Fields = this.template.cc;
      this.emailBCC_Fields = this.template.bcc;
      this.attachmentRequired = this.template.attachmentRequired;
      this.emailAction = this.template.action;
      this.emailTags = (this.template.tags || []);
      this.defaultTemplate = this.template.default || false;
      this.defaultTemplate = this.template.default || false;
    }

    onRenameTemplate() {
      this.showRenameDialog = false;
      const data = {
        name: this.renameTemplateName || '',
        subject: this.emailSubject || '',
        from: this.emailFrom || '',
        to: this.emailToFields || '',
        cc: this.emailCC_Fields || '',
        bcc: this.emailBCC_Fields || '',
        attachmentRequired: this.attachmentRequired || false,
        action: this.emailAction || '',
        tags: this.emailTags.map(t => t.trim()) || [],
        default: this.defaultTemplate || false,
        module: this.template.module || '',
      }
      this.$emit('onRenameTemplate', data);
      this.renameTemplateName = '';
      this.emailSubject = '';
      this.emailFrom = '';
      this.emailToFields = '';
      this.emailCC_Fields = '';
      this.emailBCC_Fields = '';
      this.attachmentRequired = false;
      this.defaultTemplate = false;
      this.emailAction = null;
      this.emailTags = [];
    }

    loadEditor() {
      /* eslint-disable */
      // @ts-ignore: Unreachable code error
      this.editor = unlayer.createEditor({
        id: 'editor-container',
        displayMode: this.settings.displayMode,
        projectId: this.settings.projectId,
        appearance: this.settings.appearance,
        mergeTags: this.mergeTags,
        locale: this.settings.locale,
        translations: {
          'nl-NL': localeNl
        },
      });

      this.editor.registerCallback('image', (files, done) => {
        const file = files.attachments[0];

        file.toPublic = true;
        file.onlyRef = true;
        file.addTenant = true;
        file.storageRef = 'emailFiles/';

        file.procentShowCallBack = (snapshot) => {
          const loaded = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
          done({ progress: loaded})
        };

        this.uploadFileToStorage(file).then(upfile => {
          done({ progress: 100, url: upfile.downloadableURL })
        });
      });


    }

    openDesign() {
      this.loadDesign();
    }

    @Watch('template')
    async loadNewDesign() {
      await this.loadDesign();
    }

    async loadDesign() {
      if (this.template.design) {
        await this.editor.loadDesign(this.template.design);
      }
    }

    async loadActions() {
      const firePath = "tenants/" + store.state.tenantID + "/actions/FieldUpdates/records" ;
      const res = await db.collection(firePath).where('emailAction', '==', true).get();
      const docs:any = [];
      res.forEach((doc) => {
        const rec: any = doc.data();
        rec.id = doc.id;
        (docs as any).push(rec as any);
      });
      this.emailActionsList = docs.map(d => { return { ID: d.ID, title: d.title }})
    }

    saveDesign() {
      this.editor.exportHtml((editorData) => {
          const htmlData = editorData.html;
        this.editor.saveDesign((design) => {
            this.template.design = JSON.parse(JSON.stringify(design));
            this.template.HTML = htmlData;
            this.$emit('saveTemplate', this.template);
          }
        )
        }
      )
    }

    exportHtml() {
      this.editor.exportHtml(
          (data) => {
            this.exportedHtml = ListValues.render(data.html, this.inputJson);
            this.showModalExportHtml = true;
          }
      )
    }

    autoSave(){
      if (this.template.ID) this.saveDesign();
    }
  }
