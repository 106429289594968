
import { Component, Vue, Prop } from "vue-property-decorator";
import draggable from "vuedraggable";
import { State } from "vuex-class";
import TypeOfActions from "@/components/Settings/Actions/typeOfActions";
import store from "@/store";
import db from "@/db";
import sortList from "@/common/helpers/utilities";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import AddCopyProductLines from "@/components/Settings/Actions/addCopyProductLines.vue";
import AddRecord from "@/components/Settings/Actions/addRecord.vue";
import RelatedFieldCopy from "@/components/Settings/Actions/relatedFieldCopy.vue";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddRelatedFieldCopy from "@/components/Settings/Actions/addRelatedFieldCopy.vue";
import ActionHeader from "@/components/Settings/Actions/actionHeader.vue"
import Dialog from "@/components/Parts/Dialog.vue";
import { CopyProductLinesAction, CreateRecordAction } from "@/components/Settings/Actions/actions";

@Component({
  components: {    
    draggable,
    AddFieldUpdate,
    AddCopyProductLines,
    AddRecord,
    RelatedFieldCopy,
    AddEmailTemplate,
    AddRelatedFieldCopy, 
    ActionHeader,
    Dialog  
  },
})

export default class RoboticProcessDetailsDrawer extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) readonly DetailsDrawerHeaders!: any;
  @Prop({ required: true }) readonly DetailType!: string;
  @Prop({ required: true }) readonly DetailTypeConditions!: string;
  @Prop({ required: true }) module!: string;
  @Prop({ required: true }) roPro;

  TypeOfActions = new TypeOfActions().TypeOfActions;
  actionTab = {};
  roProAction: Array<any> = [];
  showActionInRopro = false;
  selectedTypeOfAction: any = "";
  editAction = false;
  existingAction = [];
  actionDescription = false
  showAddActionDialog = false
  showTooltip = true
  sendSMS = false
  actionActive!: boolean
  deleteRecord = false
  deleteFile = {}
  typeOfAction = "";
  copyProductLines = new CopyProductLinesAction()
  createRecords = new CreateRecordAction()
  mounted() {    
    this.actionTab = this.TypeOfActions[0]
    this.loadActions(this.actionTab["title"])
  }

  // Function to show the selected action in the ropro
  toggleAction(selectedAction, index) {
    this.showActionInRopro = true;
    let actionType = "";

    if (this.actionTab["title"] == "FieldUpdates") {
      actionType = "FieldUpdate";
    } else if (this.actionTab["title"] == "CopyProductLines") {
      actionType = "CopyProductLine";
    } else if (this.actionTab["title"] == "CreateRecords") {
      actionType = "CreateRecord";
    } else if (this.actionTab["title"] == "SendEmails") {
      actionType = "SendEmail";
    } else if (this.actionTab["title"] == "RelatedFieldCopies") {
      actionType = "RelatedFieldCopy";
    } else if (this.actionTab["title"] == "SendSMSes") {      
      actionType = "SendSms"
      this.sendSMS = true
    } else if (this.actionTab["title"] == "GeneratePDFs") {
      actionType = "GeneratePdf"
    }
    // Pushing the selected action into the RoPro.actions
    this.roPro.actions.push({
      title: selectedAction.title,
      description: selectedAction.description,
      order: index,
      parameters: {},
      type: actionType,
      ID: selectedAction.ID,
    });
    
    this.roProAction.splice(index, 1);
    this.roProAction =  sortList(this.roProAction, 'description');
    this.changeActionOrder()
  }
  // Function to load in all actions
  async loadActions(actionTabTitle) {
    console.log(actionTabTitle, "action tab title");
    
    const firePath =
      "/tenants/" +
      store.state.tenantID +
      "/actions/" +
      actionTabTitle +
      "/records";
    const res = await db.collection(firePath).get();
    const docs: any = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });

    this.roProAction = docs.map((d) => {      
      return {
        ID: d.ID,
        title: d.title,     
        description: d.description,
        type: d.type,
        module: d.module,
        active: d.active
      };
    });

    let action: any = ""
    for(action in this.roProAction) {
      if(this.roPro.actions.length > 0) {
        for(const a in this.roPro.actions) {
          if(this.roProAction[action].ID == this.roPro.actions[a].ID){
            this.roProAction.splice(action, 1)
          }
        }
      }
    }    

    if(actionTabTitle == "SendEmails" || actionTabTitle == "GeneratePDFs") {
      let action: any = ""
      const list = []
      for(action in this.roProAction) {
        console.log(this.roProAction[action].module != this.module["value"]);
        if(this.roProAction[action].module == this.module["value"]) {
          list.push(this.roProAction[action])
        }
      }
      this.roProAction = list
      console.log(this.roProAction, "ropro action");
      console.log(this.module);
      
    }
    this.roProAction =  sortList(this.roProAction, 'description');
  }
  // Functino to delete the selected actions
  deleteActionFromRoPro(index) {
    let actionType = "";  
    if (this.actionTab["title"] == "FieldUpdates") {
      actionType = "FieldUpdate";
    } else if (this.actionTab["title"] == "CopyProductLines") {
      actionType = "CopyProductLine";
    } else if (this.actionTab["title"] == "CreateRecords") {
      actionType = "CreateRecord";
    } else if (this.actionTab["title"] == "SendEmails") {
      actionType = "SendEmail";
    } else if (this.actionTab["title"] == "RelatedFieldCopies") {
      actionType = "RelatedFieldCopy";
    } else if (this.actionTab["title"] == "SendSMSes") {
      actionType = "SendSms"
    } else if (this.actionTab["title"] == "GeneratePDFs"){
      actionType = "GeneratePdf"
    }

    if(this.roPro.actions[index].type != actionType) {
      this.roPro.actions.splice(index, 1);
      this.roProAction = sortList(this.roProAction, 'description');
    }else {
      this.roProAction.push(this.roPro.actions[index]);
      this.roProAction = sortList(this.roProAction, 'description');
      this.roPro.actions.splice(index, 1);
    }
  }
    // Function to toggle the description of the actions
  toggleActionDescription() {
    this.actionDescription = true
  }
  // Function to filter the action on alphabetic order
  changeActionOrder() {
    let index = 0;
    for (const i in this.roPro.actions) {
      this.roPro.actions[i].order = index;
      index += 1;
    }
    this.showTooltip = true
  }
  // Function to get the action from the database
  getActionsFromFirebase(value: any) {
    this.editAction = value
    this.showAddActionDialog = value
    this.loadActions(this.actionTab["title"])
  }
  // Function to open a editable action
  async openAction(selectedAction) {
    for (const action in this.roProAction) {      
      if (this.roProAction[action]["ID"] == selectedAction.ID) {
        this.existingAction = this.roProAction[action];
        this.actionActive = this.roProAction[action].active
      }
    }

    if (this.actionTab["title"] == "SendEmails") {
      this.selectedTypeOfAction = "sendEmail";
      this.typeOfAction = "SendEmails"
      this.editAction = true;
    } else if (this.actionTab["title"] == "RelatedFieldCopies") {
      this.selectedTypeOfAction = "relatedFieldCopy";
      this.typeOfAction = "RelatedFieldCopies"
      this.editAction = true;
    } else if (this.actionTab["title"] == "CreateRecords") {
      this.selectedTypeOfAction = "createRecord";
      this.typeOfAction = "CreateRecords"
      const createRecord: any  = await this.createRecords.import(this.existingAction["ID"], this.dynamicModules)
      this.existingAction["fieldUpdates"] = createRecord.fieldUpdates
      this.existingAction["fieldMerges"] = createRecord.fieldMerges      
      this.editAction = true;
    } else if (this.actionTab["title"] == "CopyProductLines") {
      this.selectedTypeOfAction = "copyProductLine";
      this.typeOfAction = "CopyProductLines"
      const copyProductLines: any  = await this.copyProductLines.import(this.existingAction["ID"], this.dynamicModules)
      this.existingAction["fieldUpdates"] = copyProductLines.fieldUpdates
      this.existingAction["fieldMerges"] = copyProductLines.fieldMerges
      this.existingAction["fieldUpdatesParent"] = copyProductLines.fieldUpdatesParent
      this.existingAction["fieldMergesParent"] = copyProductLines.fieldMergesParent
      this.editAction = true;
    } else if (this.actionTab["title"] == "FieldUpdates"){
      this.selectedTypeOfAction = "fieldUpdate"
      this.typeOfAction = "FieldUpdates"
      this.editAction = true
    } else if (this.actionTab["title"] == "SendSMSes") {
      this.selectedTypeOfAction = "sendSms"
      this.typeOfAction = "SendSMSes"
      this.editAction = true
      this.sendSMS = true
    } else if (this.actionTab["title"] == "GeneratePDFs") {
      this.selectedTypeOfAction = "generatePdf";
      this.typeOfAction = "GeneratePDFs"
      this.editAction = true;
    } 
  }
  // Function to open a selected action
  async openSelectedAction(selectedAction) {            
    for (const action in this.roPro.actions) {
      if (this.roPro.actions[action]["ID"] == selectedAction.ID) {        
        this.existingAction = this.roPro.actions[action];
      }
    }    
    if (selectedAction["type"] == "SendEmail") {
      this.selectedTypeOfAction = "sendEmail";
      this.typeOfAction = "SendEmails"
      this.editAction = true;
    } else if (selectedAction["type"] == "RelatedFieldCopy") {
      this.selectedTypeOfAction = "relatedFieldCopy";
      this.typeOfAction = "RelatedFieldCopies"
      this.editAction = true;
    } else if (selectedAction["type"] == "CreateRecord") {
      this.selectedTypeOfAction = "createRecord";
      this.typeOfAction = "CreateRecords"
      this.editAction = true;
    } else if (selectedAction["type"] == "CopyProductLine") {
      this.selectedTypeOfAction = "copyProductLine";
      this.typeOfAction = "CopyProductLines"
      this.editAction = true;
    } else if (selectedAction["type"] == "FieldUpdate"){
      this.selectedTypeOfAction = "fieldUpdate"
      this.typeOfAction = "FieldUpdates"
      this.editAction = true
    } else if (selectedAction["type"] == "SendSMSe") {
      this.selectedTypeOfAction = "sendSms"
      this.typeOfAction = "SendSMSes"
      this.editAction = true
    } else if (this.actionTab["title"] == "GeneratePDFs") {
      this.selectedTypeOfAction = "generatePdf";
      this.typeOfAction = "GeneratePDFs"
      this.editAction = true;
    } 
  }
    // Function to create your own function
  createNewAction() {    
    if (this.actionTab["title"] == "SendEmails") {
      this.selectedTypeOfAction = "sendEmail";
      this.typeOfAction = "SendEmails"
      this.showAddActionDialog = true
    } else if (this.actionTab["title"] == "RelatedFieldCopies") {
      this.selectedTypeOfAction = "relatedFieldCopy";
      this.typeOfAction = "RelatedFieldCopies"
      this.showAddActionDialog = true
    } else if (this.actionTab["title"] == "CreateRecords") {
      this.selectedTypeOfAction = "createRecord";
      this.typeOfAction = "CreateRecords"
      this.showAddActionDialog = true;
    } else if (this.actionTab["title"] == "CopyProductLines") {
      this.selectedTypeOfAction = "copyProductLine";
      this.typeOfAction = "CopyProductLines"
      this.showAddActionDialog = true;
    } else if (this.actionTab["title"] == "FieldUpdates"){
      this.selectedTypeOfAction = "fieldUpdate"
      this.typeOfAction = "FieldUpdates"
      this.showAddActionDialog = true
    } else if (this.actionTab["title"] == "SendSMSes") {
      this.selectedTypeOfAction = "sendSms"
      this.typeOfAction = "SendSMSes"
      this.showAddActionDialog = true
    } else if (this.actionTab["title"] == "GeneratePDFs") {
      this.selectedTypeOfAction = "generatePdf"
      this.typeOfAction = "GeneratePDFs"
      this.showAddActionDialog = true
    }
  } 

  deleteDocument(selectedAction) {
        return new Promise<any>((resolve, reject) => {
        try {
            const resp: any = db.doc(`tenants/${store.state.tenantID}/actions/${this.actionTab["title"]}/records/${selectedAction.ID}`).delete()
            resolve(resp)
            const message: any = this.$t("common.messages.recordDeleted")
            this.$q.notify({
              message: message,
              color: "negative",
              icon: "warning",
            });
            return resp
        } catch (error) {
            reject(error)
        }
    })
  }

  async deleteAction(selectedAction) {
    await this.deleteDocument(selectedAction)
    if (this.actionTab["title"] == "SendSMSes") {
      this.loadActions('SendSmse')
    } else {
      this.loadActions(this.actionTab["title"])
    }
    this.deleteRecord = false
  }

  deleteActionPopUp(selectedAction) { 
    this.deleteRecord = true   
    this.deleteFile = selectedAction
  }

}
