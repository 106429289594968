import * as lodash from "lodash";


export default class Utils {

    static isSystemField(field: string): boolean {
        const systemFields = ["ID", "createdAt", "createdBy", "changedAt", "changedBy"]
        if(systemFields.includes(field) || field.startsWith('__')){
            return true
        }
        return false
    }

    static trim(toTrim: any): string {
        // return toTrim;
        return Utils.isEmpty(toTrim) ? '' : ((toTrim + "").trim());
    }

    static zeroIfNaN(num: number): number {
        return Utils.isEmptyUndefinedNullOrInvalidNumber(num) ? 0 : num;
    }

    static formatNumber(num: number): number {
        return +num.toFixed(2);
    }

    static formatDecimalSeparator(num: number): number | string {
        return num.toLocaleString("pt-BR");
    }

    static isEmpty(toCheck: any): boolean {
        return this.isUndefined(toCheck) || toCheck === null || toCheck === '' || (typeof toCheck === 'string' && toCheck.trim() === '');
    }

    static isUndefined(toCheck: any): boolean {
        return toCheck === undefined || typeof toCheck === 'undefined';
    }

    static isUndefinedOrNull(toCheck: any): boolean {
        return this.isUndefined(toCheck) || toCheck === null;
    }

    static isEmptyUndefinedNullOrInvalidNumber(toCheck: any): boolean {
        return Utils.isEmpty(toCheck) || isNaN(toCheck);
    }

    static isEmptyRelatedField(toCheck: any): boolean {
        return !toCheck.ID || this.isEmpty(toCheck.ID) 
    }

    static isEmptyRelatedFieldOrEmpty(toCheck: any): boolean{
        return this.isEmpty(toCheck) || this.isEmptyRelatedField(toCheck)
    }

    // static getDirectoryForFiles(): string {
    //     // __dirname:  ***/dist/utils replace will change to :  ***/resources
    //     return __dirname.replace('src/utils', 'resources/generated').replace('dist/utils', 'resources/generated');
    // }

    static getNullIfUndefined(value: any): any | null {
        return (typeof value === 'undefined' || value === undefined) ? null : value;
    }

    static getRandomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static isRelatedFieldNotFilled(relatedField: any) {
        return Utils.isEmpty(relatedField) || Utils.isEmpty(relatedField.ID);
    }

    static prepareRelatedField(entity: any): any {
        if (Utils.isRelatedFieldNotFilled(entity)) {
            return null;
        }

        return { ID: Utils.getNullIfUndefined(entity.ID), name: Utils.getNullIfUndefined(entity.name) };
    }

    static areRelatedFieldsSame(relatedFieldOne: any, relatedFieldTwo: any) {
        return (Utils.isRelatedFieldNotFilled(relatedFieldOne) && Utils.isRelatedFieldNotFilled(relatedFieldTwo)) ||
            (!Utils.isRelatedFieldNotFilled(relatedFieldOne) && !Utils.isRelatedFieldNotFilled(relatedFieldTwo) && relatedFieldOne.ID === relatedFieldTwo.ID);
    }

    static buildModuleRecordKey(moduleID: string, moduleRecordId: string): string {
        return Utils.trim(moduleID) + Utils.trim(moduleRecordId);
    }

    static removeDuplicates(a: string[]): string[] {
        return Array.from(new Set(a));
    }


    static prepareJson(fieldValue: any, fieldKey: string, label?: string, _jsonToAppend?: any): any {

        const jsonToAppend = this.isEmpty(_jsonToAppend) ? {} : _jsonToAppend;
        const keys = Utils.isEmpty(fieldKey) ? [] : fieldKey.split(".");
        return this.addFieldValueToMultiLevelKey(jsonToAppend, fieldValue, keys, label);
    }

    static addFieldValueToMultiLevelKey(jsonToAdd: any, fieldValue: any, keys: string[], label?: string): any {

        if (keys.length <= 0 && !Utils.isEmpty(label)) {

            jsonToAdd[label] = fieldValue;

        } else if (Utils.isEmpty(label) && (Utils.isEmpty(keys[0]) || keys.length <= 0)) {

            
        } else if (keys.length === 1) {

            jsonToAdd[(Utils.isEmpty(label) ? keys[0] : label)] = fieldValue;
        } else {

            const value = Utils.isEmpty(jsonToAdd[keys[0]]) ? {} : jsonToAdd[keys[0]];
            jsonToAdd[keys[0]] = this.addFieldValueToMultiLevelKey(value, fieldValue, keys.splice(1), label);
        }

        return jsonToAdd;

    }

    static isTextExceededMaxLength(textToValidate: string, maxLength?: number): boolean {
        const _maxLength: number = Utils.isEmpty(maxLength) ? 255 : maxLength;

        if (Utils.isEmpty(textToValidate)) {
            return false;

        }
        return textToValidate.length > maxLength;
    }

    static isTextContainNewLine(textToValidate: string): boolean {

        if (Utils.isEmpty(textToValidate)) {
            return false;

        }

        return ('' + textToValidate).includes("\n") || ('' + textToValidate).includes("\r") || ('' + textToValidate).includes("\\n");
    }

    static validateEmail(email: string): boolean {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    static isValueIncludedInArray(fieldValue: any, values: any[]) {

        for (const value of values) {
            if (Utils.trim(fieldValue) === Utils.trim(value)) {
                return true;
            }
        }
        return false;
    }

    static isTrue(toCheck: any): boolean {
        return !Utils.isEmpty(toCheck) && (toCheck === true || toCheck === "TRUE" || toCheck === "true" || toCheck === 1);
    }

    static isFalse(toCheck: any): boolean {
        return !Utils.isEmpty(toCheck) &&
            (toCheck === false || toCheck === "FALSE" || toCheck === "false" || toCheck === 0);
    }

    static getKeySize(toCheck: any): number {
        let counter = 0;
        for (const key in toCheck) {
            counter++;
        }
        return counter;
    }

    static getIds(items: any[]): string[] {

        const ids: string[] = [];
        for (const item of items) {
            if (!Utils.isEmpty(item) && !Utils.isEmpty(Utils.getId(item))) {
                ids.push(Utils.getId(item));
            }
        }
        return ids;
    }

    static getId(item: any): string {
        return item["ID"];
    }

    static isObjectEqual(object1: any, object2: any){
        if(lodash.isEqual(object1, object2)){
            return true;
        }
        return false
    }
    static shadeColor(color, percent) {
        let R = parseInt(color.substring(1,3),16);
        let G = parseInt(color.substring(3,5),16);
        let B = parseInt(color.substring(5,7),16);
        
        R = (R * (100 + percent) / 100);
        G = (G * (100 + percent) / 100);
        B = (B * (100 + percent) / 100);
        
        R = Math.round((R<255)?R:255);
        G = Math.round((G<255)?G:255);
        B = Math.round((B<255)?B:255);
    
        const RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        const GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        const BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
    
        return "#"+RR+GG+BB;
    }
    static clientSideFiltering(propertyData, filters) {
        let data = []
        const operators = {
          "==": function (x, y) {
            return x === y;
          },
          "!=": function (x, y) {
            return x !== y;
          },
          ">": function (x, y) {
            return x > y;
          },
          "<": function (x, y) {
            return x < y;
          },
          "<=": function (x, y) {
            return x <= y;
          },
          ">=": function (x, y) {
            return x >= y;
        }}
  
        if(filters && filters.length) {
          for (const filter of filters) {
            for (const row of propertyData) {
              const checkedValue = operators[filter.operator](row[filter.field],filter.value);
              if(checkedValue) {
                data.push(row)
              }
            }
          }
        } else {
            data = propertyData
        }
        return data
    }
}