import db from "../../db";
import store from "../../store";
import { Timestamp } from '@firebase/firestore';
import {asyncFor, asyncForEach} from '@/common/helpers/async-for-each';
import ElasticModel from "@/components/Models/ElasticModel";
import RBAC from "@/components/Settings/Sections/UserManagement/RoleManagement/Models/RBAC";
import Vue from "vue";
import SelectModel from "@/components/Models/SelectModel";

export class User {
  ID = "";
  name = "";
}

export class Fields {
  ID = "";
  obsolete = false;
  createdAt: Timestamp = Timestamp.now();
  changedAt: Timestamp = Timestamp.now();
  createdBy: User = JSON.parse(JSON.stringify(new User()));
  changedBy: User = JSON.parse(JSON.stringify(new User()));
  name = "";
  active = true;
  locked = false;
  _form_uid = ''; // it's for check onDocumentDataChanged from this form or external
  status = '';
}

export default class AbModel {
  module = "";
  ID = "";

  data = { ID: "" }; // main data
  locked = false;
  relations = {};
  relationsBy = {};
  dynModuleData = {};
  dynamicFields = {};
  autoNumbers = {};
  calculatedFields = {};
  readOnlyFields = {};
  dynamicSections = {};
  subFormField = '';
  subFormFieldData = {
    dynamic: true,
    section: {},
    sections: {},
    fields: {}
  };
  guard: any = null;

  constructor() {
    this.guard = new RBAC();
    
  }

  fields(): Fields {
    const f = new Fields();
    return f;
  }

  dynFields() {
    return this.dynamicFields;
  }

  dynAutoNumbers() {
    return this.autoNumbers;
  }

  dynCalcFields() {
    return this.calculatedFields;
  }

  readOnlyFieldsFunc() {
    return this.readOnlyFields;
  }

  dynSections() {
    return this.dynamicSections;
  }

  dynModule() {
    return this.dynModuleData;
  }

  dynReadOnlyFields() {
    return { ... this.dynCalcFields(), ... this.dynAutoNumbers(), ... this.readOnlyFieldsFunc() }
  }

  getLocked() {
    return this.locked;
  }

  // module fields info
  dropDownValues = {};

  tenant(module: any = null): string {
    const lmodule = module ? module : this.module;
    return "tenants/" + store.state.tenantID + "/modules/" + lmodule + "/records";
  }

  tenantForDeleted(module: any = null): string {
    const lmodule = module ? module : this.module;
    return "tenants/" + store.state.tenantID + "/modules/" + lmodule + "/deletedRecords";
  }

  async set(dataDoc, callBack = (dataID: string) => {}) {
    return new Promise((resolve, reject)  => {
      const data = { ...dataDoc };
      this.removeReadOnlyFields(data);
      const newDoc = db.collection(this.tenant()).doc();
      data.ID = newDoc.id;
      // 
      this.setCreated(data);
      if (store.state.useElasticRelatedQuery) {
        const em = new ElasticModel(this.module);
        em.update(data).then((res) => {
          

          // error
          if (res.data && !res.data.success) {
            console.error(res.data.error);
            reject(res.data.error);
            return false;
          }

          try {
            // 
            newDoc.set(data).then(() => {
              
              if (callBack) {
                callBack(data.ID);
              }
              resolve(data.ID);
            }).catch((e) => {
              em.delete(data.ID)
              console.log('firestore error - delete the elastic');
              console.log(e);
              reject(e);
            });
          } catch (e) {
            em.delete(data.ID)
            console.log('firestore error - delete the elastic');
            console.log(e);
            reject(e);
          }

        });
      } else {
        newDoc.set(data).then(() => {
          if (callBack) {
            callBack(data.ID);
          }
          resolve(data.ID);
        }).catch((e) => {
          reject(e);
        });
      }
    });
  }

  async update(id, dataDoc, callBack = (dataID: string) => {}) {

    const data = { ...dataDoc };
    this.removeReadOnlyFields(data);
    this.setUpdated(data);
    const doc = db.collection(this.tenant()).doc(id);
    const oldDoc = await db.collection(this.tenant()).doc(id).get();
    const oldData = oldDoc ? oldDoc.data() : null;

    return new Promise((resolve, reject) => {
      if (store.state.useElasticRelatedQuery) {
        const em = new ElasticModel(this.module);
        em.update(data).then((res) => {
          
          if (res.data && !res.data.success) {
            console.error(res.data.error);
            reject(res.data.error);
            return false;
          }

          try {
            
            doc.update(data).then(() => {
              if (callBack) {
                callBack(data.ID);
              }
              resolve(data.ID);
            }).catch((e) => {
              if (oldData) {
                em.update(oldData);
                console.log('firestore error - rollback elastic update');
              }
              console.log(e.error);
              reject(e);
            });
          } catch (e) {
            if (oldData) {
              em.update(oldData);
              console.log('firestore error - rollback elastic update');
            }
            console.log(e);
            reject(e);
          }

        });
      } else {
        doc.update(data).then(() => {
          if (callBack) {
            callBack(data.ID);
          }
          resolve(data.ID);
        }).catch((e) => {
          reject(e);
        });
      }
    });
  }

  async save(data, callBack = () => {}) {
    data = await this.checkSelectUser(data);
    if (data.ID) {
      return this.update(data.ID, data, callBack);
    } else {
      return this.set(data, callBack);
    }
  }

  async checkSelectUser(data) {
    if (this.module !== 'appointments') {
      return data;
    }
    const resourcesModule = store.state.settings.planboardSchedulerSettings.resourcesModule || 'users';
    const resourcesModuleField = store.state.allModules[resourcesModule].singularName || 'user';
    if (data.status !== SelectModel.workOrderPhase.toBePlanned) {
      const user = data[resourcesModuleField];
      if (!user || !user.ID) {
        const users = data[resourcesModule].filter(u => u);
        if (users && users[0]) {
          const fullUser = await this.getByID(users[0], resourcesModule);
          const userName = fullUser ? fullUser.name : '';
          data[resourcesModuleField] = {ID: users[0], name: userName};
        }
      }
    }
    return data;
  }

  setCreated(data) {
    this.setUpdated(data);
    data.createdAt = Timestamp.now();
    if (store.state.currentUser && store.state.currentUser.ID && store.state.currentUser.name) {
      data.createdBy = store.state.currentUser;
    } else {
      data.createdBy = this.convertJson(new User());
    }
    if (store.state.PORTAL) {
      data.portalRecord = true;
    }
  }

  setUpdated(data) {
    data.changedAt = Timestamp.now();
    if (store.state.currentUser && store.state.currentUser.ID && store.state.currentUser.name) {
      data.changedBy = store.state.currentUser;
    } else {
      data.changedBy = this.convertJson(new User());
    }
  }

  async delete(id: string, module = "") {
    const lmodule = module ? module : this.module;
    const doc = db.collection(this.tenant(lmodule)).doc(id);
    const docData = (await doc.get()).data();

    if (docData.locked) {
      return 'locked';
    }

    if (store.state.useElasticRelatedQuery) {
      const em = new ElasticModel(lmodule);
      const res = await em.delete(id);

      // error
      if (res.data && !res.data.success) {
        console.error(res.data.error);
        //return res.data;
      }

    }
    if (store.state.softDelete) {
      const dataToSave = await doc.get();
      if (dataToSave) {
        await db.collection(this.tenantForDeleted(lmodule)).doc(id).set(dataToSave.data());
      }
    }
    await this.deleteSubRecords(id, lmodule);
    return doc.delete();
  }

  async deleteSubRecords(id, lmodule) {
    const subRecords = {};
    const doc = await this.getFieldsInfoDoc(lmodule);

    if (doc && doc.sections) {
      for (const subRecord in subRecords) {
        const table = this.tenant(lmodule) +  '/' + id + '/' + subRecord;
        const tableDeleted = this.tenantForDeleted(lmodule) +  '/' + id + '/' + subRecord;
        subRecords[subRecord].table = table;
        subRecords[subRecord].tableDeleted = tableDeleted;
        const collection = db.collection(table);
        const result = await collection.get();
        result.forEach((doc) => {
          const rec = doc.data();
          subRecords[subRecord].values.push(rec);
        });
      }
    }

    if (store.state.softDelete) {
      await asyncFor(subRecords, async (subRecord) => {
        await asyncForEach(subRecords[subRecord].values, async (f) => {
          const doc = db.collection(subRecords[subRecord].table).doc(f.ID);
          const dataToSave = await doc.get();
          await db.collection(subRecords[subRecord].tableDeleted).doc(f.ID).set(dataToSave.data());
        });
      });
    }

    await asyncFor(subRecords, async (subRecord) => {
      await asyncForEach(subRecords[subRecord].values, async (f) => {
        const doc = db.collection(subRecords[subRecord].table).doc(f.ID);
        await doc.delete();
      });
    });

  }

  async getByID(id: string, module: string) {
    const doc = await db.collection(this.tenant(module)).doc(id).get();
    
    return doc.data();
  }

  async subscribeDoc(id: string, module: string, sfunc: Function) {
    return await db.collection(this.tenant(module)).doc(id).onSnapshot(function(doc) {
      sfunc(doc.data());
    });
  }

  convertToObject(data) {
    const saveTs = {};

    for (const f in data) {
      if (data[f] && typeof data[f].toDate === "function") {
        saveTs[f] = data[f];
      }
    }

    const dataRes = JSON.parse(JSON.stringify(data));

    for (const f in saveTs) {
      dataRes[f] = saveTs[f];
    }

    return dataRes;
  }

  removeReadOnlyFields(data) {
    const afields = this.dynReadOnlyFields();
    
    for (const af in afields) {
      if (Object.prototype.hasOwnProperty.call(data, af)) {
        delete data[af];
      }
    }
  }

  convertJson(data) {
    data = JSON.parse(JSON.stringify(data));
    return data;
  }

  async load(id: string) {
    await this.getModuleFieldsInfo();
    if (id) {
      this.ID = id;
      const data = await this.getByID(id, this.module);
      this.locked = data !== undefined ? data.locked : false;
      return data;
    }
    return null;
  }

  setDynModuleName(module) {
    this.module = module;
  }

  setSubFormField(subFormField) {
    this.subFormField = subFormField;
  }

  setSubFormFieldData(subFormFieldData) {
    this.subFormFieldData = subFormFieldData;
  }

  async getFieldsInfoDoc(module = '') {
    const lmodule = module ? module : this.module;
    const firePath = "tenants/" + store.state.tenantID + "/modules/";
    const result: any = await db.collection(firePath).doc(lmodule).get();
    const docModule = result.data();
    return docModule;
  }

  getDynamicSections(docModule) {
    return docModule.sections || {};
  }

  localDropDownValues(module, dropDownValues) {
    if (module === 'appointments' && SelectModel.appointmentStatusOverWritten) {
      dropDownValues.status = SelectModel.appointmentStatusValues;
      
    }
  }


  async getModuleFieldsInfo() {
    this.dropDownValues = {};
    this.autoNumbers = {};
    this.calculatedFields = {};
    this.readOnlyFields = {};

    const doc = await this.getFieldsInfoDoc();
    

    if (doc) {

      if (doc.dropDownValues) {
        this.dropDownValues = doc.dropDownValues;
      }

      this.localDropDownValues(this.module, this.dropDownValues);

      this.dynModuleData = doc;
      this.dynamicSections = {};

      if (doc.sections) {
        for (const secName in doc.sections) {
          const dSec = { ...doc.sections[secName] }
          dSec.locked = 0;
          Vue.set(this.dynamicSections, secName, dSec);
        }
      }

      if (doc.fields) {
        for (const field in doc.fields) {
          // dynamic fields
          const dField = { ...doc.fields[field] }
          // set related to field
          dField.relatedToByQuery = null;
          if (dField.type === 'relatedField' && dField.query) {
            dField.query.forEach((f) => {
              if (f.relatedTo) {
                dField.relatedToByQuery = f;
              }
            });
          }

          // auto numbers
          if (dField.type === 'autoNumber') {
            this.autoNumbers[field] = dField;
          }

          if (dField.type === 'calculatedField') {
            this.calculatedFields[field] = dField;
          }
          if(dField.readOnly) {
            this.readOnlyFields[field] = dField;
          }
          dField.locked = 0;
          Vue.set(this.dynamicFields, field, dField);
        }
      }
    }
  }

  convertToTimeStamp(data, dfields) {
    for (const f in data) {
      if (
        data[f] &&
        dfields[f] &&
        dfields[f] instanceof Date &&
        !(typeof data[f].toDate === "function")
      ) {
        data[f] = Timestamp.fromMillis(
          Date.parse(data[f])
        );
      }
    }
  }

  // static methods

  static tenantState(module: any = null): string {
    return "tenants/" + store.state.tenantID + "/modules/" + module + "/records";
  }

  static async doesRecordExists(id: string, module: string) {
    const doc = await db.collection(this.tenantState(module)).doc(id).get();
    return doc.exists;
  }

  static async saveDoc(path: string, data) {
    const firePath = path.includes('tenants/') ? path : "tenants/" + store.state.tenantID + "/" + path;
    if (data.ID) {
      return await db.collection(firePath).doc(data.ID).update(data);
    } else {
      const newDoc = db.collection(firePath).doc();
      data.ID = newDoc.id;
      return await newDoc.set(data);
    }
  }

  static async getDoc(path: string, ID: string) {
    const firePath = path.includes('tenants/') ? path : "tenants/" + store.state.tenantID + "/" + path;
    const doc = await db.collection(firePath).doc(ID).get();
    return doc.data();
  }

  static async deleteDoc(path: string, data) {
    const firePath = path.includes('tenants/') ? path : "tenants/" + store.state.tenantID + "/" + path;
    if (data.ID) {
      return await db.collection(firePath).doc(data.ID).delete();
    }
  }

  static async getModuleAllDocs(module: string) {
    const path = "tenants/" + store.state.tenantID + "/modules/" + module + "/records";
    const res = await db.collection(path).get();
    const docs = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    return docs;
  }

  static async getAllDocs(path: string) {
    const firePath = path.includes('tenants/') ? path : "tenants/" + store.state.tenantID + "/" + path;
    const res = await db.collection(firePath).get();
    const docs = [];
    res.forEach((doc) => {
      const rec: any = doc.data();
      rec.id = doc.id;
      (docs as any).push(rec as any);
    });
    return docs;
  }

}
