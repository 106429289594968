export class FieldType {
    name = "Bij het wijzigen van een of meerdere velden";
    type = "FieldTrigger";
}

export class DeleteType {
    name = "Verwijderen van record";
    type = "DeleteTrigger";
}

export default class Types {
    availableTriggers = [
        new  FieldType, new DeleteType
    ];
}