import {FieldType} from "../../Models/FieldType";
import * as numeral from 'numeral';
import { PercentageField } from '../../Models/FieldTypes/PercentageField';
import { PercentageFieldValue } from "../../Models/FieldValueTypes/PercentageFieldValue";
import Column from "../../Models/Column";
import store from "@/store";
import { TableType } from "@/components/Table/Models/TableType";

export default class PercentageFieldImpl implements PercentageField {
    name: string;
    ID: string;
    column: number;
    order: number;
    mandatory: boolean | null | undefined;
    section: string;
    disabled: boolean | null | undefined;
    readOnly: boolean | null | undefined;
    hidden: boolean | null | undefined;
    type: FieldType;
    label: string;
    defaultValue: PercentageFieldValue | null;

  constructor(
    field: PercentageField,
) {
    this.name = field.name
    this.ID = field.ID
    this.column = field.column
    this.order = field.order
    this.mandatory = field.mandatory
    this.section = field.section
    this.disabled = field.disabled
    this.readOnly = field.readOnly
    this.hidden = field.hidden
    this.type = field.type
    this.label = field.label
    this.defaultValue = field.defaultValue
  }

    canAggregate(type): boolean {
        if(this.name === 'marginPercentage' && type == TableType.PRODUCT_LINES) {
            return true
        }
        return false;
    }

    getColumn(column: any, moduleID: string, typeIsRelatedRecords?: boolean): any {
        const baseColumn = new Column().getBaseColumn()
        const additionalProperties = {
            field: column.name,
            headerName: this.getLabel(moduleID),
            cellEditor: 'decimalFieldEditor',
            filter: 'agNumberColumnFilter',
            filterParams: {
                defaultOption: 'equals',
                numberParser: (text: string) => {
                    if(typeIsRelatedRecords) {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    } else {
                        return text == null ? null : parseFloat(text.replace(',', '.'))*10000;
                    } 
                }
            },
            cellRenderer: 'baseFieldRenderer',
            editable: !this.disabled && !this.readOnly && !this.hidden ? true : false,
        }
        if(column.name === 'marginPercentage' && !typeIsRelatedRecords) {
            additionalProperties['aggFunc'] = 'sum'
        }
        return {
            ...baseColumn,
            ...additionalProperties
        }
    }

    getLabel(moduleID: string) {
        if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
            return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
          }
        return this.label
    }

    getFormattedValue(value: any, moduleID?: string) {
        let res: any = Number((value / 10000).toFixed(2));
        res = numeral(res).format('0.00') + '%';
        return res;
    }

    getUnformattedValue(value: any) {
        const originalValue = numeral(value)._value;
        return (originalValue * 10000)
    }
   
    getFieldType(): FieldType {
        return FieldType.PERCENTAGE
    }

}
