
  import {Component, Vue} from "vue-property-decorator";
  import {State, Action} from "vuex-class";
  import functions from '@/functions';
  import store from '@/store';
  import QPdfviewer from '@/components/Settings/Sections/Templates/PDFgenerator/Helpers/QPdfviewer';
  import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
  import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
  import sortList from "@/common/helpers/utilities";

  class CurrentTemplateClass {
    id = null
    tags: Array<string> = []
  }

  class SelectedRecordClass {
    ID = "";
    name = "";
  }

  @Component({
    components: {QPdfviewer, BaseFieldRelated}
  })
  export default class PdfTemplates extends Vue {
    @State("tenantID") tenantID;
    @State("dynamicModules") dynamicModules;
    @Action("settings/setPDFTemplateData") setPDFTemplateData;
    @Action("settings/getPDFTemplatesData") getPDFTemplatesData;

    templates = [];
    columns = [
      {
        name: 'name',
        label: this.$t('pdf.headers.name'),
        field: 'name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'tags',
        label: this.$t('pdf.headers.module'),
        field: 'tags',
        align: 'left',
        sortable: true,
      },
      {
        name: 'filename',
        label: this.$t('pdf.headers.filename'),
        field: 'filename',
        align: 'left',
        sortable: true,
      },
      {
        name: 'editor',
        label: '',
        field: 'editor',
        align: 'center',
      },
      {
        name: 'delete',
        label: '',
        field: 'delete',
        align: 'center',
      },
      {
        name: 'copy',
        label: '',
        field: 'copy',
        align: 'center',
      },
      {
        name: 'modified',
        label: this.$t('pdf.headers.changedAt'),
        field: 'modified',
        align: 'center',
        sortable: true,
      }
    ];
    pagination = {
      rowsPerPage: 20
    };
    loading = false;
    showCreateDialog = false;
    showDeleteDialog = false;
    showCopyDialog = false;
    newTemplateName = '';
    showModalEditor = false;
    showSelectRecordDialog = false;
    editorLink = '';
    currentTemplate = new CurrentTemplateClass();
    selectedModule = '';
    selectedRecord = new SelectedRecordClass();
    formData = {};
    record = {};
    sortedModules = [];
    templateModule = {}
    get relatedModule() {
      return this.currentTemplate.tags[0];
    }

    async mounted() {
      this.templateModule = this.modulesList[0]
      this.loading = true;
      await this.getAllTemplates();
      this.loading = false;
    }

    copyToClipboard(ID: string) {
      navigator.clipboard.writeText(ID);
    }

    setFileName(props) {
      if (props.row.filename !== undefined) {
        this.setPDFTemplateData({
          id: props.row.id,
          filename: props.row.filename
        });
      }
    }

    async getFormData() {
      this.formData = await PdfTools.getFormDataForPDF(this.currentTemplate.tags[0]);
    }

    async openEditor(props) {
      this.currentTemplate = props.row;
      
      if (this.currentTemplate.tags[0]) {
        await this.getFormData();
        this.showSelectRecordDialog = true;
      } else {
        this.onTemplateEdit()
      }
    }

    onClickDeleteBtn(props){
      this.showDeleteDialog = true;
      this.currentTemplate = props.row;
    }
    onClickCopyBtn(props){
      this.showCopyDialog = true;
      this.currentTemplate = props.row;
    }

    async getAllTemplates(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.curTenantID,
        request: 'getAllTemplates'
      };

      await generatePDF(options).then((res: any) => {
        const data = res.data;
        this.getPDFTemplatesData().then((res) => {
          const templates = []
          this.templates = data.map((t: any) => { 
            if (t && res && t.tags && t.tags.includes(this.templateModule['value'])) {
              t.filename = res[String(t.id)] ? res[String(t.id)].filename : '';
              templates.push(t)
              return t;
            }
          });
          this.templates = templates
        });
      }).catch(error => {
        console.log('error: ', error);
        this.templates = [];
      });
    }

    async onTemplateCreate(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.curTenantID,
        name: this.newTemplateName,
        tag: this.selectedModule['value'],
        request: 'createTemplate'
      };
      this.newTemplateName = '';
      this.selectedModule = '';

      await generatePDF(options).then(() => {
        
        this.getAllTemplates()
      }).catch(error => {
        console.log('error: ', error);
      });
    }

    async onRecordSelect() {
      const formData = await PdfTools.getFormDataForPDF(this.currentTemplate.tags[0], this.selectedRecord.ID);
      const inputJson = await PdfTools.getInputJsonForPDF(formData);
      this.selectedRecord = new SelectedRecordClass;
      
      this.onTemplateEdit(inputJson)
    }

    async onTemplateEdit(inputJson = ''): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.curTenantID,
        templateID: this.currentTemplate.id,
        language: 'en',
        inputJSON: inputJson,
        request: 'openEditor'
      };
      this.currentTemplate = new CurrentTemplateClass();

      await generatePDF(options).then((res: any) => {
        const data = res.data;
        // 
        this.showModalEditor = true;
        this.editorLink = data
      }).catch(error => {
        console.log('error: ', error);
      });
    }

    async onTemplateDelete(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.curTenantID,
        templateID: this.currentTemplate.id,
        request: 'deleteTemplate'
      };
      await generatePDF(options).then(() => {
        
        this.getAllTemplates()
      }).catch(error => {
        console.log('error: ', error);
      });
      this.currentTemplate = new CurrentTemplateClass();
    }

    async onTemplateCopy(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.curTenantID,
        templateID: this.currentTemplate.id,
        name: this.newTemplateName,
        request: 'copyTemplate'
      };
      this.newTemplateName = '';

      await generatePDF(options).then(() => {
        
        this.getAllTemplates()
      }).catch(error => {
        console.log('error: ', error);
      });
      this.currentTemplate = new CurrentTemplateClass();
    }

    canEditTemplate(props) {
      const template = props.row;
      return template && template.owner;
    }

    onCancelCreateDialog(){
      this.newTemplateName = '';
      this.selectedModule = '';
    }

    onCancelSelectedRecordDialog(){
      this.selectedRecord = new SelectedRecordClass();
      this.currentTemplate = new CurrentTemplateClass();
    }

    onCancelCopyDialog(){
      this.newTemplateName = '';
      this.currentTemplate = new CurrentTemplateClass();
    }

    onCancelDeleteDialog(){
      this.currentTemplate = new CurrentTemplateClass();
    }

    get initialized() {
      return store.state.initialization;
    }

    get curTenantID() {
      return this.tenantID;
    }

    get modulesList() {
      const modulesList = Array<{ label: string; value: string }>();
      Object.keys(this.dynamicModules).forEach((module) => {
        modulesList.push({
          label: this.getModuleTitle(module),
          value: module,
        });
      });
      return sortList(modulesList, 'label');
    }

    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    }

    getModuleTitle(moduleID) {
      const translationKey = `modules.${moduleID}.pluralName`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        return store.state.fieldTranslations[translationKey]
      }
      const moduleTitle = this.dynamicModules && this.dynamicModules[moduleID] ? this.dynamicModules[moduleID] : {} ;
      return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : moduleID
    }

    goToCustomTranslations() {
      this.$router.push({name: 'custom-translations'})
    }

  }
