
import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import sortList from "@/common/helpers/utilities";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";

@Component({
  components: { DynamicFieldSingle },
})
export default class RelatedFieldCopyTable extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) existingRelatedFields;
  @Prop({ required: true }) fieldOptions;
  @Prop({ required: true }) relatedField;
  @Prop({ required: true }) module;

  // Delete function to delete the related field copy from the table
  deleteRelatedFieldCopy(relatedField, sourceField, index) {
    const field = this.dynamicModules[
      this.relatedField.selectedField["relatedModule"]
    ].fields[sourceField.value];

    this.fieldOptions.push({
      label: field.label,
      value: field.name,
      type: field.type,
      fieldType: field.fieldType,
      index: 0,
    });
    this.fieldOptions = sortList(this.fieldOptions, 'label');
    let i = 0;
    for (const field in this.fieldOptions) {
      this.fieldOptions[field].index = i;
      i++;
    }
    this.existingRelatedFields.splice(index, 1);
  }
}
