import {addIDToDocData, addSystemFieldsToDeleteDoc, addSystemFieldsToDocData, createFirestoreDocs, deleteFirestoreDocs, getFirestoreDocData, setFirestoreDocData} from "@/helpers/FirestoreHelper"
import store from "@/store";
import { defineStore } from "pinia";
import DatabaseResponse from "../Models/DatabaseResponse";
import { createOrUpdateDatabaseRecord, deleteDatabaseRecord, deleteDatabaseRecords, getDataForDeletedRecords } from "../Helpers/RecordsStoreHelper";

export const useRecordsStore = defineStore("records", {
    state: (): {

    } => ({
    }),
    getters: {
    },
    actions: {
      async createDoc(data: any, moduleID: string): Promise<DatabaseResponse>  {
        data = addSystemFieldsToDocData('create', data)
        data = addIDToDocData(data)
        const oldData = null
        const databaseResponse = await createOrUpdateDatabaseRecord(moduleID, data, oldData)
        return databaseResponse
      },
      async updateDoc(data: any, moduleID: string, docID: string): Promise<DatabaseResponse> {
        data = addSystemFieldsToDocData('update', data)
        data.ID = docID
        const oldDoc = await this.getDoc(moduleID, docID)
        const oldData = oldDoc ? oldDoc : null
        const databaseResponse = await createOrUpdateDatabaseRecord(moduleID, data, oldData)
        return databaseResponse
      },
      async updateDocs(records: any, moduleID: string) {
        const dataToSave = []
        for(const record of records) {
          const data = addSystemFieldsToDocData('update', record)
          data.ID = record.ID
          dataToSave.push(data)
        }
        await createFirestoreDocs(`tenants/${store.state.tenantID}/modules/${moduleID}/records`, dataToSave)
      },
      async getDoc(moduleID: string, docID: string): Promise<any> {
        const record = await getFirestoreDocData(`tenants/${store.state.tenantID}/modules/${moduleID}/records/${docID}`)
        return record.data[0]
      },
      async deleteDoc(moduleID: string, docID: string): Promise<DatabaseResponse> {
        let docData = await this.getDoc(moduleID, docID)
        docData = addSystemFieldsToDeleteDoc(docData)
        await setFirestoreDocData(`tenants/${store.state.tenantID}/modules/${moduleID}/deletedRecords`, docData)
        const deleteResponse = await deleteDatabaseRecord(moduleID, docID)
        return deleteResponse
      },
      async deleteDocs(moduleID: string, docIDs: string[]): Promise<DatabaseResponse> {
        const docsToCreate = await getDataForDeletedRecords(docIDs, moduleID)
        await createFirestoreDocs(`tenants/${store.state.tenantID}/modules/${moduleID}/deletedRecords`, docsToCreate)
        const deleteResponse = await deleteDatabaseRecords(moduleID, docIDs)
        return deleteResponse
      }
    }
  });
  
  export default useRecordsStore;
  