import RoleManagement from '@/components/Settings/Sections/UserManagement/RoleManagement/Components/RoleManagement.vue'
import RolesEdit from "@/components/Settings/Sections/UserManagement/RoleManagement/Components/RolesEdit.vue"
import RolesAdd from "@/components/Settings/Sections/UserManagement/RoleManagement/Components/RolesAdd.vue"

export default [
    {
        path: "/settings/role-management",
        name: "role-management",
        component: RoleManagement
    },
    {
        path: "/settings/role-management/edit/:id",
        name: "role-management-edit",
        component: RolesEdit
    },
    {
        path: "/settings/role-management/add",
        name: "role-management-add",
        component: RolesAdd,
    },
]