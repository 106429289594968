import {FieldType} from "../../Models/FieldType";
import store from "@/store";
import { Timestamp } from '@firebase/firestore';
import {date} from "quasar";
import { DateTimeField } from '../../Models/FieldTypes/DateTimeField';
import { DateTimeFieldValue } from "../../Models/FieldValueTypes/DateTimeFieldValue";
import Column from "../../Models/Column";

export default class DateTimeFieldImpl implements DateTimeField {

  name: string;
  ID: string;
  column: number;
  order: number;
  mandatory: boolean | null | undefined;
  section: string;
  disabled: boolean | null | undefined;
  readOnly: boolean | null | undefined;
  hidden: boolean | null | undefined;
  type: FieldType;
  label: string;
  defaultValue: DateTimeFieldValue | string | null
  constructor(
    field: DateTimeField,
  ) {
    this.name = field.name
    this.ID = field.ID
    this.column = field.column
    this.order = field.order
    this.mandatory = field.mandatory
    this.section = field.section
    this.disabled = field.disabled
    this.readOnly = field.readOnly
    this.hidden = field.hidden
    this.type = field.type
    this.label = field.label
    this.defaultValue = field.defaultValue
  }

    canAggregate(): false {
      return false;
    }

    getColumn(column: any, moduleID: string): any {
      const baseColumn = new Column().getBaseColumn()
      const additionalProperties = {
          field: column.name,
          headerName: this.getLabel(moduleID),
          editable: !this.disabled && !this.readOnly && !this.hidden && this.section ? true : false,
          cellRenderer: 'baseFieldRenderer',
          cellEditor: 'dateTimeFieldEditor',
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = this.getFormattedValue(cellValue)
              
              if (dateAsString == null) {
                  return 0;
              }

              const dateParts = dateAsString.split('-');
              const year = Number(dateParts[2].split(' ')[0]);
              const month = Number(dateParts[1]) - 1;
              const day = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);

              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
              }
              return 0;
          }
          }
      }
      return {
          ...baseColumn,
          ...additionalProperties
      }
    }

    getLabel(moduleID: string) {
      if(store.state.fieldTranslations && store.state.fieldTranslations[moduleID + '.fields.' + this.name]) {
          return store.state.fieldTranslations[moduleID + '.fields.' + this.name]
        }
      return this.label
    }

    getFormattedValue(value: any, moduleID?: string) {
        const dataMask = store.state.dateTimeMask;
        let res = value;
        if (value && typeof value.toDate === "function") {
          res = date.formatDate(value.toDate(), dataMask);
          return res
        } else if (value && value['seconds"']) {
          const ts = Timestamp.fromMillis(value['seconds"'] * 1000);
          res = date.formatDate(ts.toDate(), dataMask);
          return res
        } else if(typeof value === 'string') {
          res = value
          return res
        } else if (value && value['seconds"'] == null) {
          res = '-'
        }
        return res;
    }

    getUnformattedValue(value: any) {
      return this.toTimestampValue(value)
    }

    toTimestampValue(val) {
      if (store.state.dateTimeMask === "DD-MM-YYYY HH:mm") {
        val = date.extractDate(val, "DD-MM-YYYY HH:mm");
        val = date.formatDate(val, "YYYY-MM-DD HH:mm");
      }
      return Timestamp.fromMillis(Date.parse(val));
    }

    getFieldType(): FieldType {
        return FieldType.DATETIME
    }

}
