import { render, staticRenderFns } from "./addCreatePDF.vue?vue&type=template&id=0c844d8c&scoped=true&"
import script from "./addCreatePDF.vue?vue&type=script&lang=ts&"
export * from "./addCreatePDF.vue?vue&type=script&lang=ts&"
import style0 from "./addCreatePDF.vue?vue&type=style&index=0&id=0c844d8c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c844d8c",
  null
  
)

export default component.exports
import {QCardSection,QInput,QSelect,QItem,QItemSection,QItemLabel} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection,QInput,QSelect,QItem,QItemSection,QItemLabel})
