
  import { mapState } from "vuex";
  import BaseExtend from "@/components/Base/Mixin/BaseExtend";

  export default {
    name: "BaseFieldCurrency",
    mixins: [ BaseExtend ],
    data() {
      return {
        cModel: Math.round(this.value / 100),
        refreshKey: 1,
      };
    },
    methods: {
      refreshAfterCopy(value) {
        this.cModel = value / 100;
        this.refreshKey++;
      },
    },
    watch: {
      cModel(newValue) {
        if (!newValue) {
          newValue = '0';
          // TODO refactoring to get by names
          // eslint-disable-next-line
          // @ts-ignore: Unreachable code error
          if (this.$refs[this.name]) {
            this.$refs[this.name].$el.getElementsByTagName('input')[0].value = null;
          }
        }
        let value = String(newValue).replace(/[^0-9-]/g, '') || '0';
        value = Number(value) * 100;
        this.$emit('input', value);
      }
    },
    computed: {
      ...mapState(["moneyFormat"]),
      getKey() {
        return (this.name ?? 'element') + this.refreshKey;
      }
    }
  };
