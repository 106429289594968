import { render, staticRenderFns } from "./addFieldUpdate.vue?vue&type=template&id=70da34e4&"
import script from "./addFieldUpdate.vue?vue&type=script&lang=ts&"
export * from "./addFieldUpdate.vue?vue&type=script&lang=ts&"
import style0 from "./addFieldUpdate.vue?vue&type=style&index=0&id=70da34e4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCardSection,QInput,QSelect,QItem,QItemSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection,QInput,QSelect,QItem,QItemSection})
