import ElasticModel from "@/components/Models/ElasticModel";
import DatabaseResponse from "../Models/DatabaseResponse";
import { addSystemFieldsToDeleteDoc, deleteFirestoreDoc, deleteFirestoreDocs, getFirestoreDocData, setFirestoreDocData } from "@/helpers/FirestoreHelper";
import store from "@/store";


export async function createOrUpdateDatabaseRecord(moduleID: string, data: any, oldData: any): Promise<DatabaseResponse> {
    const elasticModel = new ElasticModel(moduleID);
    try {
        const res = await elasticModel.update(data);
        if (res.data && !res.data.success) {
            console.error('elastic-error: ', res.data.error);
            const response = res.data as DatabaseResponse;
            return response;
        }
        try {
            const dbResponse = await setFirestoreDocData(`tenants/${store.state.tenantID}/modules/${moduleID}/records`, data);
            return dbResponse;
        } catch (error) {
            if (oldData) {
                await elasticModel.update(oldData);
                console.log('firestore error - rollback elastic update');
            }
            const response = error as DatabaseResponse;
            return response;
        }
    } catch (error) {
        const response = error as DatabaseResponse;
        return response;
    }
}


export async function deleteDatabaseRecord(moduleID: string, docID: string): Promise<DatabaseResponse> {
    const elasticModel = new ElasticModel(moduleID);
    try {
        const res = await elasticModel.delete(docID);
        if (res.data && !res.data.success) {
            console.error('elastic-error: ', res.data.error);
            const response = res.data as DatabaseResponse;
            return response;
        }

        const dbResponse = await deleteFirestoreDoc(`tenants/${store.state.tenantID}/modules/${moduleID}/records/${docID}`);
        return dbResponse;
    } catch (error) {
        const response = error as DatabaseResponse;
        return response;
    }
}

export async function deleteDatabaseRecords(moduleID: string, docIDs: string[]): Promise<DatabaseResponse>  {
    const elasticModel = new ElasticModel(moduleID);
    const promises = []
    for(const docID of docIDs) {
        promises.push(elasticModel.delete(docID))
    }
    const elasticResponse = await Promise.all(promises).catch((error) => {
        const response = error as DatabaseResponse;
        return response;
    })
    if(elasticResponse && elasticResponse['error']) return elasticResponse as DatabaseResponse;

    try {
        const dbResponse = await deleteFirestoreDocs(`tenants/${store.state.tenantID}/modules/${moduleID}/records`, docIDs)
        return dbResponse;
    } catch (error) {
        const response = error as DatabaseResponse
        return response
    }
}

export async function getDataForDeletedRecords(docIDs: string[], moduleID: string) {
    const docsToSave = []
    for (const docID of docIDs) {
        const records = await getFirestoreDocData(`tenants/${store.state.tenantID}/modules/${moduleID}/records/${docID}`)
        let data = records.data[0]
        data = addSystemFieldsToDeleteDoc(data)
        docsToSave.push(data)
    }
    return docsToSave
}