
import { Component, Prop, Vue } from "vue-property-decorator";


@Component({
  components: {},
})

export default class Dialog extends Vue {
    [x: string]: any;
    @Prop() currentTemplate;
    @Prop({required: true}) cardTitle;
    @Prop({required: true}) cardSubHeader;
    deletionReason = "";
    showDialog = true;
}
