import firebase from "firebase/compat/app"
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/functions";
import "@firebase/logger";

const dbType = localStorage.getItem("databaseProd") || "prod";
// const dbType = "dev";

switch (dbType) {
  case "prod":
    firebase.initializeApp({
      apiKey: "AIzaSyD3mEr-jVeBPHOzJxYJ52FOtp_rm0Tc3W8",
      authDomain: "bitplatform-production.firebaseapp.com",
      databaseURL: "https://bitplatform-production.firebaseio.com",
      projectId: "bitplatform-production",
      storageBucket: "bitplatform-production.appspot.com",
      messagingSenderId: "1060152344236",
      appId: "1:1060152344236:web:39ffcca389d8c9a1b06909",
      measurementId: "G-W1JC9ZYF9B",
    });
    break;
  case "test":
    firebase.initializeApp({
      apiKey: "AIzaSyB-EuayHq71ZXpoeoRaveMIERkwMQQEVc4",
      authDomain: "bitplatform-test.firebaseapp.com",
      databaseURL: "https://bitplatform-test.firebaseio.com",
      projectId: "bitplatform-test",
      storageBucket: "bitplatform-test.appspot.com",
      messagingSenderId: "608984421595",
      appId: "1:608984421595:web:b37b098c6850a6db4ca8fe",
      measurementId: "G-1NZYXZ7RQP",
    });
    break;
  case "dev":
    firebase.initializeApp({
      apiKey: "AIzaSyDWKlv7-fGEcKDFl-85p_C1Z5rjcrlfbsA",
      authDomain: "bitplatform-develop.firebaseapp.com",
      databaseURL: "https://bitplatform-develop.firebaseio.com",
      projectId: "bitplatform-develop",
      storageBucket: "bitplatform-develop.appspot.com",
      messagingSenderId: "800534252098",
      appId: "1:800534252098:web:1413b5ee6a51332c693bdb",
      measurementId: "G-FLKDK0WNE2",
    });
    break;
  default:
    console.log("No database connection!");
}

// old firebase configure
/*
    firebase.initializeApp({
        apiKey: "AIzaSyCQIT06siUesWvZmKiK2ikQ7AqYGdzbKyg",
        authDomain: "firestore-db-test-7e492.firebaseapp.com",
        databaseURL: "https://firestore-db-test-7e492.firebaseio.com",
        projectId: "firestore-db-test-7e492",
        storageBucket: "firestore-db-test-7e492.appspot.com",
        messagingSenderId: "703281789749"
    });


    url: https://bitplatform-test.web.app/

    new user:
    username: test@bouwbit.nl
    password: YwjVOJXTC

    url: https://bitplatform-develop.firebaseapp.com/
    username: develop@yellowq.nl
    password: jD2laVcAv

    email: develop2@yellowq.nl
    password: IR2ZztJ2p

    */


/** Need for local test */
//const functions = firebase.functions();
//functions.useEmulator('localhost', 5001);

export default firebase;
