import db from "../db";
import store from "../store";
import { firestoreAction } from "vuexfire";
import Vue from "vue";

const state = {
  productModules: {}
};

const getters = {};

const actions = {

  // subscribe to module by Firestore
  subscribeModule: firestoreAction(({ bindFirestoreRef }, params) => {
    const { docModule, module, filter} = params;
    let collection = db.collection("tenants/" + store.state.tenantID + "/modules/" + module + "/records");
    for (const f in filter) {
      collection = collection.where(f, "==", filter[f]);
    }
    
    Vue.set(state, docModule, []);
    bindFirestoreRef(docModule, collection).then(() => {
      Vue.set(state.productModules, docModule, state[docModule]);
    });
  }),

  unSubscribeModule: firestoreAction(({ unbindFirestoreRef }, params) => {
    const { docModule } = params;
    unbindFirestoreRef(docModule);
    Vue.set(state, docModule, []);
    Vue.set(state.productModules, docModule, []);
  }),

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
