import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=097a7520&scoped=true&"
import script from "./Dialog.vue?vue&type=script&lang=ts&"
export * from "./Dialog.vue?vue&type=script&lang=ts&"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=097a7520&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097a7520",
  null
  
)

export default component.exports
import {QCard,QItem,QItemSection,QBtn,QItemLabel,QCardSection,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QItem,QItemSection,QBtn,QItemLabel,QCardSection})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
