
  import ListValues from "@/components/Mixin/ListValues";
  import BaseFieldRelated from "@/components/Base/BaseFieldRelated";

  export default {
    name: "BaseFieldRelatedMultiSelect",
    mixins: [ BaseFieldRelated ],
    data() {
      return {
        cModel: this.value,
        needFocusedToNextField: true,
        userDataLoaded: false,
      };
    },
    async mounted() {
      if(this.field.name == "users") {
        await this.loadRelatedFieldQueryOptions();
      }
    },
    methods: {
      cleanModel() {
        if (this.isReadonly) {
          return false;
        }
        this.model = [];
      },
      async setWithNames(val) {
        const wnames = [];
        if (!this.rowRelatedOptions.length) {
          await this.getRelatedFieldData();
        }
        this.relatedOptions.forEach(r => {
          if (val.includes(r.ID)) {
            wnames.push({ID: r.ID, name: r.name});
          }
        });
        this.formData.data['__' + this.name] = wnames;
      },
      refreshAfterCopy(value) {
        this.setWithNames(value);
      },
      async loadRelatedFieldQueryOptions(searchValue) {
        this.relatedOptionsLoaded = true;
        this.loadingOptions = true;
        this.getRelatedIDForQuery();
        if (this.relatedModule) {
          const field = this.formData.dynamicFields ? this.formData.dynamicFields[this.name] : {};
          const additionalField = [];
          if (field && field.primaryResultField) additionalField.push(field.primaryResultField)
          if (field && field.secondaryResultField) additionalField.push(field.secondaryResultField)
          additionalField.push('locked')

          const query = [{field: 'name', value: searchValue}]
          if(field && field.primaryResultField) {
            query.push({field: field.primaryResultField, value: searchValue})
          }
          if(field && field.secondaryResultField) {
            query.push({field: field.secondaryResultField, value: searchValue})
          }
          const param = {
            query: query,
            relatedQuery: this.relatedQuery,
            module: this.relatedModule,
            additionalField: additionalField,
            size: 500
          };
          //await new Promise(resolve => setTimeout(resolve, 3000));
          const res = await this.queryRelatedFieldOptions(param);
          if(this.formData.data['__' + this.name]) {
            this.formData.data['__' + this.name].forEach(obj => {
              const found = res.docs.some(doc => {
                if (doc.ID === obj.ID) {
                  return true;
                }
                return false;
              });
              if(!found) {
                res.docs.push({ID: obj.ID, name: obj.name});
              }
            });
          }

          this.relatedOptions = Object.freeze(res.docs);
        }
        this.loadingOptions = false;
      },
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
          this.setWithNames(val);
        }
      },
      dataLoadedForUsers () {
        return (!this.relatedModule === 'users') || this.userDataLoaded;
      },
      rowOptions () {
        if (this.loadingOptions) {
          return null;
        }
        let result = ListValues.fixQSelectData(this.relatedOptions || []);
        if (!result.length) {
          const storedNames = this.formData.data['__' + this.name];
          if (storedNames) {
            result = storedNames;
          }
        }
        const field = this.formData.dynamicFields ? this.formData.dynamicFields[this.name] : {};
        result.forEach(e => {
          if (field) {
            if (Object.prototype.hasOwnProperty.call(field,'primaryResultField')) {
              e.optionsRow1 = ListValues.transformOneByModule(e[field.primaryResultField], this.relatedModule, field.primaryResultField);
            }
            if (Object.prototype.hasOwnProperty.call(field,'secondaryResultField')) {
              e.optionsRow2 = ListValues.transformOneByModule(e[field.secondaryResultField], this.relatedModule, field.secondaryResultField);
            }
          }
        })
        //return result.length ? result : null;
        // TODO need to check open popup and frozen, it start load after go and back
        return result;
      }
    }
  };
