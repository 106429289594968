
import {Component, Prop, Vue} from 'vue-property-decorator';
  import {State} from "vuex-class";
  import store from '@/store';
  import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
  import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
  import UnlayerTools, {UnlayerSettings} from "@/components/Settings/Sections/Templates/EmailTemplates/Models/UnlayerTools";
  import TemplateConstructor from '@/components/Settings/Sections/Templates/EmailTemplates/Components/TemplateConstructor.vue';
  import ListValues from "@/components/Mixin/ListValues";
  import TagsBlock from "@/components/Parts/TagsBlock.vue";
  import OpenEmailEditor from "@/components/Settings/Sections/Templates/EmailTemplates/Components/OpenEmailEditor.vue";
import sortList from '@/common/helpers/utilities';


class CurrentTemplateClass {
    id = null;
    ID = null;
    module = '';
    type = '';
    name = '';
    design = null;
    userID = '';
    from = '';
    subject = '';
    to = '';
    cc = '';
    bcc = '';
    tags = [];
    default = false;
  }

  class SelectedRecordClass {
    ID = "";
    name = "";
  }

  @Component({
    components: {TemplateConstructor, BaseFieldRelated, TagsBlock, OpenEmailEditor}
  })

  export default class EmailTemplates extends Vue {
    @State("tenantID") tenantID;
    @State("dynamicModules") dynamicModules;
    @Prop(Boolean) isUserEdit;
    columns = [
      {
        name: 'name',
        label: this.$t('email.headers.name'),
        field: 'name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'type',
        label: this.$t('email.headers.type'),
        field: 'type',
        align: 'left',
        sortable: true,
      },
      {
        name: 'module',
        label: this.$t('email.headers.module'),
        field: 'module',
        align: 'left',
        sortable: true,
      },
        {
        name: 'tags',
        label: this.$t('attachments.fields.tags'),
        field: 'tags',
        align: 'left',
        sortable: true,
      },
      {
        name: 'editor',
        label: '',
        field: 'editor',
        align: 'center',
        sortable: true,
      },
      {
        name: 'delete',
        label: '',
        field: 'delete',
        align: 'center',
        sortable: true,
      },
      {
        name: 'copy',
        label: '',
        field: 'copy',
        align: 'center',
        sortable: true,
      },
      {
        name: 'modified',
        label: this.$t('email.headers.modifiedAt'),
        field: 'modified',
        align: 'center',
      }
    ];
    pagination = {
      rowsPerPage: 20
    };
    showCreateDialog = false;
    showDeleteDialog = false;
    showCopyDialog = false;
    showSelectedModule = false;
    newTemplateName = '';
    showModalEditor = false;
    showSelectRecordDialog = false;
    editorLink = '';
    currentTemplate = new CurrentTemplateClass();
    inputJson = null;
    selectedModule = '';
    selectedRecord = new SelectedRecordClass();
    formData = {};
    record = {};
    loading = false;
    mergeTags = {};
    designTags = {};
    designTagsDB = {};
    settings = new UnlayerSettings();
    templates = [];
    userTemplates = [];
    tenantTemplates = [];
    globalTemplates = [];
    currentDesign: any = null;
    recordSelectKey = 1;
    showOpenEmailEditor = false
    templateModule = {}

    get modulesList() {
      const modulesList = Array<{ label: string; value: string }>();
      Object.keys(this.dynamicModules).forEach((module) => {
        modulesList.push({
          label: this.getModuleTitle(module),
          value: module,
        });
      });
      return sortList(modulesList, 'label');
    }

    async mounted() {
      this.templateModule = this.modulesList[0]
      this.loading = true;
      await this.getSettings();
      await this.getAllTemplates();
      this.loading = false;
    }

    copyToClipboard(ID: string) {
      navigator.clipboard.writeText(ID);
    }

    async getAllTemplates() {
      (this.globalTemplates as any) = await UnlayerTools.getGlobalTemplates();
      (this.tenantTemplates as any) = await UnlayerTools.getTenantTemplates();
      (this.userTemplates as any) = await UnlayerTools.getUserTemplates();
      this.templates = []
      const templates = [].concat(this.globalTemplates, this.tenantTemplates, this.userTemplates);
      for(const template of templates) {
        if(template.module == this.templateModule['value']) {
          this.templates.push(template)
        }
      }
    }

    async getSettings() {
      (this.settings as any) = await UnlayerTools.getSettings();
    }

    get relatedModule() {
      return this.currentTemplate.module;
    }

    async onTemplateCreate() {
      this.currentTemplate = new CurrentTemplateClass();
      this.currentTemplate.module = this.selectedModule || '';
      await this.saveTemplate(this.currentTemplate);
      this.eraseTheFormFields();
      store.state.alertMessage = "add";
    }

    eraseTheFormFields() {
      this.newTemplateName = '';
      this.selectedModule = '';
    }

    onClickCopyBtn(props) {
      this.showCopyDialog = true;
      this.currentTemplate = JSON.parse(JSON.stringify(props.row));
    }

    async onTemplateCopy(): Promise<void> {
      this.currentTemplate.id = null;
      this.currentTemplate.ID = null;
      await this.saveTemplate(this.currentTemplate);
      this.currentTemplate = new CurrentTemplateClass();
      this.newTemplateName = '';
      store.state.alertMessage = "add";
    }

    async saveTemplate(template) {
      const currentDesign = JSON.parse(JSON.stringify(template));
      currentDesign.name = this.newTemplateName;
      currentDesign.updatedAt = new Date().toISOString();
      currentDesign.userID = this.isUserEdit ? store.state.userID : 'tenant';
      delete(currentDesign.type);
      const res = await UnlayerTools.saveTemplate(currentDesign);
      await this.getAllTemplates();
      return res;
    }

    async saveTemplateConstructor(template) {
      const currentDesign = JSON.parse(JSON.stringify(template));
      currentDesign.updatedAt = new Date().toISOString();
      delete(currentDesign.type);
      const res = await UnlayerTools.saveTemplate(currentDesign);
      store.state.alertMessage = "update";
      await this.getAllTemplates();
      return res;
    }
    prepareTags(template) {
        this.currentTemplate = template;
        this.currentTemplate.tags = Array.isArray(template.tags) ? template.tags : [];
    }
    saveTags(tags) {
        const tData = {tags: tags.map(t => t.trim()) || []};
        this.saveTemplateRename(tData)
    }
    async saveTemplateRename(tData) {
      const saveData = {
        ID: this.currentTemplate.ID,
        userID: this.currentTemplate.userID,
        ...tData
      };
      // 
      await UnlayerTools.saveTemplate(saveData);
      store.state.alertMessage = "update";
      await this.getAllTemplates();
      this.currentTemplate = { ...this.currentTemplate, ...tData };
    }

    onClickDeleteBtn(props){
      this.showDeleteDialog = true;
      this.currentTemplate = props.row;
    }

    async onTemplateDelete(): Promise<void> {
      await UnlayerTools.deleteTemplate(this.currentTemplate);
      await this.getAllTemplates();
      this.currentTemplate = new CurrentTemplateClass();
      store.state.alertMessage = "delete";
    }

    openCreateDialog() {
      this.showCreateDialog = true;
      this.showOpenEmailEditor = true;
    }

    async openEditor(props) {
      this.showSelectRecordDialog = true;
      this.showOpenEmailEditor = true;
      this.currentTemplate = props.row;
      this.selectedModule = this.currentTemplate.module;
      if (this.selectedModule) {
        this.formData = await PdfTools.getFormDataForPDF(this.selectedModule);
        this.mergeTags = this.getTagsFormData(this.formData);
      } else {
        this.formData = {};
      }
    }

    getTagsFormData(formData) {
      const fields: any = ListValues.getValidColumns(formData.dynamicFields);
      const tags  = {};
      
      for(const fname in fields) {
        let sname = '';
        // eslint-disable-next-line no-prototype-builtins
        if (formData.data[fname] && Object.prototype.hasOwnProperty.call(formData.data[fname], 'name')) {
          sname = '.name';
        }
        tags[fname] = {name: fields[fname].label, value: '{{' + fname + sname + '}}'}
      }
      
      return tags;
    }

    onCancelCopyDialog(){
      this.eraseTheFormFields();
      this.currentTemplate = new CurrentTemplateClass();
    }

    onCancelDeleteDialog(){
      this.currentTemplate = new CurrentTemplateClass();
    }

    converDate(isostr) {
      if (!isostr) {
        return '';
      }
      const date = new Date(isostr);
      return date.toLocaleString('nl-Nl')
    }

    convertName(type) {
      if (type === 'global') {
        return 'YellowQ';
      } else {
        return type;
      }
    }

    getModuleTitle(moduleID) {
      const translationKey = `modules.${moduleID}.pluralName`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        return store.state.fieldTranslations[translationKey]
      }
      const moduleTitle = this.dynamicModules && this.dynamicModules[moduleID] ? this.dynamicModules[moduleID] : {} ;
      return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : moduleID
    }

    canEditTemplate(props) {
      const template = props.row;
      return template && template.type !== 'global';
    }


    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    }

    async getOpenedEditorStatus(value: any) {
      this.showOpenEmailEditor = value
      await this.getSettings();
      await this.getAllTemplates();
    }

    getOpenedCreateDialogStatus(value: any) {
      this.showOpenEmailEditor = value
      this.showCreateDialog = false
      this.showSelectRecordDialog = false      
      this.$router.go(0)
    }

    goToCustomTranslations() {
      this.$router.push({name: 'custom-translations'})
    }
  }
