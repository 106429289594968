
import { Vue, Component, Watch } from "vue-property-decorator";
import sortList from "@/common/helpers/utilities";
import store from "@/store"
import Columns from "../Models/Columns"
import TranslationConverter from "../Helpers/TranslationConverter"
import DatabaseHelper from "../Helpers/DatabaseHelper"
import Languages from "@/components/Models/Languages"

@Component({
  mixins: [],
  components: {},
})

export default class ModuleTranslations extends Vue {
  selectedModule = null
  fieldTranslations = {}
  dropDownTranslations = {}
  sectionTranslations = {}
  translations = {}
  tableData = []
  selectedTab = 'fields'

  async mounted() {
    this.selectedModule = this.modulesList[0]
    await this.fetchTranslations()
    this.fetchModuleData()
  }

  async updateTranslations() {
    await this.fetchTranslations()
    this.fetchModuleData()
  }

  async saveTranslations() {
    const databaseHelper = new DatabaseHelper()
    const translationConverter = new TranslationConverter()
    const languages = new Languages()
    for(const language of languages.availableLanguages) {
      const dataToSave = translationConverter.convertToDatabaseData(this.tableData, this.selectedTab, language, this.selectedModule.value)
      const response = await databaseHelper.saveTranslations(dataToSave, language)
      let message: any = this.$t('common.messages.failedSaving')
      if(response) {
        message = this.$t('common.messages.recordChanged')
      }
      this.$q.notify({
        color: response ? "positive" : 'negative',
        message: message,
      });
    }
  }

  fetchModuleData() {
    const translationConverter = new TranslationConverter()
    if(this.selectedTab == "fields") {
      console.log(this.moduleFields)
      console.log(this.fieldTranslations)
      this.tableData = translationConverter.convertToTableData(this.moduleFields, this.selectedTab, this.fieldTranslations, this.selectedModule.value)
    }
    if(this.selectedTab == "dropDowns") {
      this.tableData = translationConverter.convertDropDownDataToTableData(this.moduleFields, this.selectedTab, this.dropDownTranslations, this.selectedModule.value)
    }
    if(this.selectedTab == "sections") {
      const sections = store.state.dynamicModules[this.selectedModule.value].sections
      this.tableData = translationConverter.convertToTableData(sections, this.selectedTab, this.sectionTranslations, this.selectedModule.value)
    }
  }

  async fetchTranslations() {
    const databaseHelper = new DatabaseHelper()
    const languages = new Languages()
    this.fieldTranslations = {}
    for(const language of languages.availableLanguages) {
      this.fieldTranslations[language] = {}
      this.dropDownTranslations[language] = {}
      this.sectionTranslations[language] = {}
      this.translations[language] = await databaseHelper.readTranslations(language)
      for(const transl in this.translations[language]) {
        const translation = this.translations[language][transl]
        if(transl.includes('.fields.')) {
          this.fieldTranslations[language][transl] = translation
        }
        if(transl.includes('.dropDowns.')) {
          this.dropDownTranslations[language][transl] = translation
        }
        if(transl.includes('.sections.')) {
          this.sectionTranslations[language][transl] = translation
        }
      }
    }
    this.addMissingFieldTranslations()
    this.addMissingDropDownTranslations()
    this.addMissingSectionTranslations()
  }

  addMissingFieldTranslations() {
    const languages = new Languages()
    for(const language of languages.availableLanguages) {
      for(const m in store.state.dynamicModules) {
        const module = store.state.dynamicModules[m]
        for(const f in store.state.dynamicModules[m].fields) {
          const field = store.state.dynamicModules[m].fields[f]
          if(!field.hidden) {
            if(!this.fieldTranslations[language][`${module.id}.fields.${field.name}`]) {
              this.fieldTranslations[language][`${module.id}.fields.${field.name}`] = field.label
            }
          }
        }
     }
    }
  }

  addMissingDropDownTranslations() {
    const languages = new Languages()
    for(const language of languages.availableLanguages) {
      for(const m in store.state.dynamicModules) {
        const module = store.state.dynamicModules[m]
        for(const f in store.state.dynamicModules[m].fields) {
          const field = store.state.dynamicModules[m].fields[f]
          if(field.type === "dropdown" && !field.hidden) {            
            for(const option in module.dropDownValues[f]) {
              const dropDownOption = module.dropDownValues[f][option]
              if(!this.dropDownTranslations[language][`${module.id}.dropDowns.${field.name}.${dropDownOption}`]) {
                this.dropDownTranslations[language][`${module.id}.dropDowns.${field.name}.${dropDownOption}`] = dropDownOption
              }
            }
          }
        }
      }
    }
  }

  addMissingSectionTranslations() {
    const languages = new Languages()
    for(const language of languages.availableLanguages) {
      for(const m in store.state.dynamicModules) {
        const module = store.state.dynamicModules[m]
        for(const f in store.state.dynamicModules[m].sections) {
          const section = store.state.dynamicModules[m].sections[f]
          if(!section.hidden) {
            if(!this.sectionTranslations[language][`${module.id}.sections.${section.name}`]) {
              this.sectionTranslations[language][`${module.id}.sections.${section.name}`] = section.label
            }
          }
        }
     }
    }
  }

  get moduleFields() {
    return store.state.dynamicModules[this.selectedModule.value].fields
  }

  getModuleTitle(moduleID: string) {
    const moduleTitle = store.state.dynamicModules && store.state.dynamicModules[moduleID] ? store.state.dynamicModules[moduleID] : {} ;
    const translationKey = `modules.${moduleID}.pluralName`
    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
      return store.state.fieldTranslations[translationKey]
    }
    return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : module
  }

  get modulesList() {
    const modulesList = Array<{ label: string; value: string }>();
    Object.keys(store.state.dynamicModules).forEach((module) => {
      modulesList.push({
        label: this.getModuleTitle(module),
        value: module,
      });
    });
    return sortList(modulesList, 'label');
  }

  get tableColumns() {
    const columns = new Columns()
    return columns.getTableColumns()
  }

  @Watch('selectedTab') 
  selectedTabHandler(newValue) {
    this.fetchModuleData()
  }
}
