
import { mapState, mapActions } from 'vuex';
  import store from "@/store";
  import draggable from 'vuedraggable';
  import ListValues from '@/components/Mixin/ListValues'

  export default {
    name: "DefaultColumns",
    components: {draggable},
    data: () => ({
      messageOk: false,
      messageError: false,
      columns: null,
      dynamicColumns: {},
    }),
    mounted() {
      this.getColumns();
    },

    computed: {
      ...mapState(["dynamicModules"]),
    },
    methods: {
      ...mapActions("settings", ["getTenantDefaultColumns", "setTenantDefaultColumns"]),
      getModuleTitle(module) {
        const translationKey = `modules.${module.ID}.pluralName`
        if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
          return store.state.fieldTranslations[translationKey]
        }
        return module.title
      },
      async getColumns() {
        const dataDoc = await this.getTenantDefaultColumns();
        const columns = dataDoc.data() ?? {};
        const result = {};
        const dynColumns = {};
        for (const i in this.dynamicModules) {
          const module = this.dynamicModules[i];
          dynColumns[i] = {
            ID: i,
            title : module.pluralName ?? i,
            fields : ListValues.getValidColumns(module.fields)
          };
        }
        for (const i in dynColumns) {
          const module = dynColumns[i];
          if (i === 'planboard') {
            module.fields = this.getPlanboardSharedFields(dynColumns)
          }
          result[i] = columns[i] ? columns[i].filter(c => Object.keys(module.fields).indexOf(c) !== -1) : [];
        }
        this.dynamicColumns = dynColumns;
        this.columns = result;
      },
      getPlanboardSharedFields(dynColumns){
        const woFields = dynColumns.workOrders.fields;
        const apFields = dynColumns.appointments.fields;
        const locFields = ListValues.locFields() || [];
        const sharedFields = {}

        for (const fName in woFields) {
          const addToField = {
            name: fName + '.workOrders',
            label: woFields[fName].label + ' (' + ListValues.singularTitle('workOrders') + ')'
          }
          const wo =  { ...woFields[fName], ...addToField }
          this.$set(sharedFields, fName + '.workOrders', wo)
        }

        for (const fName in apFields) {
          const addToField = {
            name: fName + '.appointments',
            label: apFields[fName].label + ' (' + ListValues.singularTitle('appointments') + ')'
          }
          const ap = { ...apFields[fName], ...addToField }
          this.$set(sharedFields, fName + '.appointments', ap)
        }

        for (const fName in locFields) {
          const addToFieldWo = {
            name: fName + '.workOrders',
            label: locFields[fName].label + ' (' + ListValues.singularTitle('workOrders') + ')'
          }
          const addToFieldAp = {
            name: fName + '.appointments',
            label: locFields[fName].label + ' (' + ListValues.singularTitle('appointments') + ')'
          }
          const locWo = { ...locFields[fName], ...addToFieldAp }
          const locAp = { ...locFields[fName], ...addToFieldWo }
          this.$set(sharedFields, fName + '.workOrders', locWo)
          this.$set(sharedFields, fName + '.appointments', locAp)
        }
        return sharedFields
      },
      getFieldLabel(moduleName, fieldName){
        let moduleFields = {};
        if (moduleName === 'planboard') {
          moduleFields = this.getPlanboardSharedFields(this.dynamicModules)
        } else {
          moduleFields = this.dynamicModules[moduleName].fields
        }
        return moduleFields[fieldName].label
      },
      saveSettings() {
          this.setTenantDefaultColumns(this.columns).then(() => {
            store.state.alertMessage = "update";
          })
      },
    },
  };
