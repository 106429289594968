
import { Vue, Component } from "vue-property-decorator";
import db from "../../../../../../db";
import store from "../../../../../../store";

@Component({})
export default class ExactOnline extends Vue {
  clientID = "";
  clientSecret = "";
  credentials: Array<any> = [];
  countryOptions = ["nl", "be"];

  async mounted() {
    this.credentials = await this.getCredentials();
    await this.checkForExactRegistration();
  }

  async getCredentials() {
    const credentials: Array<any> = [];
    await db
      .collection(
        `tenants/${store.state.tenantID}/integrations/credentials/records`
      )
      .where("service", "==", "ExactOnline")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const document = doc.data();
          document["ID"] = doc.id;
          credentials.push(document);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    return credentials;
  }

  addCredential() {
    this.credentials.push({
      name: "",
      clientID: "",
      clientSecret: "",
      country: "",
      service: "ExactOnline",
      status: "pending",
    });
  }

  async deleteCredentials(credential) {
    if (credential.ID) {
      this.credentials.splice(this.credentials.indexOf(credential));
      await db
        .collection(
          `tenants/${store.state.tenantID}/integrations/credentials/records`
        )
        .doc(credential.ID)
        .delete()
        .then(() => {
          const message: any = this.$t("common.messages.recordDeleted");
          this.$q.notify({ message: message, color: "warning" });
        })
        .catch((error) => {
          console.log("Error deleting document: ", credential.ID, error);
        });
    } else {
      this.credentials.splice(this.credentials.indexOf(credential));
    }
    this.credentials = await this.getCredentials();
  }

  async startLogin(credential) {
    await this.createIntegrationDoc(credential);
    await this.goToExactAuthPage(credential);
  }

  async createIntegrationDoc(credential) {
    await db
      .collection(
        `tenants/${store.state.tenantID}/integrations/credentials/records`
      )
      .doc()
      .set(credential, { merge: true })
      .then(() => {
        const message: any = this.$t("common.messages.successfullySaved");
        this.$q.notify({ message: message, color: "positive" });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async updateIntegrationDoc(credential) {
    await db
      .collection(
        `tenants/${store.state.tenantID}/integrations/credentials/records`
      )
      .doc(credential.ID)
      .set(credential, { merge: true })
      .then(() => {
        const message: any = this.$t("common.messages.successfullySaved");
        this.$q.notify({ message: message, color: "positive" });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async goToExactAuthPage(credential): Promise<any> {
    const apiUrl = "https://start.exactonline.nl/api/oauth2/auth";

    const params = {
      client_id: credential.clientID,
      response_type: "code",
      redirect_uri: `https://yellowq.app/settings/exact-online`,
      force_login: 1,
    };

    const queryParams = Object.keys(params)
      .map((p) => p + "=" + params[p])
      .join("&");
    const linkToLogin = apiUrl + "?" + queryParams;

    (window as any).location = linkToLogin;
  }

  async checkForExactRegistration() {
    if (this.$route.query.code) {
      for (const credential of this.credentials) {
        if (credential.status === "pending") {
          credential.integrationName = credential.ID;
          credential.redirectURL = `https://yellowq.app/settings/exact-online`;
          credential.code = this.$route.query.code;
          const response = await this.$store
            .dispatch("api/exactRegistration", credential)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              console.log(err, "error");
            });
          if (response && response.status == 200) {
            delete credential.integrationName;
            delete credential.redirectURL;
            delete credential.code;
            credential.status = "completed";
            await this.updateIntegrationDoc(credential);
          }
        }
      }
    }
  }
}
