
import { Vue, Component } from "vue-property-decorator";
import { useCreateNotificationsStore } from "../Store/CreateNotificationsStore"
import { useModulesStore } from "@/components/Modules/Store/ModuleStore"
import NotificationAction from "../Models/NotificationAction"
import Dialog from "@/components/Parts/Dialog.vue";
import store from "@/store";
import Utils from "@/utils/Utils";


@Component({
  components: {
    Dialog
  }
})
export default class CreateNotifications extends Vue {
  notificationStore = useCreateNotificationsStore()
  modulesStore = useModulesStore()
  notificationActions: NotificationAction[] = []
  newNotificationAction: NotificationAction = {
    moduleID: '',
    userID: store.state.currentUser.ID,
    message: '',
    status: null
  }
  possibleStatuses = null
  showCreateModal = false
  selectedModule = null
  step = 1
    columns = [
      {
        name: 'moduleID',
        label: this.$t('email.headers.module'),
        field: 'moduleID',
        align: 'left',
        sortable: true,
      },
      {
        name: 'status',
        label: this.$t('planBoard.fields.status'),
        field: 'status',
        align: 'left',
        sortable: true,
      },
      {
        name: 'message',
        label: this.$t('roboticProcessBuilder.fields.message'),
        field: 'message',
        align: 'left',
        sortable: true,
      },
      {
        name: 'delete',
        label: '',
        field: 'delete',
        align: 'center',
        sortable: true,
      }
    ];

  async mounted() {
    await this.notificationStore.initCreateNotifications
    await this.modulesStore.initModules
    this.notificationActions = await this.notificationStore.getNotifications()
  }

  async createNewNotificationAction() {
    if(!this.checkValidData()) return
    await this.notificationStore.createNotificationAction(this.newNotificationAction)
    this.notificationActions = await this.notificationStore.getNotifications()
    this.resetCreateNotification()
    this.showCreateModal = false
  }
  
  resetCreateNotification() {
    this.newNotificationAction = {
      moduleID: '',
      userID: store.state.currentUser.ID,
      message: '',
      status: null
    }
    this.step = 1
    this.selectedModule = null
    return;
  }

  async deleteNotificationAction(notificationActionID: string) {
    await this.notificationStore.deleteNotificationAction(notificationActionID)
    this.notificationActions = await this.notificationStore.getNotifications()
  }

  getPossibleStatuses() {
    this.newNotificationAction.moduleID = this.selectedModule.ID
    const dropdownValues = this.modulesStore.getDropDownValues(this.selectedModule.ID)
    this.possibleStatuses = dropdownValues.status
  }

  checkValidData() {
    if(this.step === 1) {
      if(!Utils.isEmpty(this.newNotificationAction.moduleID)) this.step = 2
      else this.$q.notify({ message: `Selecteer eerst de module.`, color: 'negative'})
    } else if(this.step === 2) {
      if(!Utils.isEmpty(this.newNotificationAction.status)) this.step = 3
      else this.$q.notify({ message: `Selecteer eerst een status.`, color: 'negative'})
    } else if(this.step === 3) {
      if(!Utils.isEmpty(this.newNotificationAction.message)) return true
      else this.$q.notify({ message: `Vul een bericht in.`, color: 'negative'})
    }
    return false
  }

  getModuleTitle(moduleID: string) {
    const module = this.modulesStore.modules[moduleID]
    return module.singularName
  }

  get modulesList() {
    const selectableModules = []
    for(const index in this.modulesStore.modules) {
      const module = this.modulesStore.modules[index]
      module["ID"] = index
      const statusField = this.modulesStore.getField(module["ID"], 'status')
      if(statusField) selectableModules.push(this.modulesStore.modules[module["ID"]])
    }
    return selectableModules
  }
}
