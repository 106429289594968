import db from "@/db";
import store from "@/store";
import Ajv from "ajv";
import configSchema from "../Helpers/RoProConfig.json";
import { FieldType, DeleteType } from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Models/Types";
import dynamic from "@/store/dynamic";


export default class RoPro {
    fieldType = new FieldType()
    deleteType = new DeleteType()
    actions: Array<Actions> = [];
    fields: Array<string> = [];
    active = true;
    title = ""; 
    type = {name: "", type: ""};
    description = "";
    when = "create or update";
    conditions: Array<Conditions> = [];
    criteriaPattern: Array<any> = [];
    oldFieldList = []
    ID = "";
    async import(config, dynamicModules, module) {             
        for(const criteriaPattern in config.criteriaPattern) {
          let criteriaPatt = {};
          if(config.criteriaPattern[criteriaPattern] == "and") {
            criteriaPatt = {
              label: "En",
              value: "and"
            }
          } 
          if(config.criteriaPattern[criteriaPattern] == "or") {
            criteriaPatt = {
              label: "Of",
              value: "or"
            }
          } 
          this.criteriaPattern.push(criteriaPatt)            
          }
        async function fetchAction(actionType, ID, actionList) {
          console.log(actionType, " action type");
          
          if(actionType == "RelatedFieldCopy") {
            actionType = "RelatedFieldCopie"
          }
          if(actionType == "SendSMS") {
            actionType = "SendSMSe"
          }
          if(actionType == "GeneratePdf"){
            actionType = "GeneratePDF"
          }
          
            const firePath =
                "tenants/" +
                store.state.tenantID +
                "/actions/" +
                actionType + "s" +
                "/records/";                
            const res = await db.collection(firePath).get();
            const docs: any = [];
            res.forEach((doc) => {
                const rec: any = doc.data();
                rec.id = doc.id;
                (docs as any).push(rec as any);
            });
            let roProActions = []
            roProActions = docs.map((d) => {                
                return { ID: d.ID, title: d.title, description: d.description, type: actionType, order: 0 };
            });            
            for(const action in roProActions) {
                if(ID == roProActions[action]["ID"] ) {
                    actionList.push(roProActions[action])
                }
            }
        }
    
        for( const action in config.actions) {                    
            fetchAction(config.actions[action].type, config.actions[action].ID, this.actions);
        }
        if(config.type == "FieldTrigger") {
          this.type = this.fieldType
        } else if (config.type == "DeleteTrigger") {
          this.type = this.fieldType
        }

        function getField(fieldName) {
          let field = { label: "", name: "", type: "", fieldType: "" };
          if (!fieldName) {
            return field;
          }
          const fieldNameNoID = fieldName.replace(".ID", "");
      
          field =
            dynamicModules &&
            module &&
            dynamicModules[module] &&
            dynamicModules[module].fields &&
            dynamicModules[module].fields[fieldNameNoID]
              ? Object.assign(
                  {},
                  dynamicModules[module].fields[fieldNameNoID]
                )
              : field;
          return field;
        }
        async function getFilterValueRelatedField(filter, field) {           
          const relatedModule = getField(filter.field)["relatedModule"]
          const firePath = "/tenants/" + store.state.tenantID + "/modules/" + relatedModule + "/records/";          
          const ref = db.collection(firePath).doc(filter.value)
          let name = ""
          await ref.get().then(async doc => {
              if (doc.exists) {
                  name = doc.data()['name'];
              } else {
                  console.log("Document not found");
              }
          }).catch(err => {
              throw new Error(err);
          });
          return name
        }

        const conditions = config.conditions
        for(const con in conditions) {
          const condition = conditions[con]      
          const relatedField = condition.field.split(".")[0]
          for(const f in dynamicModules[module].fields) {
            if(dynamicModules[module].fields[f].name == relatedField){
              condition.field = dynamicModules[module].fields[f].name            
            }
          }
          const firstCharacters = condition.field.substring(0, 2)
          const index = conditions.indexOf(condition, 0)
          if(firstCharacters == "__") {
            const index = conditions.indexOf(condition, 0)
            conditions.splice(index, 1)
          } else if(dynamicModules[module].fields[condition.field] == undefined) {
            this.oldFieldList.push(condition.field)
            const index = conditions.indexOf(condition, 0)
            config.conditions.splice(index, 1)            
            this.criteriaPattern.splice(index - 1, 1)
          }        
        }
        
        for(const c in config.conditions) {          
          const condition = config.conditions[c]
          condition.rule = c
          condition.field = condition.field.split(".")[0]
          if(dynamicModules[module].fields[condition.field].type == "relatedField") {
            console.log(dynamicModules[module].fields[condition.field]);
            condition.name = await getFilterValueRelatedField(condition, "value")
          }
        }
        
        this.fields = config.fields;
        this.active = config.active;
        this.title = config.tilte;
        this.description = config.description;
        this.when = config.when;
        this.conditions = config.conditions;
        this.ID = config.ID; 
    }
    
    validateJSONData() {
      const criteriaPatterns = {};
      let index = 0;
      for (const criteriaPattern in this.criteriaPattern) {
        let criteriaPatt = {}
        if(this.criteriaPattern[criteriaPattern] == "and") {
          criteriaPatt = {
            label: "En",
            value: "and"
          }
        } 
        if(this.criteriaPattern[criteriaPattern] == "and") {
          criteriaPatt = {
            label: "Of",
            value: "or"
          }
        } 
        criteriaPatterns[index.toString()] = this.criteriaPattern[
          criteriaPattern
        ];
        index += 1;
      }

      // filter the condition list
      const filteredCondition: any = [];
      for (const condition in this.conditions) {
        const c: any = {};
        (c.rule = this.conditions[condition]["rule"]),
          (c.value = this.conditions[condition]["value"]),
          (c.operator = this.conditions[condition]["operator"]),
          (c.field = this.conditions[condition]["field"]);
        filteredCondition.push(c);
      }

      // filter the action list
      const filteredAction: any = [];
      for (const action in this.actions) {
        const a: any = {};
        (a.order = this.actions[action]["order"]),
          (a.parameters = this.actions[action]["parameters"]),
          (a.type = this.actions[action]["type"]),
          (a.ID = this.actions[action]["ID"]);
        filteredAction.push(a);
      }
      
      const data = {
        title: this.title,
        active: this.active,
        fields: this.fields,
        conditions: filteredCondition,
        criteriaPattern: criteriaPatterns,
        actions: filteredAction,
        description: this.description,
        type: this.type["type"],
        ID: this.ID,
        when: this.when
      }


      const ajv = new Ajv({ allErrors: true });
      // require("ajv-errors")(ajv /*, {singleError: true} */)
      const configValidator = ajv.compile(configSchema);
  
      const configValid = configValidator(data);
      if (!configValid) {
        for (const error in configValidator.errors) {
          console.error(configValidator.errors[error].message);
        }
        return false
      } else {
        return true
      }
    }

    export (systemFields, dynamicFields) {            
      // make from the criteriaPatterns list an object
      const criteriaPatterns = {};
      let index = 0;
      for (const criteriaPattern in this.criteriaPattern) {
        let criteriaPatt = ""

        if(this.criteriaPattern[criteriaPattern]["value"] == "and") {
          criteriaPatt = "and"
          criteriaPatterns[index.toString()] = criteriaPatt
        } else if (this.criteriaPattern[criteriaPattern]["value"] == "or") {
          criteriaPatt = "or"
          criteriaPatterns[index.toString()] = criteriaPatt
        }
        index += 1;
      }

      // filter the condition list
      const filteredCondition: any = [];
      for (const condition in this.conditions) {
        const field = dynamicFields[this.conditions[condition]["field"]]        
        if(field.type == "relatedField" && field.name.includes(".ID") == false) {
          this.conditions[condition]["field"] = field.name + ".ID"
        }        
        const c: any = {};
        (c.rule = this.conditions[condition]["rule"]),
        (c.value = this.conditions[condition]["value"]),
        (c.operator = this.conditions[condition]["operator"]),
        (c.field = this.conditions[condition]["field"]);
        filteredCondition.push(c);
      }

      // filter the action list
      const filteredAction: any = [];
      for (const action in this.actions) {
        const a: any = {};
        let actionType = "";
        console.log(this.actions[action]["type"], "action type tes");
        
        if(this.actions[action]["type"] == "SendSms") {
          actionType = "SendSMS"
        } else if (this.actions[action]["type"] == "GeneratePdf") {
          actionType = "GeneratePDF"          
        } else {
          actionType = this.actions[action]["type"]
        }
          (a.order = this.actions[action]["order"]),
          (a.parameters = this.actions[action]["parameters"]),
          (a.type = actionType),
          (a.ID = this.actions[action]["ID"]);
        filteredAction.push(a);
      }

      // add the systemFields to the fields list
      for(const field in systemFields){
        this.fields.push(systemFields[field])
      }

      for(const f in this.fields) {
        const field = dynamicFields[this.fields[f]]
        if(field != undefined && field.type == "relatedField" && field.name.includes(".ID") == false) {
          this.fields[f] = field.name + ".ID"
        }        
      }

      if(this.conditions.length <= 1) {
        const data = JSON.parse(
          JSON.stringify(
            {
              title: this.title,
              active: this.active,
              fields: this.fields,
              conditions: filteredCondition,
              actions: filteredAction,
              description: this.description,
              type: this.type["type"],
              ID: this.ID,
              when: this.when
            },
            undefined,
            2
          )
        );                
        return data
      } else {
        const data = JSON.parse(
          JSON.stringify(
            {
              title: this.title,
              active: this.active,
              fields: this.fields,
              conditions: filteredCondition,
              criteriaPattern: criteriaPatterns,
              actions: filteredAction,
              description: this.description,
              type: this.type["type"],
              ID: this.ID,
              when: this.when
            },
            undefined,
            2
          )
        );
        return data
      }
    }
}

export class Conditions {
    rule = 0;
    value = "";
    operator = "";
    field = "";
}

export class Actions {
    order = 0;
    parameters = {}
    type = "";
    ID = "";
    title = "";
    description = "";
}