  import Vue from "vue";
import i18n from "./i18n";
import VueRouter from "vue-router";

import VueTimers from "vue-timers";
import money from "./lib/v-money/index.js";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import * as numeral from "numeral";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import router from "./router";
import store from "./store";
import "./auth";
import "./version-controller";
import "./quasar";
import "./styles/main.scss";
import VueIntercom from '@mathieustan/vue-intercom';
import 'ag-grid-enterprise';
import { createPinia, PiniaVuePlugin } from "pinia"

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fass } from '@fortawesome/sharp-solid-svg-icons'
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add icons to the library */
const farIcons: any = far
const fasIcons: any = fas
const falIcons: any = fal
const fatIcons: any = fat
const fadIcons: any = fad
const fasSharpIcons: any = fass
library.add(farIcons, fasIcons, falIcons, fatIcons, fadIcons,fasSharpIcons);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueRouter);
Vue.use(VueTimers);
Vue.use(money, { precision: 4 });
Vue.use(vueNumeralFilterInstaller);
Vue.use(VueIntercom, { appId: 'mlkaee8s' });

// switch between locales
numeral.locale("de");

Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.use(VueLodash, { name: "$lodash", lodash: lodash });
new Vue({
  i18n,
  router,
  store,
  pinia,
  render: (h) => h("router-view"),
}).$mount("#app");
