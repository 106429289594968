
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import Table from "@/components/Settings/Actions/table.vue";
import DynamicFieldSingle from "@/components/Forms/DynamicFieldSingle.vue";
import sortList from "@/common/helpers/utilities";

class NewFieldMergeClass {
  selectedField = {
    label: "",
    value: "",
  };
  enterValue = "";
  from = "";
  to = "";
  showValueField = false;
  dateFilterStartCheckBox = false;
  dateFilterEndCheckBox = false;
}

@Component({
  components: { Table, DynamicFieldSingle },
})
export default class FieldMerge extends Vue {
  @State("dynamicModules") dynamicModules;
  @Prop({ required: true }) module;
  @Prop({ required: true }) parentModule;
  @Prop() selectedType;
  @Prop() parentToChild;
  @Prop() existingFieldMerges;
  @Prop() openExistingAction;
  fieldMerge = new NewFieldMergeClass();
  fieldOptions = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
    index: number;
  }>();
  fieldOptionsSecondModule = Array<{
    label: string;
    value: string;
    type: string;
    fieldType: string;
    index: number;
  }>();
  fieldsToMerge = Array<{}>();
  options = this.fieldOptions;
  secondOptions = this.fieldOptionsSecondModule;
  objectOfType = {
    autoNumber: "text",
    checkbox: "boolean",
    percentage: "number",
    currency: "number",
    valutafield: "number",
    date: "timestamp",
    datetime: "timestamp",
    decimal: "number",
    dropdown: "text",
    email: "text",
    text: "text",
    largeText: "text",
    number: "text",
    phone: "text",
    relatedField: "map",
    status: "text",
    url: "text",
    signature: "text",
  };
  parentmodule!: any 
  childmodule!: any 

  async mounted() {   
    this.parentmodule = this.parentModule  
    this.childmodule = this.module      
    if(this.openExistingAction == true) {
      this.fieldsToMerge = this.existingFieldMerges      
    }
    this.getAvailableFields()
  }

  // Function to clear table when one of the modules is changed
  beforeUpdate() {
    if(!this.parentToChild) {      
      if(this.module != this.childmodule || this.parentModule != this.parentmodule) {        
        this.fieldOptions = []
        this.fieldsToMerge = []
        this.getAvailableFields()
        this.parentmodule = this.parentModule
        this.childmodule = this.module
      }
    } else {      
      if(this.module != this.childmodule || this.parentModule != this.parentmodule) {
        this.fieldOptions = []
        this.fieldsToMerge = []
        this.getAvailableFields()
        this.parentmodule = this.parentModule
        this.childmodule = this.module
      }
    }
  }

  getAvailableFields() {
    let module = "";
    if (this.selectedType == undefined) {
      module = this.module.name;
    } else if (this.selectedType.name == "childToParent") {
      module = this.module.name;
    } else if (this.selectedType.name == "parentToChild") {
      module = this.parentModule.name;
    } else if (this.selectedType.name == "inRecord") {
      module = this.module.name;
    }
    // data for the first q-select
    const fields = this.dynamicModules[module].fields;
      const listNames: any = []
      for(const existingFieldMerge in this.existingFieldMerges) {        
        listNames.push(this.existingFieldMerges[existingFieldMerge].field)
      }
        for(const field in fields) {
          if(!listNames.includes(fields[field].name)) {
            this.fieldOptions.push({
              label: fields[field].label,
              value: fields[field].name,
              type: fields[field].type,
              fieldType: fields[field].fieldType,
              index: 0,
            });
          }
      }
    let index = 0;
    this.fieldOptions = sortList(this.fieldOptions, 'label')
    for (const field in this.fieldOptions) {
      this.fieldOptions[field].index = index;
      index++;
    }
  }

  // Function to get the options for the second field
  getSecondField() {
    this.secondOptions = []
    this.fieldOptionsSecondModule = []
    let module2 = "";
    if (this.selectedType == undefined) {
      module2 = this.parentModule.name;
    } else if (this.selectedType.name == "childToParent") {
      module2 = this.parentModule.name;
    } else if (this.selectedType.name == "parentToChild") {
      module2 = this.module.name;
    } else if (this.selectedType.name == "inRecord") {
      module2 = this.module.name;
    }
    // data for the second q-select
    const fieldsSecondModule = this.dynamicModules[module2].fields;
    Object.keys(fieldsSecondModule).forEach((field) => {
      // for(const field in fieldsSecondModule){
      if (
        this.fieldMerge.selectedField["type"] == fieldsSecondModule[field].type
      ) {
        this.fieldOptionsSecondModule.push({
          label: fieldsSecondModule[field].label,
          value: fieldsSecondModule[field].name,
          type: fieldsSecondModule[field].type,
          fieldType: fieldsSecondModule[field].fieldType,
          index: 0,
        });
      }
      // }
    });
    this.fieldOptionsSecondModule = sortList(this.fieldOptionsSecondModule, 'label');
    let i = 0;
    for (const field in this.fieldOptionsSecondModule) {
      this.fieldOptionsSecondModule[field].index = i;
      i++;
    }
  }

  // Function to add the fieldmerge to the table
  addFieldToFieldsToMerge(selectedField, ind, selectedFieldMerge, index) {
    // filter fieldOptions list
    if (selectedField == this.fieldsToMerge["field"]) {
      this.fieldOptions = sortList(this.fieldOptions, 'label');
    } else {
      this.fieldOptions.splice(ind, 1);
      let i = 0;
      for (const field in this.fieldOptions) {
        this.fieldOptions[field].index = i;
        i++;
      }
    }

    // filter fieldOptionsSecondModule list
    if (selectedFieldMerge == this.fieldsToMerge["field"]) {
      this.fieldOptionsSecondModule = sortList(this.fieldOptionsSecondModule, 'label');
    } else {
      let fieldMap = {};
      fieldMap = {
        field: this.fieldMerge.selectedField.value,
        value: this.fieldMerge.enterValue,
      };
      this.fieldsToMerge.push(fieldMap);
      this.fieldMerge.selectedField = { label: "", value: "" };
      this.fieldMerge.enterValue = "";
      this.fieldOptionsSecondModule.splice(index, 1);
      let i = 0;
      for (const field in this.fieldOptionsSecondModule) {
        this.fieldOptionsSecondModule[field].index = i;
        i++;
      }
      this.sendFieldMerge();
    }
  }
  // Function to filter the fieldmerge options
  filterFieldMergeOptions(val, update) {
    if (val === "") {
      this.options = this.fieldOptions;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.fieldOptions.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });    
  }
  // Function to filter the second selectfield merge options

  filterSecondFieldMergeOptions(val, update) {
    if (val === "") {
      this.secondOptions = this.fieldOptionsSecondModule;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.secondOptions = this.fieldOptionsSecondModule.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  // Emit function to return the fieldsToMerge
  @Emit("fieldMerge")
  sendFieldMerge() {
    return this.fieldsToMerge;
  }
}
