
import { Component, Prop, Vue } from "vue-property-decorator";
import TypeOfActions from "@/components/Settings/Actions/typeOfActions";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import AddRecord from "@/components/Settings/Actions/addRecord.vue";
import AddCopyProductLines from "@/components/Settings/Actions/addCopyProductLines.vue";
import AddRelatedFieldCopy from "@/components/Settings/Actions/addRelatedFieldCopy.vue";
import ActionHeader from "@/components/Settings/Actions/actionHeader.vue"

@Component({
  components: {
    AddEmailTemplate,
    AddFieldUpdate,
    AddRecord,
    AddCopyProductLines,
    AddRelatedFieldCopy,
    ActionHeader
  },
})
export default class AddNewActions extends Vue {
  @Prop() existingAction
  TypeOfActions = new TypeOfActions().TypeOfActions;
  addDescriptionDialog = "";
  addTitleDialog = "";
  actionTab = "";
  roProAction = [];
  showAddActionDialog = false;
  showAddAction = false;
  selectedTemplateName = "";
  selectedActions = false;
  typeOfActionDialog = false;

  // To set a templateName to the readonly input field
  setTemplateName(value: string) {
    this.selectedTemplateName = value;
  }
}
