import ReleaseNotesSettings from "../Sections/General/ReleaseNotes/Models/ReleaseNotesSettings"
import RBAC from "../Sections/UserManagement/RoleManagement/Models/RBAC";
import Setting from "./Setting"


export default class Settings {
    settings: Array<Setting> = []
    guard: any = new RBAC();

    addSetting(setting: Setting) {
        this.settings.push(setting)
    }

    getSections(): Array<string>{
        const sections = []
        for (const setting of this.settings) {
            if(!sections.includes(setting.section)) {
                sections.push(setting.section)
            }
        }
        return sections
    }
    getVisibleSections(): Array<string>{
        const sections = []
        for (const setting of this.getVisibleSettings()) {
            if(!setting.hidden){
                if(!sections.includes(setting.section)) {
                    sections.push(setting.section)
                }
            }
        }
        return sections
    }

    getAllSettings(): Array<Setting> {
        const settings = []
        for (const setting of this.settings) {
            if(!setting.hidden){
                settings.push(setting)
            }
        }
        return settings
    }


    getVisibleSettings(): Array<Setting> {
        const settings = []
        for(const setting of this.settings) {
            if(!setting.noRoleRequired) {
                if(this.isShowTab(setting.name)) {
                    settings.push(setting)
                }
            } else {
                settings.push(setting)
            }
        }
        return settings
    }
    
    isShowTab(setting) {
        return this.guard.checkSetting(setting);
    }
}
