
import store from "../../store";
import AbModel from "../Models/AbModel";
import { Timestamp } from '@firebase/firestore';

export default {
  data: () => ({
    listData: [],
    loading: false,
    pagination: {
      rowsPerPage: 20,
    }
  }),
  async mounted() {
    this.loading = true;
    await this.getAllList();
    this.loading = false;
  },
  methods: {
    async getAllList() {
      this.listData = await AbModel.getAllDocs(this.getFirePath());
    },
    async saveRecord(data) {
      if (data.ID) {
        this.setUpdated(data)
      } else {
        this.setCreated(data)
      }
      return await AbModel.saveDoc(
        this.getFirePath(),
        this.convertToObject(data, 'timestamp')
      );
    },
    async getRecord(ID) {
      return await AbModel.getDoc(this.getFirePath(), ID);
    },
    async deleteRecord(data) {
      return await AbModel.deleteDoc(
        this.getFirePath(),
        JSON.parse(JSON.stringify(data))
      );
    },
    getFirePath() {
      return "";
    },
    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    },
    getModuleTitle(moduleID) {
      const translationKey = `modules.${moduleID}.pluralName`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        return store.state.fieldTranslations[translationKey]
      }
      const moduleTitle = this.dynamicModules && this.dynamicModules[moduleID] ? this.dynamicModules[moduleID] : {} ;
      return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : moduleID
    },
    setCreated(data) {
      this.setUpdated(data);
      data.createdAt = Timestamp.now();
      if (store.state.currentUser && store.state.currentUser.ID && store.state.currentUser.name) {
        data.createdBy = store.state.currentUser;
      } else {
        data.createdBy = this.convertJson({ID: "", name: ""});
      }
    },
    setUpdated(data) {
      data.changedAt = Timestamp.now();
      if (store.state.currentUser && store.state.currentUser.ID && store.state.currentUser.name) {
        data.changedBy = store.state.currentUser;
      } else {
        data.changedBy = this.convertJson({ID: "", name: ""});
      }
    },
    convertJson(data) {
      data = JSON.parse(JSON.stringify(data));
      return data;
    },
    convertToObject(data, dateType = 'timestamp', notNeedJsonStringifyFields = []) {
      const saveTs = {};
      const saveFields = {};
      notNeedJsonStringifyFields.map((f) => {
        if (data[f]) {
          saveFields[f] = data[f];
        }
      });
      for (const f in data) {
        if (data[f] && typeof data[f].toDate === "function") {
          saveTs[f] = data[f];
        }
      }
      data = JSON.parse(JSON.stringify(data));
      for (const f in saveTs) {
        data[f] = saveTs[f];
      }
      for (const f in saveFields) {
        data[f] = saveFields[f];
      }
      for (const f in data) {
        if (data[f] && Object.prototype.hasOwnProperty.call(data[f],'seconds') && Object.prototype.hasOwnProperty.call(data[f], 'nanoseconds')) {
          data[f] = this.fromObjToTimestamp(data[f], dateType);
        } else {
          if (Array.isArray(data[f])) {
            data[f].map(d => {
              for(const v in d) {
                if (d[v] && Object.prototype.hasOwnProperty.call(d[v],'seconds') && Object.prototype.hasOwnProperty.call(d[v], 'nanoseconds')) {
                  d[v] = this.fromObjToTimestamp(d[v], dateType);
                }
              }
            });
          }
        }
      }
      return data;
    },
    fromObjToTimestamp(o, dateType = 'timestamp') {
      const sec = o.seconds
      const timestamp = Timestamp.fromMillis(sec * 1000);
      if (dateType === 'timestamp') {
        return timestamp;
      }
      if (dateType === 'ISO-8601') {
        return timestamp.toDate().toISOString();
      }
    }
  },
  watch: {},
  computed: {},
};
