
  import { Component, Vue } from "vue-property-decorator";
  import db from "@/db";
  import store from "@/store";
  import { Timestamp } from '@firebase/firestore';
  import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";

  class User {
    ID = "";
    name = "";
  }

  class CurrentTemplateClass {
    ID = null;
    name = "";
    type = "";
    module = "";
    fileType = "";
    fileName = "";
    fields = Array<{}>();
    reportFilters = Array<{}>();
    skipHeaders = false;
    createdAt: any = Timestamp.now();
    changedAt: any = Timestamp.now();
    createdBy: User = new User();
    changedBy: User = new User();
  }

  @Component({
    mixins: [CrudMixinVue],
  })

  export default class RoleManagement extends Vue {
      [x: string]: any;
      selected = []
      roleColumns = [
        {
          name: 'name',
          label: this.$t('reports.headers.name'),
          field: 'name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'changedAt',
          label: this.$t('reports.headers.changedAt'),
          field: 'changedAt',
          align: 'center',
          sortable: true,
        },
        {
          name: 'edit',
          label: '',
          field: 'edit',
          align: 'center',
          sortable: false,
        },
        {
          name: 'delete',
          label: '',
          field: 'delete',
          align: 'center',
          sortable: false,
        },
      ]
      dataRoles: any = []
      pagination = {
        rowsPerPage: 20
      }
      deleteBtnVisible = false
      deleteRolesModal = false
      showDeleteDialog = false
      showCopyDialog = false
      currentTemplate = new CurrentTemplateClass()
    
    mounted() {
      this.getRoles();
    }

    getFirePath() {
      return "roles";
    }

    async getRoles() {
      this.dataRoles = [];
      const dataDoc = await db.collection(`tenants/${store.state.tenantID}/roles`).get();
      if(dataDoc.empty) {
        
        return;
      }
      dataDoc.forEach(doc => {
        const dataDoc = doc.data();
        this.dataRoles.push({ID: `${dataDoc.ID}`, name: dataDoc.name || dataDoc.ID, changedAt: dataDoc.changedAt});
      });
    }

    onRowClick(row) {
      this.$router.push({path: "/settings/role-management/edit/" + row.ID});
    }

    addRole() {
      this.$router.push({path: "/settings/role-management/add"});
    }

    onClickDeleteBtn(props) {
      this.showDeleteDialog = true;
      this.currentTemplate = props.row;
    }

    async onTemplateDelete() {
      await this.deleteRecord(this.currentTemplate);
      await this.getAllList();
      this.currentTemplate = new CurrentTemplateClass();
      store.state.alertMessage = "delete";
      this.getRoles()
    }

    onCancelDeleteDialog() {
      this.currentTemplate = new CurrentTemplateClass();
    }

    converDate(isostr) {
      if (!isostr) {
        return '';
      }
      const date = new Date(isostr.seconds * 1000);
      return date.toLocaleString('nl-Nl')
    }
  }
