import UserConfiguration from '@/components/Settings/Sections/UserManagement/UserConfiguration/Components/UserConfiguration.vue'
import EditUser from '@/components/Settings/Sections/UserManagement/UserConfiguration/Components/EditUser.vue'

export default [
    {
        path: '/settings/user-configuration', 
        name: 'user-configuration', 
        component: UserConfiguration
    },
    {
        path: "/settings/user-configuration/edit/:id",
        name: "user-configuration-edit",
        component: EditUser
    },
]