
const dbType = localStorage.getItem("databaseProd") || "prod";
// const dbType = "dev";

let urls = {};
switch (dbType) {
  case "prod":
    urls = {
      NYLAS_REDIRECT_URI : 'https://yellowq.app/nylas-web-hook',
      SYNC_ES_URI: 'https://gae-elasticsearch-dot-bitplatform-production.appspot.com/index-modules',
      DASHBOARD_SYNC_ES_URI: 'https://gae-elasticsearch-dot-bitplatform-production.appspot.com/index-modules-dashboards',
      getAccessTokenURL: "https://europe-west3-bitplatform-production.cloudfunctions.net/jwt",
      apiUrlCF: process.env.YELLOWQ_API_URL_CF || 'https://europe-west3-bitplatform-production.cloudfunctions.net',
      userAPIGateway: 'https://yellowq-a7rdfa6a.ew.gateway.dev',
    }
    break;
    case "test":
      break;
      case "dev":
        urls = {
          NYLAS_REDIRECT_URI : 'http://localhost:8080/nylas-web-hook',
          getAccessTokenURL : "https://us-central1-bitplatform-develop.cloudfunctions.net/jwt",
          apiUrlCF: process.env.YELLOWQ_API_URL_CF || 'https://europe-west3-bitplatform-develop.cloudfunctions.net',
          apiUrlCF_LOCAL: 'http://localhost:5001/bitplatform-develop/europe-west3',
          userAPIGateway: 'https://yellowq-a7rdfa6a.ew.gateway.dev',
          SYNC_ES_URI: 'https://gae-elasticsearch-dot-bitplatform-develop.appspot.com/index-modules',
          DASHBOARD_SYNC_ES_URI: 'https://gae-elasticsearch-dot-bitplatform-develop.appspot.com/index-modules-dashboards'
    }
    break;
  default:
    console.log("No database connection!");
}


export default urls;
