
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import draggable from 'vuedraggable';
import Dialog from "@/components/Parts/Dialog.vue";
import VueFontAwesomePicker from "vfa-picker";
import {colors} from "quasar";
import store from "@/store";
import db from "@/db";
import sortList from "@/common/helpers/utilities";
import * as lodash from "lodash";

Vue.use(VueFontAwesomePicker)

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {draggable, Dialog},
})

export default class StatusConfiguration extends Vue {
  [x: string]: any;
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  availableStatusesInPlanboard = []
  modulesList = []
  selectedModule = ''
  previousSelectedModule = ''
  statuses = []
  missingStatuses = []
  requiredFieldOptions = []
  statusConfigModal = false;
  statusSettingsModal = false;
  showDeleteDialog = false;
  availableInPlanboardDialog = false;
  showMissingStatuses = false;
  showChecklist = true;
  showRequiredSections = true;
  currentStatus = {}
  statusName = ''
  statusLabel = ''
  statusIcon = ''
  statusTooltip = ''
  statusInfo = ''
  statusColor = ''
  statusConfigStep = 1
  selectedRequiredSection = null
  availableInPlanboard = []
  reg = /^[A-Za-z_]+$/
  newStatus = false
  requiredFields = []
  canTransitionTo = []
  checklist = []
  requiredSections = {}

  async mounted() {
    this.statusColor = "#000000"
    this.modulesList = this.getModulesList()
    this.modulesList = sortList(this.modulesList, 'label');
    this.selectedModule = this.modulesList[0]
    this.previousSelectedModule = this.modulesList[0]
    this.getExistingStatuses(false)
    this.checkMissingStatuses()
    this.getStatusesAvailableInPlanboard()
  }

  async beforeRouteLeave (to, from , next) {
    const oldData: any = await this.oldData
    let showAlert = false
    if(oldData.length === this.statuses.length) {
      for(const index in this.statuses) {
        if(!lodash.isEqual(oldData[index],this.statuses[index])) {
          showAlert = true
        }
      }
      if(showAlert) {
        const message: any = this.$t('common.messages.unsavedWarning')
        const answer = window.confirm(message);
        if (answer) {
          next()
        }
      } else next()
    } else if(oldData.length != this.statuses.length) {
      const message: any = this.$t('common.messages.unsavedWarning')
      const answer = window.confirm(message);
      if (answer) {
        next()
      }
    } else next()
  }

  getModulesList() {
    const modulesList = Array<{ label: string; value: string }>();
    Object.keys(this.dynamicModules).forEach((module) => {
      if(module != 'planboard' && module != 'dashboards') {
        modulesList.push({
          label: this.getModuleTitle(module),
          value: module,
        });
      }
    });
    return modulesList;
  }

  getModuleTitle(moduleID) {
    const translationKey = `modules.${moduleID}.pluralName`
    if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
      return store.state.fieldTranslations[translationKey]
    }
    const moduleTitle = this.dynamicModules && this.dynamicModules[moduleID] ? this.dynamicModules[moduleID] : {} ;
    return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : moduleID
  }

  getFields() {
    this.requiredFieldOptions = []
    const fields = this.dynamicModules[this.selectedModule['value']].fields
    for(const field in fields) {
      if(!fields[field].hidden) {
        this.requiredFieldOptions.push(fields[field])
      }
    }

    this.requiredFieldOptions = sortList(this.requiredFieldOptions, 'label');
  }

  async getStatusesAvailableInPlanboard() {
    const availableInPlanboard = this.dynamicModules['appointments'].fields.status['availableInPlanboard']
    for(const status of this.statuses) {      
      if (availableInPlanboard.includes(status.label)) {
        this.availableInPlanboard.push(true)
        this.availableStatusesInPlanboard.push(status.label)
      } else {
        this.availableInPlanboard.push(false)
      }
    }
  }

  sortList(fields, keyToSort) {
    return fields.sort((a,b) => {
      const ak = a ? (a[keyToSort] || '') : ''
      const bk = b ? (b[keyToSort] || '') : ''
      return (parseInt(ak) > parseInt(bk)) ? 1 : ((parseInt(bk) > parseInt(ak)) ? -1 : 0)
    })
  }

  async getExistingStatuses(validate) {
    let validateChanges = true
    if(validate) {
      validateChanges = await this.selectedModuleHandler()
    }
    if(validateChanges) {
      this.statuses = []
      for (const m in this.dynamicModules) {
        const module = this.dynamicModules[m]
        if (module['id'] == this.selectedModule['value']) {
          for(const field in module.fields['status']) {
            if(field == 'metaData') {
              for (const status in module.fields['status'][field]) {
                this.statuses.push(module.fields['status'][field][status]);
              }
            }
          }
        }
      }
      this.statuses = this.sortList(this.statuses, 'order');
      this.getFields()
      this.checkMissingStatuses()
      this.previousSelectedModule = this.selectedModule
    } else {
      this.selectedModule = this.previousSelectedModule
    }

  }

  async checkMissingStatuses() {
    this.showMissingStatuses = false
    this.missingStatuses = []
    const dropDownValues = this.dynamicModules[this.selectedModule['value']].dropDownValues.status
    const metaDataLabels = []
  // this.dynamicModules[this.selectedModule['value']].fields.status.metaData
    for(const s in this.statuses) {
      const status = this.statuses[s]
      metaDataLabels.push(status.label)
    }

    for(const dropDownValue of dropDownValues) {
      if(!metaDataLabels.includes(dropDownValue)) {
        const status = {
          label: dropDownValue,
          missing: true,
          color: "#ffffff",
          icon: "",
          name: "",
          order: "",
          tooltip: "",
          info: "",
        }
        this.missingStatuses.push(status)
      }
    }
    if(this.missingStatuses.length) {this.showMissingStatuses = true}
  }

  getStatusLabel(item) {
    for (const status in this.statuses) {
      if(this.statuses[status].name == item) {
        return this.statuses[status].label
      }
    }
  }

  getFieldLabel(field) {
    for(const f in this.requiredFieldOptions) {
      if(field == this.requiredFieldOptions[f].name) {
        return this.requiredFieldOptions[f].label
      }
    }
  }

  saveStatusSettings() {
    const status = {
      canTransitionTo: [],
      color: this.statusColor,
      icon: this.statusIcon,
      label: this.statusLabel,
      name: this.statusName,
      requiredFields: [],
      tooltip: this.statusTooltip,
      info: this.statusInfo
    }

    for(const s of this.statuses) {
      if(this.newStatus && s.name === status.name) { 
        this.$q.notify({
          color: "negative",
          message: "Deze technische naam is al in gebruik.",
        });
        return;
      }
    }
    if (!this.reg.test(status.name) ? true : false) {
        this.$q.notify({
          color: "negative",
          message: "Vul een geldig technische naam in.(Een technische naam kan alleen letters en _ bevatten)",
        });
        return;
    } else if (status.label == null || status.label == '') {
        this.$q.notify({
          color: "negative",
          message: "Vul een geldig label in.",
        });
        return;
    } else if (status.tooltip == null || status.tooltip == '') {
        this.$q.notify({
          color: "negative",
          message: "Vul een geldig tooltip in.",
        });
        return;
    }

    if(this.currentStatus) {
      if(this.currentStatus['missing']&& status.name) {
        this.showMissingStatuses = false
        this.deleteStatus(this.currentStatus)
        delete status['missing']
        this.statuses.push(status);
        if(this.missingStatuses.length > 0) {this.showMissingStatuses = true}
        this.statusSettingsModal = false
      } else {
        status.canTransitionTo = this.currentStatus['canTransitionTo']
        status.requiredFields = this.currentStatus['requiredFields']
  
        for(const s in this.statuses) {
          if(this.statuses[s].name == this.currentStatus['name']) {
            this.statuses[s] = status
          }
        }
      this.statusSettingsModal = false
      this.newStatus = false
      }
    } else {
      this.statuses.push(status);
      this.statusSettingsModal = false
    }
    // this.checkMissingStatuses()
  }

  createNewStatus() {
    this.statusName = ''
    this.statusLabel = ''
    this.statusIcon = ''
    this.statusTooltip = ''
    this.statusInfo = ''
    this.statusColor = ''
    this.currentStatus = ''
    this.statusSettingsModal = true
    this.newStatus = true
  }

  openSettingsModal(status) {
    this.currentStatus = status
    this.statusSettingsModal = true
    this.statusName = this.currentStatus['name']
    this.statusLabel = this.currentStatus['label']
    this.statusIcon = this.currentStatus['icon']
    this.statusTooltip = this.currentStatus['tooltip']
    this.statusInfo = this.currentStatus['info']
    this.statusColor = this.currentStatus['color']
    this.newStatus = false
  }

  setColor (name) {
    if (this.showPreview) {
      colors.setBrand(name, this.colorSchema[name])
    }
  }

  async saveSettings() {
    if(this.missingStatuses.length){
      console.log(this.missingStatuses, "er mist een status");
      
    } else {
      const metadata = {}
      const statusLabels = []
      
      for(const status of this.statuses ){
        status.order = this.statuses.indexOf(status).toString()
        metadata[status['name']] = status
        statusLabels.push(status['label'])
      }
      
      if(this.selectedModule['value'] == 'appointments') {
        await db.collection('tenants').doc(store.state.tenantID).collection('modules').doc(this.selectedModule['value']).update({
          'fields.status.metaData': metadata,
          'fields.status.availableInPlanboard': this.availableStatusesInPlanboard,
          'dropDownValues.status': statusLabels
        }).then(() =>{
          this.$q.notify({ message: `${this.$t('common.messages.recordChanged')}`, color: 'positive'})
          this.$router.go(0)
        }).catch(err => console.error(err))
      } else {
        await db.collection('tenants').doc(store.state.tenantID).collection('modules').doc(this.selectedModule['value']).update({
          'fields.status.metaData': metadata,
          'dropDownValues.status': statusLabels
        }).then(() =>{
          this.$q.notify({ message: `${this.$t('common.messages.recordChanged')}`, color: 'positive'})
          this.$router.go(0)
        }).catch(err => console.error(err))
      }
    }
  }

  openDeleteModal(status) {
    this.showDeleteDialog = true;
    this.currentStatus = status
  }

  deleteStatus(status) {
    if(status.missing) {
      for(const s of this.missingStatuses) {
        if(s.label == status.label) {
          this.missingStatuses.splice(this.missingStatuses.indexOf(s), 1)
        }
      }
    } else {
      for(const s of this.statuses) {
        if(s.name == status.name) {
          this.statuses.splice(this.statuses.indexOf(s), 1)
        }
      }
      this.availableStatusesInPlanboard.splice(this.availableStatusesInPlanboard.indexOf(status.label), 1)
    }
    for(const s of this.statuses) {
      if (s.canTransitionTo && s.canTransitionTo.includes(status.name)) {
        s.canTransitionTo.splice(s.canTransitionTo.indexOf(status.name), 1)
      }
    }
  }

  openStatusConfigModal(status) {
    this.currentStatus = status
    this.statusConfigStep = 1
    this.statusConfigModal = true
  }

  addItemToChecklist() {
    this.showChecklist = false
    if(this.currentStatus['checklist']) {
      this.currentStatus['checklist'].push('')
    } else {
      this.currentStatus['checklist'] = ['']
    }
    this.showChecklist = true
  }

  addRequiredSection() {
    this.showRequiredSections = false
    if(this.currentStatus['requiredSections']) {
      this.currentStatus['requiredSections'][this.selectedRequiredSection.name] = { minimumAmount: 0}
    } else {
      this.currentStatus['requiredSections']= {}
      this.currentStatus['requiredSections'][this.selectedRequiredSection.name] = { minimumAmount: 0}
    }
    this.showRequiredSections = true
  }

  removeItemFromChecklist(index) {
    this.showRequiredSections = false
    this.currentStatus['checklist'].splice(index, 1)
    this.showRequiredSections = true
  }

  removeRequiredSection(index) {
    this.showRequiredSections = false
    delete this.currentStatus['requiredSections'][index]
    this.showRequiredSections = true
  }



  setRequiredSectionValue() {
    this.showRequiredSections = false
    this.showRequiredSections = true
  }

  getRequiredSectionLabel(section) {
    for(const s in this.dynamicModules[this.selectedModule['value']].sections) { 
      if(s == section) { 
        return this.dynamicModules[this.selectedModule['value']].sections[s].label
      } 
    }
  }

  onInputSelectStatus(value, label) {
    if(value) {
      this.availableStatusesInPlanboard.push(label)
    } else {
      this.availableStatusesInPlanboard.splice(this.availableStatusesInPlanboard.indexOf(label), 1)
    }
  }

  removeStatusFromCanTransitionTo(index) {
    this.currentStatus['canTransitionTo'].splice(index, 1)
  }

  get requiredSectionsOptions() {
    if(!this.dynamicModules[this.selectedModule['value']] || !this.dynamicModules[this.selectedModule['value']].sections) {
      return []
    }

    let usedSections = []
    if(this.currentStatus['requiredSections']) {
      usedSections = Object.keys(this.currentStatus['requiredSections'])
    }
    
    const sections = []
    for(const s in this.dynamicModules[this.selectedModule['value']].sections) {
      const section = this.dynamicModules[this.selectedModule['value']].sections[s]
      if(!usedSections.includes(section.name) && !section.hidden) {
        if(section.type == 'relatedRecords') {
          sections.push(section)
        } else if (section.type == 'productLines') { 
          sections.push(section)
        } else if (section.type == 'attachments') { 
          sections.push(section)
        } else if (section.type == 'media') { 
          sections.push(section)
        }
      }
    }
    return sections
  }

  get availableStatuses() {
    const availableStatuses = []
    for(const status of this.statuses) {
      availableStatuses.push(status)
    }
    availableStatuses.splice(availableStatuses.indexOf(this.currentStatus), 1)
    return availableStatuses
  }

  async firebaseData() {
    const doc = await db.collection(`/tenants/${store.state.tenantID}/modules/`).doc(this.previousSelectedModule['value']).get()
    let data: any;
    if(doc.exists) {
      data = doc.data()
    }
    let metaData = [];
    if(data && data.fields && data.fields.status && data.fields.status.metaData) {
      for(const s in data.fields.status.metaData) {
        const status = data.fields.status.metaData[s]
        metaData.push(status)
      }
      metaData = this.sortList(metaData, 'order')
    }
    return metaData
  }

  get oldData() {
    return this.firebaseData()
  }


  async selectedModuleHandler () {
    const oldData: any = await this.oldData
    let showAlert = false
    
    if(oldData.length === this.statuses.length) {
      for(const index in this.statuses) {
        if(!lodash.isEqual(oldData[index],this.statuses[index])) {
          showAlert = true
        }
      }
      if(showAlert) {
        const message: any = this.$t('common.messages.unsavedWarning')
        const answer = window.confirm(message);
        return answer
      }
      return true
    } else if(oldData.length != this.statuses.length) {
      const message: any = this.$t('common.messages.unsavedWarning')
      const answer = window.confirm(message);
      return answer
    }
  }
}
