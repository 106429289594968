
import { Component, Vue } from "vue-property-decorator";
import db from "../../../../../../db";
import store from "../../../../../../store";
import ISO_Codes from "i18n-iso-countries";
import BaseFieldImage from "@/components/Base/BaseFieldImage.vue";

@Component({
  mixins: [],
  components: {BaseFieldImage},
})
export default class OrganisationSettings extends Vue {
  companyName = null
  invoiceStreet = null
  invoiceHousNumber = null
  invoiceAddition = null;
  invoiceZipCode = null
  invoiceCity = null
  invoiceCountry = null
  invoiceEmail = null
  invoiceBankAccount = null
  invoiceTNV = null
  invoiceKVK = null
  invoiceBTW = null
  languageCode = null
  contactPhone = null
  contactEmail = null
  workDayStart = 0;
  workDayEnd = 0;
  swift = null
  landCodes = [];
  countries = [];
  languages = [];
  images = { 
    companyLogo: [{ url: "" }], 
    PDFHeader: [{url: "" }], 
    PDFFooter: [{url: "" }], 
    PDFWatermark: [{url: "" }], 
  };

  mounted() {
    this.landCodes = Object.keys(ISO_Codes.getAlpha3Codes());
    this.loadOrganisationSettings();
  }

  async saveSettings() {
    let companyLogoURL = null;
    let PDFHeaderURL = null;
    let PDFFooterURL = null;
    let PDFWatermarkURL = null;
    if (this.images && this.images.companyLogo && this.images.companyLogo[0].url) {
      companyLogoURL = this.images.companyLogo[0].url;
    }
    if (this.images && this.images.PDFHeader && this.images.PDFHeader[0].url) {
      PDFHeaderURL = this.images.PDFHeader[0].url;
    }
    if (this.images && this.images.PDFFooter && this.images.PDFFooter[0].url) {
      PDFFooterURL = this.images.PDFFooter[0].url;
    }
    if (this.images && this.images.PDFWatermark && this.images.PDFWatermark[0].url) {
      PDFWatermarkURL = this.images.PDFWatermark[0].url;
    }
    const data = {
      companyName: this.companyName ? this.companyName : null,
      companyLogo: companyLogoURL,
      PDFHeader: PDFHeaderURL,
      PDFFooter: PDFFooterURL,
      PDFWatermark: PDFWatermarkURL,
      locationStreet: this.invoiceStreet ? this.invoiceStreet : null,
      locationHouseNumber: this.invoiceHousNumber ? this.invoiceHousNumber : null,
      locationAddition: this.invoiceAddition ? this.invoiceAddition : null,
      locationZipCode: this.invoiceZipCode ? this.invoiceZipCode : null,
      locationCity: this.invoiceCity ? this.invoiceCity : null,
      locationCountry: this.invoiceCountry ? this.invoiceCountry : null,
      invoiceEmail: this.invoiceEmail ? this.invoiceEmail : null,
      invoiceBankAccount: this.invoiceBankAccount ? this.invoiceBankAccount : null,
      ascription: this.invoiceTNV ? this.invoiceTNV : null,
      COC_Number: this.invoiceKVK ? this.invoiceKVK : null,
      VAT_Number: this.invoiceBTW ? this.invoiceBTW : null,
      defaultLanguageCode: this.languageCode ? this.languageCode : null,
      contactPhone: this.contactPhone ? this.contactPhone : null,
      contactEmail: this.contactEmail ? this.contactEmail : null,
      swift: this.swift ? this.swift : null,
      workDayStart: this.workDayStart ? this.workDayStart : null,
      workDayEnd: this.workDayEnd ? this.workDayEnd : null,
    };
    await db
      .collection(`tenants/${store.state.tenantID}/settings`)
      .doc("organization")
      .set(data, { merge: true })
      .then(() => {
        store.state.alertMessage = "update";
      });
  }
  async loadOrganisationSettings() {
    await db
      .collection(`tenants/${store.state.tenantID}/settings`)
      .doc("organization")
      .get()
      .then((settings) => {
        if (settings.exists) {
          const data = settings.data();
          this.companyName = data.companyName;
          this.invoiceStreet = data.locationStreet;
          this.invoiceHousNumber = data.locationHouseNumber;
          this.invoiceAddition = data.locationAddition;
          this.invoiceZipCode = data.locationZipCode;
          this.invoiceCity = data.locationCity;
          this.invoiceCountry = data.locationCountry;
          this.invoiceEmail = data.invoiceEmail;
          this.invoiceBankAccount = data.invoiceBankAccount;
          this.invoiceTNV = data.ascription;
          this.invoiceKVK = data.COC_Number;
          this.invoiceBTW = data.VAT_Number;
          this.languageCode = data.defaultLanguageCode;
          this.contactPhone = data.contactPhone;
          this.contactEmail = data.contactEmail;
          this.swift = data.swift;
          this.workDayStart = data.workDayStart;
          this.workDayEnd = data.workDayEnd;
          this.images.companyLogo[0].url = data.companyLogo;
          this.images.PDFHeader[0].url = data.PDFHeader;
          this.images.PDFFooter[0].url = data.PDFFooter;
          this.images.PDFWatermark[0].url = data.PDFWatermark;
        }
      });
  }

  async setImage(file, name) {
    file.storageRef = "settings/design/images/";
    file.toPublic = true;
    file.onlyRef = true;
    file.addTenant = true;
    const uploadedFile = await store.dispatch("uploadFileToStorage", file);
    this.$set(this.images, name, uploadedFile);
  }

  async setCompanyLogo(bgImgs) {
    await this.setImage(bgImgs[0], "companyLogo");
    return true;
  }
  async setPDFHeader(images) {
    await this.setImage(images[0], "PDFHeader");
    return true;
  }
  async setPDFFooter(images) {
    await this.setImage(images[0], "PDFFooter");
    return true;
  }
  async setPDFWatermark(images) {
    await this.setImage(images[0], "PDFWatermark");
    return true;
  }

  onFileRejected() {
    this.$q.notify({
      type: "negative",
      message: "File did not pass validation constraints",
    });
  }

  removeBackgroundLoginImage() {
    ///this.images.background = '';
  }

  get companyLogoURL() {
    return this.images && this.images.companyLogo ? this.images.companyLogo[0].url : "";
  }
  
  get PDFHeaderURL() {
    return this.images && this.images.PDFHeader ? this.images.PDFHeader[0].url : "";
  }
  
  get PDFFooterURL() {
    return this.images && this.images.PDFFooter ? this.images.PDFFooter[0].url : "";
  }
  
  get PDFWatermarkURL() {
    return this.images && this.images.PDFWatermark ? this.images.PDFWatermark[0].url : "";
  }
}
