import store from "@/store";

export default class LicenseManager {
  modules = [];
  licenses = {};

  constructor() {
    this.licenses = store.state.currentUserFull.licensesPerUser || {};
    this.modules = store.state.modulesWithLicensesPerUser || [];
  }

  checkUserLicensePerModule(module: string) {
    if (this.modules.includes(module)) {
      const license = this.licenses[module] || {};
      if (license.active) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

}
