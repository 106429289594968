
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import FieldUpdate from "@/components/Settings/Actions/fieldUpdate.vue";
import FieldMerge from "@/components/Settings/Actions/fieldMerge.vue";
import AddRoboticProcess from "@/components/Settings/Sections/ProcessOptimization/RoboticProcessBuilder/Components/AddRoboticProcess.vue";
import RelatedFieldCopy from "@/components/Settings/Actions/relatedFieldCopy.vue";
import { SendSmsAction } from "@/components/Settings/Actions/actions";
import sortList from "@/common/helpers/utilities";

@Component({
  components: {
    AddEmailTemplate,
    AddFieldUpdate,
    FieldUpdate,
    FieldMerge,
    AddRoboticProcess,
    RelatedFieldCopy
  },
})
export default class AddSendSMS extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  @Prop() existingAction;
  @Prop() openExistingAction;
  typeOfFieldAction = [
    { name: "inRecord", title: "InRecord", label: "In Record" },
    { name: "childToParent", title: "ChildToParent", label: "Child To Parent" },
    { name: "parentToChild", title: "ParentToChild", label: "Parent To Child" },
  ];
  isActionTrue = true;
  isSourceModuleTrue = false;
  toggledSelectField = false;
  selectedChildTypes = false;
  selectedFieldsTypes = false;
  selectedParentTypes = false;
  childModuleSelected = false;
  parentModuleSelected = false;
  selectedTypeOfFieldAction = "";
  addTitleInDialog = "";
  addDescriptionInDialog = "";
  selectedChildModule = "";
  selectedParentModule = "";
  selectedFieldTypes = "";
  showModulesList: Array<any> = [];
  showModules: Array<any> = [];
  options = this.showModules;
  refOptions = this.showModulesList;
  sendSms = new SendSmsAction();
  fieldsToUpdate = [];
  fieldsToMerge = [];
  useDropDownPhoneNumber = false;
  variableFields: any = []
  variablePhoneFields: any = []

  async mounted() {
    this.getActionTypeFunction()     
    if(this.openExistingAction == true){      
      await this.sendSms.import(this.existingAction.ID, this.dynamicModules)
      this.getModuleFields()      
    }            
    let showModules = {};
    for (const modules in this.dynamicModules) {
      if (this.dynamicModules[modules].pluralName != undefined) {
        showModules = {
          label: this.dynamicModules[modules].pluralName,
          name: this.dynamicModules[modules].id,
        };
      } else {
        showModules = {
          label: this.dynamicModules[modules].name,
          name: this.dynamicModules[modules].id,
        };
      }
      this.showModules.push(showModules);
    }
  }

  getModuleFields() {
    this.variableFields = []
    this.variablePhoneFields = []
    for(const field in this.dynamicModules[this.sendSms.module.name].fields) {
        this.variableFields.push(this.dynamicModules[this.sendSms.module.name].fields[field])
        if(this.dynamicModules[this.sendSms.module.name].fields[field].name.toLowerCase().includes("phone") || this.dynamicModules[this.sendSms.module.name].fields[field].name.toLowerCase().includes("mobile")) {
          this.variablePhoneFields.push(this.dynamicModules[this.sendSms.module.name].fields[field])
        }
    } 
    this.variablePhoneFields = sortList(this.variablePhoneFields, 'label')
    this.variableFields = sortList(this.variableFields, 'label')
  }

  // Filter function 
  filterFn(val, update) {
    if (val === "") {
      this.options = this.showModules;
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.showModules.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  addFieldtoMessage(field) {
    this.sendSms.message = this.sendSms.message + `{{${field.name}}}`    
  }

  addFieldtoReciever(field) {
    this.sendSms.to = `{{${field.name}}}`    
  }

  // emit function to save action in actionHeader.vue
  @Emit("getActionType")
  getActionTypeFunction() {      
      const map = {
        value: this.sendSms,
        type: "SendSMS"
      }
      return map   
  }
}
