
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { State } from "vuex-class";
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
import store from "@/store";
import AddFieldUpdate from "@/components/Settings/Actions/addFieldUpdate.vue";
import AddCopyProductLines from "@/components/Settings/Actions/addCopyProductLines.vue";
import AddRecord from "@/components/Settings/Actions/addRecord.vue";
import AddRelatedFieldCopy from "@/components/Settings/Actions/addRelatedFieldCopy.vue";
import AddEmailTemplate from "@/components/Settings/Actions/addEmailTemplate.vue";
import AddCreatePDF from "@/components/Settings/Actions/addCreatePDF.vue";
import AddSendSMS from "@/components/Settings/Actions/addSendSMS.vue";
import { CreatePdfAction, SendEmailAction, SendSmsAction, RelatedFieldCopyAction, CreateRecordAction, CopyProductLinesAction, FieldUpdateAction } from "@/components/Settings/Actions/actions";
import TypeOfActions from "@/components/Settings/Actions/typeOfActions";


@Component({
  mixins: [CrudMixinVue],
  components: {
      AddFieldUpdate,
      AddCopyProductLines,
      AddRecord,
      AddRelatedFieldCopy,
      AddEmailTemplate,
      AddSendSMS,
      AddCreatePDF
  },
})


export default class ActionHeader extends Vue {
  [x: string]: any;
  @State("dynamicModules") dynamicModules;
  @Prop() firePath;
  @Prop() selectedTypeOfAction;
  @Prop() existingAction;
  @Prop() openExistingAction;
  @Prop() editAction;
  @Prop() actionActive;
  @Prop() roProModule;
  sendEmailAction = new SendEmailAction()
  relatedFieldCopyAction = new RelatedFieldCopyAction()
  createRecordAction = new CreateRecordAction()
  copyProductLineAction = new CopyProductLinesAction()
  fieldUpdateAction = new FieldUpdateAction()
  sendSmsAction = new SendSmsAction()
  createPdfAction = new CreatePdfAction()
  actionType = "";
  actionTab= "";
  TypeOfActions = new TypeOfActions().TypeOfActions;
  activeToggle = false

  mounted() {        
    if(this.actionActive == undefined){
      this.activeToggle = true
    } else {
      this.activeToggle = this.actionActive  
    }
    
    if(this.openExistingAction == false) {
      this.openExistingAction = false
 
    } else if (this.openExistingAction == true) {
      this.openExistingAction
       = true
    }
    // this.selectedActionActive() 
  }

  // emit function to reload the actions on other page
  @Emit("loadActions")
  getActionsFromFirebase() {
      return false   
  }

  // function to get the type of action and show the correct one.
  getActionTypeFunction (value: any) {      
    if(value.type == "SendEmail") {
      this.sendEmailAction = value.value
      this.actionType = value.type
    } else if (value.type == "RelatedFieldCopy") {
      this.relatedFieldCopyAction = value.value
      this.actionType = value.type
    } else if (value.type == "CreateRecord") {
      this.createRecordAction = value.value
      this.actionType = value.type
    } else if (value.type == "CopyProductLine") {
      this.copyProductLineAction = value.value
      this.actionType = value.type
    } else if (value.type == "FieldUpdate") {
      this.fieldUpdateAction = value.value
      this.actionType = value.type
    } else if (value.type == "SendSMS") {
      this.sendSmsAction = value.value
      this.actionType = value.type
    } else if (value.type == "GeneratePDF") {
      this.createPdfAction = value.value
      this.actionType = value.type
    }
  }

  // the firepath where to get the actions from
  getFirePath() {
    return "actions/" + this.firePath + "/records";
  }
// function to save the action and checks wich action needs to be saved
  async saveAction() {    
    const message: any = this.$t('roboticProcessBuilder.headers.actionNotSaved')
    if (this.actionType == "SendEmail"){
      if (this.sendEmailAction.validateJsonData() == true) {
        console.log(this.sendEmailAction);
        
        await this.saveRecord(this.sendEmailAction.export());
        store.state.alertMessage = "add";
      } else {
        this.$q.notify({
          message: message,
          color: "negative",
          icon: "warning",
        });
      }
    } else if (this.actionType == "RelatedFieldCopy") {
      if (this.relatedFieldCopyAction.validateJsonData() == true) {
        await this.saveRecord(this.relatedFieldCopyAction.export());
        store.state.alertMessage = "add";
      } else {
        this.$q.notify({
          message: message,
          color: "negative",
          icon: "warning",
        });
      }
    } else if (this.actionType == "CreateRecord") {
      if (this.createRecordAction.validateJsonData() == true) {
        await this.saveRecord(this.createRecordAction.export());
        store.state.alertMessage = "add";
      } else {
        this.$q.notify({
          message: message,
          color: "negative",
          icon: "warning",
        });
      }
    } else if (this.actionType == "CopyProductLine") {
      if (this.copyProductLineAction.validateJsonData() == true) {
        await this.saveRecord(this.copyProductLineAction.export(this.dynamicModules));
        store.state.alertMessage = "add";
      } else {
        this.$q.notify({
          message: message,
          color: "negative",
          icon: "warning",
        });
      }
    } else if (this.actionType == "FieldUpdate") {
      if (this.fieldUpdateAction.validateJsonData() == true) {
        await this.saveRecord(this.fieldUpdateAction.export());
        store.state.alertMessage = "add";
      } else {
        this.$q.notify({
          message: message,
          color: "negative",
          icon: "warning",
        });
      }
    } else if (this.actionType == "SendSMS") {
      if (this.sendSmsAction.validateJsonData() == true) {
        await this.saveRecord(this.sendSmsAction.export());
        store.state.alertMessage = "add";
      } else {
        this.$q.notify({
          message: message,
          color: "negative",
          icon: "warning",
        });
      }
    } else if (this.actionType == "GeneratePDF"){
      if (this.createPdfAction.validateJsonData() == true) {
        await this.saveRecord(this.createPdfAction.export());
        store.state.alertMessage = "add";
      } else {
        this.$q.notify({
          message: message,
          color: "negative",
          icon: "warning",
        });
      }
    } 
  }

// selectedActionActive() {
//   if(this.selectedTypeOfAction == 'fieldUpdate') {
//       this.activeToggle = this.fieldUpdateAction.active
//   } else if(this.selectedTypeOfAction == 'copyProductLine') {
//       this.activeToggle = this.copyProductLineAction.active
//   } else if(this.selectedTypeOfAction == 'createRecord') {
//       this.activeToggle = this.createRecordAction.active
//   } else if(this.selectedTypeOfAction == 'relatedFieldCopy') {
//       this.activeToggle = this.relatedFieldCopyAction.active
//   } else if(this.selectedTypeOfAction == 'sendEmail') {
//       this.activeToggle = this.sendEmailAction.active
//   } else if(this.selectedTypeOfAction == 'sendSms') {
//       this.activeToggle = this.sendSmsAction.active
//   }
// }

updateActionActive() {
    if(this.selectedTypeOfAction == 'fieldUpdate') {
      this.fieldUpdateAction.active = this.activeToggle
  } else if(this.selectedTypeOfAction == 'copyProductLine') {
      this.copyProductLineAction.active = this.activeToggle
  } else if(this.selectedTypeOfAction == 'createRecord') {
      this.createRecordAction.active = this.activeToggle
  } else if(this.selectedTypeOfAction == 'relatedFieldCopy') {
      this.relatedFieldCopyAction.active = this.activeToggle
  } else if(this.selectedTypeOfAction == 'sendEmail') {
      this.sendEmailAction.active = this.activeToggle
  } else if(this.selectedTypeOfAction == 'sendSms') {
      this.sendSmsAction.active = this.activeToggle
  } else if(this.selectedTypeOfAction == 'generatePdf') {
    this.createPdfAction.active = this.activeToggle
  }
}

}
