import store from "../../store";
import functions from "@/functions";
import ListValues from "@/components/Mixin/ListValues";

export default class ElasticModel {
  module = '';
  elasticUpdate: any = null;
  elasticDelete: any = null;

  constructor(module: string) {
    this.elasticUpdate = functions.httpsCallable('elasticUpdate');
    this.elasticDelete = functions.httpsCallable('elasticDelete');
    // TODO dev mode
    //functions.useEmulator('localhost', 5001);

    this.module = module;
  }

  async update(docData) {
    
    const tenantID = this.tenant();
    const module = this.module;
    const eDataDoc = ListValues.elasticTransformFields(this.convertToObject(docData), module);
    const data = {tenantID, module, docData: eDataDoc};
    
    const result = await this.elasticUpdate(data);
    return result;
  }

  async delete(ID) {
    
    const tenantID = this.tenant();
    const module = this.module;
    const data = {tenantID, module, ID};
    const result = await this.elasticDelete(data);
    return result;
  }

  tenant(): string {
    return store.state.tenantID;
  }

  convertToObject(data) {
    return JSON.parse(JSON.stringify(data));
  }

}
