import { render, staticRenderFns } from "./RoProDetailsDrawerTriggers.vue?vue&type=template&id=2a7f872a&scoped=true&"
import script from "./RoProDetailsDrawerTriggers.vue?vue&type=script&lang=ts&"
export * from "./RoProDetailsDrawerTriggers.vue?vue&type=script&lang=ts&"
import style0 from "./RoProDetailsDrawerTriggers.vue?vue&type=style&index=0&id=2a7f872a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a7f872a",
  null
  
)

export default component.exports
import {QCardSection,QInput,QList,QItem,QItemSection,QIcon,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection,QInput,QList,QItem,QItemSection,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
