
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import store from "@/store";
import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
import functions from '../../../functions';
import UnlayerTools, {
  UnlayerSettings,
} from "@/components/Settings/Sections/Templates/EmailTemplates/Models/UnlayerTools";
import TemplateConstructor from "@/components/Settings/Sections/Templates/EmailTemplates/Components/TemplateConstructor.vue";
import ListValues from "@/components/Mixin/ListValues";
import { SendEmailAction } from "@/components/Settings/Actions/actions";
import OpenEmailEditor from "@/components/Settings/Sections/Templates/EmailTemplates/Components/OpenEmailEditor.vue";


class CurrentTemplateClass {
  id = null;
  ID = null;
  module = "";
  type = "";
  name = "";
  design = null;
  userID = "";
  subject = "";
  to = "";
  cc = "";
  bcc = "";
}

class SelectedRecordClass {
  ID = "";
  name = "";
}

@Component({
  components: { TemplateConstructor, BaseFieldRelated, OpenEmailEditor },
})

export default class AddEmailTemplate extends Vue {
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  @Action("settings/getPDFTemplatesData") getPDFTemplatesData;
  @Prop(Boolean) isUserEdit;
  @Prop() public props!: any;
  @Prop() existingAction;
  @Prop({required: true}) openExistingAction;
  @Prop() module;
  sendEmail = new SendEmailAction();
  columns = [
    {
      name: "name",
      label: this.$t("email.headers.name"),
      field: "name",
      align: "left",
      sortable: true,
    },
    {
      name: "type",
      label: this.$t("email.headers.type"),
      field: "type",
      align: "left",
      sortable: true,
    },
    {
      name: "module",
      label: this.$t("email.headers.module"),
      field: "module",
      align: "left",
      sortable: true,
    },
    {
      name: "selectable",
      label: "",
      field: "selectable",
      align: "left",
      sortable: true,
    },
    {
      name: "editor",
      label: "",
      field: "editor",
      align: "center",
      sortable: true,
    },
    {
      name: "delete",
      label: "",
      field: "delete",
      align: "center",
      sortable: true,
    },
    {
      name: "copy",
      label: "",
      field: "copy",
      align: "center",
      sortable: true,
    },
    {
      name: "modified",
      label: this.$t("email.headers.modifiedAt"),
      field: "modified",
      align: "center",
      sortable: true,
    },
  ];
  pagination = {
    rowsPerPage: 20,
  };

  selectEmailTemplate = true;
  showCreateDialog = false;
  showDeleteDialog = false;
  showCopyDialog = false;
  newTemplateName = "";
  showModalEditor = false;
  showSelectRecordDialog = false;
  generatePDF = false;
  currentTemplate = new CurrentTemplateClass();
  inputJson = null;
  selectedModule = "";
  selectedRecord = new SelectedRecordClass();
  formData = {};
  record = {};
  loading = false;
  mergeTags = {};
  designTags = {};
  designTagsDB = {};
  settings = new UnlayerSettings();
  templates = [];
  templates2 = [];
  pdfTemplates = [];
  pdfTemplateOptions = [];
  allPDFTemplates: any = [];
  userTemplates = [];
  tenantTemplates = [];
  globalTemplates = [];
  currentDesign: any = null;
  recordSelectKey = 1;
  showModules: Array<any> = [];
  options = this.showModules;
  showOpenEmailEditor = false

  async mounted() {
    this.getActionTypeFunction()
    this.loading = true;
    await this.getAllTemplates();
    await this.getSettings();
    this.loading = false;
    if(this.openExistingAction == true){
      await this.sendEmail.import(this.existingAction.ID, this.dynamicModules, this.templates2)
      await this.getAllPDFTemplates()
      for(const template in this.templates2) {
        if(this.templates2[template]["ID"] == this.sendEmail.selectedTemplate.ID) {
          this.sendEmail.selectedTemplate = this.templates2[template]          
        }
      }
    }

    let showModules = {};
    for (const modules in this.dynamicModules) {
      if (this.dynamicModules[modules].pluralName != undefined) {
        showModules = {
          label: this.dynamicModules[modules].pluralName,
          name: this.dynamicModules[modules].id,
        };
      } else {
        showModules = {
          label: this.dynamicModules[modules].name,
          name: this.dynamicModules[modules].id,
        };
      }
      this.showModules.push(showModules);
    }
    this.sendEmail.childModule.label = this.module.label
    this.sendEmail.childModule.name = this.module.value
    if(this.sendEmail.childModule["name"] != ""){
      this.allTemplatesFilteredOnModule()
    }
  }

  // Function to get all the existing templates
  async getAllTemplates() {
    // (this.globalTemplates as any) = await UnlayerTools.getGlobalTemplates();
    (this.tenantTemplates as any) = await UnlayerTools.getTenantTemplates();
    (this.userTemplates as any) = await UnlayerTools.getUserTemplates();
    this.templates = [].concat(
      this.tenantTemplates,
      this.userTemplates
    );
    this.templates2 = this.templates;
  }

  // Function to filter the templates on module
  allTemplatesFilteredOnModule() {
    if(this.templates != this.templates2) {
      this.sendEmail.selectedTemplate = { ID: "", name: "" };
      this.sendEmail.pdfTemplate = {id: "", name: "", modified: "", owner: true, tags: [], filename: ""}
    }
    this.templates = this.templates2;
    const templates = [];    
    for (const template in this.templates) {            
      if (
        this.sendEmail.childModule["name"] == this.templates[template]["module"]
      ) {
        templates.push(this.templates[template]);
      }
    }
    this.templates = templates;    
    this.getPDFModuleTemplates();
  }

    // gets templates based on the module that is selected
    async getPDFModuleTemplates(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.tenantID,
        request: 'getModuleTemplates',
        module: this.sendEmail.childModule.name,
      };
      
      await generatePDF(options).then((res: any) => {
        const data = res.data;        
        this.getPDFTemplatesData().then((res) => {
          this.pdfTemplates = data.map((t: any) => {              
            if (t && res) {                
              t.filename = res[String(t.id)] ? res[String(t.id)].filename : '';
              return t;
            }
          });
        });          
      }).catch(error => {
          console.log('error: ', error);
        this.pdfTemplates = [];
      });
    }

      async getAllPDFTemplates(): Promise<void> {
      const generatePDF = functions.httpsCallable('GeneratePDF');

      const options = {
        tenant: this.tenantID,
        request: 'getAllTemplates'
      };      
      await generatePDF(options).then((res: any) => {
        const data = res.data;
        for(const pdftemplate in data) {          
          this.allPDFTemplates.push(data[pdftemplate])
        }  
      }).catch(error => {
        console.log('error: ', error);
        this.allPDFTemplates = [];
      });
    this.filterSelectedPDFTemplate()
    }
    filterSelectedPDFTemplate() {        
      let index: any = 0          
      for(index in this.allPDFTemplates) {        
          if(this.allPDFTemplates[index]["id"] == this.sendEmail.pdfTemplate.id) {
            this.generatePDF = true
            this.sendEmail.pdfTemplate = this.allPDFTemplates[index]
          } 
      }
      this.getPDFModuleTemplates()
    }
  // filter function for available templates
  filterAvailableTemplates(val, update) {      
    if (val === "") {
      this.pdfTemplateOptions = this.pdfTemplates;      
    }
    update(() => {
      const needle = val.toLowerCase();
      this.pdfTemplateOptions = this.pdfTemplates.filter(
        (v: any) => v.name.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  clearPdfTemplateData() {
    if(this.generatePDF == false) {
      this.sendEmail.pdfTemplate = {
        id: "",
        name: "",
        modified: "",
        owner: true,
        tags: [],
        filename: ""
      }
    }
  }

  async getSettings() {
    (this.settings as any) = await UnlayerTools.getSettings();
  }

  get relatedModule() {
    return this.currentTemplate.module;
  }

  // Function to create a new template
  async onTemplateCreate() {
    this.currentTemplate = new CurrentTemplateClass();
    this.currentTemplate.module = this.selectedModule || "";
    await this.saveTemplate(this.currentTemplate);
    this.eraseTheFormFields();
    store.state.alertMessage = "add";
  }

  eraseTheFormFields() {
    this.newTemplateName = "";
    this.selectedModule = "";
  }

  // Button to open the copy dialog
  onClickCopyBtn(props) {
    this.showCopyDialog = true;
    this.currentTemplate = JSON.parse(JSON.stringify(props.row));
  }

  // Function to copy the email template
  async onTemplateCopy(): Promise<void> {
    this.currentTemplate.id = null;
    this.currentTemplate.ID = null;
    await this.saveTemplate(this.currentTemplate);
    this.currentTemplate = new CurrentTemplateClass();
    this.newTemplateName = "";
    store.state.alertMessage = "add";
  }

  // Function to save the template
  async saveTemplate(template) {
    const currentDesign = JSON.parse(JSON.stringify(template));
    currentDesign.name = this.newTemplateName;
    currentDesign.updatedAt = new Date().toISOString();
    currentDesign.userID = this.isUserEdit ? store.state.userID : "tenant";
    delete currentDesign.type;
    const res = await UnlayerTools.saveTemplate(currentDesign);
    await this.getAllTemplates();
    return res;
  }

  async saveTemplateConstructor(template) {
    const currentDesign = JSON.parse(JSON.stringify(template));
    currentDesign.updatedAt = new Date().toISOString();
    delete currentDesign.type;
    const res = await UnlayerTools.saveTemplate(currentDesign);
    store.state.alertMessage = "update";
    await this.getAllTemplates();
    return res;
  }

  async saveTemplateRename(tData) {
    const saveData = {
      ID: this.currentTemplate.ID,
      userID: this.currentTemplate.userID,
      ...tData,
    };
    await UnlayerTools.saveTemplate(saveData);
    store.state.alertMessage = "update";
    await this.getAllTemplates();
    this.currentTemplate = { ...this.currentTemplate, ...tData };
  }

  onCancelCreateDialog() {
    this.eraseTheFormFields();
  }

  // Function to open the delete dialog
  onClickDeleteBtn(props) {
    this.showDeleteDialog = true;
    this.currentTemplate = props.row;
  }

  // Function to delete the email template
  async onTemplateDelete(): Promise<void> {
    await UnlayerTools.deleteTemplate(this.currentTemplate);
    await this.getAllTemplates();
    this.currentTemplate = new CurrentTemplateClass();
    store.state.alertMessage = "delete";
  }

  openCreateDialog() {
    this.showCreateDialog = true;
    this.showOpenEmailEditor = true;
  }

  // Function to open the editor
  async openEditor(ID) {
    
    this.currentTemplate = new CurrentTemplateClass();
    await this.getAllTemplates()
    for(const template in this.templates2) {
      if(this.templates2[template]["ID"] == ID) {
        this.currentTemplate = this.templates2[template]
      }
    }
    
    // this.currentTemplate = props.row;
    this.selectedModule = this.currentTemplate.module;

    if (this.selectedModule) {
      this.formData = await PdfTools.getFormDataForPDF(this.selectedModule);
      this.mergeTags = this.getTagsFormData(this.formData);
    } else {
      this.formData = {};
    }
    this.showSelectRecordDialog = true;
    this.showOpenEmailEditor = true
  }

  async onRecordSelect() {
    if (this.selectedModule) {
      this.currentTemplate.module = this.selectedModule;
    }
    if (this.selectedModule && this.selectedRecord) {
      const formData = await PdfTools.getFormDataForPDF(
        this.currentTemplate.module,
        this.selectedRecord.ID
      );
      const inputJson = await PdfTools.getInputJsonForPDF(formData);
      this.mergeTags = this.getTagsFormData(this.formData);
      this.onTemplateEdit(inputJson);
    } else {
      this.onTemplateEdit();
    }
    this.selectedRecord = new SelectedRecordClass();
  }

  async onSelectModule() {
    this.formData = await PdfTools.getFormDataForPDF(this.selectedModule);
    this.recordSelectKey++;
    this.selectedRecord = new SelectedRecordClass();
    this.updateTemplate();
  }

  updateTemplate() {
    const saveData = {
      ID: this.currentTemplate.ID,
      userID: this.currentTemplate.userID,
      module: this.selectedModule || "",
    };
    UnlayerTools.saveTemplate(saveData).then(() => {
      this.getAllTemplates();
    });
  }

  onTemplateEdit(inputJson = null) {
    this.inputJson = inputJson;
    this.showModalEditor = true;
  }

  getTagsFormData(formData) {
    const fields: any = ListValues.getValidColumns(formData.dynamicFields);
    const tags = {};
    for (const fname in fields) {
      let sname = "";
      if (
        formData.data[fname] &&
        Object.prototype.hasOwnProperty.call(formData.data[fname], "name")
      ) {
        sname = ".name";
      }
      tags[fname] = {
        name: fields[fname].label,
        value: "{{" + fname + sname + "}}",
      };
    }
    return tags;
  }

  // when canceling the selected record dialog the currentTemplate has ben set to a new CurrentTemplateClass
  onCancelSelectedRecordDialog() {
    this.eraseTheFormFields();
    this.selectedRecord = new SelectedRecordClass();
    this.currentTemplate = new CurrentTemplateClass();
  }

  // when canceling the copy dialog the currentTemplate has ben set to a new CurrentTemplateClass
  onCancelCopyDialog() {
    this.eraseTheFormFields();
    this.currentTemplate = new CurrentTemplateClass();
  }

  // when canceling the delete dialog the currentTemplate has ben set to a new CurrentTemplateClass
  onCancelDeleteDialog() {
    this.currentTemplate = new CurrentTemplateClass();
  }

  // function to get the tenant id
  get curTenantID() {
    return this.tenantID;
  }
  // function to get the modules
  get modulesList() {
    return Object.keys(this.dynamicModules);
  }

  // function to convert the date
  converDate(isostr) {
    if (!isostr) {
      return "";
    }
    const date = new Date(isostr);
    return date.toLocaleString("nl-Nl");
  }

  selectTemplateName(props) {
    this.sendEmail.selectedTemplate = {
      ID: props.row.ID,
      name: props.row.name,
    };
  }

  convertName(type) {
    if (type === "global") {
      return "YellowQ";
    } else {
      return type;
    }
  }

  // Function to get the title of the module
  getModuleTitle(module) {
    const moduleTitle =
      this.dynamicModules && this.dynamicModules[module]
        ? this.dynamicModules[module]
        : {};
    return moduleTitle ? moduleTitle.pluralName || moduleTitle.name : module;
  }

  canEditTemplate(props) {
    const template = props.row;
    return template && template.type !== "global";
  }

  // function to get the pagination label
  getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
    return `${firstRowIndex}-${endRowIndex} ${this.$t(
      "table.misc.of"
    )} ${totalRowsNumber}`;
  }

  // filter function on select
  filterFn(val, update) {
    if (val === "") {
      this.options = this.showModules;      
    }
    update(() => {
      const needle = val.toLowerCase();
      this.options = this.showModules.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  // emit function to save action in actionHeader.vue
  @Emit("getActionType")
  getActionTypeFunction() {      
      const map = {
        value: this.sendEmail,
        type: "SendEmail"
      }
      return map   
  }

  getOpenedEditorStatus(value: any) {
    this.showOpenEmailEditor = value
    this.allTemplatesFilteredOnModule()
  }

  async getOpenedCreateDialogStatus(value: any) {
    this.showCreateDialog = false;
    this.showOpenEmailEditor = value;
    this.showSelectRecordDialog = false;
    await this.getAllTemplates()
    await this.allTemplatesFilteredOnModule()
  }
}
