import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vue from "vue";
import store from "@/store";

interface TransactionParams {
  name: string;
  memory?: boolean;
}

export default class Trace {

  static init(router) {
    if (!store.state.traceON) {
      return;
    }
    if (store.state.traceOneMinute) {
      setInterval(() => {
        Trace.startTransaction({ name: 'traceOneMinuteMemoryUsage', memory: true});
      }, 60 * 1000);
    }
    Sentry.init({
      Vue,
      dsn: "https://7684764f545444c8b9fa8d04e84cad4f@o1017222.ingest.sentry.io/5982918",
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          //tracingOrigins: ["localhost", "yellowq.app", /^\//],
          tracingOrigins: ["yellowq.app", /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  static startTransaction(params: TransactionParams) {
    if (!store.state.traceON) {
      return { finish: () => {}};
    }
    const email = store.state.currentUserFull.email;
    const name = store.state.currentUser.name;
    const memoryUsed = Trace.getUsageMemory();
    Sentry.configureScope(scope => {
      scope.setUser({ name, email }); // attach user/email context
      if (memoryUsed) {
        scope.setTag("memoryUsage", memoryUsed); // custom-tag
      }
    });
    
    if (params.memory) {
      if (memoryUsed) {
        const transaction = Sentry.startTransaction({name: params.name});
        transaction.finish();
      }
    } else {
      const transaction = Sentry.startTransaction({ name: params.name });
      return transaction;
    }
  }

  static getUsageMemory() {
    
    //eslint-disable-next-line
    //@ts-ignore: Unreachable code error
    if (performance.memory) {
      //eslint-disable-next-line
      //@ts-ignore: Unreachable code error
      return Math.round(performance.memory.usedJSHeapSize / 1000000) + 'MB';
    } else {
      return '';
    }
  }

}
