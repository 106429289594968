import { render, staticRenderFns } from "./SMSConfiguration.vue?vue&type=template&id=0862e786&"
import script from "./SMSConfiguration.vue?vue&type=script&lang=ts&"
export * from "./SMSConfiguration.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn,QIcon,QSeparator,QSpace,QToggle,QInput} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QSeparator,QSpace,QToggle,QInput})
