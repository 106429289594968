import { render, staticRenderFns } from "./AddReportsTemplates.vue?vue&type=template&id=0e0502cf&scoped=true&"
import script from "./AddReportsTemplates.vue?vue&type=script&lang=ts&"
export * from "./AddReportsTemplates.vue?vue&type=script&lang=ts&"
import style0 from "./AddReportsTemplates.vue?vue&type=style&index=0&id=0e0502cf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e0502cf",
  null
  
)

export default component.exports
import {QSpace,QBtn,QSeparator,QSelect,QList,QItem,QItemSection,QInput,QCheckbox,QTooltip,QMarkupTable,QPopupEdit,QField,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSpace,QBtn,QSeparator,QSelect,QList,QItem,QItemSection,QInput,QCheckbox,QTooltip,QMarkupTable,QPopupEdit,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
