
import { Component, Prop, Vue } from "vue-property-decorator";
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
import axios from "axios";
@Component({
  mixins: [CrudMixinVue],
  components: {},
})
export default class PreviewFileDialog extends Vue {
    [x: string]: any;
    @Prop() file;
    file_URL = "";

    mounted() {
      this.showFile()
    }

    showFile() {
      if(this.file.fileType.startsWith("image")) {
        this.file_URL = this.file.downloadableURL
      } else {
        this.file_URL = `https://docs.google.com/gview?url=${encodeURIComponent(this.file.downloadableURL)}&embedded=true`
      }
      this.reloadIfFailed();
    }

    reloadIfFailed() {
      let count = 10;
      const checkInterval = setInterval(() => {
        if (!count) {
          clearInterval(checkInterval);
          
        }
        count--;
        const iframe: any = this.$refs['iframe'];
        if (!iframe) {
          return;
        }
        const contentWindow = iframe.contentWindow;
        try {
          const ch = contentWindow + 'check';

          // don't delete this console.log(ch) it fix for reload !!!
          console.log('reload...', ch);
          // not delete or comment it

          this.file_URL = 'about:blank';
          iframe.src = `https://docs.google.com/gview?url=${encodeURIComponent(this.file.downloadableURL)}&embedded=true`
        } catch (err) {
          console.log('loaded');
          clearInterval(checkInterval);
        }
      }, 1000);
    }

    downloadAttachment() {
      const executableExtensions = [
        'cbc', 'cbr', 'cbz', 'chm', 'djvu', 'epub', 'lit', 'lrf', 'mobi', 'pml', 'prc', 'snb', 'tcr',
        'csv', 'doc', 'docx', 'dot', 'dotx', 'log', 'mpp', 'mpt', 'pot', 'potx', 'pps', 'ppsx',
        'key', 'numbers', 'pages', 'dwf', 'dwfx', 'dwg', 'dwgx', 'dxf', 'azv', 'azv1', 'azv3', 'azv4',
        'mdi', 'png', 'tif', 'tiff', 'webp', '123', '12m', 'lwp', 'mwp', 'sam', 'wk1', 'wk2', 'wk3',
        'otg', 'oth', 'otp', 'ots', 'pdf', 'pot', 'pps', 'ppt', 'pptx', 'pxl', 'sgl', 'smf', 'srw',
        'ppt', 'pptx', 'pub', 'rtf', 'txt', 'vdx', 'vsd', 'vsdx', 'vst', 'vstx', 'wpd', 'wps', 'wri',
        'prn', 'ps', 'oxps', 'snp', 'xps', 'htm', 'html', 'web',
        'stc', 'sti', 'stw', 'sxc', 'sxg', 'sxi', 'sxm', 'sxw', 'vor', 'wv2', 'xls', 'xlsx' , 'eps',
        'tpz', 'eml', 'mbx', 'msg', 'oft', 'bmp', 'fax', 'gif', 'heic', 'ico', 'images', 'jpeg', 'jpg',
        'xls', 'xlsb', 'xlsx', 'xlt', 'xltx', 'mml', 'odc', 'odf', 'odg', 'odi', 'odm', 'odp', 'ods'
      ]
      axios({
        url: this.file.downloadableURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let fileName = this.file.fileName
        let fileType = this.file.fileType

        if (executableExtensions.includes(this.file.fileName.split(".")[this.file.fileName.split(".").length - 1])) {          
          fileType = this.file.fileName.split(".")[this.file.fileName.split(".").length - 1]
          const fileNameAttributes = this.file.fileName.split(".")
          fileNameAttributes.pop();
          fileName = fileNameAttributes.join(".")
        }
        fileLink.setAttribute('download', `${fileName}.${fileType}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }
}

