
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import store from "@/store";
import { Timestamp } from '@firebase/firestore';
import CrudMixinVue from "@/components/Mixin/CrudMixin.vue";
import addReportsTemplates from "./AddReportsTemplates.vue";
import ListValues from '@/components/Mixin/ListValues'
import sortList from "@/common/helpers/utilities";

class User {
  ID = "";
  name = "";
}

class CurrentTemplateClass {
  ID = null;
  name = "";
  type = "";
  module = "";
  exportType = "";
  fileType = "";
  fileName = "";
  fields = Array<{}>();
  reportFilters = Array<{}>();
  skipHeaders = false;
  csvDelimiter = ',';
  createdAt: Timestamp = Timestamp.now();
  changedAt: Timestamp = Timestamp.now();
  createdBy: User = new User();
  changedBy: User = new User();}

@Component({
  mixins: [CrudMixinVue],
  components: { addReportsTemplates }
})
export default class ReportsTemplates extends Vue {
  [x: string]: any;
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  @Action("api/exportModule") exportModule;
  columns = [
    {
      name: "name",
      label: this.$t("reports.headers.name"),
      field: "name",
      align: "left",
      sortable: true,
    },
    {
      name: "type",
      label: this.$t("reports.headers.type"),
      field: "type",
      align: "left",
      sortable: true,
    },
    {
      name: "module",
      label: this.$t("reports.headers.module"),
      field: "module",
      align: "left",
      sortable: true,
      style: "max-width: 300px;"
    },
    {
      name: "filename",
      label: this.$t("reports.headers.filename"),
      field: "filename",
      align: "left",
      sortable: true,
    },
    {
      name: "filetype",
      label: this.$t("reports.headers.filetype"),
      field: "filetype",
      align: "left",
      sortable: true,
    },
    {
      name: "copy",
      label: "",
      field: "copy",
      align: "center",
      sortable: true,
    },
    {
      name: "edit",
      label: "",
      field: "edit",
      align: "center",
      sortable: true,
    },
    {
      name: "delete",
      label: "",
      field: "delete",
      align: "center",
      sortable: true,
    },
    {
      name: "run",
      label: "",
      field: "run",
      align: "center",
      sortable: true,
    },
    {
      name: "changedAt",
      label: this.$t("reports.headers.changedAt"),
      field: "changedAt",
      align: "center",
      sortable: true,
    },
  ];
  showCreateDialog = false;
  showLabelListDialog = false;
  showDeleteDialog = false;
  showEditDialog = false;
  showCopyDialog = false;
  currentTemplate = new CurrentTemplateClass();
  selectedModuleData = [];
  selectedLabelFields = Array<{ key: string; name?: string }>();
  editReportData = [];
  showAddReportsTemplates = false;
  reportTypeOptions = [
    {label: this.$t("reports.fields.module"), value: 'module'},
    {label: this.$t("reports.fields.journal"), value: 'journal'},
  ];
  reportTemplates = []
  templateModule = {}

  async mounted() {
    this.templateModule = this.modulesList[0]
    await this.getReportTemplates()
  }

  async getReportTemplates() {
    await this.getAllList();
    this.reportTemplates = []
    for(const template of this.listData) {
      if(template.module === this.templateModule['value']) {
        this.reportTemplates.push(template)
      }
    }
  }

  getFirePath() {
    return "reports/templates/records";
  }

  onTemplateCreate() {
      this.showAddReportsTemplates = true;
  }

  openEditor(props) {
    this.currentTemplate = new CurrentTemplateClass();
    this.currentTemplate.ID = props.row.id;
    this.showAddReportsTemplates = true;
  }

  onClickDeleteBtn(props) {
    this.showDeleteDialog = true;
    this.currentTemplate = props.row;
  }

  onClickCopyBtn(props) {
    this.showCopyDialog = true;
    this.currentTemplate = new CurrentTemplateClass();
    this.currentTemplate.name = props.row.name;
    this.currentTemplate.ID = props.row.id;
  }

  async onTemplateDelete() {
    await this.deleteRecord(this.currentTemplate);
    await this.getAllList();
    this.currentTemplate = new CurrentTemplateClass();
    store.state.alertMessage = "delete";
  }

  async onTemplateCopy() {
    const copyDoc = await this.getRecord(this.currentTemplate.ID);
    copyDoc.name = this.currentTemplate.name;
    copyDoc.ID = ""
    await this.saveRecord(copyDoc);
    this.currentTemplate = new CurrentTemplateClass();
    store.state.alertMessage = "add";
    await this.getAllList();
  }

  async onClickRunBtn(props): Promise<void> {
    const selectedTemplate = await this.getRecord(props.row.id);
    let result;
    let blobData;
    // selectedTemplate.exportType = 'EXCEL';
    const { type, module, exportType } = selectedTemplate;
        if (exportType === "JSON") {
          result = await this.exportModule({
            module: module,
            reportType: exportType,
            reportID: selectedTemplate.ID,
            type: type
          });
          blobData = new Blob([JSON.stringify(result)], {
            type: "application/json",
          });
        }
        if (exportType === "CSV") {
          result = await this.exportModule({
            module: module,
            reportType: exportType,
            reportID: selectedTemplate.ID,
            type: type
          });
          blobData = new Blob([result], { type: "application/csv" });
        }
        if (exportType === "EXCEL") {
          result = await this.exportModule({
            module: module,
            reportType: exportType,
            responseType: "arraybuffer",
            reportID: selectedTemplate.ID,
            type: type
          });
          blobData = new Blob([new Uint8Array(result).buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        }

        if (exportType !== "EXCEL" && !result?.length) {
          store.state.alertMessage = "error";
        } else {
          this.downloadFile(blobData, this.getFileName(selectedTemplate));
        }
  }

  downloadFile(blobData, fileName) {
    const fileURL = window.URL.createObjectURL(blobData);
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  }

  getFileName(template) {
    let ext = "txt";
    if (template.exportType === "JSON") {
      ext = "json";
    }
    if (template.exportType === "CSV") {
      ext = "csv";
    }
    if (template.exportType === "EXCEL") {
      ext = "xlsx";
    }
    return template.fileName + "." + ext;
  }

  canEditTemplate() {
    return true;
  }

  onCancelCreateDialog() {
    this.currentTemplate = new CurrentTemplateClass();
  }

  onCancelCopyDialog() {
    this.currentTemplate = new CurrentTemplateClass();
  }

  onCancelDeleteDialog() {
    this.currentTemplate = new CurrentTemplateClass();
  }

  onCloseAddReportsTemplatesModal() {
    this.currentTemplate = new CurrentTemplateClass();
    this.showAddReportsTemplates = false;
    this.getAllList();
  }

  get modulesList() {
    const modulesList = Array<{ label: string; value: string }>();
    Object.keys(this.dynamicModules).forEach((module) => {
      modulesList.push({
        label: this.getModuleTitle(module),
        value: module,
      });
    });
    return sortList(modulesList, 'label')
  }

  getReportTypeTitle(typeValue) {
    const reportType =  this.reportTypeOptions.find(e => e.value === typeValue);
    return reportType ? reportType.label : typeValue
  }

  onSelectType() {
    alert(this.currentTemplate.type);
  }

  onSelectModule() {
    // this.showLabelListDialog = true;
    // this.selectedModuleData = this.dynamicModules[
    //   this.currentTemplate.module
    // ].fields;
  }

  onSelectLabelField(index) {
    const isExist = this.selectedLabelFields.find((el) => el.key === index);
    if (!isExist) {
      this.selectedLabelFields.push({
        ...(this.selectedModuleData[index] as object),
        key: index,
      });
    }
  }

  onCancelSelectLabelField() {
    this.selectedLabelFields = [];
  }

  removeSelectedLabelField(index) {
    this.selectedLabelFields = this.selectedLabelFields.filter(
      (field, id) => id !== index
    );
  }

  getRecordValue(fieldValue, module, fieldName){
    return fieldValue && module && fieldName ? ListValues.transformOneByModule(fieldValue, module, fieldName) : '';
  }
}
