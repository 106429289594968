import { render, staticRenderFns } from "./RoProBuilder.vue?vue&type=template&id=9c1168c4&scoped=true&"
import script from "./RoProBuilder.vue?vue&type=script&lang=ts&"
export * from "./RoProBuilder.vue?vue&type=script&lang=ts&"
import style0 from "./RoProBuilder.vue?vue&type=style&index=0&id=9c1168c4&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c1168c4",
  null
  
)

export default component.exports
import {QBtn,QIcon,QSeparator,QSpace,QSelect,QInput,QScrollArea,QStepper,QItem,QItemSection,QItemLabel,QMenu,QList,QStep,QTabPanel,QTooltip,QDialog,QCard,QCardSection,QCardActions,ClosePopup,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QSeparator,QSpace,QSelect,QInput,QScrollArea,QStepper,QItem,QItemSection,QItemLabel,QMenu,QList,QStep,QTabPanel,QTooltip,QDialog,QCard,QCardSection,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup,Ripple})
