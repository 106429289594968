
import 'firebase/compat/firestore';
import firebase from "../src/firebaseApp"

const db = firebase.firestore();
try {
    db.enablePersistence({
      synchronizeTabs: true
    }); 
  } catch{
  
  }
export default db;
