
import { Prop, Vue, Component, Emit } from "vue-property-decorator";
import store from "@/store"

@Component({
  components: {},
})


export default class FilePickerDialog extends Vue {
    @Prop() formData;
    @Prop() fieldName
    @Prop() module;
    @Prop() showDialog;
    @Prop() maxFiles;
    @Prop() multipleFiles;
    @Prop() fieldUsedInSettings;
    images = []
    dialog = false

    mounted() {
        this.dialog = this.showDialog
    }

    async uploadImageToStorage(file) {
        const image = file[0]
        image.storageRef = `modules/${this.module}/records/${this.formData.data.ID}/${this.fieldName}/`;
        if(this.fieldUsedInSettings) {
            image.storageRef = "settings/design/images/"
        }
        image.toPublic = true;
        image.onlyRef = true;
        image.addTenant = true;
        image.toPublic = false
        const uploadedImage = await store.dispatch('uploadFileToStorage', image);
        this.emitImages(uploadedImage)
        return uploadedImage
    }

    onFileRejected () {
        this.$q.notify({type: 'negative', message: 'File did not pass validation constraints'})
    }

    @Emit('closeFilePickerDialog')
    closeDialog() {
        this.dialog = false
        return false
    }

    @Emit('uploadedFile')
    emitImages(file) {
        return file
    }
}
