import db from "../db";
import store from "../store";
import ListValues from "@/components/Mixin/ListValues";

const state = {
  portal: null
};

const getters = {

};

const SET_PORTAL = "SET_PORTAL";

const mutations = {
  [SET_PORTAL](state, portal) {
    state.portal = portal;
  },
};

const actions = {

  // before login from the /portals collection
  async initPortalMode({ dispatch, commit }) {
    const portal = await dispatch('getPortal');
    if (portal) {
      commit("SET_PORTAL", portal);
      store.commit("SET_PORTAL", portal);
      store.commit("SET_PORTAL_TENANT", portal.tenant);
    } else {
      store.commit("SET_PORTAL", false); // null -> false
    }
  },

  async getPortalAccount({ state, commit }) {
    const user = store.state.rootUser;
    const accID = user.account || state.portal.account;
    const tenID = state.portal.tenant;
    if (accID) {
      const spath = "tenants/" + tenID + "/modules/accounts/records";
      const doc = await db.collection(spath).doc(accID).get();
      if (doc.data()) {
        const dataAcc = doc.data();
        const account = { ID: dataAcc.ID, name: dataAcc.name };
        store.commit("SET_USER_ACCOUNT", account);
      } else {
        console.error('Portal account not found');
      }
    } else {
      console.error('No account ID');
    }
  },


  // after login from /tenant/.../views
  async initPortalSettings({ dispatch, commit }) {
    await dispatch('initPortalMode');
    if (store.state.PORTAL) {
      await dispatch('getPortalAccount');
      await dispatch('getViewsSettings');
    } else {
      const sPage = '/';
      store.commit("SET_START_PAGE", sPage);
    }
  },

  async getViewsSettings({ commit }) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/views/";
    const result = await db.collection(spath).get();
    let settings = {};
    result.forEach((doc) => {
      settings[doc.id] = doc.data();
    });
    if (store.state.PORTAL) {
      //TODO fix hardcoded currentview.
      const vpath = "tenants/" + store.state.tenantID + "/views/";
      const vresult = await db.collection(vpath).doc('x').get();
      if (vresult.data()) {
        settings = {...settings, ...vresult.data()};
      }
    }
    const sPage = ListValues.render(settings.startpage || '/views', store.getters.systemVariables);
    store.commit("SET_START_PAGE", sPage);
    store.commit("SET_USER_VIEWS", settings);
    return settings;
  },


  async getPortal() {
    const spath = "portals";
    let portal = null;
    const result = await db.collection(spath).get();
    result.forEach((doc) => {
      const pdoc = doc.data();
      if (window.location.href.includes(pdoc.portalURL)) {
        portal = pdoc;
      }
    });
    return portal;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
