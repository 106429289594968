import store from "@/store";

export default class RBAC {
  data = {};
  module = '';
  userRoles = { modules: {settings: {}}, role: {hideOrDisableUI: ''}};
  permTypes = {
    create: 'createPermissions',
    read: 'readPermissions',
    update: 'updatePermissions',
    delete: 'deletePermissions',
    lock: 'lockPermissions',
    unlock: 'unlockPermissions',
    statusOverwrite: 'statusOverwrite'
  };
  userSettings = {}

  constructor(formData: any = {}) {
    // formData.data
    this.data = formData.data;
    this.module = formData.module;
    const roles: any =  store.state.currentUserFull.rolePermission || {};

    let rolePermission: any = {modules: {}};
    
    Object.values(roles).forEach((role: any) => {
      rolePermission = {...rolePermission, role};
      rolePermission.modules = {};
      for (const mName in role.modulePermissions || {}) {
        const mValue = role.modulePermissions[mName];
        const mID = mValue.ID || mName;
        rolePermission.modules[mID] = {...rolePermission.modules[mID], ...mValue}
      }
    });
    this.userRoles = rolePermission;
    

    const modules = this.userRoles.modules;
    this.userSettings = modules.settings || {};
    
  }

  hide(action, moduleParam = '') {
    const perm = this.check(action, moduleParam);
    return !perm && this.uiHide();
  }

  uiHide() {
    return this.userRoles.role.hideOrDisableUI === 'HIDE';
  }

  check(action, moduleParam = '') {
    const module = moduleParam ? moduleParam : this.module;
    
    
    
    const modules = this.userRoles.modules;
    if (!modules) {
      
      return true;
    }
    const pModule = this.userRoles.modules[module];
    // no module in the role - YES
    if (!pModule) {
      // 
      return true;
    }
    // no action in the RBAC - NO
    if (!this.permTypes[action]) {
      
      return false;
    }
    const permission = pModule[this.permTypes[action]];
    // no action in the roles module - NO
    if (!permission) {
      
      return false;
    } else {
      if (permission === 'ALLOWED') {
        
        return true;
      }
      if (permission === 'NOT_ALLOWED') {
        
        return false;
      }
      
      // other permission value - NO
      return false;
    }
  }

  checkModule(module = '') {
    return this.check('read', module);
  }

  checkSetting(setting) {
    const permission = this.userSettings[setting];
    if (!permission) {
      
      return false;
    } else {
      if (permission === 'ALLOWED') {
        
        return true;
      }
      if (permission === 'NOT_ALLOWED') {
        
        return false;
      }
      
      return false;
    }
  }
}
