
  import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
  import DynamicField from "@/components/Forms/DynamicField.vue";
  import {EventBus} from "@/event-bus";
  import {uuid} from 'vue-uuid';
  import {State} from "vuex-class";

  @Component({
    components: {DynamicField},
  })

  export default class DynamicFieldSingle extends Vue {
    @Prop(String) label;
    @Prop(String) type;
    @Prop(String) fieldName?: string = null;
    @Prop(String) module;
    @Prop(String) relatedModule;
    @Prop(Array) dropDownValues;
    @Prop() value?: any = null;
    @Prop({type: Boolean, default: false}) disabled;
    @Prop({type: Boolean, default: false}) dense;

    @State("dynamicModules") dynamicModules;

    nameKey = 'singFieldData_' + uuid.v1();
    name = this.fieldName || this.nameKey;

    formData = {
      data: {[this.name] : this.value},
      dynamicFields: {},
      dropDownValues: {},
      editingFields: []
    };

    field = { name: "" , type: "", label: "", disabled: null, dense: null, fieldName: "", relatedModule: ""};
    section = {};

    mounted() {
      this.field.type = this.type;
      this.field.label = this.label;
      this.field.disabled = this.disabled;
      this.field.dense = this.dense;
      this.field.name = this.name;
      this.field.fieldName = this.fieldName;
      this.getDropDownValues();
    }

    getDropDownValues() {

      if (this.type === 'relatedField') {
        if (this.relatedModule) {
          this.field.relatedModule = this.relatedModule;
        } else {
          const dynModule = this.dynModule;
          const dynName = this.name.split('.')[0];
          const dynField = dynModule.fields[dynName];
          this.field.relatedModule = dynField.relatedModule;
        }
      } else {
        this.field.relatedModule = '';
      }

      if (this.type === 'dropdown') {
        
        if (this.dropDownValues) {
          this.formData.dropDownValues[this.name] = this.dropDownValues;
        } else {
          this.formData.dropDownValues = this.dynModule.dropDownValues || {};
        }
      }
    }

    get dynModule() {
      return this.dynamicModules && this.dynamicModules[this.module] ? this.dynamicModules[this.module] : {} ;
    }

    @Watch('formData.data', {deep: true})
    handleData() {
      const val = this.formData.data[this.name];
      this.$emit('input', val);
    }

    @Watch('value')
    handleValue() {
      if (this.formData.data[this.name] !== this.value) {
        this.formData.data[this.name] = this.value;
        EventBus.$emit('needToUpdateFieldValueAfterCopy', {name: this.name, value: this.value});
      }
    }

    @Watch('disabled')
    handleDisabled() {
      this.field.disabled = this.disabled;
    }

    @Watch('type')
    handleType() {
      this.field.type = this.type;
    }

    @Watch('dynamicModules')
    handleModules() {
      this.getDropDownValues();
    }

    @Watch('dropDownValues')
    handleDropDownValues() {
      this.getDropDownValues();
    }

    addFieldToValidationList(data) {
      this.$emit('addFieldToValidationList', data);
    }

  }
