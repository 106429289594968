import DateTimeFieldImpl from "./Fields/DateTimeFieldImpl";
import CalculatedFieldImpl from "./Fields/CalculatedFieldImpl";
import CheckboxFieldImpl from "./Fields/CheckboxFieldImpl";
import CurrencyFieldImpl from "./Fields/CurrencyFieldImpl";
import DecimalFieldImpl from "./Fields/DecimalFieldImpl";
import DropdownFieldImpl from "./Fields/DropdownFieldImpl";
import LargeTextFieldImpl from "./Fields/LargeTextFieldImpl";
import NumberFieldImpl from "./Fields/NumberFieldImpl";
import RelatedFieldImpl from "./Fields/RelatedFieldImpl";
import TextFieldImpl from "./Fields/TextFieldImpl";
import AutoNumberFieldImpl from "./Fields/AutoNumberFieldImpl";
import PercentageFieldImpl from "./Fields/PercentageFieldImpl";
import StatusFieldImpl from "./Fields/StatusFieldImpl";
import Field from "./Field";
import { FieldType } from "../../Fields/Models/FieldType";
import { RelatedField } from "../Models/FieldTypes/RelatedField";
import { AutoNumberField } from "../Models/FieldTypes/AutoNumberField";
import { PercentageField } from "../Models/FieldTypes/PercentageField";
import { TextField } from "../Models/FieldTypes/TextField";
import { CalculatedField } from "../Models/FieldTypes/CalculatedField";
import { DateTimeField } from "../Models/FieldTypes/DateTimeField";
import { DropdownField } from "../Models/FieldTypes/DropdownField";
import { NumberField } from "../Models/FieldTypes/NumberField";
import { LargeTextField } from "../Models/FieldTypes/LargeTextField";
import { SubformField } from "../Models/FieldTypes/SubformField";
import { CurrencyField } from "../Models/FieldTypes/CurrencyField";
import { DecimalField } from "../Models/FieldTypes/DecimalField";
import { CheckboxField } from "../Models/FieldTypes/CheckboxField";
import { StatusField } from "../Models/FieldTypes/StatusField";


export default class FieldFactory {


    getFieldBasedOnType(field: any): Field {
        if(field.name === 'status' && field.type === FieldType.DROPDOWN){
            const statusField = field as StatusField;
            return new StatusFieldImpl(statusField)
        }
        if(field.type === FieldType.RELATEDFIELD) {
            const relatedField = field as RelatedField
            return new RelatedFieldImpl(relatedField)
        }
        if(field.type === FieldType.AUTONUMBER) {
            const autoNumberField = field as AutoNumberField
            return new AutoNumberFieldImpl(autoNumberField)
        }
        if(field.type === FieldType.PERCENTAGE) {
            const percentageField = field as PercentageField
            return new PercentageFieldImpl(percentageField)
        }
        if(field.type === FieldType.TEXT) {
            const textField = field as TextField
            return new TextFieldImpl(textField)
        }
        if(field.type === FieldType.CALCULATEDFIELD) {
            const calcatedField = field as CalculatedField
            return new CalculatedFieldImpl(calcatedField)
        }
        if(field.type === FieldType.DATETIME) {
            const dateTimeField = field as DateTimeField
            return new DateTimeFieldImpl(dateTimeField)
        }
        if(field.type === FieldType.DROPDOWN) {
            const dropdownField = field as DropdownField
            return new DropdownFieldImpl(dropdownField)
        }
        if(field.type === FieldType.NUMBER) {
            const numberField = field as NumberField
            return new NumberFieldImpl(numberField)
        }
        if(field.type === FieldType.CHECKBOX) {
            const checkboxField = field as CheckboxField
            return new CheckboxFieldImpl(checkboxField)
        }
        if(field.type === FieldType.DECIMAL) {
            const decimalField = field as DecimalField
            return new DecimalFieldImpl(decimalField)
        }
        if(field.type === FieldType.CURRENCY) {
            const currencyField = field as CurrencyField
            return new CurrencyFieldImpl(currencyField)
        }
        if(field.type === FieldType.LARGETEXT) {
            const largeTextField = field as LargeTextField
            return new LargeTextFieldImpl(largeTextField)
        }

        //TODO better error handling below
        // console.log('field with type ' + field.type + ' not found!')
    }
}