

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";

  export default {
    name: "BaseFieldText",
    components: {},
    mixins: [ BaseExtend ],
    data() {
      return {
      };
    },
    methods: {
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.formData.editingFields.push(this.name)
          if (!val) {
            val = '';
          }
          this.$emit('input', val);
        },
      }
    }
  };
