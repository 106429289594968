
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import { Dialog } from "quasar";
import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";

class Record {
    ID = "";
    name = "";
}

@Component({
    components: { BaseFieldRelated }
})
export default class AuditLogsExport extends Vue {
    @State("dynamicModules") dynamicModules;
    @Action("api/getAuditLogs") getAuditLogs;
    logsData = Array<{}>();
    moduleData = {};
    loadingLogsData = false;
    selectedModule = '';
    selectedRecord = new Record;
    modulesList = Array<string>();
    recordSelectKey = 1;
    dateRange = {from: "", to: ""};

    async getLogs() {
        let params: any;        
        if(typeof this.dateRange == "string") {
          params = {
            module: this.selectedModule,
              startDate: this.dateRange,
              endDate: this.dateRange
          };
        } else if (!this.selectedModule || this.selectedModule == "") {
          const message: any = this.$t('auditLogs.messages.pleaseSelectModule')
          this.$q.notify({
            color: "negative",
            message: message,
          });
        } else if(!this.dateRange.from || !this.dateRange.to) {
          const message: any = this.$t('auditLogs.messages.pleaseSelectDate')
          this.$q.notify({
            color: "negative",
            message: message,
          });
        } else {
          params = {
            module: this.selectedModule,
              startDate: this.dateRange.from,
              endDate: this.dateRange.to
          };
        }
        if (params){
          this.logsData = await this.getAuditLogs(params);
          this.loadingLogsData = true;
        }
    }

    geModulesList () {
        this.modulesList = Object.keys(this.dynamicModules);
    }

    async onSelectModule () {
        this.initLogsData();
        this.selectedRecord = new Record;
        this.loadingLogsData = false;
        this.moduleData = await PdfTools.getFormDataForPDF(this.selectedModule);
        this.recordSelectKey++;
    }

    initLogsData(){
        this.logsData = Array<{}>()
    }

    fileNameInput() {
        Dialog.create({
            title: 'File name',
            message: 'Type a file name',
            prompt: {
                model: '',
                isValid: val => val.trim().length > 0,
                type: 'text', // optional
                outlined: true
            },
            cancel: true,
            persistent: true
        })
            .onOk(name => {
                const fileName = name + '.json';
                const contentType = 'application/json';
                this.saveDataToFile(fileName, contentType) //'text/plain'
            })
    }

    saveDataToFile(filename, contentType) {
        const a = document.createElement("a");
        const file = new Blob(
            [JSON.stringify(this.logsData)],
            {type: contentType
            });
        a.href = window.URL.createObjectURL(file);
        a.download = filename;
        a.click();
    }

    async mounted() {
        this.geModulesList();

        /*this.loading = true;
        await this.getLogs();
        this.loading = false;*/
    }
}
