
  import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
  import BaseFieldText from "@/components/Base/BaseFieldText.vue";
  import BaseFieldAutoNumber from "@/components/Base/BaseFieldAutoNumber.vue";
  import BaseFieldNumber from "@/components/Base/BaseFieldNumber.vue";
  import BaseFieldCurrency from "@/components/Base/BaseFieldCurrency.vue";
  import BaseFieldPercentage from "@/components/Base/BaseFieldPercentage.vue";
  import BaseFieldDate from "@/components/Base/BaseFieldDate.vue";
  import BaseFieldDateTime from "@/components/Base/BaseFieldDateTime.vue";
  import BaseFieldDropDown from "@/components/Base/BaseFieldDropDown.vue";
  import BaseFieldStatus from "@/components/Base/BaseFieldStatus.vue";
  import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
  import BaseFieldRelatedMultiSelect from "@/components/Base/BaseFieldRelatedMultiSelect.vue";
  import BaseFieldCheckbox from "@/components/Base/BaseFieldCheckbox.vue";
  import BaseFieldLargeText from "@/components/Base/BaseFieldLargeText.vue";
  import BaseFieldDecimal from "@/components/Base/BaseFieldDecimal.vue";
  import BaseFieldImage from "@/components/Base/BaseFieldImage.vue";
  import BaseFieldSignature from "@/components/Base/BaseFieldSignature.vue";
  import BaseFieldCalculated from "@/components/Base/BaseFieldCalculated.vue";
  import ListValues from "@/components/Mixin/ListValues";
import store from '@/store';

  @Component({
    components: {BaseFieldText, BaseFieldNumber, BaseFieldCurrency, BaseFieldPercentage,
      BaseFieldDate, BaseFieldDateTime, BaseFieldAutoNumber, BaseFieldCalculated,
      BaseFieldRelated, BaseFieldDropDown, BaseFieldStatus,
      BaseFieldCheckbox, BaseFieldLargeText, BaseFieldDecimal,
      BaseFieldRelatedMultiSelect, BaseFieldImage, BaseFieldSignature
    },
  })
  export default class DynamicField extends Vue {
    @Prop(String) name;
    @Prop(String) type;
    @Prop(String) module;
    @Prop(Object) formData;
    // or
    @Prop(Object) field;
    @Prop(Object) section;
    @Prop(Object) lineSection;
    @Prop(Boolean) subFormMode;
    @Prop(Boolean) alwaysMandatory;

    highLight = false;
    dialog = false
    modalStep = null

    calculatedFieldTypes = {
      text: 'BaseFieldText',
      number: 'BaseFieldNumber',
      percentage: 'BaseFieldPercentage',
      currency: 'BaseFieldCurrency',
      date: 'BaseFieldDate',
      datetime: 'BaseFieldDateTime',
      dropdown: 'BaseFieldDropDown',
      relatedField: 'BaseFieldRelated',
      relatedMultiSelect: 'BaseFieldRelatedMultiSelect',
      checkbox: 'BaseFieldCheckbox',
      largeText: 'BaseFieldLargeText',
      decimal: 'BaseFieldDecimal',
    };

    mounted() {
      const field = document.getElementById('field');
      const app = this
      field.onclick = function() {
        app.copyFieldName()
      };
    }

    copyFieldName() {
      if (store.state.debugMode) {
        navigator.clipboard.writeText(this.fname);
      }
    }

    get fieldTypeComponent() {
      const calcType = this.fieldType && this.calculatedFieldTypes[this.fieldType] ? this.fieldType : 'text';
      return this.calculatedFieldTypes[calcType];
    }

    get flabel() {
      let label: string;
      if(store.state.fieldTranslations && store.state.fieldTranslations[this.formData.module + '.fields.' + this.field.name]) {
        label = store.state.fieldTranslations[this.formData.module + '.fields.' + this.field.name]
      }
      return label ? label : (this.field.label || this.field.name)
    }
    get fname() {
        return this.field ? this.field.name : this.name;
    }
    get ftype() {
        const ftype = this.field ? this.field.type : this.type;
        if (!ListValues.allTypingField(ftype)) {
          console.error('Type not found "'+ ftype +'", if you add new one, check the ListValues.allTypingField()');
        }
        return ftype;
    }
    get fieldType() {
      return this.field ? this.field.fieldType : '';
    }
    get ftooltip() {
      return this.field ? this.field.tooltip : '';
    }
    get fShowTooltip() {
      return this.field ? this.field.showTooltip : '';
    }
    get showTooltip() {
      return this.fShowTooltip && this.ftooltip;
    }
    get dropDownOptions() {
      const dropdownValues = []
      for(const dropdownValue of this.formData.dropDownValues[this.fname]) {
        const translationKey = `${this.formData.module}.${this.field.name}.${dropdownValue}`
        if(store.state && store.state.dropDownMap && store.state.dropDownMap[translationKey]) {
          dropdownValues.push(store.state.dropDownMap[translationKey])
        } else {
          dropdownValues.push(dropdownValue)
        }
      }
      return dropdownValues
    }
    get statusOptions() {
      return Object.keys(this.field.metaData).map(e => this.field.metaData[e].label || e)
    }
    get relatedModule() {
        return this.field ? this.field.relatedModule : this.module;
    }
    get relatedFieldCopy() {
        return this.field ? this.field.relatedFieldCopy : null;
    }
    get relatedToByQuery() {
        return this.field ? this.field.relatedToByQuery : null;
    }
    get relatedQuery() {
        return this.field ? this.field.query : null;
    }
    get relatedToField() {
      return this.field && this.relatedToByQuery ? this.dynFields[this.relatedToByQuery.relatedTo] : null;
    }
    get dynFields() {
      return this.formData && this.formData.dynamicFields ? this.formData.dynamicFields : {};
    }
    get isHidden() {
      if (this.lineSection && this.lineSection.visibleFields) {
        return this.lineSection.visibleFields.indexOf(this.field.name) === -1;
      }
      return this.field.hidden;
    }
    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
    addFieldToValidationList(data) {
      this.$emit('addFieldToValidationList', data);
    }
    focusToNextField(name){
      this.$emit('focusToNextField', name)
    }

    onBaseFieldChanged() {
      this.$emit('baseFieldChanged', this.field)
    }

    setUnsavedData() {
      this.$emit('setUnsavedData')
    }

    @Watch('formData.changedFields', {deep: true})
    handleHighLight() {
        if (this.formData.changedFields.includes(this.fname)) {
            this.highLight = true;
            setTimeout(() => this.highLight = false, 5000);
            const index = this.formData.changedFields.indexOf(this.fname);
            if (index > -1) {
                this.formData.changedFields.splice(index, 1);
            }
        }
    }
  }
