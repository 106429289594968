import FormDataTools from "@/components/Mixin/FormDataTools";
import store from "@/store";
import firebase from '@/firebaseApp';
import functions from "@/functions";
import {ConverterHelperV2} from '@yellowq-software/email'
import ListValues from "@/components/Mixin/ListValues";

export default class PdfTools {

  static converter = new ConverterHelperV2(firebase);

  static init() {
    const tz = store.state.timezone;
    PdfTools.converter.setTimeZone(tz);
    if (store.state.useSummerOffset) {
      PdfTools.converter.useSummerTimeOffset();
    }
  }

  static async getFormDataForPDF(module, ID = '') {
    const tools = new FormDataTools(module);
    await tools.load(ID);
    return tools.getFormData();
  }

  static async getInputJsonForPDF(formData, mediaFieldName = '', mediaData = []) {
    let tData = PdfTools.converter.convertToObject(formData.data, 'ISO-8601');
    // filter media
    if (!mediaFieldName) {
      for (const df in formData.dynamicSections) {
        if (formData.dynamicSections[df].type === 'media') {
          mediaFieldName = df;
          mediaData = formData.data[df].slice();
        }
      }
    }

    if (mediaFieldName) {
      tData[mediaFieldName] = mediaData.slice();
      tData[mediaFieldName + 1] = tData[mediaFieldName].filter(m => m.stage == 1);
      tData[mediaFieldName + 2] = tData[mediaFieldName].filter(m => m.stage == 2);
      tData[mediaFieldName + 3] = tData[mediaFieldName].filter(m => m.stage == 3);
    }
    // end media

    tData = PdfTools.converter.convertToObject(tData, 'ISO-8601');
    
    tData = ListValues.transformDiv10k(tData, formData);
    
    return tData;
  }

  // get full data (related records, fields and lines)
  static async getFullJsonData(formData, mediaFieldName = '', mediaData = []) {
    const formDataFull = await PdfTools.getFormDataForPDF(formData.module, formData.data.ID);
    
    //const inputJson = [];
    const inputJson = await PdfTools.getInputJsonForPDF(formDataFull, mediaFieldName, mediaData);
    
    return inputJson;
  }

  static async getModuleTemplates(module) {
    // TODO dev mode
    //functions.useFunctionsEmulator('http://localhost:5001');
    //
    const generatePDF = functions.httpsCallable('GeneratePDF');
    let templates = [];
    const options = {
      tenant: store.state.tenantID,
      module: module,
      request: 'getModuleTemplates'
    };

    await generatePDF(options).then((res: any) => {
      const data = res.data;
      templates = data;
    }).catch(error => {
      console.log('error: ', error);
      templates = [];
    });
    for(const template of templates) {
      template.fileType = 'pdf'
      template.name = '(PDF) - ' + template.name
    }
    return templates;
  }
}
